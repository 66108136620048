import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { Field, Components } from "@ais3p/ui-framework";
import Condition from "../condition/Condition";
import { CONDITION_EQUAL } from "../../constants/condtions";

import SearchStore from "../../stores/searchStore";

/**
 * @component
 * 
 * Контрол для задания условия поиска по атрибуту
 *
 * @param {Object} params набор параметров
 * @param {String} params.label заголовок поля ввода
 * @param {String} params.uid uid значения поля
 * @param {Any} params.value значение поля ввода
 * @param {Any} params.deafultValue значение поля ввода по умолчанию. Устанавлвается при сбросе
 * @param {Boolean} params.isMultiple можно задать несколько значений
 * @param {String} params.type тип поля ввода
 * @param {Array<Object>} params.options набор параметров для выпадающего списка
 * @param {Array<String>} params.conditions набор доступных занчений для сравнения
 * @param {String} params.placeholder текстовая подсказка при отсутствии значения в поле ввода
 * @param {SearchStore} params.store хранилизе для поиска
 * @param {Functiun} params.onChange callback ф-я при изменении значения поля ввода
 * 
 * @returns
 */
const FieldItem = ({
  label,
  uid,
  value,
  isMultiple = false,
  type = "string",
  options = [],
  conditions,
  placeholder,
  store,
  defaultValue,
  onChange
}) => {
  const condition = useMemo(() => {
    if (value && value.condition) {
      return value.condition;
    }
    // return type === "enum" ? CONDITION_IN : CONDITION_EQUAL;
    return CONDITION_EQUAL;
  }, [value && value.condition, type]);

  const val = value && value.value;

  const onFieldChange = useCallback(
    (value) => {
      onChange && onChange(uid, { value, condition, label, type });
    },
    [uid, condition, label, type, onChange]
  );

  const onChangeCondition = useCallback(
    (cond) => {
      onChange && onChange(uid, { value: val, condition: cond, label, type });
    },
    [uid, val, label, type, onChange]
  );

  const onClear = useCallback(() => {
    onChange && onChange(uid, { value: defaultValue, condition, label, type });
  }, [uid, condition, label, type, onChange]);

  const fieldProps = useMemo(() => {
    return {
      icon:        "",
      name:        uid,
      value:       val,
      isRequired:  false,
      multiselect: isMultiple,
      options,
      id:          uid,
      labelOnTop:  true,
      type,
      placeholder,
      size:        2,
      label,
      onChange:    onFieldChange
    };
  }, [uid, value, label, options, isMultiple, onChange]);

  const field = useMemo(() => {
    if (type === "textarea") {
      return <Field.TextArea {...fieldProps} />;
    } else if (type === "string") {
      return <Field.String {...fieldProps} autocomplete="on" />;
    } else if (type === "integer") {
      return <Field.Number {...fieldProps} autocomplete="on" />;
    } else if (type === "float") {
      return <Field.Float {...fieldProps} autocomplete="on" />;
    } else if (type === "datetime") {
      return <Field.DateTime {...fieldProps} />;
    } else if (type === "enum" && !isMultiple) {
      return <Field.SingleSelect {...fieldProps} />;
    } else if (type === "enum" && isMultiple) {
      return <Field.MultiSelect {...fieldProps} />;
    } else if (type === "boolean") {
      return <Field.Boolean {...fieldProps} />;
    }
  }, [type, fieldProps]);

  return (
    <div className="filter-form-body-item">
      <Condition
        value={condition}
        conditions={conditions}
        type={type}
        store={store}
        onChange={onChangeCondition}
      />
      {field}
      <div className="filter-form-body-item-clear">
        <Components.Icon
          {...{
            "data-tooltip": "Очистить поле"
          }}
          onClick={onClear}
          name={"clean-M"}
        />
      </div>
    </div>
  );
};

FieldItem.propTypes = {
  label:        PropTypes.string,
  uid:          PropTypes.string,
  value:        PropTypes.any,
  defaultValue: PropTypes.any,
  isMultiple:   PropTypes.bool,
  type:         PropTypes.string,
  options:      PropTypes.arrayOf(Object),
  conditions:   PropTypes.arrayOf(String),
  placeholder:  PropTypes.string,
  store:        PropTypes.instanceOf(SearchStore), 
  onChange:     PropTypes.func
};


export default FieldItem;
