import { observer } from "mobx-react";
import React, { useCallback, useMemo, useLayoutEffect, useRef } from "react";
import PlusMenuItem from "./PlusMenuItem";

const PlusMenu = observer(({ data }) => {
  const { plusMenuItems, isExpanded, isVersion } = data;

  const onItemClick = useCallback(
    (item, kind) => {
      data.setExpanded(false);
      data.createItem(item, kind);
    },
    [data]
  );

  const elementRef = useRef();

  useLayoutEffect(() => {
    if (isExpanded && elementRef && elementRef.current) {
      const rec = elementRef.current.getBoundingClientRect();
      if (
        rec.top < 0 ||
        rec.bottom >
          (window.innerHeight || document.documentElement.clientHeight)
      ) {
        elementRef.current.scrollIntoView({
          block:    "center",
          behavior: "smooth"
        });
        setTimeout(() => {
          data.setExpanded(true);
        }, 300);
      }
    }
  }, [isExpanded, elementRef]);

  const menuItems = useMemo(() => {
    if (!isExpanded) {
      return [];
    }
    return plusMenuItems.map((item) => {
      return (
        <PlusMenuItem
          key={`${item.class}-${item.title}`}
          item={item}
          onItemClick={onItemClick}
        />
      );
    });
  }, [plusMenuItems, isExpanded]);

  if (isVersion || !plusMenuItems || plusMenuItems.length < 1) {
    return null;
  }

  return (
    <div ref={elementRef} className="plus-holder">
      <div className={`menu-items-holder ${isExpanded ? "expanded" : ""}`}>
        {isExpanded && menuItems}
      </div>
    </div>
  );
});

export default PlusMenu;
