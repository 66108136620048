import React, { useCallback, useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react";
import useStores from "~/core/utils/useStores";
import "../css/index.scss";
import KindView from "./KindView";
import { Components, Field, Modal } from "@ais3p/ui-framework";

/**
 * Компонент - мастер создания объектов
 * Конфигурация берется из uiStore.wizardConfig 
 */

const WizardTool = observer(() => {
  const { uiStore } = useStores();
  const [isPending, setIsPending] = useState(false);
  const { wizardConfig } = uiStore;
  const { icon = "wizard-M", config, id, label, onSuccess, onCancel, parent } = wizardConfig || {}; ;
  const { instantConfirm, views, initialName, withName, nameTitle, className } = config || {}; ;
  const [page, setPage] = useState(0);
  const [name, setName] = useState(initialName || "");
  const [isFormValid, setFormValid] = useState(false);

  const data = useMemo(() => {
    if (wizardConfig) {
      return { name, id };
    }
  }, [name, id, wizardConfig]);

  const onConfirm = useCallback(async() => {
    setIsPending(true);
    if (wizardConfig && onSuccess) {
      await onSuccess(
        data, parent, className); // TODO: send some data
    }
    setIsPending(false);
    uiStore.hideWizard();
    setName("");
    setPage(0);
  }, [wizardConfig, data, uiStore, onSuccess, parent, className]);

  const onPrev = useCallback(() => {
    setPage((prevPage) => {
      return prevPage - 1; 
    });
  }, [setPage]);

  const onNext = useCallback(() => {
    setPage((prevPage) => {
      return prevPage + 1; 
    });
  }, [setPage]);

  const onAbort = useCallback(() => {
    if (wizardConfig && onCancel) {
      onCancel(id);
    }
    setIsPending(false);
    uiStore.hideWizard();
    setName("");
    setPage(0);
  }, [wizardConfig, uiStore, onCancel]);

  const onChangeName = useCallback(
    (name) => {
      setName(name);
    },
    [setName]
  );

  useEffect(() => {
    if (wizardConfig && instantConfirm) {
      onConfirm();
    }
  }, [wizardConfig, onConfirm, instantConfirm]);
  
  const checkValidityForm = useCallback((isValid) => {
    setFormValid(isValid);
  }, []);

  const pagesLength = useMemo(() => {
    return wizardConfig && views.length;
  }, [wizardConfig, views]);

  const canNext = useMemo(() => {
    return page < pagesLength;
  }, [pagesLength, page]);

  const isConfirmEnabled = useMemo(() => {
    if (wizardConfig) {
      return (!withName || !!name) && isFormValid;
    }
  }, [wizardConfig, name, isFormValid]);

  const hint = useMemo(() => {
    if (!name) {
      return "Это поле - обязательное!";
    }
    return;
  }, [name]);

  const nameRender = useMemo(() => {
    if (wizardConfig && !withName) {
      return null;
    }
    return (
      <div className="name-holder">
        <Field.String
          icon={"data-string-M"}
          key={"name"}
          name={"name"}
          labelOnTop={true}
          label={wizardConfig && nameTitle || "Наименование"}
          onChange={onChangeName}
          value={name}
          isValid={!!name}
          hint={hint}
        />
      </div>
    );
  }, [withName, wizardConfig, onChangeName, name, nameTitle]);

  const pageRender = useMemo(() => {
    if (wizardConfig) {
      const viewConfig = views[page];
      if (!viewConfig) {
        return null;
      }
      const { type, kindId } = viewConfig;
      if (type === "kind") {
        return (
          <div className="page-holder">
            <KindView uid={id} kindId={kindId} checkValidityForm={checkValidityForm} />
          </div>
        );
      }
      return null;
    }
  }, [page, wizardConfig, views]);

  if (wizardConfig && wizardConfig.config.instantConfirm) {
    return (
      <div className={"wizard-tool-holder"}>
        <div className="wizard-tool-content">
          <div style={{ margin: "auto" }}>
            {isPending && <Components.Preloader size={3} />}
          </div>
        </div>
      </div>
    );
  }

  const buttons = [];
  if (pagesLength > 1) {
    buttons.push(
      <Components.Button
        isDisabled={page < 1}
        icon="chevron-left-M"
        text="Назад"
        onPress={onPrev}
        isLoading={isPending}
        key="prevButton"
      />, 
      <Components.Button
        isDisabled={!canNext}
        rightIcon="chevron-right-M"
        text="Далее"
        onPress={onNext}
        isLoading={isPending}
        key="nextButton"
      />
    );
  }
  buttons.push(
    <Components.Button
      icon="save-M"
      color="positive"
      text="Сохранить"
      onPress={onConfirm}
      isDisabled={!isConfirmEnabled}
      isLoading={isPending}
      key="saveButton"
    />,
    <Components.Button
      icon="cancel-M"
      color="dark"
      text="Отменить"
      isDisabled={isPending}
      onPress={onAbort}
      isLoading={isPending}
      key="cancelButton"
    />
  );
  if (!wizardConfig) {
    return <div className={"app-wizard"}></div>;
  }
  return (
    <Modal.Window
      name="wizard"
      show={!!wizardConfig}
      icon={icon}
      title={wizardConfig && label}
      buttons={buttons}
      onKeyPressEnter={isConfirmEnabled && onConfirm}
      onKeyPressEsc={onAbort}
    >
      <div className="wizard-tool-content">
        {nameRender}
        {pageRender}
      </div>
    </Modal.Window>
  );
}
);

export default WizardTool;
