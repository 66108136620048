import { observer } from "mobx-react";
import React, { useCallback, useLayoutEffect, useRef } from "react";
import Indicator from "../../../core/components/Indicator";
import TextItemIcon from "./TextItemIcon";

const RubricTreeItem = observer(({ item, store, setContextMenu }) => {
  const { level, number, title, uid } = item;
  const { focusUid } = store;

  const element = useRef();

  const onClick = useCallback(() => {
    store.scrollToItemById(uid);
    store.setFocusUid(uid);
  }, [uid]);

  useLayoutEffect(() => {
    if (item && item.uid && focusUid && item.uid === focusUid && element && element.current) {
      if (element && element.current && element.current.scrollIntoViewIfNeeded) {
        element.current.scrollIntoViewIfNeeded(true);
      }
    }
  }, [item && item.uid, focusUid, element && element.current]);

  return (
    <div
      className={`tree-rubric ${item.uid === focusUid ? "focused" : ""}`}
      ref={element} 
      onClick={onClick}
    >
      <div
        className="rubric-tree-spacer" 
        style={{ width: `${(level - 1) * 1.5}rem` }}
      ></div>
      <div className="vertical-wrapper">
        <div className="icon-number-holder">
          <Indicator data={item} />
          <TextItemIcon onClick={onClick} setContextMenu={setContextMenu} data={item} />
          <div className="number">{number}</div>
        </div>
      </div>
      <div className="title-wrapper">
        <div className="title">{title}</div>
      </div>
    </div>
  );
});

export default RubricTreeItem;
