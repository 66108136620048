import React, { Fragment, useCallback, useMemo, useState } from "react";
import { observer } from "mobx-react";
import UserAvatar from "~/modules/users/components/avatar";

import Target from "~/core/components/Target";
import useStores from "~/core/utils/useStores";
import AisIcon from "~/core/components/AisIcon";
import "../css/index.scss";
import { Components, Field } from "@ais3p/ui-framework";

const ValidationSidepanel = observer((props) => {
  const { objectStore } = useStores();
  const { uid, version, trackedItem } = props;

  const [isFormVisible, setIsFormVisible] = useState(false);
  const [comment, setComment] = useState("");

  const aggr = useMemo(() => {
    if (trackedItem) {
      let domain = trackedItem.domain || trackedItem.tool;
      if (!domain && trackedItem.class) {
        domain = trackedItem.class.split(".")[0];
      }
      return objectStore.getVersion(
        trackedItem.uid,
        domain,
        trackedItem.version
      );
    }
    return trackedItem;
  }, [trackedItem, uid, objectStore]);

  const onToggleForm = useCallback(() => {
    setIsFormVisible(!isFormVisible);
  }, [isFormVisible, setIsFormVisible]);

  const onChangeComment = useCallback((comment) => {
    setComment(comment);
  }, [setComment]);

  const onValidate = useCallback(async() => {
    if (aggr) {
      await aggr.validate("accepted", comment);
      setComment("");
      setIsFormVisible(false);
    }
  }, [uid, aggr, comment]);
  
  const onReject = useCallback(async() => {
    if (aggr) {
      await aggr.validate("rejected", comment);
      setComment("");
      setIsFormVisible(false);
    }
  }, [uid, aggr, comment]);
  
  const content = useMemo(() => {
    if (!aggr) {
      return (
        <div className="preload-wrapper">
          <Components.Preloader
            size={3}
          />
        </div>
      );
    }

    if (!aggr.isValidatable) {
      return (
        <div className="header">
          <span className="text-holder">Объект не может быть согласован</span>
        </div>
      );
    }

    return (
      <div className="validation-list">
        <div className="user-list">
          {aggr.validatorsList.map((user) => {
            return (
              <Fragment key={user.uid}>
                <div key={user.uid} className={`user-holder ${user.isValidated}`}>
                  <UserAvatar className="image" fileId={user.image} />
                  <div className="user-name">{user.label}</div>
                  {user.isMe && !user.isValidated && (
                    <Components.Button
                      onPress={onToggleForm}
                      size={2}
                      color={isFormVisible ? "negative" : "action"}
                      icon={isFormVisible ? "close-M" : "signal-M"}
                    />
                  )}
                  {user.isValidated && (
                    <Components.Icon
                      {...{
                        "data-tooltip": user.isValidated === "rejected"
                          ? `Отклонено${user.approval && user.approval.comment ? `: ${user.approval.comment}` : ""}`
                          : `Согласовано${user.approval && user.approval.comment ? `: ${user.approval.comment}` : ""}`,
                        "data-tooltip-at": props["data-tooltip-at"]
                      }}
                      className={`expander big validate ${user.isValidated}`}
                      name={user.isValidated === "rejected" ? "close-M" : "ok-M"}
                    />
                  )}
                  {!user.isMe && !user.isValidated && (
                    <Components.Icon
                      {...{
                        "data-tooltip":    "На согласовании",
                        "data-tooltip-at": props["data-tooltip-at"]
                      }}
                      className={`expander big validate ${"in-progress"}`}
                      name={true}
                      icon={"processing-M"}
                    />
                  )}
                </div>
                {isFormVisible && user.isMe && (
                  <div className="form-holder">
                    <div className="comment-holder">
                      <div className="kind-name">{"Комментарий"}</div>
                      <Field.String
                        leftIcon={<AisIcon icon={"data-string-M"} />}
                        key={"name"}
                        id={"name"}
                        labelOnTop={true}
                        onChange={onChangeComment}
                        value={comment}
                      />
                    </div>
                    <div className="buttons-holder">
                      <Components.Button
                        loadin={aggr.pendingValidation}
                        onPress={onValidate}
                        size={2}
                        color={"positive"}
                        text="Согласовать"
                        icon={"ok-M"}
                      />
                      <Components.Button
                        loadin={aggr.pendingValidation}
                        onPress={onReject}
                        size={2}
                        color={"negative"}
                        text="Отклонить"
                        icon={"close-M"}
                      />
                    </div>
                  </div>
                )}
              </Fragment>
            );
          })}
        </div>
        {!!aggr.isValidated &&  (
          <div className="header">
            <Components.Icon
              {...{
                "data-tooltip": aggr.isValidated === "rejected"
                  ? "Отклонен"
                  : "Согласовано",
                "data-tooltip-at": props["data-tooltip-at"]
              }}
              className={`expander big validate ${
                aggr.isValidated
              }`}
              name={aggr.isValidated === "rejected" ? "close-M" : "ok-M"}
            />
            <span className="text-holder">
              Объект {aggr.isValidated === "rejected" ? "отклонен" : "согласован"}
            </span>
          </div>
        )}
        {!aggr.isValidated &&  (
          <div className="header">
            <Components.Icon
              {...{
                "data-tooltip":    "На согласовании",
                "data-tooltip-at": props["data-tooltip-at"]
              }}
              className={"expander big validate in-progress"}
              name={"processing-M"}
            />
            <span className="text-holder">
              Объект на согласовании
            </span>
          </div>
        )}
      </div>
    );
  }, [aggr, uid, aggr && aggr.isValidated, isFormVisible, onToggleForm, onValidate, onReject, comment, onChangeComment]);

  return (
    <div className={"validation-tool-holder"}>
      <div className={"validation-tracker"}>
        <Target id={uid} version={version} trackedItem={trackedItem} />
        <div className="list-holder">
          <div className="items-holder">{content}</div>
        </div>
      </div>
    </div>
  );
});

export default ValidationSidepanel;
