import React from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import classNames from "classnames";
import { Components } from "@ais3p/ui-framework";

import TraceSchemaModel from "../../models/traceSchema";
import TraceStore from "../../stores/traceStore";

/**
 * Панель для отображения  строковй информаци о выставленных параметрах
 * 
 * @param {Object} props набор параметров
 * @param {String} props.className пользовательсий className
 * @param {TraceStore} props.store хранилище TraceStore
 * @param {TraceSchemaModel} props.schema выбранная схема
 */
const ShortInfo = observer(({ className, store, schema }) => {
  return (
    <div className={classNames("parameters-info", className)}>
      <div 
        className={classNames("items", {
          visible: !store.showSchemaParams
        })}
      >
        {
          !store.showSchemaParams && !schema &&
            <div className="parameter-item">
              <div className="label">Cхема не задана</div>
            </div>
        }
        {
          !store.showSchemaParams && schema && !schema.isValid &&
            <div className="parameter-item">
              <div className="label">Параметры схемы не заданы</div>
            </div>
        }
        { !store.showSchemaParams && schema && schema.isValid &&
          schema.items.map((item, i) => {
            if (item.schemaItemType === "link") {
              return (
                <div 
                  key={i}
                  className="schema-parameter-link"
                >
                  <Components.Icon
                    name={item.direction === "in" ? "arrow-left-M" : "arrow-right-M"}
                  />
                </div>
              );
            }
            if (item.schemaItemType === "object") {
              return (
                <div 
                  key={i}
                  className="schema-parameter-object"
                >
                  <span>{item.description}</span>
                </div>
              );
            }

            return null;
          })
        }
      </div>
    </div>
  );
});

ShortInfo.propTypes = {
  className: PropTypes.string,
  store:     PropTypes.instanceOf(TraceStore).isRequired,
  schema:    PropTypes.instanceOf(TraceSchemaModel)
};


export default ShortInfo;
