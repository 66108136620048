import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import classNames from "classnames";
import { fields } from "@ais3p/ui-framework";
import TraceStore from "../../stores/traceStore";
import TraceSchemaModel from "../../models/traceSchema";
const { SingleSelect } = fields;
/**
 * Панель для отображения набора базовых схем
 * 
 * @param {Object} props набор параметров
 * @param {String} props.className пользовательсий className
 * @param {TraceStore} props.store хранилище TraceStore
 * @param {TraceSchemaMode} props.value value значение выпадающего списка
 * @param {Function} props.onSelectSchema onSelectSchema callback ф-ия на выбор схемы изи списка 
 */
const Schemas = observer(({ className, store,  value, onSelectSchema }) => {
  const schemaOptions = useMemo(() => {
    return store.schemasList.map((item) => {
      return { label: item.title, value: item };
    });
  }, [store.schemasList]);
  
  const onSetSchema = useCallback((value) => {
    onSelectSchema && onSelectSchema(value);
  }, [onSelectSchema]);

  
  return (
    <div className={classNames("schema-name", className)}>
      <SingleSelect
        onChange={onSetSchema}
        value={value}
        isMulti={false}
        name="selectedSchema"
        label="Выберите схему трассировки"
        labelOnTop={true}
        options={schemaOptions}
        className="filter-select"
        classNamePrefix="select"
      />
    </div>
  ); 
});

Schemas.propTypes = {
  className:      PropTypes.string,
  store:          PropTypes.instanceOf(TraceStore).isRequired,
  value:          PropTypes.instanceOf(TraceSchemaModel),
  onSelectSchema: PropTypes.func.isRequired
};

export default Schemas;
