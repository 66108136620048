import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { Field } from "@ais3p/ui-framework";
import Attr from "../models/Attr";
import KindMember from "../models/KindMember";

/**
 * @component
 * 
 * Поле атрибута
 * 
 * @param {Attr} attr объект атрибута
 * @param {KindMember} member объект участника Вида
 * @param {Boolean} isDisabled флаг, указывающий, что поле disabled
 * @param {Boolean} isView флаг, указывающий, что форма только для просмотра
 * @param {String} theme @default "kindsAndAttrs"
 * @param {Function} onChange callback
 */
const AttrField = observer(
  ({
    attr,
    member,
    isView,
    isDisabled,
    theme = "kindsAndAttrs",
    onChange
  }) => {
    const { isMultiple, type, opts, isRequired, name, isReadonly, uid: attrUid } = attr;
    
    const memberValue = useMemo(() => {
      if (!member) {
        return null;
      }
      const vals = isView ? member.initValues : member.allValues;
      return vals[attrUid];
    }, [
      member,
      member && member.initValues,
      member && member.allValues,
      isView,
      attrUid
    ]);

    const value = useMemo(() => {
      let value = memberValue;
      if (
        value &&
        type === "enum"
      ) {
        if (!isMultiple && Array.isArray(value)) {
          value = memberValue[0];
        }
      } 

      return value;
    }, [memberValue, opts, isMultiple, type]);

    const validateValue = (value) => {
      if (!isRequired || type === "boolean") {
        return true; 
      }
      return Array.isArray(value) ? value.length > 0 : !!value;
    };
    
    const isValid = useMemo(() => {
      return  validateValue(value);
    }, [isRequired, value, type]);

    const hint = useMemo(() => {
      if (isRequired && !value && type !== "boolean") {
        return "Это поле - обязательное!";
      }
      return;
    }, [isRequired, value, type]);

    const onFieldChange = useCallback((value, name) => {
      onChange && onChange(value, name, validateValue(value));
    }, [attr, isValid]);

    
    const icon = useMemo(() => {
      return `data-${
        type === "enum"
          ? `${type}-${isMultiple ? "multi" : "single"}`
          : type
      }-M`;
    }, [type, isMultiple]);

    const fieldProps = useMemo(() => {
      return {
        ...attr,
        label:      name,        
        name:       attrUid,
        value,
        isDisabled: isDisabled || (isReadonly && member && member.hasUid),
        isValid:    isValid === true ? true : isValid,
        options:    opts,
        hint,
        icon,
        theme,
        onChange:   onFieldChange
      };
    }, [
      attr,
      name,
      value,
      isValid,
      isDisabled,
      isReadonly,
      member && member.hasUid,
      isRequired,
      isMultiple,
      hint,
      opts,
      icon,
      theme,
      onFieldChange
    ]);
    
    if (type === "textarea") {
      return <Field.TextArea {...fieldProps} />;
    } else if (type === "string") {
      return <Field.String {...fieldProps}  />;
    } else if (type === "integer") {
      return <Field.Number {...fieldProps} />;
    } else if (type === "float") {
      return <Field.Float {...fieldProps} />;
    } else if (type === "datetime") {
      return <Field.DateTime {...fieldProps} />;
    } else if (type === "enum" && !isMultiple) {
      return <Field.SingleSelect {...fieldProps} placeholder="Выберите" />;
    } else if (type === "enum" && isMultiple) {
      return <Field.MultiSelect {...fieldProps} placeholder="Выберите" />;
    } else if (type === "boolean") {
      return <Field.Boolean {...fieldProps} />;
    }

    return null;
  }
);

AttrField.propTypes = {
  attr:       PropTypes.instanceOf(Attr).isRequired,
  member:     PropTypes.instanceOf(KindMember),
  isView:     PropTypes.bool,
  isDisabled: PropTypes.bool,
  theme:      PropTypes.string,
  onChange:   PropTypes.func
};

export default AttrField;
