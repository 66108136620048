import { observable, computed, action } from "mobx";
import WorkflowApi from "../api/WorkflowApi";
import Machine from "../models/machine";
import Workflow from "../models/workflow";

/**
 * Хранилище для работы с Workflow
 * 
 * @class WorkflowStore
 */
class WorkflowStore {
 /**
 * @type {Map<Machine>}
 * набор машин, доступных для инсрумента workflow
 */
 @observable machines = new Map();

 /**
 * @type {Map<Workflow>}
 * набор запущенных workflow для отслеживаемого элемента
 */
 @observable workflows = new Map();

 /**
 * @type {Boolean}
 * флаг загрузки
 */
 @observable isPendingObjects = false;

 /**
 * @type {RootStore}
 * указатель на корневое хранилище
 */
 @observable rootStore = null;

 /**
 * @type {Object}
 * машина, для которой вызывается диаграмма состояний в модальном окне
 */
 @observable diagramMachine = null;

 /**
 * @type {String}
 * указатель направления отображения диаграммы состояний в модальном окне
 */
 @observable diagramDirection = "LR";

 /**
 * @type {Object}
 * пути, для которых вызывается диаграмма состояний в модальном окне
 */
 @observable diagramWorkflow = null;

 constructor(root) {
   this.rootStore = root;
   this.api = new WorkflowApi(this.rootStore);
 }
 @action
 setIsPendingObjects(pending = false) {
   this.isPendingObjects = pending;
 }

@action
 async getMachines() {
   this.setIsPendingObjects(true);
   this.machines.clear();

   let objectsData;
   try {
     objectsData = await this.api.getMachines();
   } catch (error) {
     this.rootStore.setErrorText(error.message);
   } finally {
     this.setIsPendingObjects(false);
   }
   if (!objectsData || objectsData.length === 0) {
     return;
   } else {
     this.processMachines(objectsData);
   }
 }

processMachines(machinesData) {
  machinesData.forEach((machine) => {
    this.machines.set(machine.id, new Machine(machine));
  });
}

getMachinesValues() {
  return Array.from(this.machines.values());
}

@action
setWorkflows(workflowData) {
  const workflow = Array.isArray(workflowData) ? workflowData : [workflowData];
  workflow.forEach((data) => {
    if (!this.workflows.has(data.id)) {
      this.workflows.set(data.id, new Workflow(data, this.machines));
    }
  });
}

@action
async getWorkflows({ uid, version }) {
  this.setIsPendingObjects(true);
  this.workflows.clear();
  let workflowData;
  try {
    workflowData = await this.api.getWorkflows({ uid, version });
    this.setWorkflows(workflowData);
  } catch (error) {
    console.warn(error);
    // this.rootStore.setErrorText(error.message);
  } finally {
    this.setIsPendingObjects(false);
  }
  if (!workflowData || workflowData.length === 0) {
    return;
  } else {
    this.setWorkflows(workflowData);
  }
}

@action
async createWorkflow({ uid, version }, machineId) {
  this.setIsPendingObjects(true);
  let workflowData;
  try {
    workflowData = await this.api.createWorkflow({ uid, version }, machineId);
  } catch (error) {
    this.rootStore.setErrorText(error.message);
  } finally {
    this.setIsPendingObjects(false);
  }
  if (!workflowData || workflowData.length === 0) {
    return;
  } else {
    this.setWorkflows(workflowData);
  }
}

@action
async updateWorkflow(workflowId, slug) {
  this.setIsPendingObjects(true);
  let workflowData;
  try {
    workflowData = await this.api.updateWorkflow(workflowId, slug);
  } catch (error) {
    this.rootStore.setErrorText(error.message);
  } finally {
    this.setIsPendingObjects(false);
  }
  if (!workflowData || workflowData.length === 0) {
    return;
  } else {
    this.updateWorkflowData(workflowData);
  }
}

updateWorkflowData(workflowData) {
  if (workflowData && this.workflows.has(workflowData.id)) {
    const existingWorkflow = this.workflows.get(workflowData.id);
    existingWorkflow.updatePoints(workflowData);
  }
}

@computed
get isPending() {
  return this.isPendingObjects;
}

@action setDiagramMachine(machine) {
  this.diagramMachine = machine;
}

@action setDiagramWorkflow(workflow) {
  this.diagramWorkflow = workflow;
}

@action diagramClear() {
  this.diagramMachine = null;
  this.diagramDirection = "LR";
  this.diagramWorkflow = null;
}

@action setDiagramDirection(value) {
  this.diagramDirection = value;
}
}

export default WorkflowStore;
