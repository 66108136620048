import { observable, computed, action } from "mobx";
import {
  IssueModel,
  StatusModel,
  PriorityModel,
  AttachmentModel,
  TrackerModel,
  UserModel as RedmineUserModel,
  ProjectModel as RedmineProjectModel
} from "../models";

import { ISSUES_VIEW_TABLE } from "../constants/issuesViews";
import IssueApi from "../api/issueApi";
import moment from "moment";

import { MODULE_NAME_CFG, FILTERS_CFG } from "../constants/config";

/**
 * Хранилище для работы с задачами
 *
 * @class IssueStore
 */
class IssueStore {
  /**
   * Cписок задач
   *
   * @type {Map <IssueModel>}
   */
  @observable
  issues = new Map(); //

  /**
   * Cписок открытых задач в кладках
   *
   * @type {Map <IssueModel>}
   */
  @observable
  // openedIssues = new Map(); // убрал map, тк начинаются проблемы когда создается новая задача без uid.
  // Когда задача создается, то уже появлется uid и нужно тогда обновлять map. Пока сделал на array
  openedIssues = []; // список открытых задач

  /**
   * Cписок приоритетов у задачи
   *
   * @type {Map <PriorityModel>}
   */
  @observable
  priorities = new Map();

  /**
   * Cписок стутусов задачи
   *
   * @type {Map <StatusModel>}
   */
  @observable
  statuses = new Map();

  /**
   * Cписок трекеров задачи
   *
   * @type {Map <TrackerModel>}
   */
  @observable
  trackers = new Map();

  /**
   * Cписок пользователей Redmine
   *
   * @type {Map <RemineUserModel>}
   */
  @observable
  users = new Map();

  /**
   * Cписок доступных проектов из Redmine
   *
   * @type {Map <RedmineProjectModel>}
   */
  @observable
  projects = new Map();

  /**
   * Флаг, указывающий, что идет обработка/загрузка данных
   *
   * @type {Boolean}
   */
  @observable
  processing = false;

  /**
   * Флаг, указывающий, что идет инициалиация хранилища - загруза небходимых данных
   *
   * @type {Boolean}
   */
  @observable
  isInitialization = false;

  /**
   * uid текущего проекта
   *
   * @type {String}
   */
  @observable
  currentProjectUid = undefined;

  /**
   * Режим только чтение
   *
   * @type {Boolean}
   */
  @observable
  readOnly = true;

  /**
   * Отображать ли панель фильтров
   *
   * @type {Boolean}
   */
  @observable
  isShownFilters = false;

  /**
   * Отображать ли панель истории
   *
   * @type {Boolean}
   */
  @observable
  isShownJournal = false;

  /**
   * Отображать ли панель со вкладками задач на все пространство
   *
   * @type {Boolean}
   */
  @observable
  isShowIssueTabsOnFullView = false;

  /**
   * Активная задача, с которой сейчас работает пользователь
   *
   * @type {IssueModel}
   */
  @observable
  activeIssue = undefined;

  /**
   * Режим отображения списка задач - Канбан (ISSUES_VIEW_KANBAN) или Таблица (ISSUES_VIEW_TABLE)
   *
   * @type {IssueModel}
   */
  @observable
  issuesViewMode = ISSUES_VIEW_TABLE;

  /**
   * Кол-во записей на странице в табличном представлением
   *
   * @type {Number}
   */
  @observable
  pageSize = 20;

  /**
   * Номер текущей страницы в табличном представлением
   *
   * @type {Number}
   */
  @observable
  currentPage = 1;

  /**
   * Общее кол-во записей
   *
   * @type {Number}
   */
  @observable
  totalIssues = 0;

  constructor({ rootStore }) {
    this.rootStore = rootStore;
    this.api = new IssueApi(rootStore);
    // this.isShownJournal = this.getItemConfig(JOURNAL_CFG).isShownJournal;
    this.isShownFilters = this.getItemConfig(FILTERS_CFG).isShownFilters;
  }

  /**
   * Список задач
   *
   * @return {Array<IssueModel>}
   */
  @computed
  get issueList() {
    return Array.from(this.issues.values());
  }

  /**
   * Список открытых задач
   *
   * @return {Array<IssueModel>}
   */
  @computed
  get openedIssueList() {
    // return Array.from(this.openedIssues.values());
    return this.openedIssues;
  }

  @computed
  get issueListGroupedByStatus() {
    const result = {};
    this.statusList.forEach((status) => {
      result[status.id] = this.issueList
        .filter((issue) => {
          return issue.statusId === status.id;
        })
        .sort((a, b) => {
          return a.order - b.order;
        });
    });

    return result;
  }

  /**
   * Список приоритетов у задачи
   *
   * @return {Array<PriorityModel>}
   */
  @computed
  get priorityList() {
    return Array.from(this.priorities.values());
  }

  /**
   * Список статусов задачи
   *
   * @return {Array<StatusModel>}
   */
  @computed
  get statusList() {
    return Array.from(this.statuses.values());
  }

  /**
   * Список трекеров задачи
   *
   * @return {Array<TrackerModel>}
   */
  @computed
  get trackerList() {
    return Array.from(this.trackers.values());
  }

  /**
   * Список пользователей Redmine
   *
   * @return {Array<RedmineUserModel>}
   */
  @computed
  get userList() {
    return Array.from(this.users.values());
  }

  /**
   * Список пользователей Redmine, у которых есть связь с пользователями в АИС
   *
   * @return {Array<RedmineUserModel>}
   */
  @computed
  get linkedUserList() {
    return this.userList.filter((user) => {
      return user.isLinked;
    });
  }

  /**
   * Список проектов Redmine
   *
   * @return {Array<RedmineProjectModel>}
   */
  @computed
  get projectList() {
    return Array.from(this.projects.values());
  }

  /**
   * Задать активную адачу
   *
   * @param {IssueModel} issue модель задачи
   */
  @action
  setActiveIssue(issue) {
    this.activeIssue = issue;
  }

  /**
   * Задать режим отображения списка задач - Канбан (ISSUES_VIEW_KANBAN) или Табличное (ISSUES_VIEW_TABLE)
   *
   * @param {String} mode режим отображения ISSUES_VIEW_KANBAN | ISSUES_VIEW_TABLE
   */
  @action
  setIssuesViewMode(mode) {
    this.issuesViewMode = mode;
  }

  /**
   * Добавить задачу в список под ее uid
   *
   * @param {Array<IssueModel>} issue модель задачи
   */
  @action
  addIssue(issue) {
    this.issues.set(issue.uid, issue);
    this.rootStore.objectStore.addVersion(issue);
  }

  /**
   * Добавить задачу в список открытых под ее uid
   *
   * @param {Array<IssueModel>} issue модель задачи
   */
  @action
  addOpenedIssue(issue) {
    this.openedIssues.push(issue);
    // this.openedIssues.set(issue.uid, issue);
    // if (issue.isNew) {
    //   const newIssues = this.openedIssues.filter((i) => {
    //     return !i.uid;
    //   });
    //   if (newIssues.length === 0) { // добавляем только одну новую задачу (без uid)
    //     this.openedIssues.push(issue);
    //   } else {
    //     this.setActiveIssue(newIssues[0]);
    //   }
    // } else {
    //   this.openedIssues.push(issue);
    // }
  }

  /**
   * Удалить задачу из списка открытых
   *
   * @param {String} uid uid задачи
   */
  @action
  deleteOpenedIssue(uid) {
    // this.openedIssues.delete(uid);
    // if (this.openedIssues.size === 0) {
    //   this.isShowIssueTabsOnFullView = false;
    // }
    this.openedIssues = this.openedIssues.filter((issue) => {
      return issue.uid !== uid;
    });

    if (this.openedIssues.length === 0) {
      this.setActiveIssue(undefined);
    }
  }

  /**
   * Добавить приоритет задачи в список под его id
   *
   * @param {Array<PriorityModel>} priority модель приоритета задачи
   */
  @action
  addPriority(priority) {
    this.priorities.set(priority.id, priority);
  }

  /**
   * Добавить сатус задачи в список под ее id
   *
   * @param {Array<PriorityModel>} status модель статуса задачи
   */
  @action
  addStatus(status) {
    this.statuses.set(status.id, status);
  }

  /**
   * Добавить трекер задачи в список под ее id
   *
   * @param {Array<PriorityModel>} tracker модель трекера задачи
   */
  @action
  addTracker(tracker) {
    this.trackers.set(tracker.id, tracker);
  }

  /**
   * Добавить пользователя в Redmine в список под его id
   *
   * @param {Array<RedmineUserModel>} user модель пользователя в Redmine
   */
  @action
  addUser(user) {
    this.users.set(user.id, user);
  }

  /**
   * Добавить проект в Redmine в список под его id
   *
   * @param {Array<RedmineProjectModel>} project модель проекта в Redmine
   */
  @action
  addProject(project) {
    this.projects.set(project.uid, project);
  }

  /**
   * Изменение значения флага, указывающий, что идет обработка/загрузка данных
   *
   * @param {Boolean} value значение
   */
  @action
  setIsProcessing(value) {
    this.processing = value;
  }

  /**
   * Изменение значения флага, указывающий, что идет инициализация хранилища
   *
   * @param {Boolean} value значение
   */
  @action
  setIsInitialization(value) {
    this.isInitialization = value;
  } 

  /**
   * Флаг, указывающий, что идет обработка/загрузка данных
   *
   * @return {Boolean} 
   */
  @computed
  get isProcessing() {
    return this.processing  || this.isInitialization;
  }

  /**
   * Устанвоить uid текущего проекта
   *
   * @param {String} value значение
   */
  @action
  setCurrentProjectUid(value) {
    this.currentProjectUid = value;
  }

  /**
   * Выставить значение readOnly
   *
   * @param {Boolean} value
   */
  @action
  updateReadOnly(value) {
    this.readOnly = value;
  }

  /**
   * Переключить отображение панели с фильтрами
   */
  @action
  toggleShowFilters() {
    this.isShownFilters = !this.isShownFilters;
    const cfg = this.getItemConfig(FILTERS_CFG);
    this.setItemConfig(FILTERS_CFG, {
      ...cfg,
      isShownFilters: this.isShownFilters
    });
  }

  /**
   * Скрыть отображение панели с фильтрами
   */
  @action
  hideFilters() {
    this.isShownFilters = false;
    const cfg = this.getItemConfig(FILTERS_CFG);
    this.setItemConfig(FILTERS_CFG, {
      ...cfg,
      isShownFilters: false
    });
  }


  /**
   * Переключить отображение панели с историей изменений
   */
  @action
  toggleShowJournal() {
    this.isShownJournal = !this.isShownJournal;
    // const cfg = this.getItemConfig(JOURNAL_CFG);
    // this.setItemConfig(JOURNAL_CFG, {
    //   ...cfg,
    //   isShownJournal: this.isShownJournal,
    // });
  }

  /**
   * Переключить отображение панели со вкаладками задач - во весь экран или наполовину
   */
  @action
  toggleShowIssueTabs() {
    this.isShowIssueTabsOnFullView = !this.isShowIssueTabsOnFullView;
  }

  /**
   * Изменить статус у задачи
   *
   * @param {String} issueUid - uid задачи,у которой нужно изменить статус
   * @param {String} afterIssueUid - uid задачи, после котрой нужно разметстить перемещаемую задачу
   * @param {String} statusId - id новго статуса
   * @param {Boolean} isSendRequest - необходимо ли отправлять запрос на сервер
   */
  @action
  async changeIssueStatus(issueUid, afterIssueUid, statusId, isSendRequest) {
    const issue = this.getIssue(issueUid);
    const targetIssue = this.getIssue(afterIssueUid);
    // создаем временный список задач
    const issues = this.issueListGroupedByStatus[statusId];
    const cardIndex = issues.indexOf(issue);

    if (targetIssue) {
      const afterIndex = issues.indexOf(targetIssue);

      // формируем новый порядок задач
      issues.splice(cardIndex, 1);
      issues.splice(afterIndex, 0, issue);

      // обновляем параметр order согласно новой сортировке
      issues.forEach((item, i) => {
        // Делаем snapshot модели перед внесением изменений. Это позволит нам потом сделать откат назад
        // если изменения не пройдут на сервер. Snapshot нужно делать один раз в начале, т.к. данные
        // изменения будут происходить несколько раз при DnD.
        if (!item.snapshot) {
          item.createSnapshot();
        }

        item.update({
          statusId,
          order: i + 1
        });
      });
    } else { // Блок задачи бросили в пустую колонку
      // Делаем snapshot модели перед внесением изменений. Это позволит нам потом сделать откат назад
      // если изменения не пройдут на сервер. Snapshot нужно делать один раз в начале, т.к. данные
      // изменения будут происходить несколько раз при DnD.
      if (!issue.snapshot) {
        issue.createSnapshot();
      }
      issue.update({
        statusId,
        order: cardIndex + 1
      });
    }

    if (isSendRequest) {
      issues.splice(0, cardIndex);
      try {
        if (issues.length > 1) {
          // сохраняем сразу пачкой order и statusId
          await this.api.saveBulkIssues(issues.map((i) => {
            return {
              uid:      i.uid,
              statusId: i.statusId,
              order:    i.order
            };
          }));
        } else {
          // меняем только статус у задачи
          await this.api.changeStatusIssue(issueUid, statusId);
        }
        // фиксируем изменения у моделей
        issues.forEach((issue) => {
          issue.commit();
        });
      } catch (ex) {
        // если не получилось обновить на сервере занчения, то нужно визуально все вернуть назад
        issues.forEach((issue) => {
          issue.revert();
        });
        this.onError(ex.message);
      }
    }
  }

  /**
   * Задать кол-во записей на странице в табличном представлении
   *
   * @param {Number} size кол-во записей
   */
  @action
  setPageSize(size) {
    this.pageSize = size || 20;
    this.setCurrentPage(1);
  }

  /**
   * Задать номер текущей страницы в табличном представлении
   *
   * @param {Number} page номер текущей страницы в табличном представлении
   */
  @action
  setCurrentPage(page) {
    this.currentPage = page || 1;
  }

  /**
   * Задать общее кол-во записей
   *
   * @param {Number} value общее кол-во записей
   */
  @action
  setTotalIssues(value = 0) {
    this.totalIssues = value;
  }

  /**
   * Кол-во страниц
   *
   * @return {Number}
   */

  @computed
  get pages() {
    if (this.pageSize && this.totalIssues) {
      return Math.ceil(this.totalIssues / this.pageSize);
    }

    return -1;
  }

  /**
   * Можно ли перейти на предыдущую страницу
   *
   * @return {Boolean}
   */
  @computed
  get canPreviousPage() {
    return this.currentPage > 1;
  }

  /**
   * Можно ли перейти на следуюущую страницу
   *
   * @return {Number}
   */
  @computed
  get canNextPage() {
    return this.currentPage < this.pages;
  }

  /**
   * Получить задачу по ее uid
   *
   * @param {String} uid задачи
   *
   * @return {IssueModel}
   */
  getIssue(uid) {
    return this.issues.get(uid);
  }

  /**
   * Получить приоритет задачи по его id
   *
   * @param {String} id приоритета задачи
   *
   * @return {PriorityModel}
   */
  getPriority(id) {
    return this.priorities.get(id);
  }

  /**
   * Получить статус задачи по ее id
   *
   * @param {String} id статуса задачи
   *
   * @return {StatusModel}
   */
  getStatus(id) {
    return this.statuses.get(id);
  }

  /**
   * Получить трекер задачи по ее id
   *
   * @param {String} id трекера задачи
   *
   * @return {TrackerModel}
   */
  getTracker(id) {
    return this.trackers.get(id);
  }

  /**
   * Получить пользователя Redmine по его id
   *
   * @param {String} id пользователя в Redmine
   *
   * @return {RedmineUserModel}
   */
  getUser(id) {
    return this.users.get(id);
  }

  /**
   * Получить проект Redmine по его id
   *
   * @param {String} id проекта в Redmine
   *
   * @return {RedmineProjectModel}
   */
  getProject(id) {
    return this.projects.get(id);
  }

  /**
   * Получить проект Redmine по его uid
   *
   * @param {String} uid проекта в АИС
   *
   * @return {RedmineProjectModel}
   */
  getProjectByUid(uid) {
    return Array.from(this.projects.values()).filter((pr) => {
      return pr.uid === uid;
    })[0];
  }

  /**
   * Метод для инициализации хранилища.
   * Необходимо вывать для загузки словарей данны - статусы задач, приоритеты задач и т.п.
   */
  async init() {
    this.setIsInitialization(true);
    try {
      await this.loadPriorities();
      await this.loadStatuses();
      await this.loadTrackers();
      await this.loadUsers();
      await this.loadProjects();
    } finally {
      this.setIsInitialization(false);
    }
  }

  /**
   * Загрузить список приориетов задачи
   *
   * @return {Boolean} результат загрузки true | false
   */
  async loadPriorities() {
    try {
      const data = await this.api.loadPriorities();
      (data || []).forEach((item) => {
        this.addPriority(PriorityModel.create(item, this));
      });
    } catch (e) {
      this.onError(e.message);
      return false;
    }

    return true;
  }

  /**
   * Загрузить список статусов задачи
   *
   * @return {Boolean} результат загрузки true | false
   */
  async loadStatuses() {
    try {
      const data = await this.api.loadStatuses();
      (data || []).forEach((item) => {
        this.addStatus(StatusModel.create(item, this));
      });
    } catch (e) {
      this.onError(e.message);
      return false;
    }

    return true;
  }

  /**
   * Загрузить список трекеров задачи
   *
   * @return {Boolean} результат загрузки true | false
   */
  async loadTrackers() {
    try {
      const data = await this.api.loadTrackers();
      (data && data.linked || []).forEach((item) => {
        this.addTracker(TrackerModel.create(item, this));
      });
    } catch (e) {
      this.onError(e.message);
      return false;
    }

    return true;
  }

  /**
   * Загрузить список пользователей Redmine
   *
   * @return {Boolean} результат загрузки true | false
   */
  async loadUsers() {
    try {
      const data = await this.api.loadUsers();
      (data || []).forEach((item) => {
        this.addUser(RedmineUserModel.create(item, this));
      });
    } catch (e) {
      this.onError(e.message);
      return false;
    }

    return true;
  }

  /**
   * Загрузить список проектов Redmine
   *
   * @return {Boolean} результат загрузки true | false
   */
  async loadProjects() {
    try {
      const data = await this.api.loadRedmineProjects();
      (data.linked || []).forEach((item) => {
        this.addProject(RedmineProjectModel.create(item, this));
      });
    } catch (e) {
      this.onError(e.message);
      return false;
    }

    return true;
  }

  /**
  * Загрузить список задач для проекта
  *
  * @params {String} projectUid uid проекта
  *
   * @return {Boolean} результат загрузки true | false
   */
  async loadIssues(projectUid) {
    this.setIsProcessing(true);
    try {
      this.clearIssues();
      this.setCurrentProjectUid(projectUid);
      // this.setCurrentPage(1);
      const data = await this.api.loadIssues(projectUid, this.currentPage, this.pageSize);
      const { total, issues = [] } = data;
      const uids = [];
      issues.forEach((item, i) => {
        uids.push(item.uid);
        this.addIssue(IssueModel.create({ ...item, order: item.order || i + 1 }, this));
      });
      this.setTotalIssues(total);
      await this.rootStore.kindsStore.getItems(uids);
    } catch (e) {
      this.onError(e.message);
      return false;
    } finally {
      this.setIsProcessing(false);
    }

    return true;
  }

  /**
  * Загрузить список задач использую набор фильтров
  *
  * @params {Object} filter набор фильтров
  * @params {Array<String>} filter.project - список uid'ов проектов
  * @params {Array<String>} filter.tracker - список uid'ов трекеров
  * @params {Array<String>} filter.status - список идентификаторов статусов в Redmine
  * @params {Array<String>} filter.priority - список идентификаторов приоритетов в Redmine
  * @params {Array<String>} filter.author - список uid'ов пользователей-авторов
  * @params {Array<String>} filter.assignedTo - список uid'ов пользователей-исполнителей
  * @params {Date} filter.createDateFrom - нижняя граница даты создания задачи
  * @params {Date} filter.createDateTo - верхняя граница даты создания задачи
  * @params {Date} filter.updateDateFrom - нижняя граница даты последнего обновления задачи
  * @params {Date} filter.updateDateTo - верхняя граница даты последнего обновления задачи
  * @params {Date} filter.startDateFrom - нижняя граница даты начала исполнения задачи
  * @params {Date} filter.startDateTo - верхняя граница даты начала исполнения задачи
  * @params {Date} filter.dueDateFrom - нижняя граница даты запланированного завершения задачи
  * @params {Date} filter.dueDateTo - верхняя граница даты запланированного завершения задачи
  * @params {String} filter.subject - поисковая строка для поиска в теме (названии) задачи
  * @params {Boolean} filter.strict - флаг, должен ли быть поиск в теме (названии) задачи строгим
  *                  (при отсутствии или null поиск является нестрогим)
  *
   * @return {Boolean} результат загрузки true | false
   */
  async loadIssuesByFilter(filters) {
    this.setIsProcessing(true);
    try {
      this.clearIssues();
      // this.setCurrentPage(1);

      let data = {};
      if (this.issuesViewMode === ISSUES_VIEW_TABLE) {
        data = await this.api.loadIssuesByFilter(filters, this.currentPage, this.pageSize);
      } else {
        data = await this.api.loadIssuesByFilter(filters);
      }

      const { total, issues = [], perPage } = data;
      const uids = [];
      issues.forEach((item, i) => {
        uids.push(item.uid);
        this.addIssue(IssueModel.create({ ...item, order: item.order || i + 1 }, this));
      });
      this.setTotalIssues(total);
      if (perPage && this.pageSize !== perPage) {
        this.setPageSize(perPage);
      }
      await this.rootStore.kindsStore.getItems(uids);
      if (filters.project) {
        this.setCurrentProjectUid(filters.project[0]);
      } else {
        this.setCurrentProjectUid(undefined);
      }
    } catch (e) {
      this.onError(e.message);
      return false;
    } finally {
      this.setIsProcessing(false);
    }

    return true;
  }

  /**
   * Загрузить задачу по ее uid
   *
   * @param {String} uid задачи
   * @return {IssueModel}
   */
  async loadIssue(issueUid) {
    try {
      const data = await this.api.loadIssue(issueUid);
      await this.rootStore.kindsStore.getItems([issueUid]);
      let issue = this.getIssue(issueUid);
      if (issue) {
        issue.update(data);
      } else {
        issue =  IssueModel.create(data, this, this);
      }

      await Promise.all(issue.attachmentList.map(async(attachment) => {
        const meta = await this.loadFileMetadata(attachment.aisId);
        attachment.setMetadata(meta);
      }));


      this.addOpenedIssue(issue);
      return issue;
    } catch (e) {
      this.onError(e.message);
    }

    return undefined;
  }

  /**
   * Сохранить / создать  задачу
   *
   * @param {IssueModel} задача
   * @param {Object} data данные для изменения в задаче
   * @return {Boolean}
   */
  async saveIssue(issue, data) {
    try {
      let d = {};
      const saveData = {
        ...data,
        startDate: data.startDate ? moment(data.startDate).format("YYYY-MM-DD") : "",
        dueDate:   data.dueDate ? moment(data.dueDate).format("YYYY-MM-DD") : "",
        doneRatio: data.doneRatio ? (parseInt(data.doneRatio) || 0) : ""
      };
      if (issue.parent) {
        saveData.parentIssueUid = issue.parent.uid;
      }
      if (issue.isNew) {
        d = await this.api.createIssue({
          ...saveData
        });
      } else {
        d = await this.api.saveIssue(issue.uid, saveData);
      }
      issue.update({
        ...d,
        startDate: d.startDate && moment(d.startDate, "DD.MM.YYYY").toDate(),
        dueDate:   d.dueDate && moment(d.dueDate, "DD.MM.YYYY").toDate()
      });

      if (issue.parent) {
        // обновляем дерево связанных задач у родительской задачи, если она открыта во вкладке открытых задач
        await this.reloadOpenedIssue(issue.parent.uid);
      }

      // загружаем данные о файлах
      await Promise.all(issue.attachmentList.map(async(attachment) => {
        const meta = await this.loadFileMetadata(attachment.aisId);
        attachment.setMetadata(meta);
      }));

      // if (this.currentProjectUid === issue.project.uid) {
      this.issues.set(issue.uid, issue);
      // }

      return true;
    } catch (e) {
      this.onError(e.message);
    }

    return false;
  }

  /**
   * Выгрузить файл с харнилища АИС
   * @param {String} id файла в хранилище
   */
  async downloadFile(id) {
    try {
      return await this.api.downloadFile(id);
    } catch (e) {
      this.onError(e.message);
    }
  }

  /**
   * Загрузить файл на сервер
   * @param {File} file  загружаемый файл
   * @return {AttachmentModel}
   */
  async uploadFile(file) {
    try {
      const data = await this.api.uploadFile(file);
      const fileItem = data[0] || {}; // берем только первый файл
      const newAttachment = AttachmentModel.create({
        aisId: fileItem.id
      }, this);

      newAttachment.setMetadata(fileItem);
      return newAttachment;
    } catch (e) {
      this.onError(e.message);
    }

    return null;
  }

  /**
   * Загрузить данные файла с хранилища АИС
   * @param {String} id файла в хранилище
   */
  async loadFileMetadata(id) {
    try {
      return await this.api.loadFileMetadata(id);
    } catch (e) {
      this.onError(e.message);
    }
  }

  /**
   * Очистить список задач
   *
   */
  @action
  clearIssues() {
    this.issues.clear();
  }

  /**
   * Очистить список открытых задач
   *
   */
  @action
  clearOpenedIssues() {
    // this.openedIssues.clear();
    this.openedIssues = [];
  }

  /**
   * Очистить словарь данных - список статусов задачи, список приоритетов задачи
   *
   */
  @action
  clearDictonaryData() {
    this.statuses.clear();
    this.priorities.clear();
    this.trackers.clear();
    this.users.clear();
    this.projets.clear();
  }

  /**
   * Очистить данные
   *
   */
  @action
  clear() {
    this.setCurrentProjectUid(undefined);
    this.clearIssues();
    this.clearOpenedIssues();
    this.clearDictonaryData();
  }

  /**
   * Открыть вкладку с задачей
   * 
   * @param {String} uid задачи
   */
  async openIssue(uid) {
    // проверяем, есть ли уже открытая задача в закладках
    const index = this.openedIssueList.findIndex((i) => {
      return i.uid === uid;
    });
    // если нет, то загружаем задачу и добавляем потом в закладки
    if (index < 0) {
      const i = await this.loadIssue(uid);
      this.setActiveIssue(i);
    } else {
      // если есть, то делаем активной эту закладку
      const i = this.openedIssueList[index];
      this.setActiveIssue(i);
    }
  }

  /**
   * Создать новую задачу
   *
   * @param{TrackerModel} tracker трекер(вид) задачи
   * @param{IssueModel} parent родительская задача. если есть
   * 
   * @return {IssueModel}
   */
  createNewIssue(tracker, parent) {
    // const currentUser = this.rootStore.accountStore.uid
    const data = {
      tracker,
      createdOn: new Date(),
      updatedOn: new Date(),
      author:    { uid: this.rootStore.accountStore.uid, name: this.rootStore.accountStore.name },
      project:   this.getProjectByUid(this.currentProjectUid),
      parent
    };
    const newIssues = this.openedIssues.filter((i) => {
      return i.isNew;
    });
    if (newIssues.length === 0) {  // добавляем только одну новую задачу (без uid)
      const newIssue = IssueModel.create(data, this, this);
      this.addOpenedIssue(newIssue);
      return newIssue;
    } else {
      return newIssues[0];
    }
    // const newIssue = IssueModel.create(data, this, this);
    // this.addOpenedIssue(newIssue);
    // return newIssue;
  }

  /**
   * Проверить, есть ли связь проекта Redmine с участником вида
   * 
   * @param {String} memberUid uid участника вида
   * @returns 
   */
  async checkProjectsLinks(memberUid) {
    const res = await this.api.checkProjectsLinks([memberUid]);
    return res[memberUid];
  }

  /**
   * Проверить связанные трекеры с текущим проектом по набору uid Видов или id трекеров Redmine 
   *
   * @param {String} projectUid uid текущего проекта в АИС
   * @param {Array<String>} uids массив uid'ов участниов Вида, которые нужно проверить на связь с проектом в Redmine 
   * @param {Array<String>} ids массив id'ов трекеров Redmine, которые нужно проверить на связь с проектом в Redmine
   */
  async checkProjectTrackers(projectUid, uids = [], ids = []) {
    try {
      const data = await this.api.checkProjectTrackers(projectUid, uids, ids);
      return data;
    } catch (ex) {
      this.onError(ex.message);
    }
    return null;
  }

  /**
   * Создать новый проект в Redmine
   * 
   * @param {String} uid uid проекта в АИС
   * @param {String} name название проекта
   * @returns 
   */
  async createRedmineProject(uid, name) {
    this.setIsProcessing(true);
    try {
      await this.api.createRedmineProject(uid, name);
      return uid;
    } catch (e) {
      this.onError(e.message);
    } finally {
      this.setIsProcessing(false);
    }
    return null;
  }

  /**
   * Получить конфигурацию инструмент", onа;
   *
   * @return {Object}
   */
  @computed
  get config() {
    return this.rootStore.uiStore.getModuleConfig(MODULE_NAME_CFG);
  }

  /**
   * Получить параметр конфигурации инструмента
   *
   * @param {String} name название параметра
   * @return {Object}
   */
  getItemConfig(name) {
    return (this.config && this.config[name]) || {};
  }

  /**
   * Задать параметр конфигурации инструмента
   *
   * @param {String} name название параметра
   * @param {Object} params набор параметров
   */
  setItemConfig(name, params) {
    this.rootStore.uiStore.setModuleConfig(MODULE_NAME_CFG, {
      ...this.config,
      [name]: params
    });
  }

  /**
   * Перезагрузить данные открытой Задачи
   *
   * @param {String} issueUid uid задачи
   */
  async reloadOpenedIssue(issueUid) {
    // проверяем, есть ли уже открытая задача в закладках
    const index = this.openedIssueList.findIndex((i) => {
      return i.uid === issueUid;
    });
    // если нет, тоничего не делаем и выходим
    if (index < 0) {
      return;
    } 
    
    // если есть, то загружаем данные карточки и обновляем
    const issue = this.openedIssueList[index];
    issue.setIsPending(true);
    try {
      const data = await this.api.loadIssue(issue.uid);
      issue.update(data);
    } catch (ex) {
      this.onError(ex.message);
    } finally {
      issue.setIsPending(false);
    }
  }

  getIconByTracker(tracker) {
    if (this.rootStore && tracker) {
      const kind = this.rootStore.kindsStore.getKind(tracker.uid);
      if (kind) {
        return this.rootStore.accountStore.getIcon(kind.name);
      }
    }

    return "reglament-M";
  }

  /**
   * Найти задачи по тексту
   *
   * @param {String} text текст поиска
   * 
   * @returns {Array<IssueModel>}
   */
  async searchIssuesByText(text) {
    try {
      // const filters = {
      //   project: [this.currentProjectUid],
      //   subject: text
      // };
      // const { issues } = await this.api.loadIssuesByFilter(filters, 1, 10);
      const issues = await this.api.searchIssuesByText(text, 10);
      return issues.map((issue) => {
        return {
          ...issue,
          title:       issue.subject,
          titlePrefix: IssueModel.titlePrefix(issue.tracker, issue.id),
          iconString:  this.getIconByTracker(issue.tracker) 
        };
        // return IssueModel.create(issue, this);
      });
    } catch (ex) {
      this.onError(ex.message);
    }
    return [];
  }

  /**
   * Отвязать одну задачу от родительской
   * 
   * @param {String} targetIssueUid  uid Задачи, которую нужно отвзяать от родительской
   * @param {IssueModel} currentIssue текущая открытая задача, в карточке которой происходит эта операция.
   */
  async unlinkRelatedIssue(targetIssueUid, currentIssue) {
    if (!currentIssue) {
      return;
    }
    if (!targetIssueUid) {
      currentIssue.setParent(undefined);
      return;
    }
    currentIssue.setIsPending(true);
    try {
      let data  = await this.api.saveIssue(targetIssueUid, {
        parentIssueUid: ""
      });
      if (currentIssue.uid !== targetIssueUid) {
        data = await this.api.loadIssue(currentIssue.uid);
      } 
      currentIssue.update(data);
      if (!data.parent) {
        currentIssue.setParent(null);
      }
    } catch (ex) {
      this.onError(ex.message);
    } finally {
      currentIssue.setIsPending(false);
    }
  }

  /**
   * Привязать одну задачу к другой задаче
   * 
   * @param {String} targetIssueUid  uid Задачи, которую нужно привязать к родителськой задаче
   * @param {String} parentIssueUid  uid родительской Задачи, к которой привязываем Задачу
   * @param {IssueModel} currentIssue текущая открытая задача, в карточке которой происходит эта операция.
   */
  async linkRelatedIssue(targetIssueUid, parentIssueUid, currentIssue) {
    currentIssue.setIsPending(true);
    try {
      let data = await this.api.saveIssue(targetIssueUid, {
        parentIssueUid
      });
      if (currentIssue.uid !== targetIssueUid) {
        data = await this.api.loadIssue(currentIssue.uid);
      } 
      currentIssue.update(data);
    } catch (ex) {
      this.onError(ex.message);
    } finally {
      currentIssue.setIsPending(false);
    }
  }

  /**
   * Получить текстовое значение фильтра из выпадающего списка
   * 
   * @param {String} key название(ключ) фильтра
   * @param {String} value значение фильтра (uid)
   * @returns {String} текстовое значение фильтра из выпадающего списка
   */
  getFilterTitle(key, value) {
    switch (key) {
      case "tracker":{
        const tr = this.trackerList.filter((item) => {
          return item.uid === value;
        })[0];
        return tr && tr.title;
      }
      case "priority":{
        const pr = this.priorities.get(value);
        return pr && pr.title;
      }
      case "author":
      case "assignedTo":  {
        const user = this.rootStore.userStore.list.filter((u) => {
          return u.uid === value;
        })[0];
        return user && user.shortName;
      }
      default:
        return value;
    }
  }

  /**
   * Функция для вызова сообщения обшибке
   *
   * @param {String} msg текст сообщения об ошибке
   */
  onError(msg) {
    this.rootStore.onError(msg);
  }


  /**
   * Метод разрушения хранилища
   *
   */
  destroy() {
    this.clear();
  }
}

export default IssueStore;
