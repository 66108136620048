import { action, computed, observable } from "mobx";

import LibraryApi from "../../../api/LibraryApi";
import { DOMAIN_LIBRARY } from "../../../../../core/constants/Domains";
import TreeNode from "../../../models/TreeNode";
const TOOL_ID = "library.Library";

class LibraryStore {
  @observable
  rootStore = null;
  @observable
  pending = false;
  @observable
  pendingRepos = false;
  @observable
  gotError = false;
  @observable
  noRepresentation = true;
  @observable
  nodes = new Map();
  @observable
  repos = new Map();
  @observable
  root = null;

  constructor(root) {
    this.rootStore = root;
    this.api = new LibraryApi(this.rootStore);
    this.getRepos();
  }

  @action
  setPending(pending = false) {
    this.pending = pending;
  }

  @action
  setPendingRepos(pending = false) {
    this.pendingRepos = pending;
  }

  @action
  setRoot(root = null) {
    this.root = root;
  }

  @action
  setGotError(gotError = false) {
    this.gotError = gotError;
  }

  @action
  setNoRepresentation(noRep = false) {
    this.noRepresentation = noRep;
  }

  @action
  async getRepos() {
    this.setPendingRepos(true);
    const repoData = await this.api.getRepos();
    this.processRepos(repoData);
    this.setPendingRepos(false);
  }

  @action
  processRepos(repoData) {
    this.repos.clear();
    repoData.forEach((repo) => {
      this.repos.set(`${repo.id}`, repo);
    });
  }

  @action
  getVersion(uid) {
    return this.rootStore.objectStore.getVersion(uid, DOMAIN_LIBRARY);
  }

  @action
  async addRepo(target, { name, url, type, repo, username, password }) {
    target.setPending(true);

    let repoId = repo;
    if (!repoId) {
      const repoData = await this.api.addRepoToList({
        name,
        url,
        username,
        password,
        type
      });
      if (repoData) {
        repoId = `${repoData.id}`;
      }
    }

    let result = null;
    if (target && name && repoId) {
      result = await this.api.addRepoMaterial({
        targetUid:  target.uid,
        repository: `${repoId}`,
        name
      });

      if (result) {
        const node = await this.rootStore.objectStore.processLibraryItem(result, DOMAIN_LIBRARY, {});
        if (node) {
          target.addChild(node.uid, -1);
          node.setParentUid(target.uid);
        }
      }
    }

    target.setPending(false);
    return result;
  }

  @action
  getNode(uid) {
    let nodeItem = this.nodes.get(uid);
    if (!nodeItem) {
      nodeItem = this.addNode(uid);
    }
    return nodeItem;
  }

  @action
  addNode(uid) {
    const nodeItem = new TreeNode(uid, this);
    this.nodes.set(nodeItem.uid, nodeItem);
    return nodeItem;
  }

  @action
  async uploadFile(uid, position, files) {
    const result = await this.api.loadFile(
      uid,
      position,
      files
    );
    if (result && result.length) {
      const items = [];
      result.forEach((nodeData) => {
        const item = this.rootStore.objectStore.processLibraryItem(nodeData, DOMAIN_LIBRARY, {});
        this.getNode(item.uid);
        items.push(item);
      });
      return await Promise.all(items);
    }
  }

  @action
  async importFile(uid, position, files, scheme) {
    const result = await this.api.import(
      uid,
      position,
      files,
      scheme
    );
    if (result && result.length) {
      const items = [];
      result.forEach((nodeData) => {
        const item = this.rootStore.objectStore.processLibraryItem(nodeData, DOMAIN_LIBRARY, {});
        this.getNode(item.uid);
        items.push(item);
      });
      return await Promise.all(items);
    }
  }

  @action
  async getNodeData(uid, force = false) {
    if (!this.root) {
      this.setPending(true);
    }
    this.setGotError(false);
    this.setNoRepresentation(false);
    try {
      const result = await this.rootStore.objectStore.fetchRepresentation(uid, DOMAIN_LIBRARY
        , 0, {
          tool:   TOOL_ID,
          rootID: (this.root && this.root.uid) || uid
        }, { force });
      if (!this.root || result.uid === (this.root && this.root.uid)) {
        this.setRoot(this.getNode(result.uid));
      }
    } catch (error) {
      if (error && error.code === 404) {
        this.setNoRepresentation(true);
      } else {
        this.setGotError(true);
        this.rootStore.uiStore.setErrorText(error.message);
      }
    }
    this.setPending(false);
  }

  @computed
  get isPending() {
    return this.pending;
  }
  @computed
  get repoArray() {
    const repos = [];
    this.repos.forEach((repo) => {
      repos.push({
        label: repo.name,
        value: `${repo.id}`
      });
    });
    return repos;
  }

  @computed
  get isPendingRepos() {
    return this.pendingRepos;
  }

  @computed
  get toolId() {
    return TOOL_ID;
  }
}

export default LibraryStore;
