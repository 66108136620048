import BaseRedmineModel from "./baseRedmineModel";
import { observable, computed, action } from "mobx";

import { DOMAIN_LIBRARY } from "~/core/constants/Domains";

/**
 * Модель проекта. Должна быть связь с Проектом в АИС по его uid
 *
 * @class ProjectModel
 */
class ProjectModel extends BaseRedmineModel {
  /**
   * uid проекта в АИС
   *
   * @type String
   */
  @observable
  uid = undefined;

  /**
   * key проекта в АИС
   *
   * @type String
   */
  @observable
  key = undefined;

  /**
   * Название проекта
   *
   * @type String
   */
  name = undefined;

  /**
   * Cоздание модели
   *
   * @param {Object} params параметры модели
   * @param {String} params.id id проекта в Redmine
   * @param {String} params.uid uid проекта в АИС
   * @param {String} params.name название проекта
   * @param {IssueStore} issueStore хранилище задач
   *
   * @retrun {ProjectModel}
   */
  static create({
    id,
    uid,
    key,
    name
  }, issueStore) {
    return new ProjectModel({
      id,
      uid,
      key,
      name
    }, issueStore);
  }

  constructor(params, issueStore) {
    super(params, issueStore);

    this.uid = params.uid;
    this.key = params.key;
    this.name = params.name;
  }

  /**
   * Вид модели
   * @return {String}
   */
  get kindModel() {
    return "projectModel";
  }

  /**
   * Обновить св-ва модели
   * @param {Object} params параметры модели
   * @param {String} params.name название проекта
   */
  @action
  update(data) {
    this.name = data.name || this.name;
  }

  /**
   * Строковое представление проекта
   *
   * @return {String}
   */
  @computed
  get title() {
    if (this.issueStore) {
      const kindMember = this.issueStore.rootStore.kindsStore.getKindMemberSync(this.uid);
      if (kindMember && kindMember.item) {
        const object = this.issueStore.rootStore.objectStore.getVersion(kindMember.item.uid, DOMAIN_LIBRARY);
        if (object) {
          return object.title;
        }
      }
    }
    return this.name || this.uid;
  }

  /**
   * Флаг, указывающий если связь проекта с проектом в АИС
   *
   * @return {Boolean}
   */
  @computed
  get isLinked() {
    return !!this.uid;
  }

  /**
   * @computed
   * Значение для конфигурационного файла.
   *
   * @return {Object} данные
   */
  get config() {
    return {
      id:   this.id,
      uid:  this.uid,
      key:  this.key,
      name: this.name
    };
  }
}

export default ProjectModel;
