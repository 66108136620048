import getDomainByClass  from "./getDomainByClass";
import {
  DOMAIN_TEXT,
  DOMAIN_LIBRARY,
  DOMAIN_REPO,
  DOMAIN_ISSUE,
  DOMAIN_BINDER
} from "~/core/constants/Domains";

/**
 * Разобрать AisObject, полученный от сервиса #Composer и вернуть модель представления лоакльного объекта АИС
 * 
 * @param {Object} composerAisObject   AisObject, полученный от сервиса #Composer
 * @param {ObjectStore} objectStore глобавльное хранилище объектов
 * 
 * @returns {AisObject} модель представления лоакльного объекта АИС
 */
const processComposerAisObject = async(composerAisObject, objectStore) => {
  const { class:klass, version, representation = {}, members = [] } = composerAisObject;
  const domain = getDomainByClass(klass);
  let obj;
  switch (domain) {
    case DOMAIN_LIBRARY:{
      obj = await objectStore.processLibraryItem(representation, domain, {}, { loadKinds: false });
      break;
    }
    case DOMAIN_REPO: {
      obj = await objectStore.processRepositoryItem(representation, domain);
      break;
    }
    case DOMAIN_ISSUE:
      obj = await objectStore.processIssueItem(representation);
      break;
    case DOMAIN_TEXT:
      obj = await objectStore.processTextItem(representation, version, {}, { loadKinds: false });
      break;
    case DOMAIN_BINDER:
      obj = await objectStore.processRelationItem(representation);
      break;
    default:{
      // throw new Error(`No domain specified - ${domain} in class="${klass}"`);
      console.error(`Не опредлен домен - ${domain} в class="${klass}"`, composerAisObject);
    }      
  }
  await objectStore.rootStore.kindsStore.processMembers(members, version);
  return obj;
};

export  default processComposerAisObject;
