import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { Components } from "@ais3p/ui-framework";
import { observer } from "mobx-react";

import useStores from "~/core/utils/useStores";
import WSStore from "../../stores/wsStore";

/**
 * @type WSToolbar
 *  
 * Toolbar для web socket
 * 
 * @prop {WSStore} store хранилище для работы с ws пакетами
 */
const WSToolbar = observer((props) => {
  const { accountStore } = useStores();
  const { store } = props;

  const onReconnect = useCallback(() => {
    store.init(accountStore.token);
  }, [store]);

  const rightButtons = useMemo(() => {
    return [
      <Components.Button
        key="status"
        icon={store.isConnected ? "link-finish-M" : "link-delete-M"}
        color={store.isConnected ? "positive" : "negative"}
        tooltip={store.readyState}
      />
    ];
  }, [store.isConnected, store.readyState]);

  return (
    <Components.ToolBar right={rightButtons} >
      <Components.Button
        icon="refresh-M"
        tooltip="Переподключиться по web socket"
        onPress={onReconnect}
      />
    </Components.ToolBar >
  );
});

WSToolbar.propTypes = {
  store: PropTypes.instanceOf(WSStore).isRequired
};
export default WSToolbar;
