import { observable, computed } from "mobx";

import  AttributeModel from "./AttributeModel";

/**
 * Модель значения атрибута Вида, полученная через Composer
 */
export default class ValueModel {
  /**
   * @type {AttributeModel}
   * Аттрибут Вида
   */
  @observable
  attribute = undefined;

  /**
   * @type {Any}
   * Значение аттрибута Вида
   */
  @observable
  value = undefined;

  static create({ attribute, value }, store) {
    if (!attribute) {
      return undefined;
    }
    return new  ValueModel({ attribute, value }, store);
  }

  constructor(data, store) {
    this.store = store;

    this.value = data.value;
    this.attribute = AttributeModel.create(data.attribute); ;
  }

  /**
   * Получить текстовое представление значения атрибута Вида
   * 
   * @returns {String} текстовое представление значения атрибута Вида
   */
  @computed
  get title() {
    return  `${this.attribute.title} = ${this.value}`;
  }

  /**
   * Получить уникальный индификатор атрибута
   * 
   * @returns {String} уникальный индификатор атрибута
   */
  @computed
  get uid() {
    return  this.attribute.uid;
  }
}
