import { ContextMenu, Dnd } from "@ais3p/ui-framework";
import { observer } from "mobx-react";
import React, { useCallback } from "react";
import AisIcon from "~/core/components/AisIcon";
import { 
  DND_EDITORITEM_TYPE
} from "~/core/constants/DnD";


const TextItemIcon = observer(({ data, onClick, setContextMenu }) => {
  const { isFocusUid, isVersion, shortClass } = data;
  const onClickIcon = useCallback(
    (e) => {
      e.stopPropagation();
      onClick && onClick(data);
    },
    [data]
  );

  const contextMenuCollect = useCallback(
    (item) => {
      if (isVersion) {
        setContextMenu([]);
        return;
      }

      const menuItems = [
        {
          icon:   "delete-M",
          title:  "Удалить",
          action: "delete"
        }
      ];
      if (item.className === "text.element.Picture") {
        menuItems.unshift({
          icon:   "editing-M",
          title:  "Изменить подпись",
          action: "editCaption"
        });
        menuItems.unshift({
          icon:   "editing-M",
          title:  "Изменить рисунок",
          action: "editPicture" 
        });
      }
      setContextMenu(menuItems);
    },
    [isVersion, setContextMenu]
  );

  const canDrag = useCallback(() => {
    return !data.isVersion;
  }, [data.isVersion]);

  return (
    <Dnd.Source
      isRelative={true}
      item={{
        class:   data.className,
        parent:  null,
        tool:    data.domain,
        title:   data.title,
        uid:     data.uid,
        version: data.version
      }}
      uid={data.uid}
      // eslint-disable-next-line react/jsx-no-bind
      canDrag={canDrag}
      type={DND_EDITORITEM_TYPE}
    >
      <ContextMenu.Trigger
        menuId={data.editable}
        context={data}
        collect={contextMenuCollect}
      >
        <AisIcon
          onClick={onClickIcon}
          className={`icon ${shortClass} ${isFocusUid ? "focus" : ""}`}
          item={data}
        />
      </ContextMenu.Trigger>
    </Dnd.Source>
  );
});

export default TextItemIcon;
