import React from "react";
import { observer } from "mobx-react";
import AttrsForm from "~/modules/kindsAndAttrs/components/AttrsForm";

const KindView = observer(({ uid, kindId, version = 0, checkValidityForm }) => {
  return (
    <div className={"wizard-kind-view"}>
      <AttrsForm
        objectUid={uid} version={version} kindUid={kindId}
        theme={"default"} checkValidityForm={checkValidityForm}
      />
    </div>
  );
});

export default KindView;
