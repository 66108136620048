import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import "./css/stateCardList.scss";
import StateCard from "./StateCard";

const StateCardList = observer(({ workflow }) => {
  const [points, setPoints] = useState([]);

  useEffect(() => {
    const reversePoints = workflow?.points?.slice().reverse();
    setPoints(reversePoints);
  }, [workflow]);
  
  return (
    <ul className="state-card-list">
      {points?.map((point, index) => {
        return (
          <li key={point.id} className="state-card-list__item">
            <StateCard
              fistChild={index === 0}
              workflow={workflow}
              point={point}
            />
          </li>
        );
      })}
    </ul>
  );
});
export default StateCardList;
