import { observer } from "mobx-react";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef
} from "react";
import Indent from "./Indent";
import Chunk from "./Chunk";
import Rubric from "./Rubric";
import List from "./List";
import ListItem from "./ListItem";
import Table from "./Table";
import TableRow from "./TableRow";
import TableCell from "./TableCell";
import CodeLine from "./CodeLine";
import Code from "./Code";
import FlatItem from "./FlatItem";
import Picture from "./Picture";
import InlinePicture from "./InlinePicture";
import Caption from "./Caption";

import "../css/text.scss";
import Formula from "./Formula";
import InlineFormula from "./InlineFormula";
import ScrollSpacer from "./ScrollSpacer";


const FlatList = observer((props) => {
  const {
    flatItemsArray, 
    setContextMenu,
    dataStore
  } = props;

  const { 
    topSpacerHeight, 
    bottomSpacerHeight, 
    scrollTop,
    windowTopIndex, 
    windowBottomIndex 
  } = dataStore;

  const element = useRef(null);

  useEffect(() => {
    const current = element && element.current;
    if (current && current.scrollTop !== scrollTop) {
      current.scrollTo({ top: scrollTop });
    }
  }, [scrollTop, element && element.current]);

  const sensor = useMemo(() => {
    return new ResizeObserver((entries) => {
      const rect = entries[0] && entries[0].contentRect;
      if (rect) {
        dataStore.processResize(rect.width);
      }
    });
  }, []);

  useEffect(() => {
    if (element && element.current) {
      if (sensor) {
        sensor.observe(element.current);
      }
    }
    return () => {
      sensor && sensor.disconnect();
    };
  }, [sensor, element && element.current]);

  const blankSpaceClick = useCallback(() => {
    dataStore.setHoverTableUid();
  }, [dataStore]);

  const renderItem = useCallback((itemData) => {
    let itemRender = null;
    if (!itemData) {
      return null;
    }
    const { className, uid } = itemData;
    switch (className) {
      case "text.chunk.Varchar":
      case "text.chunk.InlineCode":
        itemRender = <Chunk key={uid} data={itemData} />;
        break;
      case "text.form.Text":
        itemRender = null;
        break;
      case "text.container.Rubric":
        itemRender = (
          <Rubric
            setContextMenu={setContextMenu}
            key={uid}
            data={itemData}
            renderItem={renderItem}
          />
        );
        break;
      case "text.element.Indent":
        itemRender = (
          <Indent
            setContextMenu={setContextMenu}
            key={uid}
            data={itemData}
            renderItem={renderItem}
          />
        );
        break;
      case "text.element.Picture":
        itemRender = (
          <Picture
            setContextMenu={setContextMenu}
            key={uid}
            data={itemData}
            renderItem={renderItem}
          />
        );
        break;
      case "text.chunk.InlinePicture":
        itemRender = (
          <InlinePicture
            setContextMenu={setContextMenu}
            key={uid}
            data={itemData}
            renderItem={renderItem}
          />
        );
        break;
      case "text.element.Formula":
        itemRender = (
          <Formula
            setContextMenu={setContextMenu}
            key={uid}
            data={itemData}
            renderItem={renderItem}
          />
        );
        break;
      case "text.chunk.InlineFormula":
        itemRender = (
          <InlineFormula
            setContextMenu={setContextMenu}
            key={uid}
            data={itemData}
            renderItem={renderItem}
          />
        );
        break;
      case "text.element.Caption":
        itemRender = (
          <Caption
            setContextMenu={setContextMenu}
            key={uid}
            data={itemData}
            renderItem={renderItem}
          />
        );
        break;
      case "text.element.Enumeration":
        itemRender = (
          <List
            setContextMenu={setContextMenu}
            key={uid}
            data={itemData}
            renderItem={renderItem}
          />
        );
        break;
      case "text.container.EnumerationItem":
        itemRender = (
          <ListItem
            setContextMenu={setContextMenu}
            key={uid}
            data={itemData}
            renderItem={renderItem}
          />
        );
        break;
      case "text.element.Table":
        itemRender = (
          <Table
            setContextMenu={setContextMenu}
            key={uid}
            data={itemData}
            renderItem={renderItem}
          />
        );
        break;
      case "text.container.TableRow":
        itemRender = (
          <TableRow
            setContextMenu={setContextMenu}
            key={uid}
            data={itemData}
            renderItem={renderItem}
          />
        );
        break;
      case "text.container.TableCell":
        itemRender = (
          <TableCell
            setContextMenu={setContextMenu}
            key={uid}
            data={itemData}
            renderItem={renderItem}
          />
        );
        break;
      case "text.element.code":
      case "text.element.CodeBlock":
        itemRender = (
          <Code
            setContextMenu={setContextMenu}
            key={uid}
            data={itemData}
            renderItem={renderItem}
          />
        );
        break;
      case "text.element.code.line":
      case "text.element.CodeLine":
        itemRender = (
          <CodeLine
            setContextMenu={setContextMenu}
            key={uid}
            data={itemData}
            renderItem={renderItem}
          />
        );
        break;
      default:
        break;
    }
    return itemRender;
  }, [setContextMenu]);

  const onScroll = useCallback((e) => {
    if (e.target) {
      dataStore.setScrollTop(e.target.scrollTop);
    }
  }, []);
  
  const onWheel = useCallback((e) => {
    if (e.nativeEvent) {
      dataStore.setScrollDelta(e.nativeEvent.wheelDelta);
    }
  }, []);
  
  const itemsRendered = useMemo(() => {
    if (!flatItemsArray) {
      return null;
    }
    const items = [];
     
    if (windowTopIndex > 0) {
      items.push(
        <ScrollSpacer
          key="top" root={element && element.current} dataStore={dataStore}
          position={"top"}
        />
      );
    }

    for (let index = windowTopIndex; index < windowBottomIndex; index += 1) {
      const item = flatItemsArray[index];
      items.push(
        <FlatItem
          key={item.uid} 
          renderItem={renderItem} 
          root={element && element.current}
          item={item}
          dataStore={dataStore}
        />
      );
    }
          
    items.push(
      <ScrollSpacer
        key="bottom" root={element && element.current} dataStore={dataStore}
        position={"bottom"}
      />
    );
    return items;
  }, [
    flatItemsArray, 
    renderItem, 
    windowTopIndex, 
    windowBottomIndex, 
    element && element.current, 
    topSpacerHeight, 
    bottomSpacerHeight
  ]);

  return (
    <div
      ref={element} 
      onScroll={onScroll} 
      onWheel={onWheel}
      onClick={blankSpaceClick}
      className={"flat-list"}
      style={{ overflow: "auto",  flex: 1 }}
    >
      {itemsRendered}
      <div key="bottom-scroll-space" style={{ height: "calc(100% - 4rem)" }}></div>
    </div>
  );
});

export default FlatList;
