import React, { useCallback, useState, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Components } from "@ais3p/ui-framework";
import MarkdownView from "../view/MarkdownView";
import { IssueModel } from "../../models";

/**
 * Редактор описания задачи
 *
 * @param {Object} props набор параметров
 * @param {IssueMode} params.issue задача
 * @param {String} params.name имя поля  
 * @param {String} params.text  текстовое значение поля
 * @param {String} params.label  заголовок поля
 * @param {String} params.className  пользовательский className
 * @param {Boolean} params.canBeEditable можно ли редактировать текст да- true, нет - false
 * @param {Function} params.onChange callback ф-я при изменнеия тектового содержимого поля
 */
const DescriptionEditor = ({
  className,
  issue,
  text,
  name = "description",
  onChange,
  canBeEditable,
  label = "Описание проблемы"
}) => {
  const [isEditingMode, setIsEditingMode] = useState(
    issue && issue.isEditingMode && canBeEditable
  );

  useEffect(() => {
    setIsEditingMode(issue.isEditingMode && canBeEditable);
  }, [canBeEditable, issue.isEditingMode]);

  const onDeactivateEditingMode = useCallback(() => {
    setIsEditingMode(false);
  }, []);

  const onActivateEditingMode = useCallback(() => {
    if (issue.isEditingMode) {
      setIsEditingMode(true);
    }
  }, [issue.isEditingMode]);

  const onTextChange = useCallback((e) => {
    if (onChange) {
      onChange(e);
    }
  }, [onChange]);
  return (
    <div className={`${className} issue-editor-description`}>
      <div className={"toolbar"}>
        <div className={"toolbar-title"}>
          {isEditingMode
            ? `${label} (редактирование, доступен markdown)`
            : `${label} (предпросмотр)`}
        </div>
        <div className={"toolbar-buttons"}>
          {canBeEditable && (
            <React.Fragment>
              <div
                className={classNames("toolbar-buttons-button", {
                  active: !isEditingMode
                })}
                onClick={onDeactivateEditingMode}
              >
                <Components.Icon name={"review-M"} />
              </div>
              <div
                className={classNames("toolbar-buttons-button", {
                  active: isEditingMode
                })}
                onClick={onActivateEditingMode}
              >
                <Components.Icon name={"mode-edit-M"} />
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
      <div className={"issue-editor-description-text"}>
        {isEditingMode && (
          <textarea
            type="text"
            // autoFocus={true}
            rows={8}
            name={name}
            value={text}
            onChange={onTextChange}
          />
        )}
        {!isEditingMode && <MarkdownView text={text} />}
      </div>
    </div>
  );
};

DescriptionEditor.propTypes = {
  issue:         PropTypes.instanceOf(IssueModel),
  className:     PropTypes.string,
  text:          PropTypes.string,
  name:          PropTypes.string,
  label:         PropTypes.string,
  canBeEditable: PropTypes.bool,
  onChange:      PropTypes.func
};


export default DescriptionEditor;
