import React, { useCallback, useEffect, useState } from "react";
import { observer } from "mobx-react";
import useStores from "~/core/utils/useStores";
import { Components, Field } from "@ais3p/ui-framework";
import "./css/machineList.scss";

const MachinesList = observer(({ values, object }) => {
  const { workflowStore } = useStores();
  const [machinesSelect, setMachinesSelect] = useState();
  const [options, setOptions] = useState([]);
  const [select, setSelect] = useState(null);
  const [isFormVisible, setIsFormVisible] = useState(false);

  useEffect(() => {
    const currentWorkflows = Array.from(workflowStore.workflows.values());
    
    if (currentWorkflows.length > 0 && values) {
      const machinesSelectValue =
        values.filter((machine) => {
          return !currentWorkflows.some((workflow) => {
            return workflow.machine.id === machine.id;
          });
        });
      setMachinesSelect(machinesSelectValue);
    } else {
      setMachinesSelect(values);
    }
  }, [workflowStore]);

  useEffect(() => {
    const newOptions = machinesSelect?.map((machine) => {
      return {
        label: machine.name,
        value: machine.id
      };
    });
    setOptions(newOptions ? newOptions : []);
  }, [machinesSelect]);

  const onToggleForm = useCallback(() => {
    setIsFormVisible(!isFormVisible);
  }, [isFormVisible]);

  const handleMashineBtn = useCallback((machineId) => {
    workflowStore.createWorkflow(object, machineId);
  }, [workflowStore, object]);

  return (
    <div className={`new-item ${isFormVisible ? "form-expanded" : ""}`}>
      <div className={`machine-toggle-btn ${isFormVisible ? "close" : ""} `}>
        <Components.Button
          icon={isFormVisible ? "close-M" : "plus-M"}
          text={isFormVisible ? "Закрыть" : "Назначить регламент"}
          color="default"
          onPress={onToggleForm}
        />
      </div>
      {isFormVisible && (
        <div className="form-holder">
          {options.length > 0 ?
            <div className="machines-list">
              <Field.SingleSelect 
                options={options}
                label="Выберите регламент из списка:"
                isClearable={true}
                onChange={setSelect}
                value={select}
                name="workflow-select"
                placeholder="Выбрать..."
              />
              <div className="machines-list__btn">
                <Components.Button 
                  text="Активировать регламент"
                  color="action"
                  // eslint-disable-next-line react/jsx-no-bind
                  onPress={() => {
                    return handleMashineBtn(+select);
                  }}
                />
              </div>
            </div>
            :
            <span className="machines-empty">Доступные регламенты уже запущены</span>
          }
        </div>
      )}
    </div>
  );
});

export default MachinesList;
