import React  from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import TableRow from "./TableRow";
import TableHeader from "./TableHeader";

import TraceStore from "../../stores/traceStore";
import TraceSchemaModel from "../../models/traceSchema";

/**
 * Таблица результата GAP анализа или трассировки.
 * @param {Object} props набор параметров
 * @param {String} props.type тип отчета - трассировка("trace")| GAP анализ ("gap")
 * @param {Array<Object>} props.data набор данных
 * @param {TraceStore} props.store хранилище
 * @param {TraceSchema} props.schema схема трассировки
 */
const Table = observer(({ schema, type, store, data = [] }) => {
  return (
    <table className="tracer-table">
      <TableHeader type={type} schema={schema} />
      <tbody>
        {data.map((item, i) => {
          return (
            <TableRow
              key={i}
              type={type}
              num={i + 1}  
              itemRow={item}
              store={store}
              schema={schema}
            />
          );
        })}
      </tbody>
    </table>
  );
});

Table.propTypes = {
  store:  PropTypes.instanceOf(TraceStore).isRequired,
  schema: PropTypes.instanceOf(TraceSchemaModel),
  type:   PropTypes.string,
  data:   PropTypes.arrayOf(Object)
};


export default Table;
