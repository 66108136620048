import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import Target from "~/core/components/Target";
import { Components } from "@ais3p/ui-framework";
import AisIcon from "~/core/components/AisIcon";
import RepoStore from "../../stores/RepoStore";

const TargetPanel = observer(
  ({ store, repositoryId, trackedItem, onTargetClick }) => {
    if (trackedItem) {
      return <Target trackedItem={trackedItem} onTargetClick={onTargetClick} />;
    }

    const [target, setTarget] = useState(undefined);
    const [pending, setPending] = useState(true);

    useEffect(async() => {
      setPending(true);
      try {
        const repo = await store.loadRepository(repositoryId);
        setTarget(repo);
      } finally {
        setPending(false);
      }
    }, [repositoryId]);

    return (
      <div className="object-holder">
        {pending && <Components.Preloader size={2} />}
        {!pending && (
          <div className="target-object" onClick={onTargetClick}>
            <AisIcon
              backgroundColor="#4d4d4d"
              icon={target ? target.icon : "cancel-M"}
            />
            <div className="object-name">
              {(target && target.title) || "Не задан объект для отслеживания"}
            </div>
          </div>
        )}
      </div>
    );
  }
);

TargetPanel.propTypes = {
  store:        PropTypes.instanceOf(RepoStore),
  repositoryId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number]), 
  trackedItem:   PropTypes.object, 
  onTargetClick: PropTypes.func
};

export default TargetPanel;
