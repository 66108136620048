import React from "react";
import { Modal, Components } from "@ais3p/ui-framework";
import PropTypes from "prop-types";

export default function InfoToolWindow({
  /**
   * @type {string}
   */
  content = "Информация отсутствует", 
  /**
   * State - открыто/закрыто модальное окно
   * @type {Boolean}
   */
  infoIsVisible, 
  /**
   * Ф-я для обработки открытия/закрытия модального окна по кнопке
   * @type {Function}
   */
  toggleInfoModal }) {
  if (infoIsVisible && toggleInfoModal) {
    const contentContainer = (
      <div className="ais-info-modal-content">{content}</div>
    );
    return (
      <Modal.Window
        name="info"
        show={infoIsVisible}
        icon="info-M"
        title={"Справка"}
        buttons={[
          <Components.Button 
            key="Ok"
            text="Ок"
            icon={"ok-M"}
            onPress={toggleInfoModal}
            color="action"
          />
        ]}
        onBackdropClick={toggleInfoModal}
        onKeyPressEnter={toggleInfoModal}
        onKeyPressEsc={toggleInfoModal}
      >
        {contentContainer}
      </Modal.Window>
    );
  }
}
InfoToolWindow.propTypes = {
  content:         PropTypes.string,
  toggleInfoModal: PropTypes.func,
  infoIsVisible:   PropTypes.bool
};
