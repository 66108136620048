import { observable, computed, action } from "mobx";

import CodeValueModel from "./CodeValueModel";
import ValueModel from "./ValueModel";

/**
 * Модель представитеял вида
 */
export default class MemberModel {
  /**
   * @type {String}
   * Уникальный идентификатор представителя Вида
   */
  @observable
  uid = undefined;

  /**
   * @type {String}
   * Уникальный идентификатор Вида
   */
  @observable
  kindUid = undefined;

  /**
   * @type {Map<CodeValueModel>}
   * Коды участника Вида
   */
  @observable
  codeValuesMap = new Map();

  /**
   * @type {Map<ValueModel>}
   * Значения аттрибутов участника Вида
   */
  @observable
  valuesMap = new Map();

  static create({ uid, kindUid, codeValues, values }, store) {
    return new  MemberModel({ uid, kindUid, codeValues, values }, store);
  }

  constructor(data, store) {
    this.store = store;

    this.uid = data.uid;
    this.kindUid = data.kindUid;
    
    if (Array.isArray(data.codeValues)) {
      data.codeValues.forEach((val) => {
        this.addCodeValue(CodeValueModel.create(val, store));
      });
    }
    if (Array.isArray(data.values)) {
      data.values.forEach((val) => {
        this.addValue(ValueModel.create(val, store));
      });
    }
  }

  /**
   * Получить название участника Вида
   * 
   * @returns {String} название участника Вида
   */
  @computed
  get title() {
    return  this.kindUid;
  }

  /**
   * Добавить код участника Вида
   * 
   * @param {CodeValueModel} codeValue код участника Вида
   */
  @action
  addCodeValue(codeValue) {
    codeValue && this.codeValuesMap.set(codeValue.uid, codeValue);
  }

  /**
   * Получить код участника Видапо uid атрибута
   * 
   * @param {String} uid uid код участника Вида
   * 
   * @returns {Any} значение кода
   */
  getCodeValue(uid) {
    return this.codeValuesMap.get(uid);
  }

  /**
   * Добавить значение участника Вида
   * 
   * @param {ValueModel} value значение участника Вида
   */
  @action
  addValue(value) {
    value && this.valuesMap.set(value.uid, value);
  }

  /**
   * Получить значение по uid атрибута
   * 
   * @param {String} uid uid атрибута
   * 
   * @returns {Any} значение атрибута
   */
  getValue(uid) {
    return this.valuesMap.get(uid);
  }

  @computed
  get codeValues() {
    const res = {};
    Array.from(this.codeValuesMap.entries()).forEach(([uid, codeValue]) => {
      res[uid] = codeValue.value;
    });

    return res;
  }

  @computed
  get values() {
    const res = {};
    Array.from(this.valuesMap.entries()).forEach(([uid, value]) => {
      res[uid] = value.value;
    });

    return res;
  }
}
