import { observer } from "mobx-react";
import React, { useCallback, useLayoutEffect, useMemo, useRef } from "react";
import AisIcon from "../../../core/components/AisIcon";
import PlusMenu from "./PlusMenu";
import { ContextMenu, Dnd } from "@ais3p/ui-framework";
const TableCell = observer(({ data, renderItem, setContextMenu }) => {
  const {
    items,
    uid,
    idsArray,
    isHeaderCell,
    isExpanded,
    isHovered,
    isHoverValid,
    cs,
    rs,
    tableUid,
    scrollItemId,
    diffClass
  } = data;

  const itemsRender = useMemo(() => {
    return items.map((itemData) => {
      return renderItem(itemData);
    });
  }, [idsArray, items]);

  const toggleExpanded = useCallback(
    (e) => {
      e.stopPropagation();
      data.setExpanded(!isExpanded);
    },
    [isExpanded]
  );

  const canDrop = useCallback((props, monitor) => {
    const item = monitor.getItem();
    return item.uid !== uid;
  }, [uid]);

  const onHover = useCallback(
    (props, monitor) => {
      const item = monitor.getItem();
      data.setHoveredById(item.uid);
    },
    [data]
  );

  const onMouseLeave = useCallback(() => {
    data.setExpanded(false);
  }, [data]);

  const contextMenuCollect = useCallback(
    () => {
      if (isHovered && isHoverValid) {
        setContextMenu([
          {
            icon:   "table-merge-M",
            title:  "Объеденить ячейки",
            action: "mergeCells"
          }
        ]);
      } else {
        const menuItems = [
          {
            icon:   "table-col-left-M",
            title:  "Вставить колонку слева",
            action: "insertColumnLeft"
          },
          {
            icon:   "table-col-right-M",
            title:  "Вставить колонку справа",
            action: "insertColumnRight"
          },
          {
            icon:   "table-row-top-M",
            title:  "Вставить строку сверху",
            action: "insertRowTop" 
          },
          {
            icon:   "table-row-bottom-M",
            title:  "Вставить строку снизу",
            action: "insertRowBottom" 
          }
        ];
  
        if (rs > 1) {
          menuItems.push({
            icon:   "table-divide-vert-M",
            title:  "Разбить ячейку по горизонтали",
            action: "splitCellVert"
          });
        } else {
          menuItems.push({
            icon:   "table-row-minus-M",
            title:  "Удалить строку",
            action: "deleteRow"
          });
        }
        if (cs > 1) {
          menuItems.push({
            icon:   "table-divide-hor-M",
            title:  "Разбить ячейку по вертикали",
            action: "splitCellHor"
          });
        } else {
          menuItems.push({
            icon:   "table-col-minus-M",
            title:  "Удалить колонку",
            action: "deleteColumn"
          });
        }
  
        setContextMenu(menuItems);
      }
    },
    [isHovered, cs, rs, setContextMenu, isHoverValid]
  );

  const canDrag = useCallback(() => {
    return !data.isVersion;
  }, [data.isVersion]);

  const endDrag = useCallback(
    (props, monitor) => {
      const did = monitor.didDrop();
      if (!did || !isHoverValid) {
        data.setHoveredById();
      }
    },
    [data, isHoverValid]
  );

  const element = useRef();

  useLayoutEffect(() => {
    if (
      uid &&
      scrollItemId &&
      uid === scrollItemId &&
      element &&
      element.current
    ) {
      setTimeout(() => {
        element.current.scrollIntoView(true);
      }, 10); // set timeout= DIRTY HACK
      // TODO: get rid of setTimeout()
    }
  }, [uid, scrollItemId, element && element.current]);

  return (
    <div
      ref={element}
      className={`table-cell ${diffClass} ${
        isHeaderCell ? "header-cell" : ""
      } ${isHovered ? "hovered" : ""} ${isHoverValid ? "valid" : "invalid"}`}
      style={{
        gridColumnEnd: cs ? `span ${cs}` : undefined,
        gridRowEnd:    rs ? `span ${rs}` : undefined
      }}
      onMouseLeave={onMouseLeave}
      id={uid}
    >
      <Dnd.Target
        hover={onHover}
        canDrop={canDrop}
        accept={[`table-cell-handle-${tableUid}`]}
        className="table-cell-dnd-target"
      />
      {itemsRender}
      {!itemsRender.length && (
        <AisIcon
          onClick={toggleExpanded}
          icon={"plus-M"}
          className={`expand-menu ${isExpanded ? "expanded" : ""}`}
        />
      )}
      {isExpanded && <PlusMenu data={data} />}
      <ContextMenu.Trigger
        context={data}
        menuId={data.editable}
        collect={contextMenuCollect}
        className="corner-holder"
      >
        <Dnd.Source
          canDrag={canDrag}
          end={endDrag}
          type={`table-cell-handle-${tableUid}`}
          item={data}
        >
          <AisIcon className={"icon"} item={data} />
        </Dnd.Source>
      </ContextMenu.Trigger>
    </div>
  );
});

export default TableCell;
