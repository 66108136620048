import { 
  DOMAIN_ISSUE, 
  DOMAIN_TEXT, 
  DOMAIN_LIBRARY,
  DOMAIN_REPO 
} from "~/core/constants/Domains";
import getDomainByClass from "./getDomainByClass";
import { CLS_LIBRARY_FILE } from "../constants/Classes";
import iconRender from "./IconRender";
/**
 * Получить набор параметров для открытия layout инструмента
 * Используется для перехода по ссылке.
 * Ф-я асснихронная, т.к. может потребоваться отдельный запрос для получения `uid` головного документа, для текстового
 * объекта, если текстовое представление самого документ не было загружено еще в нашего клиента (ObjectStore). 
 * Такое возможно если представление текстового элемента было отображено в таких инструментах как: 
 * `Связи`, `Анализ связей`.
 * 
 * @param {Object} item объект системы
 * @param {objectStore} objectStore глобальное хранилище
 * 
 * @return {Object} 
 */
const getPropsToOpenLayoutTool = async(item, objectStore) => {
  // Принадлежность к домену мы можем получить: от св-ва `domain`, от типа инструмента `tool`, от св-ва `class`  
  const domain = item.domain || item.tool || getDomainByClass(item.class);
  // Получаем представление объекта из ObjectStore
  const itemVersion = objectStore.getVersion(item.uid, domain, item.version);
  let name = itemVersion && (itemVersion.title || itemVersion.name);
  if (item && itemVersion) {
    let id = item.uid;
    let props = {
      focusUid:          item.uid,
      version:           item.version,
      useGlobalTracking: false
    };

    if (item.class === CLS_LIBRARY_FILE) {
      props = {
        type: item.fileType,
        name,
        icon: iconRender(item, true),
        file: item.fileId
      };
    }
    switch (domain) {
      case DOMAIN_LIBRARY:
        props = { 
          ...props,
          path: itemVersion.path || item.path
        };
        break;
      case DOMAIN_TEXT: {
        let editable = itemVersion.topmostKnownParentUid;
        // Если текстовое представление документа, в который входит наш элемент не был еще загружен, то
        // получаем uid этого текстового представления
        if (!editable) {
          editable = await objectStore.loadEditableUid(item.uid, item.version);
        }
        // получаем ноду Библиотеки
        const node = await objectStore.getLibraryNodeByEditableUid(editable, item.version);
        if (node) {
          name = node.title;
        }
        id = editable;
        props = {
          ...props,
          id:      editable, // необходимо, чтобы не открывать несколько раз один и тот же документ в разных вкладках
          editable,
          version: item.version
        };
        break;
      }
      case DOMAIN_ISSUE: {
        // указываем `uid` проекта, к которому относится задача
        // id = itemVersion.project.uid;
        props = {
          ...props,
          id
        };
        break;
      }
      case DOMAIN_REPO: {
        name = "Репозиторий";
        props = {
          ...props,
          editable: itemVersion.repositoryId || item.repositoryId,
          path:     itemVersion.path && itemVersion.path.split("/")
        };
        if (itemVersion.startLine) {
          props.focusCodeLine = itemVersion.startLine;
        }
        break;
      }
    }

    return {
      id,
      name,
      props
    };
  }

  return undefined;
};


export default getPropsToOpenLayoutTool;
