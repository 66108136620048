import { observable, action } from "mobx";
import State from "./state";
import Transition from "./transition";

/**
 * Модель для хранения инофрмации о машине
 * 
 * @type {Machine}
 */
class Machine {
  /**
   * @type {Number}
   * id машины
   */
  @observable id;

  /**
   * @type {String}
   * имя машины
   */
  @observable name;

  /**
   * @type {Map<State>}
   * коллекция всех возможных состояний
   */
  @observable states = new Map();

  /**
   * @type {Map<Transition>}
   * коллекция всех возможных переходов
   */
  @observable transitions = new Map();

  constructor(data) {
    this.id = data.id;
    this.name = data.name;
    this.initialize(data.states, data.transitions);
  }

  initialize(statesData, transitionsData) {
    statesData.forEach((state) => {
      this.states.set(state.id, new State(state));
    });

    transitionsData.forEach((transition) => {
      this.transitions.set(transition.id, new Transition(transition, this));
    });
  }
@action
  getStateById(id) {
    const idS = this.states.get(id);
    return idS;
  }
  // Получить доступные переходы из текущего состояния
@action
getAvailableTransitions(stateId) {
  const availableTransitions = [];
  const currentState = this.states.get(stateId);
  
  this.transitions.forEach((transition) => {
    if (transition.fromState === currentState) {
      availableTransitions.push(transition);
    }
  });
  return availableTransitions;
}
}

export default Machine;
