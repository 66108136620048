import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";

import { Components } from "@ais3p/ui-framework";

import Highlight from "./Highlight";
// import theme from "./themes/vsDark";
import theme from "./themes/github";
import { changeContext } from "../../utils";
import RepoStore from "../../stores/RepoStore";
import CodeFile from "../../models/CodeFile";


/**
 * Компонент для отображения кода
 * @param {Object} props передаваемые св-ва
 * @param {CodeFile} props.codeFile объект, содержащий инофрмацию о просматриваемом файле
 * @param {RepoStore} props.store хранилище  для работы с репозиторием
 * @param {LayoutItem} props.layoutItem панель layout
 *
 * @type {CodeView}
 * @returns {Component}
 */
const CodeView = observer(({ codeFile, store, layoutItem }) => {
  useEffect(() => {
    if (codeFile.focusCodeObject) {
      changeContext(
        layoutItem,
        codeFile.focusCodeObject.repositoryId,
        codeFile.focusCodeObject
      );
    } else {
      changeContext(layoutItem);
    }
  }, [layoutItem, codeFile.focusCodeObject]);

  if (codeFile.isPending) {
    return (
      <div className="preloader-wrapper">
        <Components.Preloader size={3} />
      </div>
    );
  }

  return (
    <div className="code-content">
      {!codeFile.content && (
        <div className="code-content-empty">{"Нет содержимого в файле."}</div>
      )}
      {codeFile.content && (
        <div className="prism-container">
          <Highlight codeFile={codeFile} store={store} theme={theme} />
        </div>
      )}
    </div>
  );
});

CodeView.propTypes = {
  codeFile:   PropTypes.instanceOf(CodeFile), 
  store:      PropTypes.instanceOf(RepoStore),
  layoutItem: PropTypes.object
};

export default CodeView;
