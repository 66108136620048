import React, { useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { Dnd } from "@ais3p/ui-framework";
import Target from "~/core/components/Target";
import useStores from "~/core/utils/useStores";
import { DOMAIN_LIBRARY } from "~/core/constants/Domains";
import { DND_NODE_TYPE } from "~/core/constants/DnD";

/**
 * Компонент, который отображает drop место (в которое необходимо принести FRS объект (версия РМ)) 
 * или уже выбранный (obj) FRS объект
 * 
 * @param {Object} obj уже заданный FRS объект
 * @param {Func} onSetFrs callback ф-я на задание (drop) объекта
 * @param {String} frsKindName название Вида FRS объекта, который нужно сюда принести
 */
const FrsTarget = observer(({ onSetFrs, obj, frsKindName }) => {
  const { objectStore } = useStores();
  const canDrop = useCallback((props, monitor) => {
    const item = monitor.getItem();
    const { value: libraryNode } = item;
    const itemVersion = objectStore.getVersion(libraryNode.uid, DOMAIN_LIBRARY);
    let isRightKind = false;
    if (itemVersion && itemVersion.parent && itemVersion.parent.kindsRepresentation) {
      const kindNamesSet = itemVersion.parent.kindsRepresentation.kindNamesSet;
      isRightKind = kindNamesSet.has(frsKindName);
    }
    return libraryNode.number > 0 && isRightKind;
  }, [frsKindName]);

  const trackedItem = useMemo(() => {
    if (!obj) {
      return undefined;
    }

    return {
      uid:  obj.uid,
      tool: obj.tool
    };
  }, [obj]);
  
  const onDrop = useCallback((prps, monitor) => {
    const item = monitor.getItem();
    const { value: libraryNode } = item;
    const { uid } = libraryNode;
    const version = libraryNode.number;
    const obj = {
      id:   uid,
      uid,
      version,
      tool: DOMAIN_LIBRARY
    };
    onSetFrs && onSetFrs(obj);
  }, [onSetFrs]);
  
  return (
    <div className="frs-target">
      {!trackedItem && (
        <Dnd.Target
          drop={onDrop}
          canDrop={canDrop}
          accept={[DND_NODE_TYPE]}
          className="frs-target-empty"
        >
          <div className="empty">
            версия {frsKindName}
          </div>
        </Dnd.Target>
      )}
      {trackedItem && <Target trackedItem={trackedItem} />}
    </div>
  );
});

FrsTarget.propTypes = {
  obj:         PropTypes.object,
  onSetFrs:    PropTypes.func,
  frsKindName: PropTypes.string
};

export default FrsTarget;
