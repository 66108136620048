import React from "react";
import PropTypes from "prop-types";

/**
 * Заголовок табличного представления результата CheckList
 * 
 * @param {Boolean} isTP 
 * @param {Boolean} isURS 
 */
const TableHeader = ({ isTP, isURS }) => {
  if (isTP) {
    return (
      <thead >
        <tr>
          <th className="frs-num">
            <div>
              Test #
            </div>  
          </th>
          <th>
            <div>
              Test Case
            </div>
          </th>
          <th>
            <div>
              FRS requirement
            </div>
          </th>
          <th>
            <div>
              Version
            </div>
          </th>
          <th className="user-value">
            <div>
              Test result
            </div>
          </th>
          <th className="user-value">
            <div>
              Comment
            </div>
          </th>
        </tr>
      </thead>
    );
  }

  if (isURS) {
    return (
      <thead >
        <tr>
          <th className="frs-num">
            <div>
              URS Reference #
            </div>  
          </th>
          <th>
            <div>
              Requirement
            </div>
          </th>
          <th className="user-value">
            <div>
              Is the Requirement fully met?
            </div>
          </th>
          <th className="user-value">
            <div>
              Comment
            </div>
          </th>
        </tr>
      </thead>
    );
  }

  return (
    <thead >
      <tr>
        <th className="frs-num">
          <div>
            URS Reference #
          </div>  
        </th>
        <th>
          <div>
            Requirement
          </div>
        </th>
        <th>
          <div>
            Design Reference #
          </div>
        </th>
        <th className="user-value">
          <div>
            Is the Requirement fully met?
          </div>
        </th>
        <th className="user-value">
          <div>
            Mitigation steps needed if requirement not met (considering risk level)
          </div>
        </th>
        <th className="user-value">
          <div>
            Where Veriffied?
          </div>
        </th>
      </tr>
    </thead>
  );
};

TableHeader.propTypes = {
  isTP:  PropTypes.bool,
  isURS: PropTypes.bool
};

export default TableHeader;
