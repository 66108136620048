import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";

/**
 * Тектовое поле для набора текста
 * 
 * @param {Object} props набор параметров
 * @param {String} params.name имя поля  
 * @param {String} params.text  текстовое значение поля
 * @param {String} params.className  пользовательский className
 * @param {Boolean} params.readOnly поле для чтения - true или в режиме редактирования - false
 * @param {Function} params.onChange callback ф-я при изменнеия тектового содержимого поля
 */
const ContentEditable = (props) => {
  const { className, text, name, readOnly } = props;
  const [initialValue] = useState(text);

  const onChange = useCallback((e) => {
    const value = e.target.textContent;

    if (props.onChange) {
      props.onChange({
        target: {
          name,
          value
        }
      });
    }
  }, [name, text]);

  return (
    <div
      className={className}
      onInput={onChange}
      onBlur={onChange}
      contentEditable={!readOnly}
      suppressContentEditableWarning={true}
      dangerouslySetInnerHTML={{ __html: initialValue }}
    />
  );
};

ContentEditable.propTypes = {
  className: PropTypes.string,
  text:      PropTypes.string,
  name:      PropTypes.string,
  readOnly:  PropTypes.bool,
  onChange:  PropTypes.func
};

export default React.memo(ContentEditable, (props, nextProps) => {
  if (props.text === nextProps.text && props.readOnly === nextProps.readOnly) {
    // don't re-render/update
    return true;
  }
  return false;
});
