/**
 * SidePanel инструмента Journal
 */
import React, { useMemo, useEffect } from "react";
import { observer } from "mobx-react";

import Target from "~/core/components/Target";
import useStores from "~/core/utils/useStores";

import JournalStore from "../stores/journalStore";
import JournalList from "../components/records/JournalList";
import infoToolContent from "~/core/components/InfoToolWindow/infoToolContent.js";

import "./css/index.scss";

const JournalSidepanel = observer((props) => {
  const { trackedItem } = props;

  const { rootStore } = useStores();
  const journalStore = useMemo(() => {
    return new JournalStore(rootStore);
  }, []);

  useEffect(() => {
    if (trackedItem && trackedItem.uid) {
      journalStore.setFilters({
        object: trackedItem.uid,
        action: ["create", "update", "delete"],
        order:  "desc"
      });
    }
  }, [trackedItem && trackedItem.uid]);

  const showContent = useMemo(() => {
    return trackedItem && !!trackedItem.uid;
  }, [trackedItem && trackedItem.uid]);

  return (
    <div className="journal-sidepanel">
      <Target trackedItem={trackedItem} info={infoToolContent.journal} />
      <div className="journal-sidepanel-content">
        {!showContent &&
          <div className="no-content">
            Не задан объект для отслеживания
          </div>
        }
        {showContent &&
          <JournalList store={journalStore} />
        }
      </div>
    </div>
  );
});

export default JournalSidepanel;
