import React from "react";
import { observer } from "mobx-react";

import ChildButton from "./ChildButton";
import { Components } from "@ais3p/ui-framework";

@observer
class DropButton extends React.Component {
  constructor(props) {
    super(props);

    this.onClick = this.onClick.bind(this);
    this.canDrag = this.canDrag.bind(this);
  }

  onClick() {
    const { onClick, item, mode } = this.props;
    if (onClick) {
      if (mode) {
        onClick(mode);
      } else {
        onClick(item);
      }
    }
  }

  canDrag() {
    return this.props.canDrag;
  }

  render() {
    const { icon, children, getPresset } = this.props;
    const attributes = {};

    const childArray = children.map((child) => {
      return <ChildButton getPresset={getPresset} key={child.id} {...{ ...this.props, ...child }} />;
    });
    return (
      <div className={"button-wrapper"} {...attributes}>
        <Components.Button
          size={3}
          icon={icon}
        />
        <div className={"children"}>{childArray}</div>
      </div>
    );
  }
}

export default DropButton;
