import React, { useCallback, useRef, useState } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { Components } from "@ais3p/ui-framework";

import AttachmentItem from "./AttachmentItem";
import IssueStore from "../../stores/IssueStore";
import { IssueModel } from "../../models";

/**
 * Компонент для отображения списка прикрепленных файлов к задаче
 * 
 * @param {Object} props набор параметров
 * @param {IssueStore} props.store хранилище для работы с задачами
 * @param {IssueModel} props.issue текущая задача
 * @param {String} props.title заголовок панели списка задач
 * @param {String} props.className пользовательский className
 * @param {Boolean} props.readOnly флаг указывающий, что файлы в спсиске только можно просмматривать
 * @param {Function} props.onAddFile callback функция при добавалении нового файла в список
 * @param {Function} props.onDeleteFile callback функция при удалении файла из списка
 */
const Attachments = observer(
  ({
    store,
    issue,
    title = "Файлы",
    className,
    readOnly,
    onAddFile,
    onDeleteFile
  }) => {
    const fileInputEl = useRef(null);
    const [isUploading, setIsUploading] = useState(false);

    const uploadFile = async(file) => {
      setIsUploading(true);
      try {
        const attachment = await store.uploadFile(file);
        if (attachment) {
          issue.addAttachment(attachment);
          if (onAddFile) {
            onAddFile(attachment);
          }
        }
      } finally {
        setIsUploading(false);
      }
    };

    const onSelectFile = useCallback((e) => {
      const file = e.target.files[0];
      uploadFile(file);
    }, []);

    const onClickAddFile = useCallback(() => {
      if (readOnly) {
        return;
      }
      fileInputEl.current.value = null;
      fileInputEl.current.click();
    }, [readOnly]);

    return (
      <div className={`issue-attachments ${className ? className : ""}`}>
        <div className={"issue-attachments-body"}>
          <div className={"issue-attachments-title"}>{`${title}:`}</div>
          <div className={"issue-attachments-list"}>
            {issue.attachmentList.map((attachment, i) => {
              return (
                <AttachmentItem
                  key={i} // тк могут быть прикреплены несколько раз одинаковые файлы - T4277
                  issue={issue}
                  attachment={attachment}
                  store={store}
                  readOnly={readOnly}
                  onDeleteFile={onDeleteFile}
                />
              );
            })}
          </div>
        </div>
        <div className={"issue-attachments-bottombar"}>
          <input
            type="file"
            multiple={false}
            name={"uploadFile"}
            ref={fileInputEl}
            onChange={onSelectFile}
            style={{ display: "none" }}
          />
          <Components.Button
            icon="plus-M"
            text={"Добавить"}
            tooltip="Добавить новый файл"
            color="action"
            isLoading={isUploading}
            isDisabled={readOnly}
            onPress={onClickAddFile}
          />
        </div>
      </div>
    );
  }
);

Attachments.propTypes = {
  title:        PropTypes.string,
  className:    PropTypes.string,
  store:        PropTypes.instanceOf(IssueStore), 
  issue:        PropTypes.instanceOf(IssueModel), 
  readOnly:     PropTypes.bool, 
  onAddFile:    PropTypes.func,
  onDeleteFile: PropTypes.func
};

export default Attachments;
