import React, { useState, useMemo, useCallback, useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";

import { Components } from "@ais3p/ui-framework";
import Target from "~/core/components/Target";

import Table from "../components/table/Table";
import useStores from "~/core/utils/useStores";
import CheckListStore from "../stores/checkListStore";
import FrsTarget from "../components/frs/FrsTarget";

import "./css/check-list.scss";

/**
 * Инструмент для заполнения чеклиста на пример DQ
 * 
 * @param {String} id id отслеживаемого объекта через context
 * @param {String} version номер версии отслеживаемого объекта через context
 * @param {Object} trackedItem данные об отслеживаемом объекте в Layout
 */
const CheckListTool = observer((props) => {
  const {
    id,
    version,
    trackedItem
  } = props;

  const {
    rootStore,
    objectStore

  } = useStores();

  const [isPending, setIsPending] = useState(false);
  const [frs, setFrs] = useState();

  const store = useMemo(() => {
    const  store  = new CheckListStore({ rootStore, id });
    return store;
  }, [id]);

  
  const urs = useMemo(() => {
    const item = objectStore.getVersion(trackedItem.uid, trackedItem.tool, trackedItem.version);
    if (item) {
      store.setUrs(item);
      return { id: item.uid, version: item.number };
    }
    
    return null;
  }, [trackedItem, id, version, store]);

  const doTraceUrs = useCallback(async() => {
    setIsPending(true);
    try {
      await store.doTrace(urs);
    } finally {
      setIsPending(false);
    }
  }, [store, urs]);

  useEffect(() => {
    if (store.isURS && !isPending) {
      doTraceUrs();
    }
  }, []);

  const showContent = useMemo(() => {
    return (!!frs || store.isURS) && !!urs && store.dataList.length > 0;
  }, [!!frs, !!urs, store.dataList.length]);

  const target = useMemo(() => {
    return trackedItem && 
      <Target 
        trackedItem={trackedItem} 
      />;
  }, [trackedItem, id]);

  const onSetFrs = useCallback((obj) => {
    store.setFrs(obj);
    setFrs(obj);
  }, [store]);

  const onClearFrs = useCallback(async() => {
    setFrs(undefined);
    store.init();
  }, [store]);

  const onTrace = useCallback(async() => {
    if (!!frs && !!urs) {
      setIsPending(true);
      try {
        await store.doTrace(urs, frs);
      } finally {
        setIsPending(false);
      }
    }
  }, [id, frs, urs]);

  const onComplete = useCallback(() => {
    store.saveAllItemsToLocalStorage();
  }, [store]);
  
  return (
    <div className="check-list-tool">
      <div className="check-list-parameters">
        {target}
        {!store.isURS && (
          <Fragment>
            <div className="divider">
              <Components.Icon name="arrow-right-M" />
            </div>
            <FrsTarget
              obj={frs} 
              onSetFrs={onSetFrs}
              frsKindName={store.frsKindName}
            />
            <div className="check-list-parameters-controls">
              <Components.Button
                color="negative"
                isDisabled={!frs || isPending}
                icon="delete-M"
                onPress={onClearFrs}
              />
              <Components.Button
                color="positive"
                isDisabled={showContent || isPending}
                isLoading={isPending}
                icon="ok-M"
                onPress={onTrace}
              />
            </div>
          </Fragment>
        )}
      </div>
      <div className="check-list-content">
        {isPending && <Components.Preloader size={3} className={"check-list-preloader"} />}
        {showContent && 
          <React.Fragment>
            <div className="check-list-content-data">
              <Table
                store={store}
                trackedItem={trackedItem}
                uid={id}
                version={version}
              />
            </div>
            <div className="check-list-content-controls">
              <Components.Button
                color="positive"
                icon="ok-M"
                text="Подтвердить"
                isDisabled={!store.traceDataIsComplete}
                onPress={onComplete}
              />
            </div>
          </React.Fragment>
        }
      </div>
    </div>
  );
});

CheckListTool.propTypes = {
  id:          PropTypes.string,
  version:     PropTypes.string,
  trackedItem: PropTypes.object
};

export default CheckListTool;
