export const infoToolContent = {
  library:        "Инструмент «Библиотека» предназначен для управления иерархической структурой проекта, состоящей из проектов, спецификаций и версий спецификаций. Для того, чтобы открыть спецификацию (или любой другой объект в системе АИС), необходимо установить курсор на объект (спецификацию), который необходимо открыть. Затем выполнить из контекстного меню команду «Открыть редакцию».",
  text:           "Инструмент «Редактор текста» предназначен для просмотра и редактирования спецификации. Здесь отображается текущее состояние наполнения спецификации, полученное в результате внесения изменений относительно последней версии и доступное для просмотра и редактирования всем пользователям. У спецификации может быть только одна редакция, соответствующая последнему состоянию, сохраненному в качестве редакции (повторное сохранение редакции замещает предыдущую редакцию). Для добавления объектов в спецификации АИС необходимо щёлкнуть по кнопке «+» в рабочей области спецификации и выбрать на появившейся панели инструментов желаемые действия. ",
  traceAnalyzer:  "Инструмент «Анализ связей» позволяет вывести отчет о связанных объектах требований, что позволяет поддерживать данные в инструментах в согласованном и непротиворечивом состоянии, анализировать степень покрытия, анализировать влияние изменений и гарантированно вносить изменения во все связанные данные. Для создания отчёта необходимо выбрать схему трассировки, далее с помощью мыши перетащить документы для анализа трассировочных связей в поля «Содержится в» и щелкнуть по кнопке «Трассировка». «GAP Анализ» показывает все требования, у которых отсутствует связь с источником.",
  kinds:          "Инструмент «Виды и атрибуты» предназначен для просмотра и редактирования пользователями значений атрибутов объектов. На панели отображены атрибуты данного вида. Для того, чтобы добавить новый вид следует нажать кнопку «+». На экране необходимо ввести значения атрибутов вида и нажать кнопку «Сохранить».",
  relations:      "Инструмент «Связи» предназначен для создания, просмотра и удаления связей объекта. Для установления трассировочной связи необходимо щёлкнуть по кнопке «+», выбрать тип связи, при помощи мыши перетащить объект для связывания на плашку «Перетащите объект», щёлкнуть на галочку для создания трассировочной связи.",
  journal:        "Инструмент «Журнал изменений» позволяет фиксировать и отображать изменения, которые происходят с объектами системы. Cостоит из записей, соответствующих моментам времени, когда были проведены изменения.",
  issues:         "Инструмент «Задачи» предназначен для работы с сообщениями о проблемах, запросами на изменение.",
  repo:           "Инструмент «Репозиторий» предназначен для просмотра истории изменений, а также просмотра ветвей, структуры файлов и самих файлов в рамках репозитория.",
  searchEntities: "Инструмент «Поиск артефактов» позволяет осуществить поиск сущностей по виду, коду, атрибуту."
};
export default infoToolContent;
