import Server from "~/core/api/server";


class IssueApi extends Server {
  methods = {
    loadIssues: {
      // Загрузить список задач для проекта
      url: (params) => {
        return this.getServiceUrl("tasks", this.template`api/v1/projects/${0}/issues`, params);
      },
      method:      "GET",
      textProcess: "Загрузка списка задач...",
      textSuccess: null,
      textError:   this.template`Во время загрузки списка задач произошла ошибка: ${0}`,
      timeout:     300000

    },
    loadIssuesByFilter: {
      // Загрузить список задач с применеием фильтров
      url:         this.getServiceUrl("tasks", "api/v1/issues/filter"),
      method:      "POST",
      textProcess: "Загрузка списка задач...",
      textSuccess: null,
      textError:   this.template`Во время загрузки списка задач произошла ошибка: ${0}`,
      timeout:     300000
    },
    loadIssue: {
      // Загрузить данные задачи
      url: (params) => {
        return this.getServiceUrl("tasks", this.template`api/v1/issues/${0}`, params);
      },
      method:      "GET",
      textProcess: "Загрузка данных задачи...",
      textSuccess: null,
      textError:   this.template`Во время загрузки данных задачи произошла ошибка: ${0}`,
      timeout:     300000
    },
    createIssue: {
      // Создать задачу
      url: (params) => {
        return this.getServiceUrl("tasks", this.template`api/v1/projects/${0}/issues`, params);
      },
      method:      "POST",
      textProcess: "Создание задачи...",
      textSuccess: null,
      textError:   this.template`Во время создания задачи произошла ошибка: ${0}`,
      timeout:     300000
    },
    saveIssue: {
      // сохранить задачу
      url: (params) => {
        return this.getServiceUrl("tasks", this.template`api/v1/issues/${0}`, params);
      },
      method:      "PATCH",
      textProcess: "Сохранение данных задачи ...",
      textSuccess: null,
      textError:   this.template`Во время сохранения данных задачи произошла ошибка: ${0}`,
      timeout:     300000
    },
    changeStatusIssue: {
      // обновить статус у задачи
      url: (params) => {
        return this.getServiceUrl("tasks", this.template`api/v1/issues/${0}`, params);
      },
      method:      "PATCH",
      textProcess: "Изменение статуса у задачи ...",
      textSuccess: null,
      textError:   this.template`Во время изменения статуса у задачи произошла ошибка: ${0}`,
      timeout:     300000
    },
    saveBulkIssues: {
      // сохранить несколько задач сразу
      url:         this.getServiceUrl("tasks", "api/v1/issues/bulk"),
      method:      "PATCH",
      textProcess: "Изменение данных у нескольких задач ...",
      textSuccess: null,
      textError:   this.template`Во время изменения данных у нескольких задач произошла ошибка: ${0}`,
      timeout:     30000
    },
    loadStatuses: {
      // Загрузить список статусов задачи
      url:         this.getServiceUrl("tasks", "api/v1/issues/statuses"),
      method:      "GET",
      textProcess: "Загрузка списка статусов задачи...",
      textSuccess: null,
      textError:   this.template`Во время загрузки списка статусов задачи произошла ошибка: ${0}`,
      timeout:     300000
    },
    loadPriorities: {
      // Загрузить список приоритетов задачи
      url:         this.getServiceUrl("tasks", "api/v1/issues/priorities"),
      method:      "GET",
      textProcess: "Загрузка списка приоритетов задачи...",
      textSuccess: null,
      textError:   this.template`Во время загрузки списка приоритетов задачи произошла ошибка: ${0}`,
      timeout:     300000
    },
    loadTrackers: {
      // Загрузить список трекеров
      url:         this.getServiceUrl("tasks", "api/v1/trackers"),
      method:      "GET",
      textProcess: "Загрузка списка трекеров задачи...",
      textSuccess: null,
      textError:   this.template`Во время загрузки списка трекеров задачи произошла ошибка: ${0}`,
      timeout:     300000
    },
    loadUsers: {
      // Загрузить список пользователей
      url:         this.getServiceUrl("tasks", "api/v1/users"),
      method:      "GET",
      textProcess: "Загрузка списка пользователей...",
      textSuccess: null,
      textError:   this.template`Во время загрузки списка пользователей произошла ошибка: ${0}`,
      timeout:     300000
    },
    loadRedmineProjects: {
      // Загрузить список проектов в Redmine
      url:         this.getServiceUrl("tasks", "api/v1/projects"),
      method:      "GET",
      textProcess: "Загрузка списка проектов в сервисе `Задачи` ...",
      textSuccess: null,
      textError:   this.template`Во время загрузки списка проектов в сервисе "Задачи" произошла ошибка: ${0}`,
      timeout:     300000
    },
    checkProjectTrackers: {
      // Проверки связанных трекеров с текущим проектом T5543
      url: (params) => {
        return this.getServiceUrl("tasks", this.template`api/v1/projects/${0}/trackers`, params);
      },
      method:      "POST",
      textProcess: "Проверка связанных трекеров с текущим проектом ...",
      timeout:     60000,
      textSuccess: null,
      textError:   this.template`Во время проверки связанных трекеров с текущим проектом  произошла ошибка: ${0}`
    },
    checkProjectsLinks: {
      // Получение списка связанных проектов Redmine с набором переданных uid'ов
      url:         this.getServiceUrl("tasks", "api/v1/projects/links/bulk"),
      method:      "POST",
      textProcess: "Проверка связанных проектов в сервисе `Задачи`...",
      timeout:     60000,
      textSuccess: null,
      textError:   this.template`Во время проверки связанных проектов сервисе "Задачи" произошла ошибка: ${0}`
    },
    createRedmineProject: {
      // Создание нового проекта в Redmine
      url:         this.getServiceUrl("tasks", "api/v1/projects"),
      method:      "POST",
      textProcess: "Создание нового проекта...",
      timeout:     60000,
      textSuccess: null,
      textError:   this.template`Во время создания нового проекта произошла ошибка: ${0}`
    },
    file: {
      // Получение файла c хранилища АИС
      url: (params) => {
        return this.getServiceUrl("files", this.template`api/v1/files/${0}`, params);
      },
      method:      "GET",
      textProcess: "Получение файла из хранилища...",
      textSuccess: null,
      textError:   this.template`Во время получения файла из харнилища произошла ошибка: ${0}`,
      timeout:     20000
    },
    uploadFile: {
      // Загрузка файлов
      url:         this.getServiceUrl("files", "api/v1/files"),
      method:      "POST",
      textProcess: "Загрузка файла в хранилище...",
      timeout:     60000,
      textSuccess: null,
      textError:   this.template`Во время загрузки файла в хранилище произошла ошибка: ${0}`
    },
    loadFileMetadata: {
      // Получение данных о файле c хранилища АИС
      url: (params) => {
        return this.getServiceUrl("files", this.template`api/v1/files/${0}/metadata`, params);
      },
      method:      "GET",
      textProcess: "Получение данных о файле c хранилища АИС...",
      textSuccess: null,
      textError:   this.template`Во время получения данных о файле c хранилища АИС произошла ошибка: ${0}`,
      timeout:     20000
    },
    searchIssuesByText: {
      // Поиск задач по переданному тексту
      url:         this.getServiceUrl("tasks", "api/v1/issues/typing"),
      method:      "POST",
      textProcess: "Поиск задач...",
      textSuccess: null,
      textError:   this.template`Во время поиска задач произошла ошибка: ${0}`,
      timeout:     20000
    }
  };


  /**
   * Загрузить список задач
   * @param {String} uid проекта
   * @param {Number} page номер страницы
   * @param {Number} perPage максимальное кол-во записей на странице
   */
  async loadIssues(uid, page, perPage) {
    const reqData = {
      params: [uid]
    };

    if (page && perPage) {
      reqData.params.push(page, perPage);
    }

    return await this.request("loadIssues", reqData);
  }

  /**
   * Загрузить список задач использую набор фильтров
   *
   * @params {Object} filter набор фильтров
   * @params {Array<String>} filter.project - список uid'ов проектов
   * @params {Array<String>} filter.tracker - список uid'ов трекеров
   * @params {Array<String>} filter.status - список идентификаторов статусов в Redmine
   * @params {Array<String>} filter.priority - список идентификаторов приоритетов в Redmine
   * @params {Array<String>} filter.author - список uid'ов пользователей-авторов
   * @params {Array<String>} filter.assignedTo - список uid'ов пользователей-исполнителей
   * @params {Date} filter.createDateFrom - нижняя граница даты создания задачи
   * @params {Date} filter.createDateTo - верхняя граница даты создания задачи
   * @params {Date} filter.updateDateFrom - нижняя граница даты последнего обновления задачи
   * @params {Date} filter.updateDateTo - верхняя граница даты последнего обновления задачи
   * @params {Date} filter.startDateFrom - нижняя граница даты начала исполнения задачи
   * @params {Date} filter.startDateTo - верхняя граница даты начала исполнения задачи
   * @params {Date} filter.dueDateFrom - нижняя граница даты запланированного завершения задачи
   * @params {Date} filter.dueDateTo - верхняя граница даты запланированного завершения задачи
   * @params {String} filter.subject - поисковая строка для поиска в теме (названии) задачи
   * @params {Boolean} filter.strict - флаг, должен ли быть поиск в теме (названии) задачи строгим
   *                  (при отсутствии или null поиск является нестрогим)
   *
   * @param {Number} page номер страницы
   * @param {Number} perPage максимальное кол-во записей на странице
   * 
   * @return {Promise}
   */
  async loadIssuesByFilter(filters, page, perPage) {
    return await this.request("loadIssuesByFilter", {
      ...filters,
      page,
      perPage
    });
  }

  /**
   * Загрузить данные задачи
   * @param  {String} uid задачи
   *
   */
  async loadIssue(uid) {
    const reqData = {
      params: [uid]
    };

    return await this.request("loadIssue", reqData);
  }

  /**
   * Создание задачи
   *
   * @params {Object} data данные задачи
   * @params {String} data.projectUid uid проекта в АИС
   *
   * @return {Promise}
   */
  async createIssue(data) {
    const reqData = {
      params: [data.projectUid],
      data
    };
    return await this.request("createIssue", reqData);
  }

  /**
   * Сохранить данные у задачи
   *
   * @params {String} issueUid uid задачи
   * @params {Object} data данные для сохранения
   *
   * @return {Promise}
   */
  async saveIssue(issueUid, data) {
    const reqData = {
      params: [issueUid],
      data
    };
    return await this.request("saveIssue", reqData);
  }

  /**
   * Изменить статус у задачи
   *
   * @params {String} issueUid uid задачи
   * @params {String} statusId id статуса
   *
   * @return {Promise}
   */
  async changeStatusIssue(issueUid, statusId) {
    const reqData = {
      params: [issueUid],
      data:   {
        statusId
      }
    };
    return await this.request("changeStatusIssue", reqData);
  }

  /**
   * Изменить статус у задачи
   *
   * @params {Array <Object>} data списо данных для сохранения списка задач
   *
   * @return {Promise}
   */
  async saveBulkIssues(data) {
    return await this.request("saveBulkIssues", data);
  }

  /**
   * Загрузить список статусов задачи
   *
   */
  async loadStatuses() {
    return await this.request("loadStatuses");
  }

  /**
   * Загрузить список приоритетов задачи
   *
   */
  async loadPriorities() {
    return await this.request("loadPriorities");
  }

  /**
   * Загрузить список трекеров задачи
   *
   */
  async loadTrackers() {
    return await this.request("loadTrackers");
  }

  /**
   * Загрузить список пользователей
   *
   */
  async loadUsers() {
    return await this.request("loadUsers");
  }

  /**
   * Загрузить список проектов в Redmine
   *
   */
  async loadRedmineProjects() {
    return await this.request("loadRedmineProjects");
  }

  /**
   * Проверить набор переданных uid'ов на связь с проектами в Redmine
   *
   * @param {Array<String>} массив uid'ов участниов Вида, которые нужно проверить на связь с проектом в Redmine
   */
  async checkProjectsLinks(uids = []) {
    return await this.request("checkProjectsLinks", uids);
  }

  /**
   * Проверить связанные трекеры с текущим проектом по набору uid Видов или id трекеров Redmine 
   *
   * @param {String} projectUid uid текущего проекта в АИС
   * @param {Array<String>} uids массив uid'ов участниов Вида, которые нужно проверить на связь с проектом в Redmine 
   * @param {Array<String>} ids массив id'ов трекеров Redmine, которые нужно проверить на связь с проектом в Redmine
   */
  async checkProjectTrackers(projectUid, uids = [], ids = []) {
    const reqData = {
      params: [projectUid],
      data:   {
        uids,
        ids
      }
    };
    return await this.request("checkProjectTrackers", reqData);
  }


  /**
   * Создать новый проект в Redmine
   *
   * @param {String} uid проекта в АИС
   * @param {String} Название проекта
   */
  async createRedmineProject(uid, name) {
    const reqData = {
      uid,
      name
    };
    return await this.request("createRedmineProject", reqData);
  }

  /**
   * Загрузить данные файла с хранилища АИС
   *
   * @param  {String} id файла в хранилище
   * @return {Promise}
   */
  async loadFileMetadata(id) {
    const reqData = {
      params: [id]
    };
    return await this.request("loadFileMetadata", reqData);
  }

  /**
   * Загрузить файл с хранилища АИС
   *
   * @param  {String} id файла в хранилище
   * @return {Promise}
   */
  async downloadFile(id) {
    const reqData = {
      params: [id]
    };
    return await this.request("file", reqData);
  }

  /**
   * Выгрузить файл в хранилище АИС
   *
   * @param  {File} file
   * @return {Promise}
   */
  async uploadFile(file) {
    const reqData = {
      user_uid: this.rootStore.accountStore.uid,
      file:     [file]
    };

    return await this.request("uploadFile", reqData, true);
  }

  async searchIssuesByText(text, maxCount = 10) {
    const reqData = {
      searchValue: text,
      maxCount
    };

    return await this.request("searchIssuesByText", reqData);
  }
}

export default IssueApi;
