import { observable, action, computed } from "mobx";
import RepoApi from "../api/RepoApi";
import Commit from "../models/Commit";
import Repository from "../models/Repository";
import RepoNode from "../models/RepoNode";
import { DOMAIN_REPO } from "~/core/constants/Domains";
import CodeFile from "../models/CodeFile";
import { REPO_STATE_BRANCH, REPO_STATE_COMMIT, REPO_STATE_TAG } from "../constants/repoStateTypes";
// import { generateRepoId } from "../utils";

/**
 * Хранилище для работы с репозиторием
 * 
 * @class RepoStore
 */
class RepoStore {
  /**
   * @type {String}
   * Домен для хранения объектов репозитория в глобальном хранилище ObjectStore
   */
  domain = DOMAIN_REPO;

  /**
   * @type {Map<Commit>}
   * Набор коммитов для текущего репозитория
   */
  @observable
  commits = new Map();

  /**
   * @type {Map<CodeFile>}
   * Набор открытых просматриваемых файлов в репозитории
   */
  @observable
  openedCodeFiles = new Map();

  /**
   * @type {Array<String>}
   * Набор доступных тэгов в репозитории
   */
  @observable
  tags = [];

  /**
   * @type {Array<String>}
   * Набор доступных веток в репозитории
   */
  @observable
  branches = [];

  /**
   * @type {String}
   * id последенго коммита
   */
  // @observable
  // lastCommitId = null;

  /**
   * @type {String}
   * id текущего репозитория
   */
  @observable
  repositoryId = null;

  /**
   * @type {Boolean}
   * Флаг, уазывающий, что идет запрос к сервису для работы с репозиторием. 
   * Например згрузка дерева репозитория
   */
  @observable
  isPending = false;

  /**
   * @type {Boolean}
   * Флаг, указывающий, что еще есть записи для загрузки списка коммитов
   */
  @observable
  nextPageAvailable = true;

  /**
   * @type {Boolean}
   * Флаг, указывающий, что идет запрос списа коммитов
   */
  @observable
  isCommitPending = false;

  /**
   * @type {CodeFile}
   * Здесь указан какой файл сейчас открыт на просмотр в табах
   */
  @observable
  activeCodeFile = undefined;
  
  /**
   * @type {ObjectStore}
   * Указатель на глобальное хранилище ObjectStore для хранения объектов АИС
   */
  @observable
  objectStore = null;

  /**
   * @type {Object}
   * Здесь мы храним состояние репозитория за ключом состояния (commitId, branch, tag):
   */
  @observable
  repoState = {
    // [REPO_STATE_COMMIT]: ""
  };

  /**
   * @type {RepoNode}
   * Головная нода. В ней хранитя иноформация о текущем типе репозитория, ветка, тэг
   */
  @observable
  rootRepoNode = undefined;

  /**
   * @type {RepoNode}
   * Нода, котрую выделт пользваотель, кликнув по ее иконке
   */
  @observable
  selectedNode = undefined;


  /**
   * Конструктор хранилища
   * 
   * @param {RootStore} root root хранилище
   * @param {ObjectStore} objectStore глобальное хранилище для хранения объектов АИС
   */
  constructor(root, objectStore) {
    this.rootStore = root;
    this.objectStore = objectStore;
    this.api = new RepoApi(root);
  }

  /**
   * Инициализация репозитория
   * 
   * @param {String} repositoryId id репозитория
   */
  @action
  async init(repositoryId) {
    this.clear();
    this.setPending(true);
    try {
      this.repositoryId = repositoryId;
      await this.loadRootNode(); ;
      await this.loadTagsAndBranches();
      // меняем статус (работает как триггер), чтобы началась загрука дерева репозитория
      this.setRepoState({});
    } catch (ex) {
      this.onError(ex.message);
    } finally {
      this.setPending(false);
    }
  }

  /**
   * Обработка ошибки
   * 
   * @param {String} error собщение об ошибке
   */
  @action
  setError(error) {
    console.warn(error);
    this.rootStore.setErrorText(error.message);
  }

  /**
   * Задать объект кода, который попал в фокус
   * 
   * @param {CodeObject} codeObject объект кода, который попал в фокус
   */
  @action
  setFocusCodeObject(codeObject) {
    this.focusCodeObject = codeObject;
  }

  /**
   * Задать активным объект файла, содержимое которого начал просматривать пользователь в табах
   * 
   * @param {CodeFile} codeFile объект файла, который просматривает пользователь
   */
  @action
  setActiveCodeFile(codeFile) {
    this.activeCodeFile = codeFile;
  }

  /**
   * Задать выделенную ноду дерева
   * 
   * @param {RepoNode} repoNode нода, которую выделил пользвоатель через клик на ее иконке
   */
  @action
  setSelectedNode(repoNode) {
    this.selectedNode = repoNode;
  }

  /**
   * Открыть файл для просмотра.
   * Если файл уже был открыт, то вохвращается объект этого файла
   * 
   * @param {String} id id файла
   * @param {String} commitId id коммита файла
   * @param {String} name имя файла
   * @param {Number} focusCodeLine номер строки, на которую нужно поставить фокус после открытия файла.
   * Необходио при переходе по ссылке с другого интсрумента 
   * 
   * @return {CodeFile}
   */
  async openFile(id, commitId, name, focusCodeLine) {
    let openedFile = this.openedCodeFiles.get(id);
    if (openedFile) {
      this.setActiveCodeFile(openedFile);
      return openedFile;
    }
    
    const item = this.getItem(id);
    openedFile = CodeFile.create({
      id,
      name,
      repositoryId: this.repositoryId,
      commitId,
      path:         item.path,
      icon:         item.iconString
    });
    this.addCodeFile(openedFile);
    this.setActiveCodeFile(openedFile);
    openedFile.setIsPending(true);
    try {
      const content = await this.api.getContent(
        this.repositoryId,
        item.path,
        {
          commitId
        }
      );
      openedFile.setContent(content);
      
      const language = item.language && item.language.toLowerCase();
      if (language && (language === "c" || language === "cpp")) {
        // Пока сервис может обрабатывать структуру файлов, написанных на языке c и c++
        const outlines =  await this.api.getOutlines(
          openedFile.repositoryId,
          openedFile.commitId,
          openedFile.path
        );
        openedFile.processOutlines(outlines, this.rootStore.objectStore);

        if (openedFile.relationUids.length > 0) {
          await this.rootStore.relationStore.getBatchRelations(openedFile.relationUids, 0);
        }
      }
      
      openedFile.setFocusCodeLine(focusCodeLine);
    } catch (ex) {
      this.setError(ex);
    } finally {
      openedFile.setIsPending(false);
    }

    return openedFile;
  }

  /**
   * Получить массив открытых файлов
   * 
   * @return {Array<CodeFile>}
   */
  @computed
  get openedCodeFileList() {
    return Array.from(this.openedCodeFiles.values());
  }

  /**
   * Добавить файл в список открытых
   * 
   * @param {CodeFile}
   */
  @action 
  addCodeFile(codeFile) {
    this.openedCodeFiles.set(codeFile.id, codeFile);
  }

  /**
   * Удалить файл из списка открытых - те закрыть вкладку
   * 
   * @param {String} id id файла
   */
  @action 
  deleteCodeFile(id) {
    this.openedCodeFiles.delete(id);
  }

  /**
   * Загрузить список коммитов для репозитория
   * @param {String} path путь до ветки
   * @param {Number} start номер начального коммита для постраничного вывода
   * @param {Number} limit максимальное кол-во записей в ответе для постраничного вывода
   * 
   * @returns {Array<Object>}
   */
  @action
  async loadCommits(path = "/", start = -1, limit = 20) {
    if (this.commitPending) {
      // еще идет запрос на загрузку истории изменений
      return;
    }
    this.setCommitPending(true);
    try {
      let entity = {};
      if (Object.keys(this.repoState).length > 0) {
        entity = this.repoState;
      } else if (this.rootRepoNode) {
        entity.commitId = this.rootRepoNode.commitId;
      }

      const data = await this.api.getCommits(
        this.repositoryId,
        path,
        entity,
        start,
        limit
      );

      if (data) {
        this.nextPageAvailable = !(data.length < limit);
        data.forEach((commitData) => {
          this.addCommit(commitData);
        });
      }
      return data;
    } catch (ex) {
      console.error(ex);
    } finally {
      this.setCommitPending(false);
    } 

    return [];
  }

  /**
   * Загрузить следующую страницу с коммитами
   */
  @action
  getCommitsNextPage() {
    this.loadCommits(undefined, this.commitForNextPage);
  }

  /**
   * Получить информацию о последнем коммите
   * 
   * @param {String} path путь до ветки
   * @param {Number} start номер начального коммита для постраничного вывода
   * @param {Number} limit максимальное кол-во записей в ответе для постраничного вывода
   * 
   */
  @action
  async loadLastCommit(path = "/", start = -1, limit = "1") {
    const data = await this.loadCommits(path, start, limit);
    if (data && data[0]) {
      this.addCommit(data[0]);
      this.setCurrentCommit(data[0].commit);
    }
  }

  /**
   * Загрузить root ветку репозитория
   */
  async loadRootNode(entity) {
    const data = await this.api.getNode(this.repositoryId, "/", entity);
    const root = RepoNode.create({ ...data, name: "root" }, this.objectStore);
    this.setRootRepoNode(root);
  }
  
  /**
   * Загрузить информацию о тэгах и ветках в текущем репозитории
   * 
   */
  @action
  async loadTagsAndBranches() {
    this.tags = await this.api.getTags(this.repositoryId) || [];
    this.tags = this.tags.map((item) => {
      const arr = item.split("/");
      return arr[arr.length - 1];
    });
    this.branches = await this.api.getBranches(this.repositoryId) || [];
    this.branches = this.branches.map((item) => {
      const arr = item.split("/");
      return arr[arr.length - 1];
    });
  }

  /**
   * Загрузить информацию о репозитории
   * 
   * @param {Stirng} repositoryId id репозитория
   * 
   * @return {Repository} 
   */
  async loadRepository(repositoryId) {
    try {
      const data = await this.api.getRepository(repositoryId);
      return Repository.create(data);
    } catch (ex) {
      this.onError(ex.message);
    }

    return undefined;
  }

  /**
   * Добавить запиь о коммите в спиcок коммитов 
   * 
   * @param {*} commitData 
   */
  @action
  addCommit(commitData) {
    const commit = Commit.create(commitData);
    this.commits.set(`${commit.id}`, commit);
  }

  /**
   * Задать id последнего коммита
   * 
   * @param {String} id id коммита
   */
  @action
  setCurrentCommit(id) {
    this.setRepoState({
      ...this.repoState,
      [REPO_STATE_COMMIT]: id
    });
  }

  /**
   * Задать головную ноду репозитория.
   * В ней хранится информация о репоитори - тип, ветка, тэг
   * 
   * @param {RepoNode} root головная нода репозитория
   */
  @action
  setRootRepoNode(root) {
    this.rootRepoNode  = root;
  }

  /**
   * Задать tag коммита
   * 
   * @param {String} tag tag коммита
   */
  @action
  async setCurrentTag(tag) {
    // очищаем список коммитов
    this.clearCommits();
    // удалем root ноду
    this.setRootRepoNode(undefined);
    this.setPending(true);
    try {
      // загружаем root ноду
      await this.loadRootNode({ [REPO_STATE_TAG]: tag });
      // меняем статус
      this.setRepoState({
        [REPO_STATE_TAG]: tag
      });
    } catch (ex) {
      this.onError(ex.message);
    } finally {
      this.setPending(false);
    }
  }

  /**
   * Задать аткивную ветку в репозитории
   * 
   * @param {String} branch ветка репозитория
   */
  @action
  async setCurrentBranch(branch) {
    // очищаем список коммитов
    this.clearCommits();
    // удалем root ноду
    this.setRootRepoNode(undefined);
    this.setPending(true);
    try {
      // загружаем root ноду
      await this.loadRootNode({ [REPO_STATE_BRANCH]: branch });
      // меняем статус
      this.setRepoState({
        [REPO_STATE_BRANCH]: branch
      });
    } catch (ex) {
      this.onError(ex.message);
    } finally {
      this.setPending(false);
    }
  }

  /**
   * Задать флаг обработки коммитов
   * 
   * @param {Boolean} isPending значение
   */
  @action
  setCommitPending(isPending = false) {
    this.isCommitPending = isPending;
  }

  /**
   * Получить запись из глобального хранилища ObjectStore
   * 
   * @param {String} id ноды 
   * 
   * @return {RepoNode}
   */
  @action
  getItem(id) {
    return this.objectStore.getVersion(id, this.domain);
  }

  /**
   * Получить ноду дерева репозитория.
   * 
   * @param {String} id ноды 
   * 
   * @return {RepoNode}
   */
  @action
  async loadNode(id) {
    const node = await this.objectStore.fetchRepresentation(
      id,
      this.domain,
      0,
      this.repoState,
      {
        force: true
      }
    );
    return node;
  }

  /**
   * Задать флаг обработки репозитория
   * 
   * @param {Boolean} pending значение
   */
  @action
  setPending(pending = false) {
    this.isPending = pending;
  }

  /**
   * Задать текущее состояние отображения репозитория по его ключам -  commitId, tag, branch
   * 
   * @param {Object} state
   */
  @action
  async setRepoState(state = {}) {
    this.repoState = state;
  }

  /**
   * Флаг, указывающий, что идут запросы к сервису репозитория
   * 
   * @param {Boolean} pending значение
   */
  @computed
  get pending() {
    return !this.repositoryId && !this.rootRepoNode;
    // return this.isPending;
  }

  /**
   * Получить данные о последнем коммите
   * 
   * @return {Commit}
   */
  @computed
  get lastCommit() {
    const commitId  = this.rootRepoNode && (this.rootRepoNode.commitId || this.rootRepoNode.tag);
    return commitId && this.commits.get(commitId);
  }

  /**
   * Получить id коммита для следующей страницы
   * 
   * @return {String}
   */
  @computed
  get commitForNextPage() {
    let id = -1;
    this.commits.forEach((commit) => {
      id = commit.id;
    });
    return id;
  }

  /**
   * Есть ли следующая страница с записями коммитов
   * 
   * @return {Boolean}
   */
  @computed
  get hasNextPage() {
    return this.nextPageAvailable;
  }

  /**
   * Флаг, указывающий, что идет запрос для обработки коммитов
   * 
   * @return {Boolean}
   */
  @computed
  get commitPending() {
    return this.isCommitPending;
  }

  /**
   * Массив коммитов
   * 
   * @return {Array<Commit>}
   */
  @computed
  get commitList() {
    const commits = [];
    this.commits.forEach((commit) => {
      commits.push(commit.info);
    });
    return commits;
  }

  /**
   * Очистить список историии коммитов
   */
  @action
  clearCommits() {
    this.commits.clear();
  }

  /**
   * Очистить все списки, отсносящиеся к репозиторию: список коммитов, список октрыты файлов, наборы тэгов и веток
   */
  @action
  clear() {
    this.commits.clear();
    this.openedCodeFiles.clear();
    this.tags = [];
    this.branches = [];
  }

  /**
   * Обработчик ошибок
   * 
   * @param {String} message сообщение об ошибке
   */
  onError(message) {
    this.rootStore.onError(message);
  }

  /**
   * Деструктор хранилища
   */
  destroy() {
    this.clear();
  }
}

export default RepoStore;
