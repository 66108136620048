import React, { useCallback } from "react";
import { observer } from "mobx-react";

import { useStores } from "../utils";
import { Components, Dnd } from "@ais3p/ui-framework";

const NormalButton = observer((props) => {
  const { layoutStore } = useStores();
  const { onClick, item, mode, canDrag, labelPosition, icon } = props;

  const onClickButton = useCallback(() => {
    if (onClick) {
      if (mode) {
        onClick(mode);
      } else {
        onClick(item);
      }
    }
  }, [onClick, mode]);

  const setIsDragging = useCallback(
    (isDragging = false) => {
      layoutStore.setIsDragging(isDragging);
    },
    [layoutStore]
  );


  const canDragFunc = useCallback(() => {
    return canDrag;
  }, [canDrag]);

  const attributes = {
    "data-tooltip":    item.name,
    "data-tooltip-at": labelPosition || "bottom"
  };
  return (
    <Dnd.Source 
      className={"button-wrapper"}
      type={"GLOBAL"}
      item={item}
      checkIsDragging={setIsDragging}
      canDrag={canDragFunc}
    >
      <div className="button-wrapper" {...attributes}>
        <Components.Button
          size={3}
          onPress={onClickButton}
          icon={icon}
          theme={"outline"}
        />
      </div>
    </Dnd.Source>
  );
});

export default NormalButton;
