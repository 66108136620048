import React from "react";
import PropTypes from "prop-types";
import TableValue from "./TableValue";

/**
 * @component
 * 
 * Компонент для отображения ячейки таблицы с результатом поиска артефактов
 * 
 * @param {EntityModel} entity запись найденнего артефакта
 * @param {String} columnId id колонки - ключ для отображения значения
 * @param {SearchStore} store хранилище для поиска артефатов 
 * @param {Number} index номер записи в табличичном представлении
 * @param {String} keyValues ключ, за которым хранится набор значений
 */
const TableCell = (props) => {
  const {
    columnId
  } = props;
  return (
    <td>
      <div
        className={`entities-table-cell ${columnId}-cell`}
      > 
        <TableValue {...props} />
      </div>
    </td>
  );
};

TableCell.propTypes = {
  columnId: PropTypes.string.isRequired 
};


export default TableCell;
