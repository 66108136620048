import React, { useCallback } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { conditionTitle, conditionIcon } from "../../constants/condtions";

/**
 * @component
 * 
 * Пункт в выпадающем списке доступного условий сравнения
 * 
 * @param {Object} params  набор параметров 
 * @param {String} params.value значение условия сравнения
 * @param {Boolean} params.active активен ли сейчас пункт
 * @param {Function} params.onClick callback ф-я при выборе этого пункта
 *
 * @returns 
 */
const ConditionPickerItem = ({ value, active, onClick }) => {
  /**
   * Обработчик события нажатия на пункт условия.
   */
  const onClickItem = useCallback((e) => {
    e.stopPropagation();
    onClick && onClick(value);
  }, [value, onClick]);

  return (
    <div
      className={classNames("condition-picker-item", {
        active
      })}
      onClick={onClickItem}
    >
      <label>{conditionIcon(value)}</label>
      <span>{conditionTitle(value)}</span>
    </div>
  );
};

ConditionPickerItem.propTypes = {
  value:   PropTypes.string,
  active:  PropTypes.bool,
  onClick: PropTypes.func
};


export default ConditionPickerItem;
