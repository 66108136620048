import React, { useCallback, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { Field } from "@ais3p/ui-framework";

import useStores from "~/core/utils/useStores";
import AttrField from "./AttrField";
import KindItem from "../models/KindItem";

/**
 * @component
 * 
 * Форма для отображения полей атрибута
 * 
 * @param {KindItem} kindItem запись представителя Вида.
 * @param {String} objectUid uid объекта АИС. Если не задан kindItem, то ищем по objectUid и verion объекта АИС
 * @param {Number} version номер версии объекта АИС. Если не задан kindItem, то ищем по objectUid и verion объекта АИС
 * @param {String} kindUid uid Вида
 * @param {Boolean} isDisabled флаг, указывающий, что форма disabled
 * @param {Boolean} isView флаг, указывающий, что форма только для просмотра
 * @param {Function} onChange callback функция на изменения в контролах формы
 * @param {Function} checkValidityForm callback функция на проверку валидности всех контролов формы
 * @param {String} theme цветовая тема контролов формы
 */
const AttrsForm = observer(
  ({ 
    kindItem, 
    objectUid, 
    version = 0, 
    kindUid, 
    isDisabled, 
    isView, 
    onChange,
    theme,
    checkValidityForm
  }) => {
    const { kindsStore } = useStores();
    /** 
     * @property
     * Объект Вида 
     * 
     * @returns {Kind}
     * */ 
    const kind = useMemo(() => {
      return kindsStore.getKind(kindUid);
    }, [kindUid]);

    /** 
     * @property
     * Получаем представителя Вида (KindItem).
     * Если он не был передан явно через kindItem, то берем его по objectUid и verion объекта АИС
     * 
     * @returns {KindItem} 
     */ 
    const item = useMemo(() => {
      if (kindItem) {
        return kindItem;
      }

      if (!kindsStore.isPendingData) {
        return kindsStore.getItem(objectUid, version);
      }
    }, [kindItem, objectUid, version, kindsStore.isPendingData]);

    /**
     * @property
     * Участник Вида (KindMember)
     * 
     * @returns {KindMember}
     */ 
    const member = useMemo(() => {
      if (!item) {
        return null;
      }
      let member = item.getMember(kindUid);
      if (!member) {
        member = item.addKind(kindUid);
      }
      return member;
    }, [item, item && item.pending, kindUid]);

    /**
     * @function
     * 
     * Событие на изменение поля атрибута
     * 
     * @param {Any} value значение поля атрибута
     * @param {String} uid uid атрибута
     * @param {Boolean} isValid флаг валидности значения атрибута
     */ 
    const onFieldChange = useCallback(
      (value, uid, isValid = true) => {
        member && member.setAttrValue(uid, value, isValid);
        checkValidityForm && checkValidityForm(member.isValid);
        if (member && onChange) {
          onChange(member);
        }
      },
      [member, kindUid, checkValidityForm]
    );
    
    useEffect(() => {
      checkValidityForm && checkValidityForm(member && member.isValid);
    }, [member]);

    /**
     * @function
     * 
     * Пустая ф-я
     * 
     */ 
    const emptyFn = useCallback(() => {

    }, []);
    

    /**
     * @property
     * 
     * Набор полей атрибутов
     * 
     * @returns {Array<AttrField>}
     */
    const fields = useMemo(() => {
      const fields = [];

      kind.attributes.forEach((attr) => {
        fields.push(
          <AttrField
            key={attr.id}
            attr={attr}
            member={member}
            isView={isView}
            isDisabled={isDisabled || isView}
            onChange={onFieldChange}
            theme={theme}
          />
        );
      });
      return fields;
    }, [
      kind && kind.attributes,
      member,
      isDisabled,
      isView,
      onFieldChange
    ]);

    /**
     * @property
     * 
     * Признак, нужно ли отображать коды участника Вида.
     * 
     * @returns {Boolean}
     */
    const showCodes = useMemo(() => {
      return member && member.hasUid;
    }, [member && member.hasUid]);

    /**
     * @property
     * 
     * Отрисовка кодов участника Вида
     * 
     * @returns {Array<Field>}
     */
    const codesRender = useMemo(() => {
      const codes = [];
      kind.codesArray.forEach((code) => {
        if (code) {
          codes.push(
            <Field.String
              icon={"data-integer-M"}
              key={code.uid}
              name={code.uid}
              isDisabled={true}
              label={code.name}
              onChange={emptyFn}
              value={
                member && member.codesObject && member.codesObject[code.uid]
              }
            />
          );
        }
      });
      return codes;
    }, [member && member.codesObject, kind && kind.codesArray]);

    // Представитель Вида не был определен, поэтому ничего не отображаем
    if (!item) {
      return null;
    }

    return (
      <div className="kind-form">
        {showCodes && codesRender}
        {fields}
      </div>
    );
  }
);

AttrsForm.propTypes = {
  kindItem:   PropTypes.instanceOf(KindItem),
  objectUid:  PropTypes.string,
  version:    PropTypes.number, 
  kindUid:    PropTypes.string.isRequired, 
  isDisabled: PropTypes.bool, 
  isView:     PropTypes.bool, 
  onChange:   PropTypes.func
};

export default AttrsForm;
