import React from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { Components } from "@ais3p/ui-framework";
import BranchesAndTags from "./BranchesAndTags";
import { declOfNum } from "~/core/utils";
import RepoStore from "../../stores/RepoStore";

/**
 * Панель для отобрадения инфомрации о доступных тэгах, ветках и в какой ветке/тэге находится
 * просматриваемый репозиторий
 */
const Info = observer(({ store }) => {
  return (
    <div className="repo-info">
      <div className="repo-type">
        {store.rootRepoNode && `${store.rootRepoNode.repositoryType}:`}
      </div>
      <div className="repo-info-item">
        <BranchesAndTags store={store} />
      </div>
      <div className="repo-info-item">
        <Components.Icon name="repository-M" className="repo-icon" />
        <label>{store.branches && store.branches.length}</label>
        <span>
          {declOfNum(store.branches && store.branches.length, [
            "ветка",
            "ветки",
            "веток"
          ])}
        </span>
      </div>
      <div className="repo-info-item">
        <Components.Icon name="token-M" className="repo-icon" />
        <label>{store.tags && store.tags.length}</label>
        <span>
          {declOfNum(store.tags && store.tags.length, ["тэг", "тэга", "тэгов"])}
        </span>
      </div>
    </div>
  );
});

Info.propTypes = {
  store: PropTypes.instanceOf(RepoStore)
};

export default Info;
