import React from "react";
import PropTypes from "prop-types";
import { Components } from "@ais3p/ui-framework";

/**
 * Компонент с сообщением, что не выбран объект слежения
 * 
 * @param {Object} props набор параметров
 * @param {String} props.noRepresantationText отображаемый текст
 */
const NoRepresantation = ({ 
  noRepresantationText = "Объект не имеет подходящего представления"
}) => {
  return (
    <div className="no-representation-wrapper">
      <Components.Icon
        className="no-representation-wrapper-icon"
        name="query-M"
      />
      <div className="no-representation-wrapper-text">
        {noRepresantationText}
      </div>
    </div>
  );
};

NoRepresantation.propTypes = {
  noRepresantationText: PropTypes.string
};

export default NoRepresantation;
