import React, { useMemo, useCallback, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import classNames from "classnames";
import { Components } from "@ais3p/ui-framework";
import useStores from "~/core/utils/useStores";
import TraceStore from "../stores/traceStore";
import Parameters from "../components/parameters";
import SchemaData from "../components/schema-data";
import InfoToolWindow from "~/core/components/InfoToolWindow/InfoToolWindow.jsx";
import infoToolContent from "~/core/components/InfoToolWindow/infoToolContent.js";

import "./css/index.scss";

/**
 * Инструмент для анализа трассировки связей
 * @params {Object} props набор параметров
 * @params {String} props.schemaId id схемы, согласно которой нужно будет делать трассировку или анализ связей
 * @params {String} props.traceType что делать - трассировку "trace" | анализ связей "gap"
 * @params {Object} props.trackedItem информация о ноде в Библиотеке, для которой была вызвана трассировка или 
 * анализ связей
 */
const TraceAnalyzer = observer(({ schemaId, traceType, trackedItem }) => {
  const { rootStore, objectStore, layoutStore } = useStores();

  const [schemaType, setSchemaType] = useState();
  const [schema, setSchema] = useState();
  const [schemaData, setSchemaData] = useState();
  const [infoIsVisible, setInfoIsVisible] = useState(false);
  const [showExportMenu, setShowExportMenu] = useState(false);

  const store = useMemo(() => {
    return new TraceStore(rootStore, true);
  }, []);

  useEffect(() => {
    window.addEventListener("click", onMouseClick);
    return () => {
      window.removeEventListener("click", onMouseClick);
    };
  }, []);

  const onMouseClick = useCallback((e) => {
    const el = e.target.closest(".dropdown-menu-button");
    if (!el) {
      // кликнули не по кнопке dropdown
      setShowExportMenu(false);
    }
  }, []);


  useEffect(async() => { 
    await store.init();
    let currentSchema;
    // Если schemaId указана, то значит нужно делать трассировку или анализ связей. 
    // Те инстурмент был вызван через контекстное меню у ноды в Библиотеке
    if (schemaId) {
      currentSchema = store.getSchema(schemaId);
      setSchema(currentSchema);
    }
    
    if (currentSchema && trackedItem) {
      // получаем инофмацию об объекте, для котрого был вызан инструмент
      const obj = objectStore.getVersion(trackedItem.uid, trackedItem.domain, trackedItem.version);
      if (obj) {
        // задаем этот объект в схеме ограничений
        currentSchema.setFirstBoundaryValue(obj);
      }

      // если после задания объекта, схема стала валидной, то производит трассировку или анализ связей, согласно
      // типу traceType
      if (currentSchema.isValid) {
        if (traceType === "gap") {
          const data = await store.doGapAnalize(currentSchema);
          onProcessSchema(traceType, currentSchema, data);
        }

        if (traceType === "trace") {
          const data = await store.doTraceAnalize(currentSchema);
          onProcessSchema(traceType, currentSchema, data);
        }
      } else {
        setSchema(currentSchema);
      }
    }
  }, [schemaId, traceType, trackedItem]);

  /**
   * Callback ф-я на выбор схемы из выпадающего списка
   * 
   * @param {TraceSchema} schema выбранная схема
   */
  const onChangeSchema = useCallback((schema) => {
    setSchema(schema);
  }, []);
  
  /**
   * Callback ф-я на результат трассировки или анализа связей
   * 
   * @param {String} type тип результата - трассировка (trace)  | анализ связей (gap)
   * @param {TraceSchema} schema выбранная схема
   * @param {Array<Object>} data набор данных
   */
  const onProcessSchema = useCallback((type, scheme, data) => {
    setSchemaType(type);
    setSchema(scheme);
    setSchemaData(data);
    
    if (type) {
      store.toggleShowSchemaParams();
    }    
  }, []);

  /** 
   * Callback ф-я для переключения отображения панели с набором параметров для схемы
   */
  const onToggleParams = useCallback(() => {
    store.toggleShowSchemaParams();
  }, []);

  /** 
   * Callback ф-я для переключения отображения панели с информацией о текущем инструменте
   */
  const onToggleInfoModal = useCallback(() => {
    setInfoIsVisible(!infoIsVisible);
  }, [infoIsVisible]);

  /** 
   * Callback ф-я для печати отчета в PDF
   */
  const onPrintReportToPdf = useCallback(async() => {
    setShowExportMenu(false);
    if (store.currentSchema) {
      const data = await store.printReport(store.currentSchema, "pdf");
      const title = store.currentSchema.type === "trace" ? "Трассировка" : "Gap Анализ";
      if (data) {
        const tool = {
          icon:      "file-pdf-M",
          component: "viewer"
        };
        const item = {
          name:  title,
          id:    data.aisId,
          props: {
            type:     "pdf",
            name:     title,
            fileBlob: data
          }
        };
        layoutStore.open({ ...item, ...tool });
      }
    }
  }, [store.currentSchema]);

  const onPrintReportToOdt = useCallback(async() => {
    setShowExportMenu(false);
    if (store.currentSchema) {
      const blobUrl = await store.printReport(store.currentSchema, "odt");
      saveFile(blobUrl, `${schemaType}.odt`);
    }
  }, [store.currentSchema, schemaType]);

  const onPrintReportToDocx = useCallback(async() => {
    setShowExportMenu(false);
    if (store.currentSchema) {
      const blobUrl = await store.printReport(store.currentSchema, "docx");
      saveFile(blobUrl, `${schemaType}.docx`);
    }
  }, [store.currentSchema, schemaType]);

  const saveFile = (blobUrl, name = "traceReport") => {
    if (!blobUrl) {
      return;
    }
    const a = document.createElement("a");
    a.style = "display: none";
    a.href = blobUrl;
    a.download = name;
    document.body.appendChild(a);
    a.click();
    setTimeout(() => {
      window.URL.revokeObjectURL(blobUrl);
      a.remove();
    }, 100);
  };

  const onToggleExportMenu = useCallback(() => {
    setShowExportMenu((state) => {
      return !state;
    });
  }, []);

  const rightButtons = useMemo(() => {
    return ([
      <div 
        key="export"
        className="dropdown-menu-button"
      >
        <Components.Button
          icon={showExportMenu ? "chevron-down-M" : "export-M"}
          text="Экспорт"
          isSelected={showExportMenu}
          isDisabled={!store.currentSchema}
          onPress={onToggleExportMenu}
        />
        <div className={classNames("dropdown-menu-list", {
          show: showExportMenu
        })}
        >
          <Components.Button
            key="pdf"
            icon="file-pdf-M"
            text="PDF"
            tooltip="Экспорт в pdf"
            onPress={onPrintReportToPdf}
          />
          <Components.Button
            key="odt"
            icon="file-odt-M"
            text="ODT"
            tooltip="Экспорт в odt"
            onPress={onPrintReportToOdt}
          />
          <Components.Button
            key="docx"
            icon="file-docx-M"
            text="DOCX"
            tooltip="Экспорт в docx"
            onPress={onPrintReportToDocx}
          />
        </div>
      </div>,
      <Components.Button
        key="settings"
        icon="settings-M"
        tooltip="Параметры"
        isSelected={store.showSchemaParams}
        onPress={onToggleParams}
      />,
      (<Components.Spacer key="right-spacer-2" />),
      (
        <Components.Button
          key={"info-M"} icon="info-M" tooltip={"Справка"}
          onPress={onToggleInfoModal}
          isSelected={infoIsVisible}
        />
      )
    ]
    );
  }, [store.showSchemaParams, showExportMenu]);

  return (
    <div className="trace-analyzer-tool">
      <Components.ToolBar right={rightButtons} />
      {
        store.isPending &&
        <div className="trace-preloader">
          <Components.Preloader size={3} className="preloader-icon" />
        </div>
      }
      <div className="trace-analyzer-tool-body">
        <Parameters 
          store={store}
          schema={schema}
          onProcessSchema={onProcessSchema} 
          onChangeSchema={onChangeSchema}
        />
        {!store.showSchemaParams &&
          <SchemaData
            type={schemaType} 
            schema={schema} 
            data={schemaData}
            store={store}
          />
        }
      </div>
      {infoIsVisible && (
        <InfoToolWindow 
          content={infoToolContent.traceAnalyzer}
          infoIsVisible={infoIsVisible}
          toggleInfoModal={onToggleInfoModal}
        />
      )}
    </div>
  );
});

TraceAnalyzer.propTypes = {
  schemaId:    PropTypes.string, 
  traceType:   PropTypes.string,
  trackedItem: PropTypes.object
};


export default TraceAnalyzer;
