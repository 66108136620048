import React from "react";
import { observer, inject } from "mobx-react";
import { Components, ContextMenu } from "@ais3p/ui-framework";

@inject("accountStore")
@observer
class ChildButton extends React.Component {
  constructor(props) {
    super(props);

    this.onClick = this.onClick.bind(this);
    this.canDrag = this.canDrag.bind(this);
    this.onMenuClick = this.onMenuClick.bind(this);
    this.contextMenuCollect = this.contextMenuCollect.bind(this);
  }

  onClick() {
    const { onClick, item } = this.props;
    if (onClick) {
      onClick && onClick(item);
    }
  }

  canDrag() {
    return this.props.canDrag;
  }

  onMenuClick(e, contextMenuData) {
    const { action, item } = contextMenuData;
    const presset = this.props.getPresset();
    switch (action) {
      case "save":
        this.props.accountStore.saveWorkspaceToItem(item.id, presset);
        break;
      case "edit":
        this.props.accountStore.showForm(item.id, presset);
        break;
      case "delete":
        this.props.accountStore.deleteWorkspace(item.id);
        break;
    }
  }

  contextMenuCollect(props) {
    this.props.menuCollect(props);
    return props;
  }

  render() {
    const { id, icon, item, setIsDragging, tool, labelPosition, loading } =
      this.props;
    const attributes = {
      "data-tooltip":    item.name,
      "data-tooltip-at": labelPosition
    };
    return (
      <div key={id} className={"button-wrapper"} {...(loading ? {} : attributes)}>
        <ContextMenu.Trigger
          key={id}
          id={loading || !item.hasMenu ? "" : "treeId"}
          item={item}
          menuType={"HEADER"}
          holdToDisplay={-1}
          onItemClick={this.onMenuClick}
          collect={this.contextMenuCollect}
        >
          <Components.Button
            size={3}
            onPress={this.onClick}
            icon={icon}
            isLoading={loading}
          />
        </ContextMenu.Trigger>
      </div>
    );
  }
}

export default ChildButton;
