import { observable, computed } from "mobx";

/**
 * Модель шаблона кода Вида
 *
 */
class Code {
  /**
   * Уникальный идентифиактор кода
   * @type String
   */
  @observable 
  uid = null;

  /**
   * Наименование кода в системе.
   * @type String
   */
  @observable
  name = undefined;

  /**
   * Строковое название типа в система. Константа.
   * @type String
   */
  @observable
  class = undefined;

  /**
   * Идентификатор вида.
   * @type String
   */
  @observable
  kindUid = undefined;

  /**
   * Шаблон значения кода.
   * @type String
   */
  @observable
  template = false;

  /**
   * Cоздание модели
   * @param {Object} params параметры модели
   * @param {Object} params.uid uid вида
   * @param {String} params.name Название кода.
   * @param {String} params.class Строковое название типа в система
   * @param {Boolean} params.kindUid Идентификатор вида.
   * @param {Boolean} params.template Шаблон значения кода
   *
   * @retrun {CodeModel}
   */
  static create({
    uid,
    name,
    class: klass,
    kindUid,
    template
  }) {
    return new Code({
      uid,
      name,
      class: klass,
      kindUid,
      template
    });
  }

  constructor(params) {
    this.uid = params.uid;
    this.name = params.name;
    this.class = params.class;
    this.kindUid = params.kindUid;
    this.template = params.template;
  }

  /**
   * id кода
   *
   * @return {String}
   */
  @computed
  get id() {
    return this.uid;
  }

  /**
   * Строковое название объекта
   *
   * @return {String}
   */
  @computed
  get title() {
    return this.name;
  }
}

export default Code;
