import React, { useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";

import { Tabs } from "@ais3p/ui-framework";

import CodeView from "./CodeView";
import RepoStore from "../../stores/RepoStore";

/**
 * Панель для отображения табов для просмотра содержимого файлов
 *
 * 
 * @param {Object} props передаваемые св-ва
 * @param {LayoutItem} props.layoutItem панель layout
 * @param {RepoStore} props.store хранилище  для работы с репозиторием
 * @param {Boolean} props.disabled флаг, указывающий, должна ли быть панель отображена или скрыта.
 * Скрытность панели делаем для того, чтобы не сбрасывать рендер и сохранить состояние открытых владок
 *
 * @type {SourceView}
 * @returns {Component}
 */
const SourceView = observer((props) => {
  const { store, disabled, layoutItem, onCloseAllTabs } = props;
  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    if (store.activeCodeFile) {
      const index = store.openedCodeFileList.indexOf(store.activeCodeFile);
      if (index >= 0) {
        setTabIndex(index);
      }
    }

    // если вкладки все закрыли, то переходим в режим просмотра репозитория
    if (store.openedCodeFileList.length === 0) {
      onCloseAllTabs && onCloseAllTabs();
    }
  }, [store.activeCodeFile, store.openedCodeFileList && store.openedCodeFileList.length]);

  /**
   * Событие на выбор вкладки
   */
  const onSelectTab = useCallback((index) => {
    if (store.openedCodeFileList[index]) {
      store.setActiveCodeFile(store.openedCodeFileList[index]);
      setTabIndex(index);
    }
  }, []);

  /**
   * Событие на закрытие вкладки
   */
  const onCloseTab = useCallback((index) => {
    const codeFile = store.openedCodeFileList[index];
    if (!codeFile) {
      return;
    }
    const isCurrentTab = store.activeCodeFile === codeFile;
    store.deleteCodeFile(codeFile.id);

    if (isCurrentTab) {
      let newIndex = index - 1;
      if (index <= 0) {
        newIndex = 0;
      }

      store.setActiveCodeFile(store.openedCodeFileList[newIndex]);
    }
  }, [store.activeCodeFile, store.openedCodeFileList && store.openedCodeFileList.length]);

  /**
   * Получаем набор отрытых вкладок
   */
  const items = useMemo(() => {
    const result = [];
    store.openedCodeFileList.forEach((codeFile, index) => {
      result.push({
        label:     codeFile.title,
        icon:      codeFile.icon,
        id:        index,
        component: <CodeView store={store} codeFile={codeFile} layoutItem={layoutItem} />
      });
    });
    return result;
  }, [store.openedCodeFileList]);

  return (
    <div
      className="source-view"
      style={{
        display: disabled ? "none" : "flex"
      }}
    >
      {store.openedCodeFileList.length === 0 && (
        <div className="source-view-empty-tabs">
          Выберите файл для просмотра его содержимого.
        </div>
      )}
      {store.openedCodeFileList.length > 0 && (
        <Tabs
          items={items}
          selectedIndex={tabIndex}
          onSelect={onSelectTab}
          isClosable={true}
          onClose={onCloseTab}
        />
      )}
    </div>
  );
});
SourceView.propTypes = {
  store:          PropTypes.instanceOf(RepoStore),
  disabled:       PropTypes.bool,
  layoutItem:     PropTypes.object,
  onCloseAllTabs: PropTypes.func
};

export default SourceView;
