/**
 * Набор типов доменов
 */
const DOMAIN_TEXT = "text";
const DOMAIN_REPO = "sourcecode";
const DOMAIN_LIBRARY = "library";
const DOMAIN_GROUPS = "groups";
const DOMAIN_POF = "pof";
const DOMAIN_KINDS = "kinds";
const DOMAIN_KINDS_AND_ATTRIBUTES = "kindsattrs";
const DOMAIN_WF = "wf";
const DOMAIN_ISSUE = "tasks";
const DOMAIN_BINDER = "binder";
const DOMAIN_WORKFLOW = "workflow";


/**
 * Проверяем, существует ли домен с такми именем
 * 
 * @param {String} domain название домена
 * @returns {Boolean}
 */
const domainExists = (domain) => {
  if (!domain) {
    return false;
  }
  return [
    DOMAIN_TEXT,
    DOMAIN_REPO,
    DOMAIN_LIBRARY,
    DOMAIN_POF,
    DOMAIN_KINDS,
    DOMAIN_GROUPS,
    DOMAIN_WF,
    DOMAIN_ISSUE,
    DOMAIN_BINDER,
    DOMAIN_WORKFLOW
  ].includes(domain.toLowerCase());
};

export {
  DOMAIN_TEXT,
  DOMAIN_REPO,
  DOMAIN_LIBRARY,
  DOMAIN_POF,
  DOMAIN_KINDS,
  DOMAIN_KINDS_AND_ATTRIBUTES,
  DOMAIN_GROUPS,
  DOMAIN_WF,
  DOMAIN_ISSUE,
  DOMAIN_BINDER,
  DOMAIN_WORKFLOW,
  domainExists
};
