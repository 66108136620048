import React, { useCallback, useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react";
import useStores from "~/core/utils/useStores";
import { Components } from "@ais3p/ui-framework";
import UserAvatar from "../../users/components/avatar/UserAvatar";
import moment from "moment";
import "./css/stateCard.scss";


const StateCard = observer(({ fistChild, workflow, point }) => {
  const { workflowStore, userStore } = useStores();
  const { currentWorkflow } = workflowStore;
  const [availableTransitions, setAvailableTransitions] = useState([]);
  const [isExpanded, setIsExpanded] = useState(false);
  const [state, setState] = useState();

  const handleUpdateState = useCallback((workflowId, name) => {
    workflowStore.updateWorkflow(workflowId, name);
  }, [workflowStore, currentWorkflow]);

  useEffect(() => {
    const currentState = workflow?.machine?.getStateById(point.stateId);
    setState(currentState);
    if (fistChild) {
      const availableTransitions = workflow.machine?.getAvailableTransitions(currentState.id);
      setAvailableTransitions(availableTransitions);
      setIsExpanded(true);
    }
  }, [workflow, point]);

  let transitionButtons = [];
  if (availableTransitions && fistChild) {
    transitionButtons = availableTransitions.map((transition) => {
      return (
        <Components.Button 
          key={transition.id}
          text={transition.name}
          color="action"
          // eslint-disable-next-line react/jsx-no-bind
          onPress={() => {
            return handleUpdateState(workflow.id, transition.slug);
          }}
        />
      );
    });
  };

  const user = useMemo(() => {
    return userStore.get(point.driverUid);
  }, [userStore, point]);

  // const handleToggleExpand = useCallback(() => {
  //   setIsExpanded(!isExpanded);
  // }, [isExpanded]);

  return (
    <div className={`state-card-container ${fistChild ? "" : "finished"}`}>

      {/* {isExpanded && ( */}
      <div className="state-card-content">
        <div className="state-title">
          {/* <Icon icon="aggr-create-M" className="state-title__icon" /> */}
          <div className="state-title__container">
            <div className="state-title__name">
              <div className="text-content">{state && state.name}</div>
            </div>
            {/* <div onClick={handleToggleExpand}>
            <Components.Icon
              name={isExpanded ? "chevron-up-M" : "chevron-down-M"} 
              color="#4d4d4d"
            />
          </div> */}
          </div>
          <div className="state-time">{point.arrivedAt && moment(point.arrivedAt)
            .format("DD MMMM YYYY, HH:mm")}</div>
        </div>
        <div className="users">
          {user &&
          <div className={`user-name ${user.image ? "with-image" : ""}`}>{user.firstname} {user.lastname}</div>
          }
          <div className="user-image">
            <UserAvatar 
              className="user-image-avatar"
              fileId={user && user.image}
            />
          </div>
        </div>
       
        
      </div>
      {/* )} */}
      {state && state.isFinal ?
        <p className="state-final-text">Регламент завершен</p>
        :
        null  
      }
      {transitionButtons.length > 0  && 
      <div className="state-btn__container">
        {transitionButtons}
      </div>}
    </div>
  );
});
export default StateCard;
