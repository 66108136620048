import React, { useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Field } from "@ais3p/ui-framework";

const BoolOptions = [
  { label: "Yes", value: true },
  { label: "No", value: false },
  { label: "N/A", value: undefined }
];

const TestOptions = [
  { label: "Pass", value: true },
  { label: "Fail", value: false },
  { label: "N/A", value: undefined }
];

/**
 * Компонент для отображения/редактирования boolean значения в ячейке табличного представления результата CheckList
 * 
 * @param {String} name имя переменной boolean значения
 * @param {String} value значение переменной boolean значения
 * @param {String} className пользовательский className
 * @param {Func} onChange callback ф-я на изменение значения
 */
const BooleanContent = ({ name, value, className, onChange }) => {
  const [isEditMode, setEditMode] = useState(false);
  useEffect(() => {
    window.addEventListener("click", onMouseClick);
    return () => {
      window.removeEventListener("click", onMouseClick);
    };
  }, []);

  const onMouseClick = useCallback((e) => {
    const el = e.target.closest(".ais-field");
    if (!el) { // кликнули не в поле select
      setEditMode(false);
    }
  }, []);

  const onClick = useCallback((e) => {
    e.stopPropagation();
    setEditMode(true);
  }, []);

  const onChangeValue = useCallback((val) => {
    onChange && onChange(name, val);
  }, [name, isEditMode, value, onChange]);

  const renderBooleanValue = (value) => {
    switch (value) {
      case undefined:
        return "N/A";
      case true:
        return name === "testResult" ? "Pass" : "Yes";
      case false:
        return name === "testResult" ? "Fail" :  "No";  
      default:
        return value;
    }
  };

  return (
    <div
      className={classNames("boolean-value", className, {
        editing: isEditMode,
        yes:     value === true,
        no:      value === false
      })}
    >
      {!isEditMode && <div onClick={onClick}>{renderBooleanValue(value)}</div>}
      {isEditMode && (
        <Field.SingleSelect
          icon=""
          value={value}
          onChange={onChangeValue}
          isClearable={false}
          options={name === "testResult" ? TestOptions : BoolOptions}
        />
      )}
    </div>
  );
};

BooleanContent.propTypes = {
  name:      PropTypes.string.isRequired,
  value:     PropTypes.string,
  onChange:  PropTypes.func,
  className: PropTypes.string
};

export default BooleanContent;
