import React, { useCallback, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { Components } from "@ais3p/ui-framework";

import BranchesAndTagsMenu from "./BranchesAndTagsMenu";
import {
  REPO_STATE_BRANCH,
  REPO_STATE_TAG
} from "../../constants/repoStateTypes";
import RepoStore from "../../stores/RepoStore";

/**
 * Кнопка для отображения выбранной ветки/тэга репозитория.
 * А так же  для вызова выпадающего меню для выбора ветки/тэга репозитория.
 *
 * @type {BranchesAndTags}
 */
const BranchesAndTags = observer(({ store }) => {
  const [isShowMenu, setIsShowMenu] = useState(false);

  /**
   * Событие для открытия меню со списком веток/тэгов
   */
  const onShowMenu = useCallback(() => {
    setIsShowMenu((state) => {
      return !state;
    });
  }, []);

  /**
   * Событие для закрытия меню со списком веток/тэгов
   */
  const onCloseMenu = useCallback(() => {
    setIsShowMenu(false);
  }, [setIsShowMenu]);

  /**
   * Событие при выборе ветки или тэга
   *
   * @param {String} type тип значания: branch | tag
   * @param {String} value значение
   */
  const onSelectItem = useCallback(() => {
    setIsShowMenu(false);
  }, []);

  /**
   * Определяем какую иконку инужно отобразить: для ветки или тэга
   */
  const icon = useMemo(() => {
    let result = "repository-M";
    if (!store.rootRepoNode) {
      return result;
    }
    const tag =
      store.rootRepoNode[REPO_STATE_TAG] || store.repoState[REPO_STATE_TAG];
    if (tag) {
      result = "token-M";
    }

    return result;
  }, [store.rootRepoNode]);

  /**
   * Определяем что отобразить- название ветки или тэга
   */
  const label = useMemo(() => {
    let result = "";
    if (!store.rootRepoNode) {
      return result;
    }
    const tag =
      store.rootRepoNode[REPO_STATE_TAG] || store.repoState[REPO_STATE_TAG];
    const branch =
      store.rootRepoNode[REPO_STATE_BRANCH] ||
      store.repoState[REPO_STATE_BRANCH];
    if (tag) {
      result = tag;
    } else if (branch) {
      result = branch;
    }

    return result;
  }, [store.rootRepoNode]);

  return (
    <div className="repo-branches-tags">
      <Components.Icon name={icon} className="repo-icon" />
      <label>{label}</label>
      <div onClick={onShowMenu} className="repo-dropdown">
        <Components.Icon name={"decrement-S"} />
      </div>
      <BranchesAndTagsMenu
        disabled={!isShowMenu}
        label={label}
        store={store}
        onSelectItem={onSelectItem}
        onClose={onCloseMenu}
      />
    </div>
  );
});

BranchesAndTags.propTypes = {
  store: PropTypes.instanceOf(RepoStore)
};


export default BranchesAndTags;
