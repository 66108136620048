import React, { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { observer } from "mobx-react";
import Target from "~/core/components/Target";
import useStores from "~/core/utils/useStores";
import { Components } from "@ais3p/ui-framework";
import MachinesList from "../components/MachinesList";
import "./css/index.scss";
import WorkflowList from "../components/WorkflowList";
import Diagram from "../components/Diagram";

/**
 * Инструмент для работы с жизненными циклами
 *
 * @param {Object} props набор параметров
 * @param {String} props.uid id отселживаемого элемента
 * @param {Number} props.version версия отселживаемого элемента
 * @param {Object} props.trackedItem информация об отселживаемом элементе
 */
const WorkflowSidePanel = observer ((props) => {
  const { uid, version, trackedItem } = props;
  const { workflowStore } = useStores(); 
  const { isPending } = workflowStore;
  const [machinesValues, setMachinesValues] = useState();
  const [object, setObject] = useState(null);
  const [switchBtn, setSwitchBtn] = useState(false);

  useEffect(async() => {
    const values = await workflowStore.getMachinesValues();
    setMachinesValues(values);
  }, [workflowStore]);

  useLayoutEffect(() => {
    if (uid) {
      workflowStore.getWorkflows({ uid, version });
      setObject({ uid, version });
    }
  }, [workflowStore, uid, version]);

  const hideModal = useCallback(() => {
    workflowStore.diagramClear();
    workflowStore.setDiagramDirection("LR");
    setSwitchBtn(false);
  }, []);

  return (
    <div className="workflow-sidepanel">
      <Target id={uid} version={version} trackedItem={trackedItem} />
      <div className="workflow-content">
        {isPending && (
          <div className="preload-wrapper">
            <Components.Preloader size={4} />
          </div>
        )}
        {!isPending && object && machinesValues && (
          <WorkflowList object={object} trackedItem={trackedItem} />
        )}
        {!isPending  && object && machinesValues && 
        (<MachinesList values={machinesValues} object={object} />)}
      </div>
      {!!workflowStore.diagramMachine && (
        <Diagram
          key={`diagram-${workflowStore.diagramWorkflow.id}`}
          trackedItem={trackedItem}
          onHide={hideModal}
          switchBtn={switchBtn}
          setSwitchBtn={setSwitchBtn}
        />
      )}
    </div>
  );
});
export default WorkflowSidePanel;
