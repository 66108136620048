import React, { useCallback, useMemo } from "react";
import classNames  from "classnames";
import { observer } from "mobx-react";

import { dnd } from "@ais3p/ui-framework";
import AisIcon from "~/core/components/AisIcon";
import { DOMAIN_REPO } from "~/core/constants/Domains";
import { CLS_REPO_FILE_FUNCTION } from "~/core/constants/Classes";
import { DND_SOURCECODE_TYPE } from "~core/constants/DnD";

/**
 * Компонент для отображения иконки типа сущности и индикатором наличия связи
 * На текущий момент индиация и иконка оторажается только у сущности типа  - функция
 *
 * @param {Object} params набор параметров
 * @param {Object} params.index индекс линии
 * @param {Array<Token>} params.line данные о линии в коде- массив токенов
 * @param {String} params.className пользовательский className
 * @param {CodeFile} params.codeFile объект, хранящий в себе информацию об открытом файле
 *
 * @type {CodeLineControls}
 * @returns {Component}
 */
const CodeLineControls = observer(({ index, line, className, codeFile, isFocused }) => {
  const codeObj = useMemo(() => {
    return codeFile.getCodeObject(index + 1);
  }, [index]);

  const onIconClick = useCallback(() => {
    codeFile.setFocusCodeObject(codeObj);
    codeFile.setFocusCodeLine(undefined);
  }, [codeObj]);

  const content = useMemo(() => {
    const { title } = line;

    if (!codeObj) {
      return;
    }

    // Пока позволяем делать связи только с функциями
    if (codeObj.type !== "function") {
      return;
    }

    const icon = codeObj.icon;
    // const uid = `${codeId}|${codeObj.type}/${codeObj.signature}`;
    const uid = codeObj.uid;

    const attributes = {
      "data-tooltip":    title,
      "data-tooltip-at": "bottom"
    };
    const isFocused = codeFile.focusCodeObject === codeObj;

    return (
      <div className="indicators">
        <dnd.Source
          uid={uid}
          type={DND_SOURCECODE_TYPE}
          item={{
            tool:    DOMAIN_REPO,
            uid,
            title,
            class:   CLS_REPO_FILE_FUNCTION,
            version: 0
          }}
        >
          <div
            className={classNames("code-icon", className)}
            {...attributes}
            onClick={onIconClick}
          >
            <AisIcon
              icon={icon}
              item={codeObj}
              className={isFocused ? "focused" : ""}
            />
          </div>
        </dnd.Source>
      </div>
    );
  }, [line, codeObj, codeFile.focusCodeObject]);

  return (
    <div className={classNames("code-line-controls", className, {
      focused: isFocused
    })}
    >
      {content}
    </div>
  );
});

export default CodeLineControls;
