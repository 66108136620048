import { observable, computed } from "mobx";

import  CodeModel from "./CodeModel";

/**
 * Модель значения кода Вида, полученная через Composer
 */
export default class CodeValueModel {
  /**
   * @type {CodeModel}
   * Код Вида
   */
  @observable
  code = undefined;

  /**
   * @type {Any}
   * Значение кода Вида
   */
  @observable
  value = undefined;

  static create({ code, value }, store) {
    if (!code) {
      return undefined;
    }
    return new  CodeValueModel({ code, value }, store);
  }

  constructor(data, store) {
    this.store = store;

    this.value = data.value;
    this.code = CodeModel.create(data.code);
  }

  /**
   * Получить текстовое представление кода Вида
   * 
   * @returns {String} текстовое представление кода Вида
   */
  @computed
  get title() {
    return  `${this.code.title} = ${this.value}`;
  }

  /**
   * Получить уникальный индификатор кода
   * 
   * @returns {String} уникальный индификатор кода
   */
  @computed
  get uid() {
    return  this.code.uid;
  }
}
