import { observable, action, computed, toJS } from "mobx";

/**
 * Модель значения атрибута 
 */
class AttrValue {
  /**
   * Уникальный иденитификатор атрибута, для которого здесь хранится значение
   * 
   * @type {String} 
   */
  @observable 
  id;

  /**
   * Занчение атрибута
   * 
   * @type {Any} 
   */
  @observable 
  attrValue;

  /**
   * Занчение атрибута при инициализации
   * Потребуется, чтбы потом  мб сделать откат (revert)
   * 
   * @type {Any} 
   */
  @observable 
  initValue;

  /**
   * Тип значения атрибута: string, integer, float, enum, date, datetime
   * 
   * @type {Any} 
   */
  @observable 
  type;

  /**
   * Признак, что значение должно быть не пустым
   * Будет использваться при валидации
   * 
   * @type {Boolean} 
   */
  @observable 
  isRequired;

  /**
   * Признак, что значение валидно
   * 
   * @type {Boolean} 
   */
  @observable 
  valid;

  /**
   * Конструктор
   * 
   * @param {Object} data значения для инициализации модели
   * @param {KindStore} store хранилище для работы с Видами и Атрибутами
   */
  constructor(data, store) {
    this.store = store;

    this.id = data.id;
    this.type = data.type;
    this.isRequired = this.attribute.isRequired;

    let val = data.value;
    if (this.type === "datetime") {
      val = new Date(val);
    } else if (this.type === "boolean") {
      val = val === undefined ? false : val;
    }

    this.attrValue = val;
    this.initValue = val;

    this.valid = data.isValid;
  }

  /**
   * Метод задания значения у атрибута
   * 
   * @param {Any} value новое значение у атрибута
   * @param {Booelan} isValid признак валидности нового занчения у атрибута
   */
  @action
  change(value, isValid) {
    this.attrValue = value;
    this.valid = isValid;
  }

  /**
   * Зафиксировать значение атрибута
   * 
   */
  @action
  save() {
    this.initValue = toJS(this.attrValue);
  }

  /**
   * Вернуть (revert) значение атрибута к первоначальному состоянию
   */
  @action
  revert() {
    this.attrValue = toJS(this.initValue);
    this.valid = true;
  }

  /**
   * Вернуть uid атриубта
   * 
   * @returns {String} id атрибута
   */
  @computed
  get uid() {
    return this.id;
  }

  /**
   * Получить модель атрибута
   * 
   * @returns {Attr} модель атрибута
   */
  @computed
  get attribute() {
    return this.store.getAttr(this.id) || {};
  }

  /**
   * Получить значение атрибута
   * 
   * @return {Any} значение атрибута
   */
  @computed
  get value() {
    return toJS(this.attrValue);
  }

  /**
   * Получить значение атрибута при инициализации, те до сделанных изменений
   * 
   * @returns {Any} значение атрибута при инициализации, те до сделанных изменений
   */
  @computed
  get initialValue() {
    return toJS(this.initValue);
  }

  /**
   * Признак валидности значения атрибута
   * 
   * @returns {Boolean}
   */
  @computed
  get isValid() {
    // если уже был задан пользователем флаг valid, то используем его
    // если не был задан, то ставим сначала, что значение валидно и делаем потом свою валидацию
    let valid = this.valid === undefined ? true : this.valid;
    if (
      this.isRequired &&
      (this.value === undefined || 
       (Array.isArray(this.value) && this.value.length === 0) || 
       this.value === "")
    ) {
      valid = false;
    }
    return valid;
  }

  /**
   * Признак, что были сделаны изменения в значении атрибута
   * 
   * @returns {Boolean}
   */
  @computed
  get isEdited() {
    let edited = toJS(this.initValue) !== toJS(this.attrValue);
    if (this.type === "string") {
      const blankStrings = new Set(["", undefined, null]);
      edited =
        edited &&
        !(blankStrings.has(this.initValue) && blankStrings.has(this.attrValue));
    } else if (this.type === "enum") {
      edited =
        JSON.stringify(toJS(this.initValue)) !==
        JSON.stringify(toJS(this.attrValue));
    }
    return edited;
  }

  /**
   * Получить значение атрибута в формате для сохранения его на стороне сервиса
   * 
   * @return {Any} значение атрибута в формате для сохранения его на стороне сервиса
   */
  @computed
  get valueToSend() {
    if (this.type === "datetime") {
      return this.attrValue.toISOString();
    }
    return toJS(this.attrValue);
  }
}

export default AttrValue;
