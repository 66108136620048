import { observable, computed } from "mobx";

/**
 * Модель атрибута Вида, полученная через Composer
 */
export default class AttributeModel {
  /**
   * @type {AttributeModel}
   * Уникальный идентификатор атрибута Вида
   */
  @observable
  uid = undefined;

  /**
   * @type {String}
   * Название атрибута Вида
   */
  @observable
  name = undefined;

  static create({ uid, name }, store) {
    return new  AttributeModel({ uid, name }, store);
  }

  constructor(data, store) {
    this.store = store;

    this.uid = data.uid;
    this.name = data.name;
  }

  /**
   * Получить название атрибута Вида
   * 
   * @returns {String} название атрибута Вида
   */
  @computed
  get title() {
    return  this.name;
  }
}
