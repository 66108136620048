import React, { useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import Prism from "prismjs";
import { observer } from "mobx-react";

import { generateLines, themeToDict } from "../../utils";
import defaultTheme from "./themes/github";
import CodeLine from "./CodeLine";
import CodeFile from "../../models/CodeFile";

/**
 * Компонент для подсветки кода
 * 
 * @param {Object} props передаваемые св-ва
 * @param {CodeFile} props.codeFile объект, содержащий инофрмацию о просматриваемом файле
 * @param {Object} props.theme theme предустановленная тема, для отображения кода. 
 * По умолчанию устаналвивается "./themes/github"
 * 
 * @type {Highlight}
 * @returns {Component}
 */
const Highlight = observer(({ 
  codeFile, 
  theme = defaultTheme
}) => {
  /**
   * Набор стилей для выбранной темы и языа
   * 
   */
  const themeDict = useMemo(() => {
    return theme
      ? themeToDict(theme, codeFile.language)
      : undefined;
  }, [theme, codeFile.language]);

  /**
   * Получить свойства для DOM линии с кодом
   * 
   * @param {Object} props передаваемые св-ва
   * @param {Number} props.key индекс, а заодно и key для линии кода 
   * @param {String} props.className пользовательский className для линии кода 
   * @param {Object} props.style пользовательский style для линии кода 
   * 
   * @return {Object}
   */
  const getLineProps = useCallback(({
    key,
    className,
    style,
    // eslint-disable-next-line no-unused-vars
    line, 
    ...rest
  }) => {
    const output = {
      ...rest,
      className: "token-line",
      style:     undefined,
      key:       undefined
    };

    if (themeDict !== undefined) {
      output.style = themeDict.plain;
    }

    if (style !== undefined) {
      output.style =
        output.style !== undefined ? { ...output.style, ...style } : style;
    }

    if (key !== undefined) output.key = key;
    if (className) output.className += ` ${className}`;

    return output;
  }, [codeFile.content, themeDict]);

  /**
   * Получить набор стилей для token'a  в коде
   * 
   * @param {Object} props передаваемые св-ва
   * @param {Array<String>} props.tokens набор типа токена
   * @param {Boolean} props.empty пустой ли токен
   * 
   * @return {Object}
   */
  const getStyleForToken = ({ types, empty }) => {
    const typesSize = types.length;
    
    if (themeDict === undefined) {
      return undefined;
    } else if (typesSize === 1 && types[0] === "plain") {
      return empty ? { display: "inline-block" } : undefined;
    } else if (typesSize === 1 && !empty) {
      return themeDict[types[0]];
    }

    const baseStyle = empty ? { display: "inline-block" } : {};
    const typeStyles = types.map((type) => {
      return themeDict[type];
    });
    return Object.assign(baseStyle, ...typeStyles);
  };

  /**
   * Получить свойства для DOM token'a в коде
   * 
   * @param {Object} props передаваемые св-ва
   * @param {Number} props.key key для токена
   * @param {String} props.className пользовательский className для токена
   * @param {Object} props.style пользовательский style для токена
   * 
   * @return {Object}
   */
  const getTokenProps = useCallback(({
    key,
    className,
    style,
    token,
    ...rest
  }) => {
    const output = {
      ...rest,
      className: `token ${token.types.join(" ")}`,
      children:  token.content,
      style:     getStyleForToken(token),
      key:       undefined
    };

    if (style !== undefined) {
      output.style =
        output.style !== undefined ? { ...output.style, ...style } : style;
    }

    if (key !== undefined) output.key = key;
    if (className) output.className += ` ${className}`;

    return output;
  }, [codeFile.code, themeDict]);

  /**
   * Разобрать код на токены
   * 
   * @param {String} code содержимое кода
   * @param {Grammar} grammar Prism грамматика 
   * @param {String} language тип языка
   * 
   * @return {Array<Token>}
   */
  const tokenize = (
    code,
    grammar,
    language
  ) => {
    const env = {
      code,
      grammar,
      language,
      tokens: []
    };

    Prism.hooks.run("before-tokenize", env);
    const tokens = (env.tokens = Prism.tokenize(env.code, env.grammar));
    Prism.hooks.run("after-tokenize", env);

    return tokens;
  };

  /**
   * Формируем набор линий для кода на основе грамммаики Prism
   */
  const lines = useMemo(() => {
    const grammar = Prism.languages[codeFile.language];
    const mixedTokens =
      grammar !== undefined
        ? tokenize(codeFile.content, grammar, codeFile.language)
        : [codeFile.content];
    return generateLines(mixedTokens, codeFile.language);
  }, [codeFile.content, codeFile.code, codeFile.language]);

  return (
    <pre 
      className={`prism-code language-${codeFile.language}`} 
      style={themeDict !== undefined ? themeDict.root : {}}
    >
      {lines.map((line, i) => {
        return (
          <CodeLine
            key={i}
            index={i}
            needScrollToThisLine={codeFile.focusCodeLine === (i + 1)}
            line={line}
            getLineProps={getLineProps}
            getTokenProps={getTokenProps}
            codeFile={codeFile}
          />
        );
      })}
    </pre>
  );
});

Highlight.propTypes = {
  codeFile: PropTypes.instanceOf(CodeFile),
  theme:    PropTypes.object
};

export default Highlight;
