import { observable } from "mobx";

/**
 * Модель для хранения инофрмации о переходе
 * 
 * @type {Transition}
 */
class Transition {
  /**
   * @type {Number}
   * id перехода
   */
  @observable id;

  /**
   * @type {String}
   * имя перехода
   */
  @observable name;

  /**
   * @type {String}
   * уникальный идентификатор
   */
  @observable slug;

  /**
   * @type {Object}
   * начальное состояние перехода
   */
  @observable fromState;

  /**
   * @type {Object}
   * конечное состояние перехода
   */
  @observable toState;

  constructor(data, machine) {
    this.id = data.id;
    this.name = data.name;
    this.slug = data.slug;
    this.fromState = machine.getStateById(data.fromStateId);
    this.toState = machine.getStateById(data.toStateId);
  }
}

export default Transition;
