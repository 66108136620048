import { observable, action, computed } from "mobx";

import Code from "./Code";

/**
 * Модель вида
 *
 */
class Kind {
  /**
   * Уникальный идентифиактор вида
   * @type {String}
   */
  @observable 
  uid;
  
  /**
   * Наименование вида. Уникально в пределах экземпляра системы.
   * @type {String}
   */
  @observable 
  name;

  @observable 
  type = "kind";
  
  /**
   * Массив uid'ов атрибутов у вида
   * @type {Array<String>}
   */
  @observable 
  attrs = [];

  /**
   * Набор кодов
   * 
   * @type {Map<Code>}
   */
  @observable 
  codes = new Map();

  /**
   * Набор схем трассировки вида
   * 
   * @type {Map<TraceSchema>}
   */
  @observable 
  traceSchemasMap = new Map();

  /**
   * Доступные типы (названия Видов)
   * 
   * @type {Array<String>}
   */
  @observable 
  allowedTypes = null;

  /**
   * Доступные виды (названия Видов)
   * 
   * @type {Array<String>}
   */
  @observable 
  allowedKinds = null;

  /**
   * Доступные задачи (названия Видов)
   * 
   * @type {Array<String>}
   */
  @observable 
  allowedTasks = null;
  
  /**
   * Конструктор 
   * 
   * @param {Object} data данные атрибута
   * @param {KindStore} kindStore храилище для работы с видами и атрибутами
   */
  constructor(data, kindStore) {
    this.kindStore = kindStore;

    this.uid = data.uid;
    this.name = data.name;

    if (data.allowedTypes  && data.allowedTypes.length > 0) {
      this.allowedTypes = data.allowedTypes;
    }

    if (data.allowedKinds && data.allowedKinds.length > 0) {
      this.allowedKinds = data.allowedKinds;
    }

    if (data.allowedTasks && data.allowedTasks.length > 0) {
      this.allowedTasks = data.allowedTasks;
    }

    if (data.codes) {
      data.codes.forEach((code) => {
        this.addCode(Code.create(code));
      });
    }

    data.attributes &&
      data.attributes.forEach((attr) => {
        this.attrs.push(attr.uid);
      });
  }

  /**
   * id вида
   * 
   * @returns {String} id вида
   */
  @computed
  get id() {
    return this.uid;
  }

  /**
   * Изменить имя у вида
   * 
   * @param {String} name новое имя Вида
   */
  @action
  changeName(name) {
    this.name = name;
  }
  
  /**
   * Добавить Code в набор кодов
   * 
   * @param {Code} code код вида
   */
  @action
  addCode(code) {
    if (code) {
      this.codes.set(code.uid, code);
    }
  }
  
  /**
   * Добавить uid атрибута в массив атриубутов у Вида
   * 
   * @param {String} uid добавляемого атрибута
   */
  @action
  addAttribute(uid) {
    this.attrs.push(uid);
  }
  
  /**
   * Удалить uid атрибута из массива атриубутов у Вида
   * 
   * @param {String} uid добавляемого атрибута
   */
  @action
  deleteAttribute(uid) {
    this.attrs.remove(uid);
  }
  
  /**
   * Получить список атрибутов, принадлежащих Виду
   * 
   * @returns {Array<Attr>} массив атрибутов
   */
  @computed
  get attributes() {
    return this.attrs.map((id) => {
      return this.kindStore.getAttr(id);
    });
  }

  /**
   * Получить список Кодов, принадлежащих Виду
   * 
   * @returns {Array<Code>} массив кодов
   */
  @computed
  get codesArray() {
    return Array.from(this.codes.values());
  }

  /**
   * Очистить список схем трассировок/gap анализа связей 
   */
  @action
  clearTraceSchemas() {
    this.traceSchemasMap.clear();
  }

  /**
   * Добавить схему трассировки/gap анализа связей, доступную для Вида
   * 
   * @param {TraceSchema} schema схема трассировки/gap анализа связей, доступная для Вида
   */
  @action
  addTraceSchema(schema) {
    this.traceSchemasMap.set(schema.id, schema);
  }

  /**
   * Получить схему трассировки/gap анализа связей, доступную для Вида по ее id
   * 
   * @return {TraceSchema} схема трассировки/gap анализа связей, доступная для Вида
   */
  getTraceSchema(schemaId) {
    return this.traceSchemasMap.get(schemaId);
  }

  /**
   * Получить список схем трассировки/gap анализа связей, доступных для Вида
   * 
   * @return {Array<TraceSchema>} список схем трассировки/gap анализа связей, доступных для Вида
   */
  @computed
  get traceSchemasList() {
    return  Array.from(this.traceSchemasMap.values());
  }
}

export default Kind;
