import React, { useCallback, useMemo, cloneElement } from "react";
import { observer } from "mobx-react";
import { Modal } from "@ais3p/ui-framework";
import useStores from "~/core/utils/useStores";

/**
 * Компонент для отображения окна с подтверждением действия
 * Конфигурацию об отображении окна берется из uiStore.confirmCfg 
 */
const Confirm = observer(() => {
  const { uiStore } = useStores();

  const onEscPress = useCallback(() => {
    if (uiStore.confirmCfg && uiStore.confirmCfg.onKeyPressEsc) {
      uiStore.confirmCfg.onKeyPressEsc && uiStore.confirmCfg.onKeyPressEsc();
    } else {
      uiStore.hideConfirm();
    }    
  }, [uiStore.confirmCfg && uiStore.confirmCfg.onKeyPressEsc]);

  const onEnterPress = useCallback(() => {
    uiStore.confirmCfg && uiStore.confirmCfg.onKeyPressEnter && uiStore.confirmCfg.onKeyPressEnter();
  }, [uiStore.confirmCfg && uiStore.confirmCfg.onKeyPressEnter]);

  // обновляем сво-ва isLoading у кнопок, если установлен флаг uiStore.isConfirmPending 
  const buttons = useMemo(() => {
    const res = [];
    uiStore.confirmCfg && uiStore.confirmCfg.buttons && uiStore.confirmCfg.buttons.forEach((btn) => {
      res.push(cloneElement(btn, { isLoading: uiStore.isConfirmPending }));
    });
    return res;
  }, [uiStore.confirmCfg && uiStore.confirmCfg.buttons, uiStore.isConfirmPending]);

  // если uiStore.confirmCfg не задан, то ничего не отображаем (пустой блок)
  if (!uiStore.confirmCfg) {
    return <div className={"app-confirm"} />;
  }

  // если uiStore.confirmCfg задан, то отображаем  модальное окно
  const {
    name = "confirm",
    icon = "plane-M",
    title = "Подтверждение",
    // buttons = [],
    content = ""
  } = uiStore.confirmCfg;

  
  return (
    <Modal.Window
      name={name}
      icon={icon}
      show={true}
      title={title}
      buttons={buttons}
      onKeyPressEsc={onEscPress}
      onKeyPressEnter={onEnterPress}
    >
      {content}
    </Modal.Window>
  );
});


export default Confirm;
