import React from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import KindItem from "../models/KindItem";
import KindGroup from "./KindGroup";

/**
 * @component
 * 
 * Отображение записи представителя Вида 
 * 
 * @param {KindItem} kindItem объект представителя Вида 
 */
const ItemView = observer(({ kindItem, objectUid, version  }) => {
  if (!kindItem || (kindItem && kindItem.kindsSize <= 0)) {
    return (
      <div className="item-content">
        <div className="header">
          <span className="text-holder">Объект не имеет видов</span>
        </div>
      </div>
    );
  }

  return (
    <div className="item-content">
      {
        kindItem.initialKinds.map((member) => {
          return (
            <KindGroup
              key={member.uid}
              kindItem={kindItem}
              objectUid={objectUid}
              version={version}
              member={member}
            />
          );
        })
      }
    </div>
  );
});

ItemView.propTypes = {
  kindItem: PropTypes.instanceOf(KindItem)
};

export default ItemView;
