import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { Dnd } from "@ais3p/ui-framework";
import { DND_ISSUE_TYPE } from "~/core/constants/DnD";
import IssueStore from "../../stores/IssueStore";

/**
 * Компонент для отображения пустой области на доске Kanban, в которую можно перенести карточку задачи
 * для изменения ее статуса в колонке Статуса задачи
 * 
 * @param {Object} props набор параметров
 * @param {IssueStore} params.store хранилище для работы с задачами
 * @param {String} params.statusId id статуса задачи, в колонке которого будет отображена эта область
 * @param {Function} params.changeIssueStatus ф-я, которую необходимо вызвать для изменения статуса у задачи
 * @param {Function} params.moveIssue ф-я, которую необходимо вызвать для перемещения порядка следования задачи 
 * в колонке со статусами задач (те изменить ее order)
 */
const EmptyDnDTarget = observer((props) => {
  const { store, statusId, changeIssueStatus, moveIssue } = props;
  const { readOnly } = store;

  const canDrop = useCallback(() => {
    return !readOnly;
  }, [readOnly]);

  const onHover = useCallback(
    (prps, monitor) => {
      if (readOnly) {
        return;
      }

      const { uid: draggedUid, isCard } = monitor.getItem();

      // переносить можем только карточки
      if (!isCard) {
        return;
      }

      if (draggedUid && moveIssue) {
        moveIssue(draggedUid);
      }
    },
    [moveIssue, statusId, readOnly]
  );

  const onDrop = useCallback(
    (prps, monitor) => {
      if (readOnly) {
        return;
      }

      const { uid: draggedUid, isCard } = monitor.getItem();
      // переносить можем только карточки
      if (!isCard) {
        return;
      }

      if (draggedUid && changeIssueStatus) {
        changeIssueStatus(draggedUid, statusId);
      }
    },
    [changeIssueStatus, statusId, readOnly]
  );

  return (
    <Dnd.Target
      drop={onDrop}
      hover={onHover}
      canDrop={canDrop}
      accept={[DND_ISSUE_TYPE]}
      className="issue-dnd-empty-wrapper"
    >
      <div className="issue-dnd-empty" />
    </Dnd.Target>
  );
});

EmptyDnDTarget.propTypes = {
  store:             PropTypes.instanceOf(IssueStore).isRequired, 
  statusId:          PropTypes.number.isRequired,
  changeIssueStatus: PropTypes.func.isRequired,
  moveIssue:         PropTypes.func.isRequired  
};

export default EmptyDnDTarget;
