/**
 * Library module
 */
import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { observer } from "mobx-react";

import useStores from "~/core/utils/useStores";

import "./css/index.scss";
import TreeNode from "./TreeNode";
import iconRender from "~/core/utils/IconRender";
import {
  SIDEPANEL_RELATIONS,
  SIDEPANEL_KINDS_ATTRS,
  SIDEPANEL_JOURNAL,
  SIDEPANEL_VALIDATION,
  SIDEPANEL_WORKFLOW
} from "~/core/constants/SidePanels";
import {
  CLS_LIBRARY_COLLECTION, 
  CLS_LIBRARY_FILE, 
  CLS_LIBRARY_REPOSITORY, 
  CLS_LIBRARY_TEXT_MATERIAL, 
  CLS_LIBRARY_TEXT_VERSION, 
  CLS_TEXT_FORM_TEXT
} from "~/core/constants/Classes";
import { DOMAIN_LIBRARY } from "~/core/constants/Domains";
import { Components, ContextMenu, Field, Modal } from "@ais3p/ui-framework";
import uid from "../../../../../core/utils/uid";
import LibraryStore from "../stores/LibraryStore";
import InfoToolWindow from "~/core/components/InfoToolWindow/InfoToolWindow.jsx";
import infoToolContent from "~/core/components/InfoToolWindow/infoToolContent.js";
/**
 * Библиотека -- приложение для навигации между материалами и их коллекциями.
 */

const repoSelectOptions = [
  {
    label: "SVN",
    value: "svn"
  },
  {
    label: "GIT",
    value: "git"
  },
  {
    label: "Mercurial HG",
    value: "hg"
  }
];

const menu = {
  STATIC: [
    {
      icon:   "editing-M",
      title:  "Переименовать",
      type:   "write",
      action: "rename"
    },
    { isDivider: true },
    {
      icon:   "cancel-M",
      title:  "Удалить",
      type:   "delete",
      action: "remove"
    }
  ],
  COLLECTION: [
    {
      icon:  "collection-create-M",
      title: "Создать...",
      type:  "create"
    },
    {
      icon:     "upload-M",
      isAction: true,
      type:     "library.FileMaterial",
      title:    "Загрузить файл",
      action:   "upload"
    },
    { isDivider: true },
    {
      icon:     "plus-M",
      isAction: true,
      type:     "library.Upload-URS",
      title:    "Загрузить URS",
      action:   "importUrs"
    },
    {
      icon:     "plus-M",
      isAction: true,
      type:     "library.Upload-ROS",
      title:    "Импортировать",
      data:     { action: "importRos" }
    },
    {
      icon:     "file-docx-M",
      isAction: true,
      type:     "library.TextMaterial",
      action:   "import",
      title:    "Импортировать"
    },
    {
      icon:     "app-tree-M",
      title:    "Добавить репозиторий",
      type:     "library.Upload",
      isAction: true,
      action:   "repo",
      children: [
        {
          icon:     "plus-M",
          isAction: true,
          type:     "library.Upload",
          title:    "Добавить новый",
          action:   "addRepoNew"
        },
        {
          icon:     "list-M",
          isAction: true,
          type:     "library.Upload",
          title:    "Выбрать из списка",
          action:   "addRepo"
        }
      ]
    },
    { isDivider: true },
    {
      icon:   "open-M",
      type:   "read",
      title:  "Открыть в новом окне",
      action: "openCollection"
    },
    { isDivider: true },
    {
      icon:   "editing-M",
      type:   "write",
      title:  "Переименовать",
      action: "rename"
    },
    {
      icon:   "cancel-M",
      type:   "delete",
      title:  "Удалить",
      action: "remove"
    }
  ],
  REPORT: [
    {
      icon:   "open-M",
      title:  "Gap анализ",
      type:   "open",
      action: "openReport"
    },
    { isDivider: true },
    {
      icon:   "cancel-M",
      title:  "Удалить",
      type:   "delete",
      action: "remove"
    }
  ],
  MATERIAL: [
    {
      icon:   "open-M",
      type:   "read",
      title:  "Открыть редакцию",
      action: "openRedaction"
    },
    { isDivider: true },
    {
      icon:   "editing-M",
      title:  "Переименовать",
      type:   "write",
      action: "rename"
    },
    {
      icon:   "cancel-M",
      title:  "Удалить",
      type:   "delete",
      action: "remove"
    }
  ],
  VERSION: [
    {
      icon:   "open-M",
      type:   "read",
      title:  "Открыть",
      action: "openVersion"
    }
  ],
  FILE: [
    {
      icon:   "open-M",
      type:   "read",
      title:  "Открыть",
      action: "openFile"
    },
    {
      icon:   "editing-M",
      title:  "Переименовать",
      type:   "write",
      action: "rename"
    },
    { isDivider: true },
    {
      icon:   "cancel-M",
      title:  "Удалить",
      type:   "delete",
      action: "remove"
    }
  ],
  REPO: [
    {
      icon:   "open-M",
      type:   "read",
      title:  "Открыть",
      action: "openRepo"
    },
    { isDivider: true },
    {
      icon:   "editing-M",
      title:  "Переименовать",
      type:   "write",
      action: "rename"
    },
    {
      icon:   "cancel-M",
      title:  "Удалить",
      type:   "delete",
      action: "remove"
    }
  ]
};

const Library = observer((props) => {
  const {
    tabId,
    layoutStore,
    id,
    layoutItem,
    isSubVisible
    // path,
    // cursor
  } = props;

  const { rootStore, objectStore, kindsStore, accountStore, configStore, uiStore } = useStores();
  const [contextMenu, setContextMenu] = useState([]);
  const [selected, setSelected] = useState(null);
  const [fileTarget, setFileTarget] = useState(null);
  const [importType, setImportType] = useState(null);
  const [repoTarget, setRepoTarget] = useState(null);
  const [repoFormId, setRepoFormId] = useState(null);
  const [repoFormValues, setRepoFormValues] = useState({});
  const [repoFormValidations, setRepoFormValidations] = useState({});
  const fileInput = useRef(null);
  const [infoIsVisible, setInfoIsVisible] = useState(false);

  useEffect(() => {
    const item = objectStore.getVersion(selected, DOMAIN_LIBRARY);
    if (item) {
      const props = {
        uid:         item.uid,
        version:     item.number,
        editable:    item.editable,
        trackedItem: {
          uid:     item.uid,
          parent:  item.number ? item.parentUid : null,
          version: 0,
          class:   item.class,
          tool:    DOMAIN_LIBRARY
        }
      };
      layoutItem.changeContext(id, selected, props);
    }
  }, [selected]);

  const store = useMemo(() => {
    return new LibraryStore(rootStore);
  }, [rootStore]);

  const { root, isPending, noRepresentation, gotError } = store;
  
  const onRetry = useCallback(() => {
    store.getNodeData(id);
  }, [id]);

  useEffect(() => {
    store.getNodeData(id);
  }, [id]);

  const createWithType = useCallback(async(item, data) => {
    item.setPending(true);
    let version = null;
    try {
      let result = null;
      if (data.type === CLS_LIBRARY_COLLECTION) {
        result = await store.api.createCollection(item.uid, { name: data.name || "Новая коллекция", uid: data.uid });
      } else if (data.type === CLS_LIBRARY_TEXT_MATERIAL) {
        const createdWM = await store.api.createText({
          class:   CLS_TEXT_FORM_TEXT,
          rubrics: []
        });
        const editable = createdWM && createdWM[0].uid;
        result = await store.api.createWM({ 
          name:   data.name || "Рабочий материал", 
          uid:    data.uid, 
          parent: item.uid, 
          editable 
        });
      }
      if (result) {        
        if (result.uid && data.uid) {
          await kindsStore.changeItemId(data.uid, result.uid);
        }
        version = await objectStore.processLibraryItem(result, DOMAIN_LIBRARY, {});
        version.setParentUid(item.uid);
        item.addChild(version.uid, -1);
      }
    } catch (error) {
      console.warn(error);
    }
    item.setPending(false);
    return version;
  }, [objectStore]);

  const createItemCallback = useCallback(async(data, parentUid, className) => {
    if (!parentUid || !data) {
      return false;
    }
    const parent = objectStore.getVersion(parentUid, DOMAIN_LIBRARY);
    const { name, id } = data;
    await createWithType(parent, { type: className, name, uid: id });
  }, []);

  const openWizard = useCallback((item, data) => {
    if (!data) {
      return false;
    }
    const { kind: kindId, type: className } = data;
    
    const kind = kindsStore.getKind(kindId);
    const id = uid();

    const kindIcon = !!kind && accountStore.getIcon(kind.name);

    uiStore.setWizard({
      icon:      kindIcon || "wizard-M",
      id,
      label:     kind.name,
      parent:    item.uid,
      onSuccess: createItemCallback,
      config:    {
        instantConfirm: false,
        nameTitle:      "Наименование",
        className,
        withName:       true,
        views:          [
          {
            type: "kind",
            kindId
          }
        ]
      }
    });
  }, [kindsStore, uiStore]);

  const createWithKind = useCallback((item, data) => {
    item.setPending(true);
    openWizard(item, data);
    item.setPending(false);
  }, [objectStore]);

  const onExpandNode = useCallback(async(item) => {
    if (item.isExpanded) {
      item.setExpanded(false);
    } else {
      item.setPending(true);
      await store.getNodeData(item.uid, true);
      item.setExpanded(true);
      item.setPending(false);
    }
  }, []);

  const renameNode = useCallback((item) => {
    item.setEditing(true);
  }, []);

  const openCollection = useCallback((item) => {
    const icon = iconRender(item, true);
    layoutStore.open({
      name:      item.title,
      id:        item.uid,
      icon:      icon || "app-library-M",
      component: "library",
      props:     {
        id: item.uid
      }
    });
  }, []);

  const openRedaction = useCallback((item) => {
    const icon = iconRender(item, true);
    layoutStore.open({
      name:      item.title,
      id:        item.uid,
      icon:      icon || "app-text-M",
      component: "text",
      props:     {
        id:       item.uid,
        editable: item.editable,
        uid:      item.uid,
        version:  0
      }
    });
  }, []);

  const openVersion = useCallback((item) => {
    const icon = iconRender(item, true);
    layoutStore.open({
      name:      item.title,
      id:        item.uid,
      icon:      icon || "app-text-M",
      component: "text",
      props:     {
        id:       item.parent.uid,
        editable: item.parent.editable,
        uid:      item.uid,
        version:  item.number
      }
    });
  }, []);

  const openFile = useCallback((item) => {
    layoutStore.open({
      name:      item.title,
      id:        item.uid,
      icon:      iconRender(item, true),
      component: "viewer",
      props:     {
        type: item.fileType,
        name: item.title,
        icon: iconRender(item, true),
        file: item.fileId
      }
    });
  }, []);

  const onRename = useCallback(async(item) => {
    item.setPending(true);
    await store.api.updateNode(item.uid, { name: item.name });
    item.setPending(false);
  }, []);

  const onDelete = useCallback(async(item) => {
    await store.api.removeNode(item.uid);
    if (item.class === "library.TextMaterial") {
      layoutStore.closeByCriterion({
        component: "text",
        props:     {
          version:  0,
          editable: item.editable
        }
      });
    }
    item.removeFromParent();
  }, []);

  const uploadFile = useCallback((item) => {
    setFileTarget(item);
    fileInput.current.click();
  }, [fileInput]);

  const importAsIs = useCallback((item) => {
    setImportType("as_is_doc_parser");
    setFileTarget(item);
    fileInput.current.click();
  }, [fileInput]);

  const importUrs = useCallback((item) => {
    setImportType("urs_parser");
    setFileTarget(item);
    fileInput.current.click();
  }, [fileInput]);
  
  const importRos = useCallback((item) => {
    setImportType("ros_tz_parser");
    setFileTarget(item);
    fileInput.current.click();
  }, [fileInput]);

  const confirmDelete = useCallback((item) => {
    item.confirmDelete();
  }, []);

  const showGAPAnalysis = useCallback((item, data) => {
    layoutStore.open({
      name:      "GAP анализ",
      id:        item.uid,
      icon:      "tracer-gap-analyser-M",
      component: "traceAnalyzer",
      props:     {
        id:          item.uid,
        traceType:   "gap",
        schemaId:    data.schemaId,
        trackedItem: {
          uid:     item.uid,
          tool:    item.tool,
          domain:  item.domain,
          version: item.number
        }
      }
    });
  }, []);

  const showTraceAnalysis = useCallback((item, data) => {
    layoutStore.open({
      name:      "Трассировка",
      id:        item.uid,
      icon:      "tracer-gap-analyser-M",
      component: "traceAnalyzer",
      props:     {
        id:          item.uid,
        traceType:   "trace",
        schemaId:    data.schemaId,
        trackedItem: {
          uid:     item.uid,
          tool:    item.tool,
          domain:  item.domain,
          version: item.number
        }
      }
    });
  }, []);

  const showTasks = useCallback((item, data) => {
    layoutStore.open({
      // name:      "Задачи",
      name:      item.name,
      id:        data.memberUid,
      icon:      "app-spzi-M",
      component: "tasks",
      props:     {
        id:          data.memberUid,
        trackedItem: {
          uid:     item.uid,
          tool:    item.tool,
          domain:  item.domain,
          version: item.number
        }
      }
    });
  }, []);

  const showDQTrace = useCallback((item, data) => {
    layoutStore.open({
      name:      `${data.schemaUid} Check list`,
      id:        data.schemaUid,
      icon:      "domain-M",
      component: "checkList",
      props:     {
        id:          data.schemaUid,
        version:     item.number,
        trackedItem: {
          uid:     item.uid,
          tool:    item.tool,
          domain:  item.domain,
          version: item.version
        }
      }
    });
  }, []);

  const openRepo = useCallback((item) => {
    layoutStore.open({
      name:      item.name,
      id:        item.uid,
      icon:      "app-tree-M",
      component: "sourcecode",
      props:     {
        id:          item.uid,
        editable:    item.editable,
        trackedItem: {
          uid:     item.uid,
          tool:    item.tool,
          domain:  item.domain,
          version: item.version
        }
      }
    });
  }, []);

  const addRepo = useCallback((item) => {
    setRepoTarget(item);
    setRepoFormValues({});
    setRepoFormId("new");
  }, [setRepoFormId]);

  const chooseRepo = useCallback((item) => {
    store.getRepos();
    setRepoTarget(item);
    setRepoFormValues({});
    setRepoFormId("list");
  }, [setRepoFormId, store]);

  const onMenuItemClick = useCallback((action, context, data) => {
    switch (action) {
      case "createWithType":
        createWithType(context, data);
        break;
      case "createWithKind":
        createWithKind(context, data);
        break;
      case "openCollection":
        openCollection(context, data);
        break;
      case "openRedaction":
        openRedaction(context, data);
        break;
      case "openVersion":
        openVersion(context, data);
        break;
      case "openFile":
        openFile(context, data);
        break;
      case "upload":
        uploadFile(context, data);
        break;
      case "remove":
        confirmDelete(context, data);
        break;
      case "showGAPAnalysis":
        showGAPAnalysis(context, data);
        break;
      case "showTraceAnalysis":
        showTraceAnalysis(context, data);
        break;
      case "showTasks":
        showTasks(context, data);
        break;
      case "showDQTrace":
        showDQTrace(context, data);
        break;
      case "addRepoNew":
        addRepo(context, data);
        break;
      case "addRepo":
        chooseRepo(context, data);
        break;
      case "openRepo":
        openRepo(context, data);
        break;
      case "import":
        importAsIs(context, data);
        break;
      case "importUrs":
        importUrs(context, data);
        break;
      case "importRos":
        importRos(context, data);
        break;
      case "rename":
        renameNode(context);
        break;
      default:
        break;
    }
  }, [renameNode, createWithKind, createWithType]);
  
  const makeCreateButtons = useCallback((caller) => {
    const { permissions } = accountStore;

    
    const allowedObjects = permissions.get("object");

    let expander = [];

    if (caller) {
      let callerKindItem = caller.kindsRepresentation;
      if (callerKindItem && callerKindItem.kindsSize === 0) {
        callerKindItem = undefined;
      }

      const additionalItems = configStore.getToolConfig(store.toolId);

      const additionalExpander = [];
      const limitations = additionalItems.typeCreate[caller.class || caller.payload.class] || [];

      let kindLimits = [];

      if (callerKindItem) {
        limitations.forEach((item) => {
          const perm = allowedObjects.get(item.as);
          if (perm && perm.has("create")) {
            const icon = iconRender({ class: item.as }, true);
            const title = item.name;
            const data = { action: "createWithType", type: item.as };
            if (
              title &&
              (!callerKindItem ||
                callerKindItem.etype === "aggr.kindsattrs.item.empty" ||
                (callerKindItem.allowedTypes &&
                  !item.kind &&
                  callerKindItem.allowedTypes.has(item.as)))
            ) {
              additionalExpander.push({
                icon,
                title,
                data
              });
            }
          }
        });

        kindLimits = additionalItems.kindCreate;

        kindLimits.forEach((item) => {
          const kind = kindsStore.getKindByName(item.kind);
          const perm = allowedObjects && allowedObjects.get(item.as);
          if (perm && perm.has("create") && kind) {
            let icon = iconRender({ class: item.as }, true);
            if (accountStore.getIcon(kind.name)) {
              icon = accountStore.getIcon(kind.name);
            }
            const title = kind.name;
            const data = {
              kind: kind.id,
              type: item.as
            };
            if (
              !callerKindItem ||
              (callerKindItem.allowedKinds &&
                callerKindItem.allowedKinds.has(item.kind))
            ) {
              additionalExpander.push({
                icon,
                title,
                action: "createWithKind",
                data
              });
            }
          }
        });
      } else {
        limitations.forEach((item) => {
          const perm = allowedObjects && allowedObjects.get(item.as);
          if (perm && perm.has("create")) {
            let icon = iconRender({ class: item.as }, true);
            let title = item.name;
            let action = "createWithType";
            let data = { type: item.as };
            if (item.kind) {
              const kind = kindsStore.getKindByName(item.kind);
              if (accountStore.getIcon(kind && kind.name)) {
                icon = accountStore.getIcon(kind && kind.name);
              }
              title = item.kind;
              action = "createWithKind";
              data = {
                kind: kind && kind.id,
                type: item.as
              };
            }
            if (
              title &&
              (!callerKindItem ||
                callerKindItem.etype === "aggr.kindsattrs.item.empty" ||
                (callerKindItem.allowedTypes &&
                  !item.kind &&
                  callerKindItem.allowedTypes.has(item.as)))
            ) {
              additionalExpander.push({
                icon,
                title,
                action,
                data
              });
            }
          }
        });
      }

      expander = [...additionalExpander];
    }
    return expander;
  }, []);

  /**
   * Добавить дополнительно возможные варианты действий с активной нодой по ее виду
   * @param {String} uid uid ноды
   * @param {KindItem} kind  вид ноды
   * @param {String} menuType тип меню
   * 
   * @return {Array<MenuItem>} массив дополнительных пунктов для контекстного меню
   */
  const additionalActionsByKind = useCallback((kindItem, menuType) => {
    const res = [];

    let isDQTemplate = false;
    let isQTemplate = false;
    // const redmineProjects = [];
    const tasks = [];
    kindItem.kindsArray.forEach((member) => {
      isDQTemplate = isDQTemplate || member.kindName.indexOf("[DQ]") >= 0;
      isQTemplate = isQTemplate || member.kindName.indexOf("[*Q]") >= 0;
      if (member.allowedTasks && member.allowedTasks.size > 0) {
        tasks.push(member);
      }
    });

    if (tasks.length > 0) {
      if (tasks.length === 1) {
        const member = tasks[0];
        res.push({
          icon:     "app-spzi-M",
          isAction: true,
          type:     "execute",
          title:    "Задачи",
          action:   "showTasks", 
          data:     {
            memberUid: member.uid
          }
        });
      }

      if (tasks.length > 1) {
        tasks.forEach((member) => {
          res.push({
            icon:     "app-spzi-M",
            isAction: true,
            type:     "execute",
            title:    `Задачи - ${member.kindName}`,
            action:   "showTasks", 
            data:     {
              memberUid: member.memberUid
            }
          });
        });
      }
    }

    if (isDQTemplate || isQTemplate) {
      const array = [];
      if (isDQTemplate) {
        array.push({
          icon:     "domain-M",
          isAction: true,
          type:     "execute",
          title:    "DQ",
          action:   "showDQTrace", 
          data:     {
            schemaUid: "DQ"
          }
        });
        array.push({
          icon:     "domain-M",
          isAction: true,
          type:     "execute",
          title:    "URS",
          action:   "showDQTrace",
          data:     {  schemaUid: "URS" }
        });
      }
      if (isQTemplate) {
        array.push({
          icon:     "domain-M",
          isAction: true,
          type:     "execute",
          title:    "*Q",
          action:   "showDQTrace", 
          data:     {
            schemaUid: "*Q"
          }
        });
      }
      res.push({
        icon:     "check-M",
        title:    "Чеклисты",
        type:     "checkLists",
        isAction: true,
        children: array
      });
    }

    if (menuType === "VERSION") {
      if (kindItem.traceSchemas.length > 0) {
        res.push({
          icon:     "tracer-gap-analyser-M",
          type:     "read",
          title:    "GAP анализ",
          children: kindItem.traceSchemas.map((schema) => {
            return {
              icon:   "tracer-gap-analyser-M",
              title:  schema.title,
              action: "showGAPAnalysis", 
              data:   {
                schemaId: schema.uid
              }
            };
          })
        });

        res.push({
          icon:     "tracer-mode-report-M",
          type:     "read",
          title:    "Трассировка",
          children: kindItem.traceSchemas.map((schema) => {
            return {
              icon:   "tracer-mode-report-M",
              title:  schema.title,
              action: "showTraceAnalysis", 
              data:   {
                schemaId: schema.uid
              }
            };
          })
        });
      }
    }

    if (res.length > 0 || isDQTemplate) {
      res.unshift({ isDivider: true });
    }

    return res;
  }, []);

  const collectMenu = useCallback((context) => {
    const { permissions } = accountStore;

    
    const allowedObjects = permissions.get("object");
    // const allowedActions = permissions.get("action");

    let menuType = "STATIC";
    switch (context.class) {
      case CLS_LIBRARY_COLLECTION:
        menuType = "COLLECTION";
        break;
      case CLS_LIBRARY_TEXT_MATERIAL:
        menuType = "MATERIAL";
        break;
      case CLS_LIBRARY_TEXT_VERSION:
        menuType = "VERSION";
        break;
      case CLS_LIBRARY_FILE:
        menuType = "FILE";
        break;
      case CLS_LIBRARY_REPOSITORY:
        menuType = "REPO";
        break;
      case "library.material.Report":
        menuType = "REPORT";
        break;
      default: 
        menuType = "STATIC";  
        break;
    }

    const createButtons = makeCreateButtons(context);

    let resultMenu = [];
    
    const callerObj = allowedObjects && allowedObjects.get(context.class);

    menu[menuType].forEach((item) => {
      if (menuType === "REPORT") {
        resultMenu.push(item);
      }
      if (
        item.isDivider &&
          resultMenu.length > 0 &&
          !resultMenu[resultMenu.length - 1].isDivider
      ) {
        resultMenu.push(item);
      } else {
        let addToMenu = false;
        if (item.isAction && allowedObjects) {
          const actPerms = allowedObjects.get(item.type);
          if (actPerms && (actPerms.has("execute") || actPerms.has(item.action))) {
            addToMenu = true;
          }
        } else {
          if (callerObj && callerObj.has(item.type)) {
            addToMenu = true;
          }
        }

        if (addToMenu) {
          if (context && context.permissions && 
                    (context.permissions.get(item.type) || context.permissions.get(item.type) === undefined)) {
            if (item.type === "create" && createButtons.length !== 0) {
              resultMenu.push({ ...item, children: createButtons });
            } else {
              resultMenu.push(item);
            }
          }
        }
      }
    });

    if (resultMenu[resultMenu.length - 1] && resultMenu[resultMenu.length - 1].isDivider) {
      resultMenu.splice(resultMenu.length - 1, 1);
    }

    const kindItem = context.kindsRepresentation;
    if (kindItem) {
      resultMenu = resultMenu.concat(additionalActionsByKind(kindItem, menuType));
    }
      
    if (context.class === CLS_LIBRARY_TEXT_VERSION) {
      const kindItem = context.parent.kindsRepresentation;
      if (kindItem) {
        resultMenu = resultMenu.concat(additionalActionsByKind(kindItem, menuType));
      }
    }

    setContextMenu(resultMenu);
  }, []);

  const renderItem = useCallback((node) => {
    if (!node.item) {
      return null;
    }
    return (
      <TreeNode
        item={node.item} 
        selected={selected} 
        onRename={onRename}
        onDelete={onDelete}
        collectMenu={collectMenu}
        setSelected={setSelected}
      />
    );
  }, [collectMenu, onRename, onDelete, selected, setSelected]);

  const onToggleRelations = useCallback(() => {
    layoutStore.toggleSubPanel(tabId, SIDEPANEL_RELATIONS);
  }, [layoutStore, tabId]);

  const onToggleKinds = useCallback(() => {
    layoutStore.toggleSubPanel(tabId, SIDEPANEL_KINDS_ATTRS);
  }, [layoutStore, tabId]);

  const onToggleLog = useCallback(() => {
    layoutStore.toggleSubPanel(tabId, SIDEPANEL_JOURNAL);
  }, [layoutStore, tabId]);

  const onToggleValidation = useCallback(() => {
    layoutStore.toggleSubPanel(tabId, SIDEPANEL_VALIDATION);
  }, [layoutStore, tabId]);

  const onToggleWorkflow = useCallback(() => {
    layoutStore.toggleSubPanel(tabId, SIDEPANEL_WORKFLOW);
  }, [layoutStore, tabId]);

  const onToggleInfoModal = useCallback(() => {
    setInfoIsVisible(!infoIsVisible);
  }, [infoIsVisible]);

  const onRefreshAll = useCallback(() => {
    onRetry();
  }, [onRetry]);

  const toolBar = useMemo(() => {
    const buttons = [];
    if (root) {
      if (root.isPending) {
        buttons.push(<Components.Preloader size={1} />);
        buttons.push(<Components.Spacer key="spacer-0" />);
      } else if (root.item && root.item.permissionsObject) {
        if (root.item.permissionsObject.create) {
          const createButtons = makeCreateButtons(root);
          createButtons.forEach((button) => {
            buttons.push(
              <Components.Button
                key={button.title} 
                icon={button.icon} 
                tooltip={button.title}
              onPress={(e) => { // eslint-disable-line
                  onMenuItemClick(button.action, root.item, button.data);
                }}
              />
            );
          });
          buttons.push(<Components.Spacer key="spacer-1" />);
        }
        if (root.item.permissionsObject.upload) {
          buttons.push(
            <Components.Button
              key={"upload"} 
              icon={"upload-M"} 
              tooltip={"Загрузить файл"}
              onPress={(e) => { // eslint-disable-line
                onMenuItemClick("upload", root.item, {});
              }}
            />
          );
          buttons.push(<Components.Spacer key="spacer-2" />);
        }
      }
    }

    const rightButtons = [
      (<Components.Spacer key="right-spacer" />),
      (
        <Components.Button
          key={"app-relations-M"} icon="app-relations-M" tooltip={"Связи"}
          onPress={onToggleRelations}
          isSelected={isSubVisible[SIDEPANEL_RELATIONS]}
        />
      ),
      (
        <Components.Button
          key={"app-attributes-M"} icon="app-attributes-M" tooltip={"Виды и атрибуты"}
          onPress={onToggleKinds}
          isSelected={isSubVisible[SIDEPANEL_KINDS_ATTRS]}
        />
      ),
      (
        <Components.Button
          key={"log-M"} icon="log-M" tooltip={"Журнал изменений"}
          onPress={onToggleLog}
          isSelected={isSubVisible[SIDEPANEL_JOURNAL]}
        />
      ),
      (
        <Components.Button
          key={"app-workflow-M"} icon="app-workflow-M" tooltip={"Жизненные циклы"}
          onPress={onToggleWorkflow}
          isSelected={isSubVisible[SIDEPANEL_WORKFLOW]}
        />
      ),
      (
        <Components.Button
          key={"ok-M"} icon="ok-M" tooltip={"Согласование"}
          onPress={onToggleValidation}
          isSelected={isSubVisible[SIDEPANEL_VALIDATION]}
        />
      ),
      (<Components.Spacer key="right-spacer-2" />),
      (
        <Components.Button
          key={"info-M"} icon="info-M" tooltip={"Справка"}
          onPress={onToggleInfoModal}
          isSelected={infoIsVisible}
        />
      )
    ];

    return (
      <Components.ToolBar right={rightButtons}>
        {buttons}
      </Components.ToolBar>
    );
  }, [
    root,
    root && root.isPending,
    onToggleRelations,
    onToggleKinds,
    onToggleLog,
    onToggleValidation,
    onRefreshAll,
    isSubVisible,
    onToggleInfoModal
  ]);

  const catchFile = useCallback(async(files, target = fileTarget, position = 0) => {
    const node = store.getNode(target.uid);
    if (importType) {
      await node.importFile(files, position, importType);
      setImportType(null);
    } else {
      await node.uploadFile(files, position);
    }
  }, [fileTarget, store, importType]);

  const onHandleFileInput = useCallback((e) => {
    if (fileTarget) {
      const fileArray = Object.keys(e.target.files).map((key) => {
        return e.target.files[key];
      });
      catchFile(fileArray);
    }
  }, [fileTarget]);

  const canDragNode = useCallback(() => {
    return true;
  }, []);

  const canDropNode = useCallback((item, target) => {
    // TODO: check if item as being dragged can be created in such collection
    return target 
    && target.permissionsObject 
    && target.permissionsObject.create
    && target.class === CLS_LIBRARY_COLLECTION // only allow drop to collection
    && item 
    && item.permissionsObject 
    && item.permissionsObject.move;
  }, []);

  const canDropFile = useCallback((node) => {
    // TODO: check if item as being dragged can be created in such collection
    return node 
    && node.item 
    && node.item.permissionsObject 
    && node.item.permissionsObject.create
    && node.class === CLS_LIBRARY_COLLECTION; // only allow drop to collection
  }, []);

  const moveNode = useCallback(async(sourcePosition, item, target, position = 0) => {
    const from = item.parent;
    from.setPending(true);
    target.setPending(true);
    item.moveTo(sourcePosition, target, position);
    await store.api.updateNode(item.uid, { parent: target.uid, position });
    from.setPending(false);
    target.setPending(false);
  }, [store]);

  const onChangeRepoForm = useCallback((value, name) => {
    if (name === "name" || name === "password" || name === "username" || name === "repo" || name === "type") {
      setRepoFormValidations((oldValues) => {
        return { ...oldValues, [name]: !!value };
      });
    } else if (name === "url") {
      const regex = /http(s)?:\/\/(www\.)?[A-z, -]+\.[A-z, -]+/gi;
      const isValid = value.match(regex);
      setRepoFormValidations((oldValues) => {
        return { ...oldValues, [name]: isValid };
      });
    }
    setRepoFormValues((oldValues) => {
      return { ...oldValues, [name]: value };
    });
  }, []);

  const repoForm = useMemo(() => {
    if (repoFormId === "new") {
      return [
        (
          <Field.String
            key="name" 
            label="Наименование" 
            name="name"
            value={repoFormValues.name}
            isValid={repoFormValidations.name}
            onChange={onChangeRepoForm}
          />
        ), (
          <Field.String 
            key="url" 
            label="URL" 
            icon="globaltracking-M"
            name="url" 
            placeholder={"https://repo.url"}
            value={repoFormValues.url}
            isValid={repoFormValidations.url}
            onChange={onChangeRepoForm} 
          />
        ), (
          <Field.SingleSelect 
            key="type" 
            name="type" 
            icon="repository-M"
            value={repoFormValues.type}
            isValid={repoFormValidations.type}
            label="Тип репозитория" 
            options={repoSelectOptions}
            onChange={onChangeRepoForm} 
          />
        ), (
          <Field.String
            key="username" 
            label="Имя пользователя" 
            icon="user-M"
            name="username"
            value={repoFormValues.username}
            isValid={repoFormValidations.username}
            onChange={onChangeRepoForm}
          />
        ), (
          <Field.String 
            key="password" 
            label="Пароль" 
            icon="fixed-M"
            name="password" 
            value={repoFormValues.password}
            isValid={repoFormValidations.password}
            onChange={onChangeRepoForm} 
          />
        )
      ];
    } else { // repoFormId ==="list"
      return [
        (
          <Field.String
            key="name" 
            label="Наименование" 
            name="name"
            value={repoFormValues.name}
            isValid={repoFormValidations.name}
            onChange={onChangeRepoForm}
          />
        ), (
          <Field.SingleSelect 
            key="repo" 
            name="repo" 
            value={repoFormValues.repo}
            isValid={repoFormValidations.repo}
            label="Репозиторий" 
            isLoading={store.isPendingRepos}
            options={store.repoArray}
            onChange={onChangeRepoForm} 
          />
        )
      ];
    }
  }, [repoFormId, onChangeRepoForm, repoFormValues, store.repoArray, store.isPendingRepos, repoFormValidations]);

  const onCancelAddRepo = useCallback(() => {
    setRepoFormValues({});
    setRepoFormValidations({});
    setRepoFormId(null);
    setRepoTarget(null);
  }, []);

  const onAddRepo = useCallback(async() => {
    const result = await store.addRepo(repoTarget, repoFormValues);
    if (result) {
      onCancelAddRepo();
    }
  }, [repoFormValues, repoTarget]);


  const isRepoFormValid = useMemo(() => {
    return !!repoFormValidations.name 
    && (
      (
        !!repoFormValidations.url 
        && !!repoFormValidations.type
      ) 
        || !!repoFormValidations.repo
    );
  }, [repoFormValidations]);

  const repoFormButtons = useMemo(() => {
    return [
      ( 
        <Components.Button
          key="add"
          text="Добавить"
          icon="plus-M"
          onPress={onAddRepo}
          isDisabled={!isRepoFormValid}
          isLoading={repoTarget && repoTarget.isPending}
          color="action"
        />
      ), (
        <Components.Button
          key="cancel"
          text="Отмена"
          icon="cancel-M"
          onPress={onCancelAddRepo}
          isLoading={repoTarget && repoTarget.isPending}
          color="negative"
        />
      )
    ];
  }, [onAddRepo, repoTarget, onCancelAddRepo, isRepoFormValid]);

  return (
    <div className="library">
      {isPending && <Components.Preloader size={3} />}
      {!gotError && !noRepresentation && !isPending && !!root && (
        <Fragment>
          {toolBar}
          <Components.Tree
            root={root}
            renderItem={renderItem}
            onExpandNode={onExpandNode}
            canDragNode={canDragNode}
            canDropFunc={canDropNode}
            move={moveNode}
            canDropFileFunc={canDropFile}
            catchFile={catchFile}
          />
          <ContextMenu.Menu id={DOMAIN_LIBRARY} items={contextMenu} onMenuClick={onMenuItemClick} />
          <input
            type="file"
            style={{
              overflow: "hidden",
              width:    "0px",
              height:   "0px",
              opacity:  0
            }}
            ref={fileInput}
            onChange={onHandleFileInput}
          />
          <Modal.Window
            name="repo"
            icon="app-tree-M"
            show={!!repoFormId && !!repoTarget}
            title={"Добавление репозитория"}
            buttons={repoFormButtons}
            onKeyPressEnter={onAddRepo}
            onKeyPressEsc={onCancelAddRepo}
          >
            {repoForm}
          </Modal.Window>
        </Fragment>
      )}
      {infoIsVisible && (
        <InfoToolWindow 
          content={infoToolContent.library}
          infoIsVisible={infoIsVisible}
          toggleInfoModal={onToggleInfoModal}
        />
      )}
    </div>
  );
});

export default Library;
