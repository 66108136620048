import React from "react";
// import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
// import { DOMAIN_TEXT } from "~/core/constants/Domains";
// import useStores from "~/core/utils/useStores";
import AisObject from "~/core/components/AisObject";

import {
  ENTITY_VALUE_NUM,
  ENTITY_VALUE_TITLE,
  ENTITY_VALUE_VERSION
} from "../../constants/values";
import SearchStore from "../../stores/searchStore";
import EntityModel from "../../models/EntityModel";

/**
 * @component
 * 
 * Компонент для отображения значения в ячейке таблицы
 * 
 * @param {SearchItemModel} entity запись найденнего артефакта
 * @param {String} columnId id колонки - ключ для отображения значения
 * @param {SearchStore} store хранилище для поиска артефатов 
 * @param {Number} index номер записи в табличичном представлении
 * @param {String} keyValues ключ, за которым хранится набор значений
 */
const TableValue = observer(({ entity, columnId, store, index, keyValues, kindUid }) => {
  if (keyValues) {
    const member = entity.getMember(kindUid);
    if (member) {
      return (
        <span className="entity-table-cell-text">
          {Array.isArray(member[keyValues][columnId]) ? 
            member[keyValues][columnId].join(", ") : 
            member[keyValues][columnId]}
        </span>
      );  
    };
  }

  switch (columnId) {
    case ENTITY_VALUE_NUM:
      return (
        <div className="entity-table-cell-num">
          {`${(store.currentPage - 1) * store.pageSize + index + 1}`}
        </div>
      );
    case ENTITY_VALUE_TITLE:
      return (
        <span className="entity-table-cell-text">
          <AisObject object={entity.object} />
        </span>
      );
    case ENTITY_VALUE_VERSION:
      return <span className="entity-table-cell-text">{entity[columnId]}</span>;
    default:
      return <span className="entity-table-cell-text">{entity[columnId]}</span>;
  }
});

TableValue.propTypes = {
  columnId:  PropTypes.string.isRequired, 
  index:     PropTypes.number.isRequired,
  store:     PropTypes.instanceOf(SearchStore),
  entity:    PropTypes.instanceOf(EntityModel),
  keyValues: PropTypes.string
};


export default TableValue;
