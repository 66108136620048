import React, { useCallback, useEffect, useState } from "react";
import { observer } from "mobx-react";
import useStores from "~/core/utils/useStores";
import StateCardList from "./StateCardList";
import { Components } from "@ais3p/ui-framework";
import "reactflow/dist/style.css";
import "./css/workflowList.scss";

const WorkflowList = observer(({ object, trackedItem }) => {
  const { workflowStore } = useStores();
  const [workflowsValues, setWorkflowsValues] = useState();
  const [currentMachines, setCurrentMachines] = useState();

  useEffect(() => {
    const currentWorkflows = Array.from(workflowStore.workflows.values());
    if (currentWorkflows.length > 0) {
      setWorkflowsValues(currentWorkflows);
      const machines = currentWorkflows.map((flow) => {
        return (
          flow.machine
        );
      });
      if (machines.length > 0) {
        setCurrentMachines(machines);
      }
    }
  }, [workflowStore]);

  const showModal = useCallback((workflow) => {
    workflowStore.setDiagramWorkflow(workflow);
    const machine = currentMachines?.find((obj) => {
      return obj?.id === workflow?.machine?.id;
    });
    workflowStore.setDiagramMachine(machine);
  }, [currentMachines]);

  return (
    <ul className="workflow-content__list">
      {workflowsValues ?
        workflowsValues.map((workflowItem) => {
          return (
            <div key={workflowItem.id}>
              <li className="workflow-content__item">
                <p className="workflow-title">{workflowItem?.machine?.name}</p>
                <div className="diagram-btn-wrapper">
                  <Components.Button 
                    text="Открыть диаграмму"
                    color="default"
                    // eslint-disable-next-line react/jsx-no-bind
                    onPress={() => {
                      return showModal(workflowItem);
                    }}
                  />
                </div>
                <StateCardList object={object} workflow={workflowItem} />
              </li>
              
            </div>
          );
        })
        :
        <div className="workflow-empty">Регламент не установлен</div>
      }
    </ul>
  );
});

export default WorkflowList;
