import Server from "./server";

class ApprovalsApi extends Server {
  methods = {
    getApprovals: {
      url: (params) => {
        return this.getServiceUrl(
          "approvals",
          this.template`api/v0/containers/${0}/versions/${1}/records`,
          params
        );
      },
      method:      "GET",
      textProcess: "Получение валидаций...",
      textSuccess: null,
      textError:   this
        .template`Во время получения валидаций произошла ошибка: ${0}`
    },
    setApproval: {
      url: (params) => {
        return this.getServiceUrl(
          "approvals",
          this.template`api/v0/containers/${0}/versions/${1}/records`,
          params
        );
      },
      method:      "POST",
      textProcess: "Получение валидаций...",
      textSuccess: null,
      textError:   this
        .template`Во время получения валидаций произошла ошибка: ${0}`
    }
  };

  async getApprovals(uid, version = 0) {
    try {
      const reqData = {
        params: [uid, version]
      };

      return await this.request("getApprovals", reqData);
    } catch (error) {
      this.rootStore.uiStore.setErrorText(error.message);
    }
  }

  /**
   * 
   * @param {string} uid = uid контейнера (editable || wmUid)
   * @param {number} version 
   * @param {string} objectId = UID того что аппрувим
   * @param {number} objectVersion 
   * @param {string} state = rejected/accepted
   * @param {string} comment 
   * @returns promise
   */
  async setApproval(uid, version = 0, objectId, objectVersion, state, comment) {
    try {
      const reqData = {
        params: [uid, version],
        data:   {
          objectId, 
          objectVersion, 
          state, 
          comment
        }
      };
      return await this.request("setApproval", reqData);
    } catch (error) {
      this.rootStore.uiStore.setErrorText(error.message);
    }
  }
}

export default ApprovalsApi;
