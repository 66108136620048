import React, { useCallback } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const ListItem = ({ text, isActive, onClickItem }) => {
  const onClick = useCallback(() => {
    onClickItem && onClickItem(text);
  }, [onClickItem, text]);

  return (
    <div 
      className={classNames("list-item", {
        active: isActive
      })}
      onClick={onClick}
    >
      {text}
    </div>
  );
};

ListItem.propTypes = {
  text:        PropTypes.string, 
  isActive:    PropTypes.bool, 
  onClickItem: PropTypes.func
};
export default ListItem;
