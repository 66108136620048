import base64 from "base-64";

import Server from "~/core/api/server";

class AccountApi extends Server {
  methods = {
    login: {
      url:         this.getServiceUrl("auth", "api/v1/auth/login"),
      method:      "POST",
      textProcess: "Авторизация...",
      textSuccess: null,
      timeout:     4000,
      textError:   this.template`Во время авторизации произошла ошибка: ${0}`
    },
    logout: {
      url:         this.getServiceUrl("auth", "api/v1/auth/logout"),
      method:      "POST",
      textProcess: "Деавторизация ...",
      textSuccess: null,
      textError:   this.template`Во время деавторизации произошла ошибка: ${0}`
    },
    permissions: {
      url: (params) => {
        return this.getServiceUrl(
          "admin",
          this.template`api/v2/permissions/scope/modules/users/${0}/rules/raw`,
          params
        );
      },
      method:      "GET",
      textProcess: "Получение разрешений...",
      textSuccess: null,
      textError:   this
        .template`Во время получения разрешений произошла ошибка: ${0}`
    },
    loadUser: {
      url: (params) => {
        return this.getServiceUrl("users", this.template`api/v2/users/${0}?withGroups=${1}`, params);
      },
      method:      "GET",
      textProcess: "Загрузка данных пользователя...",
      textSuccess: null,
      timeout:     30000,
      textError:   this.template`Во время загрузки данных пользователя произошла ошибка: ${0}`
    },
    layoutPut: {
      url: (params) => {
        return this.getServiceUrl(
          "users",
          this.template`api/v2/users/${0}/layout`,
          params
        );
      },
      method:      "PUT",
      textProcess: "Сохранение layout ...",
      textSuccess: null,
      timeout:     20000,
      textError:   this
        .template`Во время сохранения layout произошла ошибка: ${0}`
    },
    layoutGet: {
      url: (params) => {
        return this.getServiceUrl(
          "users",
          this.template`api/v2/users/${0}/layout`,
          params
        );
      },
      method:      "GET",
      textProcess: "get layout ...",
      textSuccess: null,
      textError:   this.template`Во время get layout произошла ошибка: ${0}`
    },
    getUserWorkspaces: {
      url: (params) => {
        return this.getServiceUrl(
          "users",
          this.template`api/v2/users/${0}/workspaces`,
          params
        );
      },
      method:      "GET",
      textProcess: "get workspaces ...",
      textSuccess: null,
      textError:   this.template`Во время get workspaces произошла ошибка: ${0}`
    },
    getAllWorkspaces: {
      url: (params) => {
        return this.getServiceUrl(
          "users",
          this.template`api/v2/workspaces`,
          params
        );
      },
      method:      "GET",
      textProcess: "get all workspaces ...",
      textSuccess: null,
      textError:   this
        .template`Во время get all workspaces произошла ошибка: ${0}`
    },
    getWorkspace: {
      url: (params) => {
        return this.getServiceUrl(
          "users",
          this.template`api/v2/workspaces/${0}`,
          params
        );
      },
      method:      "GET",
      textProcess: "get a workspace ...",
      textSuccess: null,
      textError:   this.template`Во время get a workspace произошла ошибка: ${0}`
    },
    createWorkspace: {
      url:         this.getServiceUrl("users", "api/v2/workspaces"),
      method:      "POST",
      textProcess: "Сохранение workspace ...",
      textSuccess: null,
      textError:   this
        .template`Во время сохранения workspace произошла ошибка: ${0}`
    },
    deleteWorkspace: {
      url: (params) => {
        return this.getServiceUrl(
          "users",
          this.template`api/v2/workspaces/${0}`,
          params
        );
      },
      method:      "DELETE",
      textProcess: "Сохранение workspace ...",
      textSuccess: null,
      textError:   this
        .template`Во время сохранения workspace произошла ошибка: ${0}`
    },
    replaceWorkspace: {
      url: (params) => {
        return this.getServiceUrl(
          "users",
          this.template`api/v2/workspaces/${0}`,
          params
        );
      },
      method:      "PUT",
      textProcess: "Сохранение workspace ...",
      textSuccess: null,
      textError:   this
        .template`Во время сохранения workspace произошла ошибка: ${0}`
    },
    loadPictograms: {
      url:         this.getServiceUrl("admin", "api/v1/pictograms"),
      method:      "GET",
      textProcess: "Загрузка списка пиктограмм ...",
      textSuccess: null,
      textError:   this.template`Во время загруки списка пикторграмм произошла ошибка: ${0}`
    }
  };

  /**
   * Авторизовать пользователя
   *
   * @param  {String} login    логин пользователя
   * @param  {String} password пароль пользователя
   * @return {Promise <Object>} данные авторизации
   */
  async login(login, password) {
    const reqData = {
      code: base64.encode(`${login}:${password}`)
    };
    return await this.request("login", reqData);
  }

  /**
   * Деавторизовать пользователя
   * @return {Promise}
   */
  async logout() {
    return await this.request("logout");
  }

  /**
   * Загрузить данные пользователя
   * @param  {String} uid пользователя
   * @param  {Boolean} withGroups добавить ли данные по группам, в которые входит пользователь
   * @return {Object}
   */
  async loadUser(uid, withGroups = false) {
    const reqData = {
      params: [uid, withGroups]
    };
    return await this.request("loadUser", reqData);
  }

  /**
   * Загрузить сохраненную конфигурации рабочего пространства пользователя
   *
   * @param  {String} userUid uid пользователя
   * @return {Promise<Object>}
   */
  async loadPresset(userUid) {
    const reqData = {
      params: [userUid]
    };
    return await this.request("layoutGet", reqData);
  }

  /**
   * Загрузить права доступа пользователя
   *
   * @return {Promise<Object>}
   */
  async loadPermissions(uid) {
    const reqData = {
      params: [uid]
    };
    return await this.request("permissions", reqData);
  }

  /**
   * Сохранить конфигурацию рабочего пространства пользователя
   *
   * @param  {String} userUid uid пользователя
   * @param {Object} layout конфигурация рабочего пространства пользователя
   * @return {Promise}
   */
  async setPresset(userUid, layout) {
    const reqData = {
      params: [userUid],
      data:   { layout }
    };
    return await this.request("layoutPut", reqData);
  }

  /**
   * Загрузить все предустановленные конфигурации рабочего пространства пользователя
   *
   * @param  {String} userUid uid пользователя
   * @return {Promise<Object>}
   */
  async loadUserWorkspaces(userUid) {
    const reqData = {
      params: [userUid]
    };
    return await this.request("getUserWorkspaces", reqData);
  }

  /**
   * Получить именованное рабочее пространства по uid
   *
   * @param  {String} uid uid пространства
   * @return {Promise<Object>}
   */
  async loadWorkspace(uid) {
    const reqData = {
      params: [uid]
    };
    return await this.request("getWorkspace", reqData);
  }

  /**
   * Изменить атрибуты именованного рабочего пространства
   *
   * @param  {Object} data атрибуты именованного рабочего пространства
   * @return {Promise<Object>}
   */
  async changeWorkspace(data) {
    const reqData = {
      params: [data.uid],
      data
    };
    return await this.request("replaceWorkspace", reqData);
  }

  /**
   * Создать новую именованную конфигурации рабочего пространства
   *
   * @param  {Object} data атрибуты именованного рабочего пространства
   * @return {Promise<Object>}
   */
  async createWorkspace(data) {
    return await this.request("createWorkspace", data);
  }

  /**
   * Удалить именованную конфигурации рабочего пространства
   *
   * @param  {Object} data атрибуты именованного рабочего пространства
   * @return {Promise<Object>}
   */
  async deleteWorkspace({ uid }) {
    const reqData = {
      params: [uid]
    };
    return await this.request("deleteWorkspace", reqData);
  }

  /**
   * Получить список пиктограмм у объектов
   *
   *
   * @return {Promise}
   */
  async loadPictograms() {
    return await this.request("loadPictograms");
  }
}

export default AccountApi;
