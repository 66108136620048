import { observable, computed, action } from "mobx";

import CodeObject from "./CodeObject";

/**
 * Модель для хранения информации об открытом файле
 * 
 * @class {CodeFile}
 */
class CodeFile {
  /**
   * @type {String}
   * id файла
   */
  @observable 
  id = false;

  /**
   * @type {Boolean}
   * Флаг загрузки содержимого файла
   */
  @observable 
  isPending = false;

  /**
   * @type {String}
   * Название файла
   */
  @observable 
  name = undefined;

  /**
   * @type {String}
   * id репозитория
   */
  @observable 
  repositoryId = undefined;

  /**
   * @type {String}
   * id коммита
   */
  @observable 
  commitId = undefined;

  /**
   * @type {String}
   * Путь до файла
   */
  @observable 
  path = undefined;

  /**
   * @type {String}
   * Иконка файла
   */
  @observable 
  icon = undefined;

  /**
   * @type {Number}
   * Номер строки на которую нужно поставить фокус после открытия файла.
   * Необходимо при переходе по ссылке из другого инструмента
   */
  @observable
  focusCodeLine = undefined;

  /**
   * @type {CodeObject}
   * Объект, который в фокусе
   */
  @observable
  focusCodeObject = undefined;

  /**
   * @type {String}
   * Содердимое файла
   */
  @observable 
  content = undefined;

  /**
   * @type {Map<CodeObject>}
   * Набор объектов в файле
   */
  @observable
  codeObjects = new Map();
  
  /**
   * Статичный конструктор модели.
   * При создании модели, лучше использовать статичный метод, тк если поменяются названия входных параметров,
   * то эти названия можно будет здесь только поменять, не меняя названия в самой модели
   * 
   * @param {Object} params
   * @param {String} params.id id файла
   * @param {String} params.name название файла
   * @param {String} params.commitId id коммита файла
   * @param {String} params.path путь до файла
   * @param {String} params.icon инока файла
   *  
   * @returns CodeFile
   */
  static create({
    id,
    name,
    repositoryId,
    commitId,
    path,
    icon
  }) {
    return new CodeFile({
      id,
      name,
      repositoryId,
      commitId,
      path,
      icon
    });
  }

  /**
   * Конструктор модели.
   * 
   * @param {Object} params
   * @param {String} params.id id файла
   * @param {String} params.name название файла
   * @param {String} params.commitId id коммита файла
   * @param {String} params.path путь до файла
   * @param {String} params.icon инока файла
   *  
   * @returns CodeFile
   */
  constructor({
    id,
    name,
    repositoryId,
    commitId,
    path,
    icon
  }) {
    this.id = id;
    this.name = name;
    this.repositoryId = repositoryId;
    this.commitId = commitId;
    this.path = path;
    this.icon = icon;
  }

  /**
   * Текстовое представление модели
   * @return {String}
   */
  @computed
  get title() {
    return (this.name || "").trim();
  }

  /**
   * Язык кода в файле
   * 
   * @return {String}
   */
  @computed
  get language() {
    let res = "c";

    if (!this.name) {
      return res;
    }
    
    const arrFileName = this.name.split(".") || [];
    const ext = arrFileName[arrFileName.length - 1];
    if (ext === "sh") {
      res = "bash";
    }

    if (ext !== "h" && ext !== "c" && ext !== "cpp" && ext !== "sh") {
      res = "markup";
    }
    
    return res;
  }

  /**
   * Cписок uid-ов сущностей в файле, которые нужно запросить для проверки на связи
   * 
   * @return {Array<String>}
   */
  @computed
  get relationUids() {
    // Получаем список связей. Пока связи мы создаем только на определения ф-ий. 
    // Поэтому делаем фильтрацию
    const uidArray = Array.from(this.codeObjects.values())
      .filter((obj) => {
        return obj.type === "function" && !!obj.uid;
      })
      .map((obj) => {
        return obj.uid;
      });
    return uidArray;
  }

  /**
   * Задать флаг обработки(загрузки) файла 
   * 
   * @param {Boolean} value 
   */
  @action
  setIsPending(value) {
    this.isPending = value;
  }

  /**
   * Задать содержимое файла 
   * 
   * @param {String} content
   */
  @action
  setContent(content) {
    this.content = content;
  }

  /**
   * Задать номер строки, на котрую нужно будет выставить фокус после загрузки файла.
   * Это необходимо при переходе по ссылке из другого инструмента
   * 
   * @param {Number} focusCodeLine
   */
  @action
  setFocusCodeLine(focusCodeLine) {
    this.focusCodeLine = focusCodeLine;
    const obj = this.codeObjects.get(focusCodeLine);
    if (obj && obj.type === "function") {
      this.setFocusCodeObject(obj);
    }
  }

  /**
   * Задать объект, который попал в фокус через нажатие иконки самого объекта
   * 
   * @param {CodeObject} codeObject
   */
  @action
  setFocusCodeObject(codeObject) {
    this.focusCodeObject = codeObject;
  }

  /**
   * Задать объект, который попал в фокус через нажатие иконки самого объекта
   * 
   * @param {Array<Object>} outlines набор полученных объектов от сервиса, после разбора структуры файла
   * @param {ObjectStore} objectStore глобальное хранилище с обхектами системы АИС
   */
  @action
  processOutlines(outlines = [], objectStore) {
    this.codeObjects.clear();
    if (outlines.length === 0) {
      return;
    }
    
    outlines.forEach((data) => {
      const obj = CodeObject.create(data, objectStore);
      this.codeObjects.set(obj.startLine, obj);
    });
  }

  /**
   * Получить обект кода по номеру строки
   * 
   * @param {Number} lineNum номер строки
   * 
   * @return {CodeObject}
   */
  getCodeObject(lineNum) {
    return this.codeObjects.get(lineNum);
  }

  /**
   * Деструктор модели
   */
  destroy() {
    this.codeObjects.clear();
  }
}

export default  CodeFile;
