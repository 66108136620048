import { observer } from "mobx-react";
import React, { useCallback, useLayoutEffect, useMemo, useRef } from "react";
import Indicator from "../../../core/components/Indicator";
import TextItemIcon from "./TextItemIcon";

const ListItem = observer(({ data, renderItem, setContextMenu }) => {
  const { id, flatItemsArray, isFocusUid, scrollItemId, uid, diffClass } = data;

  const element = useRef();

  useLayoutEffect(() => {
    if (
      uid &&
      scrollItemId &&
      uid === scrollItemId &&
      element &&
      element.current
    ) {
      setTimeout(() => {
        element.current && element.current.scrollIntoView(true);
      }, 200); // set timeout= DIRTY HACK 
      // TODO: get rid of setTimeout()
    }
  }, [uid, scrollItemId, element && element.current]);

  const itemsRender = useMemo(() => {
    return flatItemsArray.map((itemData) => {
      return renderItem(itemData);
    });
  }, [flatItemsArray]);

  const onClickIcon = useCallback(
    () => {
      data.setEditing();
      data.setFocusUid();
    },
    [data]
  );

  return (
    <div ref={element} id={id} className={`list-item ${diffClass}  ${isFocusUid ? "focused" : ""}`}>
      <Indicator data={data} />
      <TextItemIcon onClick={onClickIcon} setContextMenu={setContextMenu} data={data} />
      <div className={`list-item-body ${isFocusUid ? "focused" : ""}`} onClick={onClickIcon}>
        {itemsRender}
      </div>
    </div>
  );
});

export default ListItem;
