import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import classNames from "classnames";
import { Components } from "@ais3p/ui-framework";
import TableColHeader from "./TableColHeader";
import TableCell from "./TableCell";
import Pagination from "~/core/components/pagination";

import SearchStore from "../../stores/searchStore";

import { FILTERS_CFG } from "../../constants/config";

import {
  ENTITIES_TABLE_COLUMNS,
  getEntityValueLabel
} from "../../constants/values";


/**
 * @component
 * 
 * Панель для отображения списка найденных сущностей в табличном представлении
 * 
 * @param {SearchStore} store хранилище для поиска артефатов 
 * @param {String} className пользовательский className
 * @param {LayoutItem} layoutItem сущность layout. Требуется для перехода к объектам АИС
 */
const EntitiesTable = observer((props) => {
  const { className, store, layoutItem } = props;

  const reload = useCallback(() => {
    const { codes, attributes, version, kind } = store.getItemConfig(FILTERS_CFG);
    store.searchEntities(codes, attributes, version, kind);
  }, [store]);

  const onPageSizeChange = useCallback((size) => {
    store.setPageSize(size);
    store.setCurrentPage(1);
    reload && reload();
  }, [store.pageSize]);

  const onPageChange = useCallback((p) => {
    store.setCurrentPage(p);
    reload && reload();
  }, [store.currentPage]);

  const columns = useMemo(() => {
    const  kindUid = store.currentKind && store.currentKind.uid;
    const res = Object.keys(ENTITIES_TABLE_COLUMNS).map((key) => {
      return {
        columnId: key,
        title:    getEntityValueLabel(key),
        kindUid
      };
    });
      
    if (store.currentKind) {
      (store.currentKind.codesArray || []).forEach((code) => {
        const { uid, name } = code;
        res.push({
          columnId:  uid,
          title:     name,
          keyValues: "codeValues",
          kindUid
        });
      });

      (store.currentKind.attributes || []).forEach((attr) => {
        const { uid, name } = attr;
        res.push({
          columnId:  uid,
          title:     name,
          keyValues: "values",
          kindUid
        });
      });
    }
    return res;
  }, [store.currentKind]);
  
  return (
    <div className={classNames("entities-table-wrapper", className)}>
      {store.isProcessing &&
        <div className="entities-table-preloader">
          <Components.Preloader size={3} />
        </div>
      }
      <div className={classNames("entities-table-content")}>
        <table>
          <thead>
            <tr>
              {columns.map(({ columnId, title }) => {
                return (
                  <TableColHeader 
                    key={columnId}
                    columnId={columnId}
                    title={title}
                  />
                );
              })}
            </tr>
          </thead>
          <tbody>
            {store.entityList.map((entity, index) => {
              return (
                <tr key={entity.uid}>
                  {columns.map(({ columnId, keyValues, kindUid }) => {
                    return (
                      <TableCell 
                        key={`${entity.uid}-${columnId}`}
                        index={index}
                        columnId={columnId}
                        entity={entity}
                        keyValues={keyValues}
                        kindUid={kindUid}
                        store={store}
                        layoutItem={layoutItem}
                      />
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <Pagination
        className="entities-pagination"
        startPageIndex={1}
        canPrevious={store.canPreviousPage}
        canNext={store.canNextPage}
        pageSize={store.pageSize}
        page={store.currentPage}
        pages={store.pages}
        reload={reload}
        onPageSizeChange={onPageSizeChange}
        onPageChange={onPageChange}
        loading={store.isProcessing}
      />
    </div>
  );
});

EntitiesTable.propTypes = {
  className:  PropTypes.string, 
  store:      PropTypes.instanceOf(SearchStore), 
  layoutItem: PropTypes.object
};
export default EntitiesTable;
