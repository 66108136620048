import React, { useCallback, useMemo, useState } from "react";
import { observer } from "mobx-react";
import classNames from "classnames";
import { Components } from "@ais3p/ui-framework";

import User from "./User";
import HeaderButton from "./HeaderButton";
import useStores from "../utils/useStores";

/**
 * Верхняя панель клиента АИС ППП
 */
const AppHeader = observer((props) => {
  const {
    buttons,
    modeButtons,
    setStore,
    getPresset,
    menuCollect,
    setIsDragging
  } = props;

  const { wsStore, uiStore, layoutStore } = useStores();

  const { mode, setMode } = useState();
  const onSetMode = useCallback(
    (mode) => {
      const newMode = props.setMode(mode);
      setMode(newMode);
    },
    [props.setMode]
  );

  const onShowAbout = useCallback(() => {
    props.onShowAbout(true);
  }, [props.onShowAbout]);

  const onOpen = useCallback((itemData) => {
    layoutStore.open(itemData);
  }, [layoutStore]);
  
  const headerButtons = useMemo(() => {
    return buttons.map((button) => {
      return (
        <HeaderButton
          setStore={setStore}
          getPresset={getPresset}
          menuCollect={menuCollect}
          key={button.id}
          {...button}
          onClick={onOpen}
          setIsDragging={setIsDragging}
          canDrag={!button.modal}
        />
      );
    });
  }, [buttons, setStore, getPresset, menuCollect, setIsDragging, open]);

  const modeHeaderButtons = useMemo(() => {
    return modeButtons.map((button) => {
      return (
        <HeaderButton
          setStore={setStore}
          getPresset={getPresset}
          menuCollect={menuCollect}
          key={button.id}
          {...button}
          onClick={button.onClick || (button.mode ? onSetMode : open)}
          currentMode={mode}
          setIsDragging={setIsDragging}
          canDrag={!button.modal}
        />
      );
    });
  }, [
    modeButtons,
    setStore,
    getPresset,
    menuCollect,
    setIsDragging,
    open,
    mode
  ]);

  return (
    <div className="app-header">
      <div className="left-side">
        {/* TODO: (ai) Унести в компонент */}
        <div className="ais-logo-wrapper">
          <Components.Icon
            onClick={onShowAbout}
            className="ais-logo-icon"
            name="plane-M"
          />
          {!wsStore.isConnected && (
            <div
              className={classNames("ais-logo-ws-status", "left", {
                connected: wsStore.isConnected
              })}
            />
          )}
          {wsStore.isConnected && (
            <div
              className={classNames("ais-logo-ws-status", "right", {
                connected: wsStore.isConnected
              })}
            />
          )}
          {uiStore.isPendingTasks && (
            <div
              className={"ais-logo-request-pending"}
              {...{
                "data-tooltip":    uiStore.pendingTasksList,
                "data-tooltip-at": "bottom"
              }}
            />
          )}
        </div>
        {headerButtons}
      </div>
      <div className="right-side">
        {modeHeaderButtons}
        <User />
      </div>
    </div>
  );
});

export default AppHeader;
