import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { Dnd } from "@ais3p/ui-framework";
import { observer } from "mobx-react";
import useStores from "~/core/utils/useStores";
import { getDomainByClass } from "~/core/utils";
import SchemaBoundaryModel from "../../models/schemaBoundary";
import { DND_NODE_TYPE } from "~/core/constants/DnD";

/**
 * Компонент для отображения места drop зоны
 * 
 * @param {Object} props набор параметров
 * @param {SchemaBoundaryModel} props.boundary  запись ограничения Доли в схеме отчетов о трассировке
 */
const DropPlace = observer(({ boundary }) => {
  const { parameter, object } = boundary;
  const { objectStore } =  useStores();
  
  const onCanDrop = useCallback((props, monitor) => {
    const droppedItem = monitor.getItem();
    const { value } = droppedItem;
    if (!value) {
      return false;
    }

    // уже объект задан
    if (!!object) {
      return false;
    }


    const { restrictions } = parameter; 
    if (!restrictions) {
      return false;
    }

    if (restrictions.class && value.class) {
      if (Array.isArray(restrictions.class)) {
        return restrictions.class.includes(value.class);
      } else {
        return restrictions.class === value.class;
      }
    }
    
    return false;
  }, [parameter, boundary]);

  const onDrop = useCallback((props, monitor) => {
    const droppedItem = monitor.getItem();
    const { value } = droppedItem;

    if (!boundary || !value) {
      return;
    }
    
    // const version = value.version || value.number; // у Library всегда версия 0
    const version = value.version;
    const uid = value.uid || value.id;
    const domain = getDomainByClass(value.class);
    const obj = objectStore.getVersion(uid, domain, version);
    boundary.setValue(obj);
  }, [boundary]);

  return (
    <div className="schema-trace-item-drop">
      <Dnd.Target
        canDrop={onCanDrop}
        drop={onDrop}
        accept={[DND_NODE_TYPE]}
      >
        <span>{parameter.description}</span>
      </Dnd.Target>
    </div>
  );
});

DropPlace.propTypes = {
  boundary: PropTypes.instanceOf(SchemaBoundaryModel).isRequired
};


export default DropPlace;
