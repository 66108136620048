import Server from "~/core/api/server";


class CheckListApi extends Server {
  methods = {
    loadData: {
      // Загрузить данные трассировки согласно схеме
      url:         this.getServiceUrl("composer", ""),
      method:      "POST",
      accept:      "application/json",
      textProcess: "Загрузка данных трассировки ...",
      textSuccess: null,
      textError:   this.template`Во время загрузки данных трассировки произошла ошибка: ${0}`,
      timeout:     300000

    },
    loadSchemas: {
      // Загрузить все схемы трассировки
      url:         this.getServiceUrl("tracer2", "api/v2/schemes"),
      method:      "POST",
      textProcess: "Загрузка всех схемы трассировки...",
      textSuccess: null,
      textError:   this.template`Во время загрузки всех схемы трассировки произошла ошибка: ${0}`,
      timeout:     300000
    },
    loadSchema: {
      // Загрузить схему по ее uid
      url: (params) => {
        return this.getServiceUrl("tracer2", this.template`api/v2/schemes/${0}`, params);
      },
      method:      "GET",
      textProcess: "Загрузка схемы трассировки...",
      textSuccess: null,
      textError:   this.template`Во время загрузки схемы трассировки произошла ошибка: ${0}`,
      timeout:     300000
    }
  };


  /**
   * Загрузить данные трассировки согласно схемы
   * 
   * @param {String} schemaUid схема uid трассировки
   */
  async loadData(schemaUid = "bed0bfcc006f44d08cd1039a93ff9d56") {
    const reqData = {
      query: `query TraceQuery {
        TraceBySchemeUid(scheme: "${schemaUid}") {
          __typename
          ... on AisObject {
            uid
            class
            representation
            version
            members {
              uid
            }
          }
          __typename
          ... on RelationsObject {
            uid
          }
        }
      }`
    };

    return await this.request("loadData", reqData);
  }

  async doTraceWithSchema(schema) {
    const reqData = {
      // operationName: "TraceQuery",
      variables: {
        scheme: schema
      },
      query: `query TraceQuery($scheme: JSON!) {
        Trace(scheme: $scheme) {
          __typename
          ... on AisObject {
            uid
            class
            representation
            version
            members {
              uid
            }
          }
          __typename
          ... on RelationsObject {
            uid
          }
        }
      }`
    };
    return await this.request("loadData", reqData);
  }

  
  /**
   * Загрузить все схемы трассировки
   *
   */
  async loadSchemas() {
    const reqData = {
      params: []
    };

    return await this.request("loadSchemas", reqData);
  }

  /**
   * Загрузить схему трассировки по ее uid
   * @param  {String} uid схемы
   *
   */
  async loadSchema(uid) {
    const reqData = {
      params: [uid]
    };

    return await this.request("loadSchema", reqData);
  }
}

export default CheckListApi;
