import React from "react";
import PropTypes from "prop-types";
import TableValue from "./TableValue";
import { IssueModel } from "../../models";
import IssueStore from "../../stores/IssueStore";

/**
 * Компонент для отображения ячейки в табличном представлении списка задач
 * 
 * @param {IssueModel} issue модель задачи
 * @param {String} columnId идентификатор колонки - ключ значения задачи
 * @param {IssueStore} store хранилище для работы с задачами
 * @param {LayoutItem} layoutItem сущность в Layout
 */
const TableCell = ({ issue, columnId, store, layoutItem }) => {
  return (
    <td> 
      <div className={`issues-table-cell ${columnId}-cell`}>
        <TableValue 
          columnId={columnId}
          issue={issue}
          store={store}
          layoutItem={layoutItem}
        />
      </div>
    </td>
  );
};

TableCell.propTypes = {
  issue:      PropTypes.instanceOf(IssueModel).isRequired,
  columnId:   PropTypes.string.isRequired,
  store:      PropTypes.instanceOf(IssueStore).isRequired,
  layoutItem: PropTypes.object.isRequired
};

export default TableCell;
