import { observable, computed } from "mobx";
import { 
  CLS_ATTR_BOOLEAN, 
  CLS_ATTR_DATE, 
  CLS_ATTR_DATE_TIME, 
  CLS_ATTR_ENUM, 
  CLS_ATTR_FLOAT, 
  CLS_ATTR_INTEGER, 
  CLS_ATTR_STRING 
} from "../../../core/constants/Classes";

/**
 * Модель атрибута 
 */
class Attr {
  /**
   * Уникальный идентифиактор атрибута
   * @type String
   */
  @observable 
  uid = null;

  /**
   * Наименование аттрибута в системе.
   * @type String
   */
  @observable 
  name = null;

  /**
   * Строковое название типа в система.
   * Одно из значений: `kindsattrs.StringAttribute`, `kindsattrs.IntegerAttribute`,
   * `kindsattrs.FloatAttribute`, `kindsattrs.BooleanAttribute`, `kindsattrs.DateAttribute`,
   * `kindsattrs.DatetimeAttribute`, `kindsattrs.EnumAttribute`
   * @type String
   */
  @observable 
  class = null;

  /**
   * Флаг указывает на обязательность установки значения атрибута при назначении вида или изменении представителя после.
   * @type Boolean
   */
  @observable 
  isRequired = false;
  
  /**
   * Атрибуты только на чтение не позволяют изменять значения установленное для представителя вида после создания.
   * @type Boolean
   */
  isReadonly = false;

  /**
   * Флаг включает возможность указания нескольких значений для Enum атрибутов.
   * Применим только если `class` имеет значение `kindsattrs.EnumAttribute`. Для остальных отсутствует.
   * @type Boolean
   */
  isMultiple = false;
  
  /**
   * Список опций для EnumAttribute. Только строки.
   * Порядок постоянный и может быть изменен через PUT в атрибут по `uid`.
   * @type Array<String>
   */
  @observable 
  options = [];

  /**
   * Конструктор 
   * 
   * @param {Object} data данные атрибута
   * @param {KindStore} kindStore храилище для работы с видами и атрибутами
   */
  constructor(data, kindStore) {
    this.kindStore = kindStore;
    this.uid = data.uid;
    this.name = data.name;
    this.class = data.class;
    if (data.options && data.options.length) {
      this.options = data.options;
    }
    this.isRequired = data.isRequired;
    this.isMultiple = data.isMultiple;
    this.isReadonly = data.isReadonly;
  }

  /**
   * id атрибута
   * 
   * @returns {String} id атрибута
   */
  @computed
  get id() {
    return this.uid;
  }

  /**
   * Тип вида, для отображения необходимого контрола
   * 
   * @returns {String} тип вида
   */
  @computed
  get type() {
    switch (this.class) {
      case CLS_ATTR_STRING:
        return "string";
      case CLS_ATTR_INTEGER:
        return "integer";
      case CLS_ATTR_FLOAT:
        return "float";
      case CLS_ATTR_ENUM:
        return "enum";
      case CLS_ATTR_BOOLEAN:
        return "boolean";
      case CLS_ATTR_DATE_TIME:
        return "datetime";
      case CLS_ATTR_DATE:
        return "date";
      default:
        return this.class;
    }
  }

  /**
   * Набор options для отображения списка в SelectBox
   * 
   * @returns {Array<Option>} тип вида
   */
  @computed
  get opts() {
    return this.options.map((option) => {
      return {
        label: option,
        value: option
      };
    });
  }
}

export default Attr;
