import { observable, action, computed } from "mobx";

import KindMember from "./KindMember";
import { DOMAIN_KINDS } from "~/core/constants/Domains";
import AisVersion from "../../../core/data/models/AisVersion";

const arrayDiff = (a, b) => {
  return a.filter((i) => {
    return b.indexOf(i) < 0;
  });
};

/**
 * @extends AisVersion
 * 
 * Модель представителя Вида 
 * Здесь хранится информация о привязанных Видах к объекту АИС и участниках вида
 */
class KindItem extends AisVersion {
  /**
   * Признак обработки модели на стороне сервиса
   * 
   * @type {Boolean}
   */
  @observable
  pendingItem = false;

  /**
   * Набор участников вида
   * Участник вида будет лежать за ключем kindUid (один к одному)
   * 
   * @type {Map<KindMember}
   */
  @observable
  members = new Map();

  /**
   * Список участников вида, которые необходимо удалить
   * 
   * @type {Array<KindMember}
   */
  @observable
  membersToRemove = [];

  /**
   * Список участников вида, которые необходимо добавить
   * 
   * @type {Array<KindMember}
   */
  @observable
  membersToAdd = [];

  /**
   * Конструктор
   * 
   * @param {Object} data данные для создания модели 
   * @param {KindStore} store хранилище для работы с Видами и Атрибутами
   */
  constructor(data, store) {
    super({
      uid:     data.objectId,
      version: data.version,
      domain:  DOMAIN_KINDS
    }, store.objectStore);

    this.store = store;
    this.init(data);
  }

  /**
   * Инициализация модели
   * 
   * @param {Object} data набор данных участника Вида
   */
  @action
  init(data) {
    this.members = new Map();
    this.addMemberData(data);
  }

  /**
   * Получить участника Вида по uid самого Вида
   * 
   * @param {String} kindUid uid Вида
   * @returns {KindMemebr}
   */
  @action
  getMember(kindUid) {
    return this.members.get(kindUid);
  }

  /**
   * Задать значение признака обработки модели на стороне сервиса
   * 
   * @param {Boolean} pending значение признака обработки модели на стороне сервиса
   */
  @action
  setPending(pending = false) {
    this.pendingItem = pending;
  }

  /**
   * Задать id у объекта
   * 
   * @param {String} uid uid у объекта
   */
  @action
  setId(uid) {
    this.uid = uid;
    // обновляем objectId у вложенных участников Вида
    this.members.forEach((member) => {
      member && member.setObjectId(uid);
    });
  }

  /**
   * Заменить uid'ы
   * 
   * @param {Array<String>} uidArray массив uid'ов
   */
  @action
  replaceKinds(uidArray) {
    const removeDiff = arrayDiff(this.kindUids, uidArray);
    const addDiff = arrayDiff(uidArray, this.kindUids);
    removeDiff.forEach((uid) => {
      const member = this.members.get(uid);
      this.membersToRemove.push(member.uid);
      this.members.delete(uid);
    });
    addDiff.forEach((uid) => {
      this.addKind(uid);
    });
  }

  /**
   * Добавить Вид
   
  * @param {String} kindUid uid вида
   * @returns 
   */
  @action
  addKind(kindUid) {
    if (!kindUid) {
      return null;
    }
    this.membersToAdd.push(kindUid);
    // Добавляем пустой новый участник Вида
    const member = new KindMember(
      {
        kindUid,
        objectId: this.uid
      },
      this.store,
      this
    );
    this.members.set(
      kindUid,
      member
    );
    this.store.addKindMember(member);
    return this.getMember(kindUid);
  }

  /**
   * Добавить данные об участнике Вида
   * 
   * @param {Object} data данные участника Вида. В данных обязательно должен быть указан kindUid
   *  
   * @returns {KindMember} участник Вида
   */
  @action
  addMemberData(data) {
    if (!data || !data.kindUid) {
      return null;
    }

    const member = new KindMember(data, this.store, this);
    this.members.set(data.kindUid, member);
    this.store.addKindMember(member);

    return member;
  }

  /**
   * Удалить участника Вида из списка участников
   * 
   * @param {String} kindUid uid Вида
   */
  @action
  deleteMember(kindUid) {
    this.members.delete(kindUid);
  }

  /**
   * Добавить участника Вида в список на удаление для запросв к сервису
   * 
   * @param {String} memberUid uid участника Вида
   */
  @action
  removeMember(memberUid) {
    this.membersToRemove.push(memberUid);
  }

  /**
   * Отменить сделанные изменения 
   */
  @action
  revert() {
    this.membersToRemove.clear();
    this.membersToAdd.clear();
    this.members.forEach((kindMember, key) => {
      if (kindMember.hasUid) {
        kindMember.revert();
      } else {
        this.members.delete(key);
      }
    });
  }

  /**
   * Сохранить на сервисе сделанные изменения
   * 
   * @returns {Promise}
   */
  @action
  async save() {
    this.setPending(true);
    try {
      const deletePromises = [];
      this.membersToRemove.forEach((memberUid) => {
        const member = this.store.getKindMemberSync(memberUid);

        this.members.delete(member.kindUid);
        this.store.deleteKindMember(memberUid);
        deletePromises.push(this.store.api.deleteKindMember(memberUid));
      });
      await Promise.all(deletePromises);
      this.membersToRemove.clear();
      const savePromises = [];
      this.members.forEach((kindMember) => {
        savePromises.push(kindMember.save());
      });
      const data = await Promise.all(savePromises);
      await this.store.processMembers(data);
    } finally {
      this.setPending(false);
    }
  }

  /**
   * Задать значение атрибута участника Вида
   * 
   * @param {String} kindUid  uid Вида
   * @param {String} id id атрибута
   * @param {Any} value значение атрибута
   * @param {Boolean} isValid признак валидности значения атрибута
   */
  @action
  setAttrValue(kindUid, id, value, isValid) {
    const member = this.members.get(kindUid);
    if (member) {
      member.setAttrValue(id, value, isValid);
    }
  }

  /**
   * Получить значение атрибута участника Вида
   * 
   * @param {String} kindUid  uid Вида
   * @param {String} id id атрибута
   * 
   * @returns {Any} значение атрибута
   */
  @action
  getAttrValue(kindUid, id) {
    const member = this.members.get(kindUid);
    if (member) {
      return member.getAttrValue(id);
    }
    return null;
  }

  /**
   * Получить название представлителя Вида
   * 
   * @returns {String} название представлителя Вида
   */
  @computed
  get name() {
    const { objectStore } = this.store;

    const obj = objectStore.getObject(this.uid);
    
    return obj ? obj.title : this.uid;
  }

  /**
   * Получить список Видов (Option) для выпадающего списка, где нужно добавить новый Вид.
   * В данном списке будет исключены уже добавленные Виды
   * 
   * @returns {Array<Option>} список Видов (Option)
   */
  @computed
  get kindsToAddForSelect() {
    if (this.kindUids.length === 0) {
      return this.store.kindsForSelect;
    }
    const kindsArray = [];
    this.store.kindsForSelect.forEach((kind) => {
      if (!this.kindNamesSet.has(kind.label)) {
        kindsArray.push({ ...kind, icon: this.objectStore.rootStore.accountStore.getIcon(kind.label) });
      }
    });
    return kindsArray;
  }

  /**
   * Получить список Видов (Option) для выпадающего списка
   * 
   * @returns {Array<Option>} список Видов (Option)
   */
  @computed
  get kindsForSelect() {
    if (this.kindUids.length === 0) {
      return undefined;
    }
    const kindsArray = [];
    this.kindUids.forEach((kindUid) => {
      const kind = this.store.getKind(kindUid);
      if (kind) {
        kindsArray.push({
          value: kindUid,
          label: kind.name,
          icon:  this.objectStore.rootStore.accountStore.getIcon(kind.name)
        });
      }
    });
    return kindsArray;
  }

  /**
   * Получить список участников Вида
   * 
   * @returns {Array<KindMember>}
   */
  @computed
  get kindsArray() {
    const array = [];
    this.members.forEach((value) => {
      array.push(value);
    });
    return array;
  }

  /**
   * Получить список имен, привязанных Видов 
   * 
   * @returns {Array<String>}
   */
  @computed
  get kindNames() {
    const array = [];
    this.kindsForSelect &&
      this.kindsForSelect.forEach((value) => {
        array.push(value.label);
      });
    return array;
  }

  /**
   * Получить набор имен, привязанных Видов 
   * 
   * @returns {Set<String>}
   */
  @computed
  get kindNamesSet() {
    return new Set(this.kindNames);
  }

  /**
   * Массив uid'ов участников вида
   * 
   * @return {Array<String>}
   */
  @computed
  get memberUids() {
    const uids = Array.from(this.members.values()).map((member) => {
      return member.uid;
    });
    
    return uids;
  }

  /**
   * Получить массив участников Вида
   * 
   * @returns {Array<KindMember>}
   */
  @computed
  get initialKinds() {
    if (this.kindInitialUids.length === 0) {
      return [];
    }
    const membersArray = [];
    this.kindInitialUids.forEach((kindUid) => {
      const member = this.members.get(kindUid);
      membersArray.push(member);
    });
    return membersArray;
  }

  /**
   * Размер списка uid Видов
   * 
   * @returns {Number}
   */
  @computed
  get kindsSize() {
    return this.members.size;
  }

  /**
   * Список uid Видов
   * 
   * @returns {Array<String>}
   */
  @computed
  get kindUids() {
    return Array.from(this.members.keys());
  }

  /**
   * Получить массив uid'ов Видов, которые уже имеют реального участника 
   * 
   * @returns {Array<String>}
   */
  @computed
  get kindInitialUids() {
    const uids = [];

    if (this.isPending) {
      return uids;
    }
    this.members.forEach((kind, key) => {
      if (kind.hasUid) {
        uids.push(key);
      }
    });
    return uids;
  }

  /**
   * Получить Options Видов для выпадющего списка SelectBox
   * 
   * @returns {Array<Option>}
   */
  @computed
  get kindsInitSelect() {
    if (this.kindInitialUids.length === 0) {
      return undefined;
    }
    const kindsArray = [];
    this.kindInitialUids.forEach((kindUid) => {
      const kind = this.store.getKind(kindUid);
      kindsArray.push({
        value: kindUid,
        label: kind.name
      });
    });
    return kindsArray;
  }

  /**
   * Признак валидности записи представителя Вида - все участики Вида должны быть валидны
   * 
   * @returns {Boolean} Признак валидности
   */
  @computed
  get isValid() {
    let valid = true;
    this.members.forEach((member) => {
      if (member.isValid === false) {
        valid = false;
      }
    });
    return valid;
  }

  /**
   * Признак возможности сохранить изменения
   * Эта возможность будет если были сделаны у атрибутов участников видов изменеия и эти изменения валидны
   * 
   * @returns {Boolean} признак возможности сохранить изменения
   */
  @computed
  get canSave() {
    let canSave = false;
    this.members.forEach((member) => {
      canSave = canSave || member.needsPersist;
    });
    return (canSave || this.membersToRemove.length > 0) && this.isValid;
  }


  /** 
   * Получить иконку записи представителя Вида
   * Если у представителя несколько привязанных Видо, то иконка будет браться последнего Вида
   * 
   * @returns {String}
   */
  @computed
  get iconString() {
    const iconStrings = [];
    this.members.forEach((kind) => {
      const str = kind.iconString;
      if (str) {
        iconStrings.push(str);
      }
    });
    if (iconStrings.length > 0) {
      return iconStrings[iconStrings.length - 1];
    } else if (this.members.size > 0) {
      return "token-M";
    } else {
      return null;
    }
  }

  /**
   * Признак обработки модели на стороне сервиса
   */
  @computed
  get isPending() {
    return this.pendingItem;
  }

  /**
   * Признак обработки представителей Вида или самой модели на стороне сервиса
   */
  @computed
  get pending() {
    let p = false;
    this.members.forEach((kindMember) => {
      if (kindMember.pending) {
        p = true;
      }
    });

    return p || this.isPending;
  }

  /**
   * Получить набор доступных типов
   * 
   * @returns {Set}
   */
  @computed
  get allowedTypes() {
    let allowedTypes = undefined;
    this.members.forEach((kindMember) => {
      if (kindMember.allowedTypes !== undefined) {
        if (allowedTypes) {
          new Set(
            [allowedTypes].filter((x) => {
              return kindMember.allowedTypes.has(x);
            })
          );
        } else {
          allowedTypes = kindMember.allowedTypes;
        }
      }
    });
    return allowedTypes;
  }

  /**
   * Получить набор доступных Видов
   * 
   * @returns {Set}
   */
  @computed
  get allowedKinds() {
    let allowedKinds = undefined;
    this.members.forEach((kindMember) => {
      if (kindMember.allowedKinds !== undefined) {
        if (allowedKinds) {
          new Set(
            [allowedKinds].filter((x) => {
              return kindMember.allowedKinds.has(x);
            })
          );
        } else {
          allowedKinds = kindMember.allowedKinds;
        }
      }
    });
    return allowedKinds;
  }

  /**
   * Получить набор доступных Видов для Задач
   * 
   * @returns {Set}
   */
  @computed
  get allowedTasks() {
    let allowedTasks = undefined;
    this.members.forEach((kindMember) => {
      if (kindMember.allowedTasks !== undefined) {
        if (allowedTasks) {
          new Set(
            [allowedTasks].filter((x) => {
              return kindMember.allowedTasks.has(x);
            })
          );
        } else {
          allowedTasks = kindMember.allowedTasks;
        }
      }
    });
    return allowedTasks;
  }

  /**
   * Получить список схем трассировки/gap анализа связей, которые привязаны к Виду
   * 
   * @returns {Array<TraceSchema>}
   */
  @computed 
  get traceSchemas() {
    if (this.kindUids.length === 0) {
      return [];
    }
    const res = new Map();
    this.kindUids.forEach((kindUid) => {
      const kind = this.store.getKind(kindUid);
      if (kind) {
        kind.traceSchemasList.forEach((schema) => {
          res.set(schema.id, schema);
        });
      }
    });
    return Array.from(res.values());
  }
}

export default KindItem;
