import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Handle } from "reactflow";
import useStores from "~/core/utils/useStores";
import { Components } from "@ais3p/ui-framework";
import UserAvatar from "../../users/components/avatar/UserAvatar";
import moment from "moment";
import "./css/node.scss";

const Node = ((props) => {
  const { workflowStore, userStore } = useStores();
  const [points, setPoints] = useState([]);
  const [isComplete, setIsComplete] = useState(false);
  const [isFinish, setIsFinish] = useState(false);
  const [finalStateId, setFinalStateId] = useState(false);
  const [transitions, setTransitions] = useState([]);
  const { workflow, state, machine, targetStates } = props.data;

  useEffect(() => {
    const arrayPoints = workflow.points.filter((point) => {
      return point.stateId === state.id;
    });
    setPoints(arrayPoints);

    const isCompleteState = workflow.completedStatesId.includes(state.id);
    setIsComplete(isCompleteState);

    const isFinishWorkflow = workflow.isFinish;
    setIsFinish(isFinishWorkflow);

    const availableTransitions = workflow.machine.getAvailableTransitions(state.id);
    setTransitions(availableTransitions);

    let finalStateId;
    if (isFinishWorkflow && workflow.lastState.isFinal) {
      finalStateId = workflow.lastState.id;
      setFinalStateId(finalStateId);
    }
    setIsFinish(isFinishWorkflow);
  }, [workflow, workflowStore.diagramWorkflow.points, state.id]);
 
  const targetNodesId = Array.from(machine.transitions.values()).map((transition) => {
    return transition.toState.id;
  });
 
  const handleUpdateState = useCallback((workflowId, name) => {
    workflowStore.updateWorkflow(workflowId, name);
  }, [workflowStore]);

  const transitionButtons = useMemo(() => {
    let transitionButtons = [];
    if (transitions) {
      transitionButtons = transitions.map((transition) => {
        return (
          <Components.Button 
            key={transition.id}
            text={transition.name}
            color="action"
            // eslint-disable-next-line react/jsx-no-bind
            onPress={() => {
              return handleUpdateState(workflow.id, transition.slug);
            }}
          />
        );
      });
    };
    return transitionButtons;
  }, [workflow.id, transitions]);

  return (
    <div className="nowheel">
      {(!state.isInitial || targetNodesId.includes(state.id)) &&
      <Handle
        type="target"
        position={props.targetPosition}
      />
      }
      <div className={`workflow-node ${targetStates.includes(state.id) ? "target" : ""}
        ${(!isComplete) ? "incomplete" : ""}
        ${(!isComplete && isFinish) ? "inactive" : ""} ${isFinish ? "finish" : ""} 
        ${finalStateId === state.id ? "finish-state" : ""} 
        `}
      >
        <div className={`status ${isComplete ? "complete" : ""} `}>{state && state.name}</div>
        <div className="status-info-wrapper">
          {points && points.map((point) => {
            return (
              <div key={point.id}>
                <div className="status-info">
                  <div className="status-time">{point.arrivedAt && moment(point.arrivedAt)
                    .format("DD MMMM YYYY, HH:mm")}
                  </div>
                  <div className="users">
                    {point.driverUid &&
                    <div className={`user-name ${userStore.get(point.driverUid).image ? "with-image" : ""}`}>
                      {userStore.get(point.driverUid).firstname} {userStore.get(point.driverUid).lastname}
                    </div>
                    }
                    <div className="user-image">
                      <UserAvatar 
                        className="user-image-avatar"
                        fileId={point.driverUid && userStore.get(point.driverUid).image}
                      />
                    </div>
                  </div>
                </div>
              </div>
            );
          }
          )
          }
        </div>
        {(workflow.lastState.id === state.id) && (
          <div className="buttons-holder">
            {transitionButtons}
          </div>
        )}
      </div>
      {!state.isFinal &&
      <Handle
        type="source"
        position={props.sourcePosition}
      />
      }
    </div>
  );
});

export default Node;
