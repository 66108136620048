import { observer } from "mobx-react";
import React, { useCallback, useLayoutEffect, useMemo, useRef } from "react";

const CodeLine = observer(({ data, renderItem }) => {
  const { items, uid, idsArray, lang, scrollItemId, isFocusUid, diffClass } = data;

  const language = useMemo(() => {
    return lang || "javascript";
  }, [lang]);

  const hasChunks = useMemo(() => {
    return !!items.length;
  }, [idsArray, items]);

  const onCreateChunk = useCallback(
    (e) => {
      e && e.stopPropagation();
      if (!hasChunks) {
        data.createFirstChunk();
      }
    },
    [hasChunks]
  );

  const itemsRender = useMemo(() => {
    return items.map((itemData) => {
      return renderItem(itemData);
    });
  }, [idsArray, items]);

  const element = useRef();

  const onSetEditing = useCallback((e) => {
    e.stopPropagation();
    data.setEditing();
  }, [data]);

  useLayoutEffect(() => {
    if (
      uid &&
      scrollItemId &&
      uid === scrollItemId &&
      element &&
      element.current
    ) {
      setTimeout(() => {
        element.current && element.current.scrollIntoView(true);
      }, 200); // set timeout= DIRTY HACK 
      // TODO: get rid of setTimeout()
    }
  }, [uid, scrollItemId, element && element.current]);

  return (
    <div
      id={uid} ref={element} onClick={onSetEditing}
      className={`code-line  ${diffClass}`}
    >
      <div className="number-holder"></div>
      <div className={`item-holder  ${isFocusUid ? "focus" : ""}`}>
        <pre>
          <code
            language={language}
            className={`language-${language} code-line-body`}
          >
            {itemsRender}
            {!hasChunks && (
              <div onClick={onCreateChunk} className="placeholder">
                Нет содержимого
              </div>
            )}
          </code>
        </pre>
      </div>
    </div>
  );
});

export default CodeLine;
