import { observer } from "mobx-react";
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { Components, ContextMenu } from "@ais3p/ui-framework";

import Target from "~/core/components/Target";
import useStores from "~/core/utils/useStores";
import AisIcon from "~/core/components/AisIcon";

import DataStore from "../stores/DataStore";

import PlusMenu from "./PlusMenu";
import RubricTree from "./RubricTree";
import SplitPane from "react-split-pane";

import "../css/text.scss";
import { DOMAIN_TEXT } from "../../../core/constants/Domains";

import {
  SIDEPANEL_RELATIONS,
  SIDEPANEL_KINDS_ATTRS,
  SIDEPANEL_JOURNAL
} from "~/core/constants/SidePanels";
import FlatList from "./FlatList";
import { SIDEPANEL_VALIDATION } from "../../../core/constants/SidePanels";
import InfoToolWindow from "~/core/components/InfoToolWindow/InfoToolWindow.jsx";
import infoToolContent from "~/core/components/InfoToolWindow/infoToolContent.js";
import VersionButton from "./VersionButton";

/**
 * Функция для усечения строки до определенной длины. Если переданная строка окажется длинее,
 * чем `maxlength`, то строка обрежется и добавится `...`
 * 
 * @param {String} str строка для усечения
 * @param {Number} maxLength максимальная длина строки
 * @returns {String} усеченная строка
 */
const truncate = (str, maxLength) => {
  return (str.length > maxLength) ?
    `${str.slice(0, maxLength - 1)}…` : str;
};

const Material = observer((props) => {
  const {
    uid,
    editable,
    focusUid: selectedUid,
    version,
    trackedItem,
    isGlobal,
    layoutItem,
    isTracking,
    tabId,
    layoutStore,
    isSubVisible,
    useGlobalTracking
  } = props;
  const { rootStore, uiStore } = useStores();

  const [contextMenu, setContextMenu] = useState([]);

  const dataStore = useMemo(() => {
    const dataStore = new DataStore(rootStore, layoutStore);
    return dataStore;
  }, []);

  const [isDiffVisible, setIsDiffVisible] = useState(false);
  const [diffVersion, setDiffVersion] = useState(null);
  const [infoIsVisible, setInfoIsVisible] = useState(false);

  const onToggleInfoModal = useCallback(() => {
    setInfoIsVisible(!infoIsVisible);
  }, [infoIsVisible]);
  
  const isDiffVersionSelected = useMemo(() => {
    return diffVersion !== null;
  }, [diffVersion]);

  const onSelectDiffVersion = useCallback((versionNumber) => {
    setIsDiffVisible(false);
    setDiffVersion(versionNumber);
  }, []);

  const availableVersionRender = useMemo(() => {
    const versions = [];
    if (isDiffVisible && dataStore.availableDiffVersions) {
      if (!dataStore.availableDiffVersions.length) {
        return (
          <div className="no-versions">
            Объект не имеет версий для сравнения
          </div>
        );
      }
      dataStore.availableDiffVersions.forEach((child) => {
        versions.push(
          <VersionButton
            isSelected={child.number === diffVersion}
            name={child.name}
            version={child.number}
            key={`v-${child.number}`}
            onClick={onSelectDiffVersion}
          />
        );
      });
    }
    return versions;
  }, [
    isDiffVisible,
    isDiffVersionSelected,
    diffVersion,
    dataStore.availableDiffVersions
  ]);

  const isDiffLoading = useMemo(() => {
    return dataStore.isPending || dataStore.isDiffPending;
  }, [dataStore.isPending, dataStore.isDiffPending]);

  const toggleDiffPanel = useCallback(() => {
    if (isDiffVisible) {
      setDiffVersion(null);
    }
    setIsDiffVisible(!isDiffVisible);
  }, [isDiffVisible]);

  useLayoutEffect(() => {
    dataStore.initDiff(diffVersion);
  }, [diffVersion]);

  useEffect(() => {
    useGlobalTracking && !isGlobal && layoutItem.toggleGlobalTracking();
  }, [useGlobalTracking, isGlobal]);

  useLayoutEffect(() => {
    if (editable) {
      dataStore.init(editable, uid, version);
    }
  }, [editable, version]);

  useLayoutEffect(() => {
    if (selectedUid && dataStore.isReady && !dataStore.isPending) {
      dataStore.setFocusUid(selectedUid);
      dataStore.scrollToItemById(selectedUid);
    }
  }, [selectedUid, dataStore.isReady, dataStore.isPending]);

  const [isTreeVisible, setIsTreeVisible] = useState(true);

  const element = useRef(null);

  const {
    flatItemsArray,
    root,
    focusUid,
    focusItem,
    isVersionPending,
    isVersion,
    isPending,
    canSaveVersion
  } = dataStore;

  useEffect(() => {
    if (focusUid && focusItem) {
      layoutItem.changeContext(uid, focusUid, {
        uid:         focusUid,
        editable:    uid,
        version,
        trackedItem: {
          uid:   focusUid,
          version,
          name:  focusItem.title,
          class: focusItem.className,
          tool:  DOMAIN_TEXT
        }
      });
    } else {
      layoutItem.changeContext();
    }
  }, [focusUid, focusItem, uid, version, layoutItem]);

  const isExpanded = root && root.isExpanded;

  const setScrollToIndex = useCallback(
    (index) => {
      const id = flatItemsArray[index] && flatItemsArray[index].id;
      if (id) {
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }
    },
    [flatItemsArray, uid, version]
  );

  const itemsLength = useMemo(() => {
    return (flatItemsArray && flatItemsArray.length) || null;
  }, [flatItemsArray]);

  const hasItems = useMemo(() => {
    return itemsLength && itemsLength > 1;
  }, [itemsLength]);

  const onToggleTree = useCallback(() => {
    setIsTreeVisible(!isTreeVisible);
  }, [isTreeVisible]);

  const toggleGlobalTracking = useCallback(() => {
    layoutItem.toggleGlobalTracking();
  }, [layoutItem]);

  const toggleSubPanel = useCallback(
    (tag) => {
      layoutStore.toggleSubPanel(tabId, tag);
    },
    [layoutStore, tabId]
  );

  const isSaveVersion = useMemo(() => {
    return version === undefined || version === null || version === 0;
  }, [version]);

  const onSaveVersion = useCallback(() => {
    dataStore.saveVersion(uid);
  }, [dataStore, uid]);

  const onToggleRelations = useCallback(() => {
    toggleSubPanel(SIDEPANEL_RELATIONS);
  }, []);
  const onToggleKinds = useCallback(() => {
    toggleSubPanel(SIDEPANEL_KINDS_ATTRS);
  }, []);
  const onToggleLog = useCallback(() => {
    toggleSubPanel(SIDEPANEL_JOURNAL);
  }, []);
  const onToggleValidation = useCallback(() => {
    toggleSubPanel(SIDEPANEL_VALIDATION);
  }, []);

  const ToolBar = useMemo(() => {
    const buttons = [
      <Components.Button
        key={"tree"} 
        icon="app-tree-M" 
        isSelected={isTreeVisible}
        tooltip={"Отобразить дерево рубрик"}
        onPress={onToggleTree}
      />
    ];
    if (isSaveVersion) {
      buttons.push(<Components.Spacer key="save-spacer" />);
      buttons.push(
        <Components.Button
          key={"save"} 
          icon="save-M" 
          loading={isVersionPending}
          disabled={!canSaveVersion}
          tooltip={"Зафиксировать версию"}
          onPress={onSaveVersion}
        />
      );
    }

    const rightButtons = [
      (
        <Components.Button
          key={"diff"} 
          icon="fork-M" 
          tooltip={"Сравнить с версией"}
          pressed={isDiffVisible}
          loading={isDiffLoading}
          onPress={toggleDiffPanel}
        />
      ),
      (<Components.Spacer key="right-spacer" />),
      (
        <Components.Button
          key={"app-relations-M"} 
          icon="app-relations-M" 
          tooltip={"Связи"}
          onPress={onToggleRelations}
          isSelected={isSubVisible[SIDEPANEL_RELATIONS]}
        />
      ),
      (
        <Components.Button
          key={"app-attributes-M"} 
          icon="app-attributes-M" 
          tooltip={"Виды и атрибуты"}
          onPress={onToggleKinds}
          isSelected={isSubVisible[SIDEPANEL_KINDS_ATTRS]}
        />
      ),
      (
        <Components.Button
          key={"log-M"} 
          icon="log-M" 
          tooltip={"Журнал изменений"}
          onPress={onToggleLog}
          isSelected={isSubVisible[SIDEPANEL_JOURNAL]}
        />
      ),
      (
        <Components.Button
          key={"ok-M"} 
          icon="ok-M" 
          tooltip={"Согласование"}
          onPress={onToggleValidation}
          isSelected={isSubVisible[SIDEPANEL_VALIDATION]}
        />
      ),
      (<Components.Spacer key="right-spacer-2" />),
      (
        <Components.Button
          key={"info-M"} 
          icon="info-M" 
          tooltip={"Справка"}
          onPress={onToggleInfoModal}
          isSelected={infoIsVisible}
        />
      )
    ];

    return (
      <Components.ToolBar right={rightButtons}>
        {buttons}
      </Components.ToolBar>
    );
  }, [
    isTreeVisible,
    onToggleTree,
    isGlobal,
    toggleGlobalTracking,
    onToggleRelations,
    onToggleKinds,
    onToggleLog,
    onToggleValidation,
    isSubVisible,
    isSaveVersion,
    isVersionPending,
    isDiffVisible,
    isDiffLoading,
    onSaveVersion,
    onToggleInfoModal
  ]);

  const toggleExpanded = useCallback(
    (e) => {
      e.stopPropagation();
      root.setExpanded(!isExpanded);
    },
    [isExpanded]
  );

  const hideDiffSelector = useCallback(() => {
    setIsDiffVisible(false);
  }, []);

  const itemsRendered = useMemo(() => {
    if (!dataStore || dataStore.isPending) {
      return null;
    }
    return (
      <FlatList 
        setContextMenu={setContextMenu}
        dataStore={dataStore}
        flatItemsArray={flatItemsArray} 
      />
    );
  }, [flatItemsArray, dataStore.isPending]);

  const itemsList = useMemo(() => {
    return (
      <div ref={element} className="items-list">
        {!hasItems && root && (
          <AisIcon
            onClick={toggleExpanded}
            icon={"plus-M"}
            className={`expand-menu ${isExpanded ? "expanded" : ""}`}
          />
        )}
        {hasItems && itemsRendered}
        {!hasItems && root && (
          <PlusMenu layoutStore={layoutStore} data={root} />
        )}
      </div>
    );
  }, [hasItems, root, toggleExpanded, isExpanded, itemsRendered]);

  /**
   * Скрыть окно подтверждения
   */
  const onCancelConfirm = useCallback(() => {
    uiStore.hideConfirm();
  }, []);

  /**
   * Отобразить окно для подтверждения действия удаления объекта
   * 
   * @param {String} action тип действия
   * @param {TextObject} item текстовый объект, над которым происходит действие
   */
  const showDeleteConfirm = (action, item) => {
    let icon = "delete-M";
    let title = "Удаление текстового объекта";
    let content = `Вы действительно хотите удалить тектовый объект "${item.title}"?`;
    let actionFn = () => {};

    switch (action) {
      case "delete":{
        icon = "delete-M";
        title = "Удаление текстового объекта";
        content = (
          <div className="confirm-delete-content">
            Вы действительно хотите удалить 
            <div
              className="text-content-confirm" 
            >
              <AisIcon
                className={"expander big text-content-confirm-icon"} 
                item={item}
              />{truncate(item.title, 32)}
            </div>?
          </div>
        );
        actionFn =  item.selfDestruct.bind(item);
        break;
      }

      case "deleteRow":{
        icon = "table-row-minus-M";
        title = "Удаление строки из таблицы";
        content = "Вы действительно хотите удалить строку из таблицы?";
        actionFn =  item.deleteRow.bind(item);
        break;
      }

      case "deleteColumn":{
        icon = "table-col-minus-M";
        title = "Удаление колонки из таблицы";
        content = "Вы действительно хотите удалить колонку из таблицы?";
        actionFn =  item.deleteColumn.bind(item);
        break;
      }

      default: {
        return;
      }
    };

    const doAction = () => {
      uiStore.setConfirmPending(true);
      try {
        actionFn();
        // console.log("actionFn 2", actionFn);
        // await actionFn();
        uiStore.hideConfirm();
      } finally {
        uiStore.setConfirmPending(false);
      }
    };

    uiStore.setConfirm({
      icon,
      title,
      content, 
      buttons: [
        <Components.Button
          key="delete"
          text="Удалить"
          icon={icon}
          // eslint-disable-next-line react/jsx-no-bind
          onPress={doAction}
          color="negative"
        />,
        <Components.Button
          key="cancel"
          text="Отмена"
          icon="cancel-M"
          onPress={onCancelConfirm}
          color="dark"
        />
      ],
      onKeyPressEsc:   onCancelConfirm,
      onKeyPressEnter: doAction
    });
  };
  
  const onMenuClick = useCallback((action, item) => {
    switch (action) {
      case "delete":
        // item.selfDestruct();
        showDeleteConfirm(action, item);
        break;
      case "editPicture":
        item.setEditingPicture(true);
        break;
      case "editCaption":
        item.setEditing(true);
        break;
      case "insertColumnLeft":
        item.insertColumnLeft();
        break;
      case "insertColumnRight":
        item.insertColumnRight();
        break;
      case "insertRowTop":
        item.insertRowTop();
        break;
      case "insertRowBottom":
        item.insertRowBottom();
        break;
      case "deleteRow":
        showDeleteConfirm(action, item);
        // item.deleteRow();
        break;
      case "deleteColumn":
        showDeleteConfirm(action, item);
        // item.deleteColumn();
        break;
      case "mergeCells":
        item.mergeCells();
        break;
      case "splitCellVert":
        item.splitCell("vertically");
        break;
      case "splitCellHor":
        item.splitCell("horizontally");
        break;
      default:
        break;
    }
  }, []);

  if (!editable) {
    return (
      <div className={`material ${isVersion ? "version" : ""}`}>
        {ToolBar}
        <div className={"editor-container"}>
          {(isGlobal || isTracking) && <Target trackedItem={trackedItem} />}
          <div className="items-list-holder">
            <div className="no-content">
              <AisIcon icon="cancel-M" /> Не текст
            </div>
          </div>
        </div>
        {infoIsVisible && (
          <InfoToolWindow 
            content={infoToolContent.text}
            infoIsVisible={infoIsVisible}
            toggleInfoModal={onToggleInfoModal}
          />
        )}  
      </div>
    );
  }

  if (isPending) {
    return (
      <div className={`material ${isVersion ? "version" : ""}`}>
        {ToolBar}
        <div className={"editor-container"}>
          {(isGlobal || isTracking) && <Target trackedItem={trackedItem} />}
          <div className="items-list-holder">
            <div className="no-content">
              <Components.Preloader size={3} />
            </div>
          </div>
        </div>
        {infoIsVisible && (
          <InfoToolWindow 
            content={infoToolContent.text}
            infoIsVisible={infoIsVisible}
            toggleInfoModal={onToggleInfoModal}
          />
        )}  
      </div>
    );
  }

  return (
    <div
      className={`material ${isVersion ? "version" : ""} ${
        isDiffVersionSelected ? "with-diff" : ""
      }`}
    >
      {ToolBar}
      <div onClick={hideDiffSelector} className={"editor-container"}>
        {isDiffVisible && (
          <div className="version-List">{availableVersionRender}</div>
        )}
        {isDiffVersionSelected && (
          <div className="object-holder">
            <div className="target-object">
              <div className="object-name">Сравнение</div>
              <AisIcon
                data-tooltip={
                  dataStore.libraryRepresentation &&
                  dataStore.libraryRepresentation.title
                }
                item={dataStore.libraryRepresentation}
              />
              <div className="object-name">
                {dataStore.libraryRepresentation &&
                  dataStore.libraryRepresentation.title}
              </div>
              <AisIcon
                data-tooltip={
                  dataStore.version === 0
                    ? "Редакция"
                    : `Версия ${dataStore.version}`
                }
                className="added-icon"
                icon={"version-M"}
              />
              <div className="object-name">
                {dataStore.version === 0
                  ? "Редакция"
                  : `Версия ${dataStore.version}`}
              </div>
              <AisIcon backgroundColor="#4d4d4d" icon="arrow-right-M" />
              <AisIcon
                data-tooltip={`Версия ${
                  dataStore.diffStore && dataStore.diffStore.version
                }`}
                className="removed-icon"
                icon={"version-M"}
              />
              <div className="object-name">
                {`Версия ${dataStore.diffStore && dataStore.diffStore.version}`}
              </div>
            </div>
          </div>
        )}
        {!isDiffVersionSelected && dataStore.libraryRepresentation && (
          <div className="object-holder">
            <div
              className="target-object"
            > 
              <AisIcon 
                data-tooltip={dataStore.libraryRepresentation && dataStore.libraryRepresentation.title} 
                item={dataStore.libraryRepresentation}
              />
              <div className="object-name">
                {dataStore.libraryRepresentation && dataStore.libraryRepresentation.title}
              </div>
              &ndash;
              <AisIcon 
                data-tooltip={dataStore.version === 0 ? "Редакция" : `Версия ${dataStore.version}`} 
                icon={"version-M"}
              />
              <div className="object-name">
                {dataStore.version === 0 ? "Редакция" : `${dataStore.version}`}
              </div>
            </div>
          </div>
        )}
        {(isGlobal || isTracking) && (
          <Target trackedItem={trackedItem} />
        )}
        <div className="items-list-holder">
          {isTreeVisible && (
            <SplitPane
              split="vertical"
              minSize={isTreeVisible ? 300 : 0}
              style={{ flex: 1 }}
            >
              <div className="rubric-tree-holder">
                {isTreeVisible && (
                  <RubricTree
                    uid={uid}
                    version={version}
                    setContextMenu={setContextMenu}
                    setScrollToIndex={setScrollToIndex}
                    store={dataStore}
                  />
                )}
              </div>
              {itemsList}
            </SplitPane>
          )}
          {!isTreeVisible && itemsList}
        </div>
      </div>
      {infoIsVisible && (
        <InfoToolWindow 
          content={infoToolContent.text}
          infoIsVisible={infoIsVisible}
          toggleInfoModal={onToggleInfoModal}
        />
      )}
      <ContextMenu.Menu
        id={dataStore.editable} 
        items={contextMenu} 
        onMenuClick={onMenuClick}
      />
    </div>
  );
});

export default Material;
