import { observable, action, computed } from "mobx";

/**
 * Модель для хранения инофрмации о workflow
 * 
 * @type {Workflow}
 */
class Workflow {
  /**
   * @type {Number}
   * id workflow
   */
  @observable id;

  /**
   * @type {Object}
   * машина, по которой запущен workflow
   */
  @observable machine;

  /**
   * @type {Number}
   * версия машины
   */
  @observable machineVersion;

  /**
   * @type {String}
   * id элемента, которому принадлежит workflow
   */
  @observable objectId;

  /**
   * @type {Number}
   * версия элемента, которому принадлежит workflow
   */
  @observable objectVersion;

  /**
   * @type {Array<Object>}
   * текущие пути для версии элемента
   */
  @observable points;
  
  constructor(data, machines) {
    this.id = data.id;
    this.machine = machines.get(data.machineId);
    this.machineVersion = data.machineVersion;
    this.objectId = data.objectId;
    this.objectVersion = data.objectVersion;
    this.points = data.points;
  }

@action
  updatePoints(data) {
    this.points = data.points;
  }
@computed
get completedStatesId() {
  const statesId = this.points.map((point) => {
    return point.stateId;
  });
  return statesId;
}
getUserId(stateId) {
  const point = this.points.find((point) => {
    return point.stateId === stateId;
  });

  return point ? point.driverUid : null;
}
@computed
get lastState() {
  const arrayOfPoints = Array.from(this.points.values());
  const lastStateId = arrayOfPoints[arrayOfPoints.length - 1].stateId;
  const lastState = this.machine.getStateById(lastStateId);
  return lastState;
}

@computed
get isFinish() {
  return this.lastState.isFinal;
}
}
 
export default Workflow;
