import React, { useState, useCallback, useEffect, useMemo } from "react";
import { Modal, Components, Field } from "@ais3p/ui-framework";
import useStores from "~/core/utils/useStores";
import { observer } from "mobx-react";
import ReactFlow, {
  useNodesState,
  useEdgesState,
  MarkerType, Panel
} from "reactflow";
import dagre from "dagre";
import Node from "./Node";
import "reactflow/dist/style.css";
import "./css/workflowList.scss";

const nodeTypes = {
  input:      ReactFlow.InputNode,
  output:     ReactFlow.OutputNode,
  default:    ReactFlow.DefaultNode,
  customNode: Node
};

const Diagram = observer(({ trackedItem, onHide, switchBtn, setSwitchBtn }) => {
  const { workflowStore, objectStore } = useStores();
  const [modalTitle, setModalTitle] = useState("");
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  
  const item = useMemo(() => {
    if (trackedItem) {
      const item = objectStore.getVersion(
        trackedItem.uid,
        trackedItem.tool,
        trackedItem.version
      );
      return item;
    }
  }, [trackedItem]);
  const targetStates = useMemo(() => {
    const availableTransitions = workflowStore.diagramWorkflow.machine.getAvailableTransitions(
      workflowStore.diagramWorkflow.lastState.id);
    const availableStates = availableTransitions.map((transition) => {
      return transition.toState.id;
    });
    return availableStates;
  }, [workflowStore.diagramWorkflow.lastState.id]);

  // создаю экземпляр графа
  const dagreGraph = new dagre.graphlib.Graph();

  // по умолчанию для каждого ребра создается объект в качестве label
  dagreGraph.setDefaultEdgeLabel(() => {
    return {};
  });
  
  const nodeWidth = 170;
  const nodeHeight = 100;
  // const rankdir; // Direction for rank nodes. Can be TB, BT, LR, or RL, where T = top, 
  // B = bottom, L = left, and R = right.
  const ranksep = 150; // Number of pixels between each rank in the layout.
  const edgesep = 180; // Number of pixels that separate edges horizontally in the layout.
  const nodesep = 200; // Number of pixels that separate nodes horizontally in the layout.

  const getLayoutedElements = useCallback((nodes, edges) => {
    const isHorizontal = workflowStore.diagramDirection === "LR";

    // установить объект для label графа
    dagreGraph.setGraph({ rankdir: workflowStore.diagramDirection, ranksep, edgesep, nodes,
      ranker:  "longest-path"
    });
  
    nodes.forEach((node) => {
      dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });      
    });
  
    edges.forEach((edge) => {
      dagreGraph.setEdge(edge.source, edge.target);
    });
    // выполнить разметку узлов и ребер
    dagre.layout(dagreGraph, {
      nodeSep: nodesep,
      edgeSep: edgesep,
      rankSep: ranksep,
      rankDir: workflowStore.diagramDirection
    });
  
    nodes.forEach((node) => {
      const nodeWithPosition = dagreGraph.node(node.id);
      node.targetPosition = isHorizontal ? "left" : "top";
      node.sourcePosition = isHorizontal ? "right" : "bottom";
  
      // We are shifting the dagre node position (anchor=center center) to the top left
      // so it matches the React Flow node anchor point (top left).
      node.position = {
        x: nodeWithPosition.x - nodeWidth / 2,
        y: nodeWithPosition.y + nodeHeight
      };
  
      return node;
    });
  
    return { nodes, edges };
  }, []);

  const getEdgeStyle = (id, workflow, transition) => {
    const transitionsFromPoints = [];
    for (let i = 0; i < workflow.completedStatesId.length - 1; i += 1) {
      const pair = `${workflow.completedStatesId[i]}-${workflow.completedStatesId[i + 1]}`;
      transitionsFromPoints.push(pair);
    }
      
    const isCompleteTransition = transitionsFromPoints.includes(id);
    let style;
    if (isCompleteTransition && (workflow.completedStatesId[workflow.completedStatesId.length - 1] !== 
      transition.fromState.id)) {
      style = { stroke: "#64aa43" };
    } else if (workflow.completedStatesId[workflow.completedStatesId.length - 1] === 
      transition.fromState.id) {
      style = { stroke: "#01abfb" };
    } else {
      style = {};
    }
    return style;
  };

  const getInitialDiagramData = useCallback((machine, workflow) => {
    if (!machine) {
      // Вернуть пустые данные, если machine не существует
      return { initialNodes: [], initialEdges: [] };
    }
    const initialNodes = [];
    const initialEdges = [];
      
    machine.states.forEach((state) => {
      const node = {
        id:       state.id.toString(),
        position: { x: 0, y: 0 },
        data:     { state, workflow, machine, targetStates },
        type:     "customNode"
      };
      
      initialNodes.push(node);
  
      machine.transitions.forEach((transition) => {
        if (transition.fromState.id === state.id) {
          const edge = {
            id:       `${transition.fromState.id}-${transition.toState.id}`,
            source:   state.id.toString(),
            target:   transition.toState.id.toString(),
            type:     "smoothstep",
            label:    `${transition.name}`,
            animated: (workflow.completedStatesId[workflow.completedStatesId.length - 1] === 
               transition.fromState.id),
            style:     getEdgeStyle(`${transition.fromState.id}-${transition.toState.id}`, workflow, transition),
            markerEnd: {
              type:   MarkerType.ArrowClosed,
              width:  20,
              height: 20
            }
          };
      
          initialEdges.push(edge);
        }
      });
    });
  
    return { initialNodes, initialEdges };
  }, [workflowStore.diagramMachine, workflowStore.diagramWorkflow, targetStates]);

  const { initialNodes, initialEdges } = useMemo(() => {
    return getInitialDiagramData(workflowStore.diagramMachine, workflowStore.diagramWorkflow);
  }, [workflowStore.diagramMachine, workflowStore.diagramWorkflow.points]);

  const { nodes: layoutedNodes, edges: layoutedEdges } = useMemo(() => {
    return getLayoutedElements(initialNodes, initialEdges);
  }, [initialNodes, initialEdges]);

  useEffect(() => {
    if (workflowStore.diagramMachine) {
      setModalTitle(workflowStore.diagramMachine.name);
      setNodes(layoutedNodes);
      setEdges(layoutedEdges);
    }
  }, [workflowStore.diagramMachine, layoutedNodes, layoutedEdges]);

  const onLayout = useCallback(
    () => {
      const currentDirection = (workflowStore.diagramDirection === "LR" ? "TB" : "LR");
      workflowStore.setDiagramDirection(currentDirection);
      setSwitchBtn(!switchBtn);
      const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
        nodes,
        edges,
        currentDirection
      );

      setNodes([...layoutedNodes]);
      setEdges([...layoutedEdges]);
    },
    [nodes, edges]
  );

  return (
    <Modal.Window
      name="demo"
      icon="app-workflow-M"
      show={!!workflowStore.diagramMachine}
      title={`${item && item.name}: ${modalTitle}`}
      onBackdropClick={onHide}
      onKeyPressEsc={onHide}
      buttons={
        <Components.Button
          text="Закрыть" onPress={onHide} color="action"
          icon="close-M"
        />
      }
    >
      {/* обертка с размерами - требование Reactflow */}
      <div style={{ width: "75vw", height: "70vh" }}>
        <ReactFlow
          className="workflow-diagram"
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          nodeTypes={nodeTypes}
          fitView="true"
          edgesUpdatable={false}
          nodesConnectable={false}
          maxZoom={1}
        >
          <Panel position="top-right">
            <Field.Boolean
              onChange={onLayout} value={switchBtn} name="direction"
              label="По вертикали"
            />
          </Panel>
        </ReactFlow>
      </div>
    </Modal.Window>
  );
});

export default Diagram;
