import { observable } from "mobx";

/**
 * Модель для хранения инофрмации о состоянии
 * 
 * @type {State}
 */
class State {
  /**
   * @type {Number}
   * id состояния
   */
  @observable id;

  /**
   * @type {Boolean}
   * флаг конечного состояния
   */
  @observable isFinal;

  /**
   * @type {Boolean}
   * флаг начального состояния
   */
  @observable isInitial;

  /**
   * @type {Number}
   * id машины, которой принадлежит состояние
   */
  @observable machineId;

  /**
   * @type {String}
   * имя состояния
   */
  @observable name;

  /**
   * @type {String}
   * уникальный идентификатор
   */
  @observable slug;

  constructor(data) {
    this.id = data.id;
    this.isFinal = data.isFinal;
    this.isInitial = data.isInitial;
    this.machineId = data.machineId;
    this.name = data.name;
    this.slug = data.slug;
  }
}

export default State;
