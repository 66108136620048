import { observer } from "mobx-react";
import React, { useCallback, useLayoutEffect, useMemo, useRef } from "react";

import AisIcon from "~/core/components/AisIcon";
import Indicator from "../../../core/components/Indicator";
import PlusMenu from "./PlusMenu";
import TextItemIcon from "./TextItemIcon";

const Indent = observer(({ data, renderItem, setContextMenu }) => {
  const { items, uid, hasChunks, idsArray, isExpanded, scrollItemId, isFocusUid, diffClass } = data;

  const itemsRender = useMemo(() => {
    return items.map((itemData) => {
      return renderItem(itemData);
    });
  }, [idsArray, items]);

  const onCreateChunk = useCallback(
    (e) => {
      e && e.stopPropagation();
      if (!hasChunks) {
        data.createFirstChunk();
      }
    },
    [hasChunks]
  );

  const onClickIcon = useCallback(() => {
    if (!hasChunks) {
      onCreateChunk();
    } else {
      data.setEditing();
    }
    data.setFocusUid();
  }, [data, hasChunks]);

  const toggleExpanded = useCallback(
    (e) => {
      e.stopPropagation();
      data.setExpanded(!isExpanded);
    },
    [isExpanded]
  );

  const element = useRef();

  useLayoutEffect(() => {
    if (
      uid &&
      scrollItemId &&
      uid === scrollItemId &&
      element &&
      element.current
    ) {
      setTimeout(() => {
        element.current && element.current.scrollIntoView(true);
      }, 200); // set timeout= DIRTY HACK 
      // TODO: get rid of setTimeout()
    }
  }, [uid, scrollItemId, element && element.current]);

  return (
    <div id={uid} ref={element} className={`indent element ${diffClass}`}>
      <div className={`item-holder  ${isFocusUid ? "focus" : ""}`}>
        <Indicator data={data} />
        <AisIcon
          onClick={toggleExpanded}
          icon={"plus-M"}
          className={`expand-menu ${isExpanded ? "expanded" : ""}`}
        />
        <TextItemIcon onClick={onClickIcon} setContextMenu={setContextMenu} data={data} />
        <div className="indent-body" onClick={onClickIcon}>
          {itemsRender}
          {!hasChunks && (
            <div onClick={onCreateChunk} className="placeholder">
              Нет содержимого
            </div>
          )}
        </div>
      </div>
      {isExpanded && <PlusMenu data={data} />}
    </div>
  );
});

export default Indent;
