import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { Components } from "@ais3p/ui-framework";

import File from "../components/File";

import "./css/index.scss";

/**
 * Инструмент для просмотра файлов: изображение, pdf
 *
 * @param {String} type тип файла
 * @param {String} name название файла
 * @param {String} icon название файла
 * @param {String} file id файла в файловом хранилище
 * @param {String} fileBlob url локального Blob файла, который уже был загружен.
 * Те это файл, который уже был загружен клиентом и его не нужно брать из файлового хранилища АИС
 */
const ViewerTool = observer((props) => {
  const { type, name, icon } = props;
  const [file, setFile] = useState(props.file);
  const [loaded, setLoaded] = useState(props.fileBlob);

  const onRefresh = useCallback(() => {
    setFile(undefined);
    setLoaded(undefined);
    setFile(props.file);
    setLoaded(props.fileBlob);
  }, []);

  const onLoad = useCallback((file) => {
    setLoaded(file);
  }, []);

  return (
    <div className="viewer">
      <Components.ToolBar >
        <Components.Button
          icon="refresh-M"
          tooltip="Обновить"
          onPress={onRefresh}
        />
      </Components.ToolBar >
      <div className="viewer-content">
        <File
          file={file}
          type={type}
          name={name}
          icon={icon}
          onLoad={onLoad}
          loaded={loaded}
        />
      </div>
    </div>
  );
});

ViewerTool.propTypes = {
  type:     PropTypes.string,
  name:     PropTypes.string,
  icon:     PropTypes.string,
  file:     PropTypes.string,
  fileBlob: PropTypes.string
};

export default ViewerTool;
