import { observable, action, computed } from "mobx";
import AisVersion from "~/core/data/models/AisVersion";
import { CLS_REPO_DIR } from "../../../core/constants/Classes";
import { DOMAIN_REPO } from "~/core/constants/Domains";

/**
 * Модель для хранения информации о ноде в дереве репозитория
 * 
 * @class {RepoNode}
 */
class RepoNode extends AisVersion {
  /**
   * @type {String} 
   * Автор коммита
   */
  @observable 
  author = null;

  /**
   * @type {String} 
   * id коммита
   */
  @observable 
  commitId = null;

  /**
   * @type {String} 
   * Авторское сообщение к коммиту
   */
  @observable 
  commitMessage = null;

  /**
   * @type {Date} 
   * Дата коммита
   */
  @observable 
  date = null;

  /**
   * @type {String} 
   * Вид ноды
   */
  @observable kind = null;

  /**
   * @type {String} 
   * Назание ноды
   */
  @observable 
  name = null;

  /**
   * @type {String} 
   * id репозитория
   */
  @observable 
  repositoryId = null;

  /**
   * @type {String} 
   * тип репозитория - git, svn, hg
   */
  @observable 
  repositoryType = undefined;

  /**
   * @type {String} 
   * Путь до ноды
   */
  @observable 
  path = null;

  /**
   * @type {String} 
   * сlass ноды
   */
  @observable 
  class = null;

  /**
   * @type {Number} 
   * Размер файла в байтах
   */
  @observable 
  size = null;

  /**
   * @type {Array<String>} 
   * Uid's вложенных нод
   */
  @observable 
  childItems = [];
  
  /**
   * @type {RepoNode} 
   * Предок ноды
   */
  @observable 
  parent = null;

  /**
   * @type {Boolean} 
   * Признак резверуности ноды
   */
  @observable
  expanded = false;

  @observable
  isPending = false;

  // static generateRepoNodeUid(data) {
  //   return generateRepoId(data.repositoryId, data.commitId, data.path, data.codeItem);
  // }

  static create({
    id,
    commitId,
    class:klass,
    language,
    name, 
    path,
    size,
    kind,
    date,
    author,
    commitMessage,
    url,
    entries,
    parent,
    domain = DOMAIN_REPO,
    repositoryId,
    repositoryType,
    branch,
    tag
  }, objectStore) {
    // const uid = id || RepoNode.generateRepoNodeUid({ repositoryId, branch, tag, commitId, path });

    return new RepoNode({
      uid:   id,
      branch,
      tag,
      commitId,
      class: klass,
      language,
      name, 
      path,
      repositoryId,
      repositoryType,
      size,
      kind,
      date,
      author,
      commitMessage,
      url,
      entries,
      parent,
      domain,
      repositoryId,
      repositoryType,
      branch,
      tag
    }, objectStore);
  }

  /**
   * Конструктор ноды дерева репозитория
   * 
   * @param {Object} props набор параметров для создания ноды
   * @param {ObjectStore} objectStore глобальное хранилище для работы с объектами АИС
   * 
   * @return {RepoNode}
   */
  constructor({
    uid,
    domain = DOMAIN_REPO,
    commitId,
    class: klass,
    language,
    name, 
    path,
    size,
    kind,
    date,
    author,
    commitMessage,
    url,
    entries,
    parent,

    repositoryId,
    repositoryType,
    branch,
    tag
  }, objectStore) {
    super(
      {
        uid,
        domain
      },
      objectStore
    );
    
    this.branch = branch;
    this.tag = tag;
    this.commitId = commitId;
    this.class = klass;
    this.language = language;
    this.name = name;
    this.path = path;
    this.repositoryId = repositoryId;
    this.repositoryType = repositoryType;
    this.size = size;
    this.kind = kind;
    this.date = date;
    this.url = url;
    this.author = author;
    this.commitMessage = commitMessage;

    this.parent = parent;
    
    this.processEntries(entries);

    objectStore && objectStore.addVersion(this);
    // this.updateParentInChildren();
    // this.isExpandable = this.kind === "directory";
  }
  
  @action
  processEntries(entries) {
    if (!entries) {
      return;
    }
    this.childItems = [];
    entries.forEach((entry) => {
      let node = RepoNode.create({ 
        ...entry, 
        parent:         this,
        repositoryId:   this.repositoryId,
        repositoryType: this.repositoryType,
        branch:         this.branch,
        tag:            this.tag 
      }, this.objectStore);
      this.objectStore.addVersion(node);
      node = this.objectStore.getVersion(node.uid, DOMAIN_REPO);
      this.childItems.push(node);
    });
  }

  @action
  setIsPending(value) {
    this.isPending = value;
  }

  @computed
  get children() {
    return this.childItems.map((item) => {
      return item.uid;
    });
  }

  @action
  setParent(parent) {
    this.parent = parent;
  }

  @action
  updateParentInChildren() {
    if (!this.children) {
      return;
    }

    this.children.forEach((child) => {
      child.setParent(this);
    });
  }

  @computed
  get isLeaf() {
    return this.class !== CLS_REPO_DIR;
  }

  @computed
  get isExpanded() {
    return this.expanded;
  }

  @action 
  expand(expanded = false) {
    this.expanded = expanded;
  }

  @computed
  get level() {
    if (!this.parent) {
      return -1;
    }
    return this.parent.level + 1;
  }
  
  /**
   * Обновить данные ноды
   * 
   * @param {Object} props 
   * @param {String} props.repositoryId id репозитория 
   * @param {String} props.path путь до ноды
   * @param {String} props.сommit id коммита
   * @param {String} props.commitMessage авторское сообщение к коммиту
   * @param {String} props.name название ноды
   * @param {String} props.class class ноды
   * @param {String} props.class class ноды
   * @param {String} props.url url ноды
   * @param {Array<Object>} props.entries набор вложенных нод
   * @param {Date} props.date дата коммита
   * @param {Number} props.size размер файла в байтах
   * @param {String} props.author автор коммита
   * @param {String} props.kind тип ноды
   * 
   * @return {RepoNode} this
   */
  @action
  update(props) {
    if (props.repositoryId) {
      this.repositoryId = props.repositoryId;
    }
    if (props.repositoryType) {
      this.repositoryType = props.repositoryType;
    }
    if (props.path) {
      this.path = props.path;
    }
    if (props.commitId) {
      this.commitId = props.commitId;
    }
    if (props.commitMessage) {
      this.commitMessage = props.commitMessage;
    }
    if (props.name) {
      this.name = props.name;
    }
    if (props.class) {
      this.class = props.class;
    }
    if (props.url) {
      this.url = props.url;
    }
    
    if (props.entries) {
      this.processEntries(props.entries);
    }
    if (props.date) {
      this.date = props.date;
    }
    
    if (props.kind) {
      this.kind = props.kind;
    }
    
    if (props.size) {
      this.size = props.size;
    }
    if (props.author) {
      this.author = props.author;
    }
    return this;
  }

  /**
   * Получить текстовое название ноды
   * 
   * @return {String}
   */
  @computed
  get title() {
    return this.name;
  }

  /**
   * Alias на class
   * 
   * @returns {String} class
   */
  @computed
  get className() {
    return this.class;
  }

  /**
   * Получить расширение файла
   * 
   * @returns {String} расширение файла в нижнем регистре
   */
  @computed
  get fileExt() {
    return this.name.split(".").pop()
      .toLowerCase();
  }

  /**
   * Получить иконку ноды
   * 
   * @returns {String} строковое название иконки ноды
   */
  @computed
  get iconString() {
    if (!this.isLeaf) {
      return "collection-closed-M";
    } 

    switch (this.fileExt) {
      case "doc":
        return "file-doc-M";
      case "docx":
        return "file-docx-M";
      case "odt":
        return "file-odt-M";
      case "xls":
        return "file-xls-M";
      case "pdf":
        return "file-pdf-M";
      case "c":
      case "cpp":  
        return "file_c-M";
      case "h":  
        return "file_h-M";
      case "png":
      case "jpg":  
      case "jpeg":  
      case "bmp":  
      case "gif":  
      case "tiff":  
      case "psd":  
      case "ai":  
      case "indd":  
      case "raw":  
        return "file-picture-M";
      case "txt":  
      case "md":  
        return "file-text-M";
    }

    return "file-unknown-M";
  }

  /**
   * Деструктор модели
   */
  destroy() {
  }
}

export default RepoNode;
