import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { Components } from "@ais3p/ui-framework";

import IssueStore from "../../stores/IssueStore";
import { IssueModel } from "../../models";
import RelatedIssueItem from "./RelatedIssueItem";
import SearchIssues from "./SearchIssues";
import Menu from "./Menu";

/**
 * Компонент для отображения подзадач Задачи
 * 
 * @param {Object} props набор параметров
 * @param {IssueStore} props.store хранилище для работы с задачами
 * @param {IssueModel} props.issue текущая задача
 * @param {String} props.title заголовок панели списка задач
 * @param {String} props.className пользовательский className
 * @param {Boolean} props.readOnly флаг указывающий, что файлы в спсиске только можно просмматривать
 * @param {Function} props.onAddFile callback функция при добавалении нового файла в список
 * @param {Function} props.onDeleteFile callback функция при удалении файла из списка
 */
const RelatedIssues = observer(
  ({
    store,
    issue,
    title = "Связанные задачи",
    className,
    layoutItem,
    readOnly
  }) => {
    const [searchTaskMode, setSearchTaskMode] = useState(false);
    const [isShowMenu, setIsShowMenu] = useState(false);

    const onMouseClick = useCallback((e) => {
      const el = e.target.closest(".issue-related-issues-header-controls");
      // значит кликнули не по кнопке. Скрываем меню 
      if (!el) {
        setIsShowMenu(false);
      }
    }, []);

    useEffect(() => {
      window.addEventListener("click", onMouseClick);
      return () => {
        window.removeEventListener("click", onMouseClick);
      };
    }, []);

    const onAddRelationTask = useCallback(() => {
      // !isShowMenu && setIsShowMenu(true);
      setIsShowMenu(!isShowMenu);
    }, [isShowMenu]);

    const onSelectAction = useCallback((action) => {
      switch (action) {
        case "link-parent":{
          setSearchTaskMode("parent");
          break;
        }
        case "link-subtask":{
          setSearchTaskMode("subtask");
          break;
        }

        case "create-subtask":{
          const newIssue = store.createNewIssue(issue.tracker, issue);
          store.setActiveIssue(newIssue);
          break;
        }          
      }

      setIsShowMenu(false);
    }, [store, issue]);

    // const onLinkParentTask = useCallback(() => {
    //   setSearchTaskMode("parent");
    // }, []);

    // const onLinkSubtask = useCallback(() => {
    //   setSearchTaskMode("subtask");
    // }, []);

    // // Создать новую подзадачу
    // const onCreateSubtask = useCallback(() => {
    //   const newIssue = store.createNewIssue(issue.tracker, issue);
    //   store.setActiveIssue(newIssue);
    // }, [store, issue]);

    const onSelectSearchIssue = useCallback((selectedIssue) => {
      if (searchTaskMode === "parent") {
        if (issue.isNew) {
          issue.setParent(selectedIssue);
        } else {
          store.linkRelatedIssue(issue.uid, selectedIssue.uid, issue);
        }
      }

      if (searchTaskMode === "subtask") {
        store.linkRelatedIssue(selectedIssue.uid, issue.uid, issue);
      }
      setSearchTaskMode(undefined);
    }, [searchTaskMode, issue, store]);

    const onCancelSearch = useCallback(() => {
      setSearchTaskMode(undefined);
    });
    
    return (
      <div className={`issue-related-issues ${className ? className : ""}`}>
        <div className={"issue-related-issues-header"}>
          <label>{`${title}:`}</label>
          {!readOnly &&
            <div className={"issue-related-issues-header-controls"}>
              {
                <Components.Button
                  icon="plus-M"
                  // text="Добавить"
                  tooltip="Прикрепить задачу"
                  color="action"
                  onPress={onAddRelationTask}
                />
              }
              {/*
              <Components.Button
                icon="link-start-M"
                text="Родительская"
                tooltip="Прикрепить родительскую задачу"
                color="light"
                isDisabled={readOnly}
                onPress={onLinkParentTask}
              />
              <Components.Button
                icon="link-start-M"
                text="Подзадача"
                tooltip="Прикрепить подзадачу"
                color="positive"
                isDisabled={readOnly || issue.isNew}
                onPress={onLinkSubtask}
              />
              <Components.Button
                icon="plus-M"
                text="Подзадача"
                tooltip="Создать подзадачу"
                color="action"
                isDisabled={readOnly || issue.isNew}
                onPress={onCreateSubtask}
              />
            */}
              <Menu
                issue={issue}
                isDisabled={!isShowMenu}
                onSelectAction={onSelectAction}
              />
            </div>
          }
        </div>
        {searchTaskMode &&
          <SearchIssues 
            store={store}
            mode={searchTaskMode}
            issue={issue}
            onSelectIssue={onSelectSearchIssue}
            onCancel={onCancelSearch}
          />
        }
        { issue.relatedIssues.length === 1 &&
          <div className={"issue-related-issues-empty"}>
            Нет связанных задач
          </div>
        }
        {
          // если в списке только одна задача, то значит это текщуая задача
          // поэтому ее не выводим
          issue.relatedIssues.length > 1 &&
          <div className={"issue-related-issues-body"}>
            {issue.relatedIssues.map((item) => {
              return (
                <RelatedIssueItem 
                  key={item.id}
                  issue={issue}
                  item={item}
                  store={store}
                  readOnly={readOnly}
                  layoutItem={layoutItem}
                />
              );
            })}
          </div>
        }
      </div>
    );
  }
);

RelatedIssues.propTypes = {
  title:        PropTypes.string,
  className:    PropTypes.string,
  store:        PropTypes.instanceOf(IssueStore), 
  issue:        PropTypes.instanceOf(IssueModel), 
  readOnly:     PropTypes.bool, 
  onAddFile:    PropTypes.func,
  onDeleteFile: PropTypes.func
};

export default RelatedIssues;
