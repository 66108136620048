import { observer } from "mobx-react";
import React, { useMemo } from "react";
import RubricTreeItem from "./RubricTreeItem";

const RubricTree = observer(({ store, setScrollToIndex, setContextMenu }) => {
  const { flatTreeItemsArray } = store;

  const rubricsRendered = useMemo(() => {
    return flatTreeItemsArray.map((item) => {
      return (
        <RubricTreeItem
          store={store}
          setContextMenu={setContextMenu}
          key={item.uid}
          item={item}
          setScrollToIndex={setScrollToIndex}
        />
      );
    });
  }, [flatTreeItemsArray]);
  return <div className="rubric-tree">{rubricsRendered}</div>;
});

export default RubricTree;
