import { observable, computed } from "mobx";

/**
 * Модель кода Вида, полученная через Composer
 */
export default class CodeModel {
  /**
   * @type {String}
   * Уникальный идентификатор кода Вида
   */
  @observable
  uid = undefined;

  /**
   * @type {String}
   * Название кода Вида
   */
  @observable
  name = undefined;

  /**
   * @type {String}
   * Class кода Вида
   */
  @observable
  class = undefined;

  /**
   * @type {String}
   * Шаблон кода Вида
   */
  @observable
  template = undefined;

  static create({ uid, name, class:klass, template }, store) {
    return new  CodeModel({ uid, name, class: klass, template }, store);
  }

  constructor(data, store) {
    this.store = store;

    this.uid = data.uid;
    this.name = data.name;
    this.class = data.class;
    this.template = data.template;
  }

  /**
   * Получить название кода Вида
   * 
   * @returns {String} название кода Вида
   */
  @computed
  get title() {
    return  this.name;
  }
}
