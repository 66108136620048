import React, { Component } from "react";
import { Provider, observer } from "mobx-react";

import "@ais3p/ui-framework/bundle.css";

import AppContainer from "~/core/components/AppContainer";

import RootStore from "~/core/data/stores/rootStore";

import "~/App.scss";
import Confirm from "./Confirm";

import { Components, Dnd, Notification } from "@ais3p/ui-framework";

import { errorNotify } from "../utils";
import ReloadBtn from "./ReloadBtn";
import WizardTool from "../../modules/wizard/components/WizardTool";


@observer
class App extends Component {
  constructor(props) {
    super(props);

    this.onReload = this.onReload.bind(this);
    this.onDismissError = this.onDismissError.bind(this);
    const rootStore = new RootStore();

    this.state = {
      rootStore
    };
  }

  componentWillUnmount() {
    const { rootStore } = this.state;
    rootStore.destroy();
  }

  onReload() {
    const { rootStore } = this.state;
    const { accountStore } = rootStore;
    accountStore.clearUserData();
    window.location.reload(true);
  };

  onDismissError() {
    const { rootStore } = this.state;
    const { uiStore } = rootStore;
    uiStore.dismissError();
  }
  render() {
    const { hasError, error } = this.props;
    const { rootStore } = this.state;
    const {
      userStore,
      accountStore,
      relationStore,
      marksStore,
      objectStore,
      kindsStore,
      groupsStore,
      uiStore,
      configStore,
      workflowStore,
      wsStore
    } = rootStore;

    if (hasError) {
      return (
        <div className="App">
          <div id="ais-app-root" className="app-container login error"></div>
          <div className="overlay-blur visible"></div>
          <div className={"app-error visible uif-modal-window "}>
            <div className="error-header">{"Критическая ошибка"}</div>
            <div className="error-text">{error && error.message}</div>
            <div className="footer">
              <Components.Button
                key="close"
                icon="close-M"
                tooltip="Перезапустить"
                text={"Перезапустить"}
                color={"negative"} // eslint-disable-next-line
            onPress={() => {
                  accountStore.clearUserData();
                  window.location.reload(true);
                }}
              />
            </div>
          </div>
        </div>
      );
    }

    const { showError, errorText, errorIsCritical } = uiStore;
    if (showError && errorText) {
      const content = <ReloadBtn onReload={this.onReload} errorText={errorText} />;
      errorNotify(errorIsCritical ? content : errorText, this.onDismissError);
    }

    return (
      <Dnd.Provider>
        <Provider
          rootStore={rootStore}
          uiStore={uiStore}
          userStore={userStore}
          accountStore={accountStore}
          relationStore={relationStore}
          marksStore={marksStore}
          objectStore={objectStore}
          kindsStore={kindsStore}
          groupsStore={groupsStore}
          configStore={configStore}
          workflowStore={workflowStore}
          wsStore={wsStore}
        >
          <div className={`App ${uiStore.showConfirm ? "confirm" : ""}`}>
            <AppContainer />
            <div className="overlay-blur"></div>
            <Notification.Area />
            <Confirm />
            <WizardTool />
          </div>
        </Provider>
      </Dnd.Provider>
    );
  }
}

export default App;
