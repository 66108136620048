import React from "react";
import PropTypes from "prop-types";
import { getIssueValueLabel } from "../../constants/values";

/**
 * Компонент для отображения ячейки заголовка в табличном представлении списка задач
 * 
 * @param {String} id идентификатор колонки
 */
const TableColHeader = ({ id }) => {
  return (
    <th
      className={`issues-table-column-header ${id}-header`}
    >
      <span className="issues-table-column-header-text">
        {getIssueValueLabel(id)}
      </span>
    </th>
  );
};

TableColHeader.propTypes = {
  id: PropTypes.string.isRequired
};


export default TableColHeader;
