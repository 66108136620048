import React from "react";
import PropTypes from "prop-types";
import { components } from "react-select";
import { Components } from "@ais3p/ui-framework";

/**
 * Компонент для отображения значения-проекта в поле, где отображается текущий проект
 * 
 * @param {Object} props набор параметров
 * @param {Object} params.data данные проекта 
 */
const ProjectValue = (props) => {
  const { data } = props;

  return (
    <components.SingleValue {...props}>
      <div className="issue-project-value">
        <Components.Icon name="project-M" size={2} />
        <div className="label">{data.label}</div>
      </div>
    </components.SingleValue>
  );
};

ProjectValue.propTypes = {
  data: PropTypes.object.isRequired
};

export default ProjectValue;
