import React, { useCallback } from "react";
import { observer } from "mobx-react";
import { Components } from "@ais3p/ui-framework";

import JournalItem from "./JournalItem";
import Pagination from "~/core/components/pagination";

/**
 * Компонент для отображения списка журнала
 *
 * @class JournalList
 */
const JournalList = observer(
  ({ store, noRecordsText = "Нет записей в журнале изменений" }) => {
    const onPageChange = useCallback(
      (page) => {
        store.goToPage(page);
      },
      [store.currentPage]
    );

    const setPageSize = useCallback(
      (size) => {
        store.setPageSize(size);
      },
      [store.setPageSize]
    );

    const reload = useCallback(() => {
      store.loadRecords({
        page:    store.currentPage,
        perPage: store.perPage,
        ...store.filters
      });
    }, [store.currentPage, store.perPage, store.filters]);

    return (
      <div className="journal-list">
        {store.isProcessing && (
          <div className="journal-list-preloader">
            <Components.Preloader size={3} />
          </div>
        )}
        {store.records.size === 0 && !store.isProcessing && (
          <div className="no-records">{noRecordsText}</div>
        )}
        <div className={`journal-list-items order-${store.filters.order}`}>
          {store.recordList.map((record) => {
            return <JournalItem key={record.uid} item={record} store={store} />;
          })}
        </div>
        <Pagination
          className="journal-list-pagination"
          startPageIndex={1}
          canPrevious={store.canPreviousPage}
          canNext={store.canNextPage}
          pageSize={store.perPage}
          page={store.currentPage}
          pages={store.pageCount}
          onPageChange={onPageChange}
          reload={reload}
          onPageSizeChange={setPageSize}
          loading={store.isProcessing}
        />
      </div>
    );
  }
);

export default JournalList;
