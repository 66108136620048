import React from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";

import TableHeader from "./TableHeader";
import TableRow from "./TableRow";
import CheckListStore from "../../stores/checkListStore";

/**
 * Табличное представление результата CheckList
 * 
 * @param {CheckListStore} store хранилище для работы с CheckList
 */
const Table = observer(({ store }) => {
  return (
    <table>
      <TableHeader isTP={store.isTP} isURS={store.isURS} />
      <tbody>
        {store.dataArray.map((item) => {
          return <TableRow key={item.id} isURS={store.isURS} item={item} />;
        })}
      </tbody>
    </table>
  );
});

Table.propTypes = {
  store: PropTypes.instanceOf(CheckListStore).isRequired
};


export default Table;
