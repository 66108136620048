import React, { useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Field } from "@ais3p/ui-framework";

/**
 * Компонент для отображения/редактирования строкового значения в ячейке табличного представления результата CheckList
 * 
 * @param {String} name имя переменной строкового значения
 * @param {String} value значение переменной строкового значения
 * @param {String} className пользовательский className
 * @param {Func} onChange callback ф-я на изменение значения
 */
const StringContent = ({ name, value, className, onChange }) => {
  const [isEditMode, setEditMode] = useState(false);
  useEffect(() => {
    window.addEventListener("click", onMouseClick);
    return () => {
      window.removeEventListener("click", onMouseClick);
    };
  }, []);

  const onMouseClick = useCallback((e) => {
    const el = e.target.closest(".ais-field");
    if (!el) {
      // кликнули не в поле input
      setEditMode(false);
    }
  }, []);

  const onClick = useCallback((e) => {
    e.stopPropagation();
    setEditMode(true);
  }, []);

  const onChangeValue = useCallback(
    (val) => {
      onChange && onChange(name, val);
    },
    [name, isEditMode, value, onChange]
  );

  return (
    <div
      className={classNames("string-value", className, {
        editing: isEditMode
      })}
      data-tooltip={value}
      data-tooltip-at="bottom"
    >
      {!isEditMode && (
        <div className="string-value-content" onClick={onClick}>
          {value}
        </div>
      )}
      {isEditMode && (
        <Field.String
          icon=""
          name={name}
          value={value}
          onChange={onChangeValue}
        />
      )}
    </div>
  );
};

StringContent.propTypes = {
  name:      PropTypes.string.isRequired,
  value:     PropTypes.string,
  onChange:  PropTypes.func,
  className: PropTypes.string
};

export default StringContent;
