import { observer } from "mobx-react";
import React, {
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef
} from "react";

import AisIcon from "~/core/components/AisIcon";
import Indicator from "../../../core/components/Indicator";

import InlinePicture from "./InlinePicture";
import PlusMenu from "./PlusMenu";
import TextItemIcon from "./TextItemIcon";

const Picture = observer(({ data, renderItem, setContextMenu }) => {
  const {
    uid,
    inlineItem,
    caption,
    isExpanded,
    scrollItemId,
    isFocusUid,
    diffClass
  } = data;
  const captionRender = caption && renderItem(caption);

  const hasChunks = useMemo(() => {
    return !!(caption && caption.textId);
  }, [caption && caption.textId]);

  const onClickIcon = useCallback(() => {
    if (!hasChunks) {
      caption && caption.createOnlyChunk();
    } else {
      data.setEditing();
    }
    data.setFocusUid();
  }, [data, hasChunks]);

  const toggleExpanded = useCallback(
    (e) => {
      e.stopPropagation();
      data.setExpanded(!isExpanded);
    },
    [isExpanded]
  );

  const element = useRef();

  useLayoutEffect(() => {
    if (
      uid &&
      scrollItemId &&
      uid === scrollItemId &&
      element &&
      element.current
    ) {
      setTimeout(() => {
        element.current.scrollIntoView(true);
      }, 10); // set timeout= DIRTY HACK
      // TODO: get rid of setTimeout()
    }
  }, [uid, scrollItemId, element && element.current]);

  return (
    <div id={uid} ref={element} className={`image element ${diffClass}`}>
      <div className={`item-holder  ${isFocusUid ? "focus" : ""}`}>
        <Indicator data={data} />
        <AisIcon
          onClick={toggleExpanded}
          icon={"plus-M"}
          className={`expand-menu ${isExpanded ? "expanded" : ""}`}
        />
        <TextItemIcon setContextMenu={setContextMenu} onClick={onClickIcon} data={data} />
        <div className="image-body" onClick={onClickIcon}>
          <div className="image-content">
            {inlineItem && <InlinePicture data={inlineItem} />}
          </div>
          {caption && captionRender}
        </div>
      </div>
      {isExpanded && <PlusMenu data={data} />}
    </div>
  );
});

export default Picture;
