import { observer } from "mobx-react";
import React, { useCallback, useMemo, useState } from "react";

import AisIcon from "~/core/components/AisIcon";
import AddRelationItem from "./AddRelationItem";

import RelationItem from "./RelationItem";

const KindGroup = observer(({ 
  kind, items, plusToggle, expandToAddKindId, targetInfo, relationUIStore, onDeleteRelation }) => {
  const { name, id } = kind;

  const [isExpanded, setIsExpanded] = useState(true);
  const toggleExpanded = useCallback(() => {
    setIsExpanded(!isExpanded);
  }, [isExpanded]);

  const isPlusExpanded = useMemo(() => {
    return id === expandToAddKindId;
  }, [id, expandToAddKindId]);

  const onTogglePlus = useCallback(() => {
    plusToggle(isPlusExpanded ? null : id);
  }, [plusToggle, isPlusExpanded, id]);

  return (
    <div className="kind-group">
      <div className="item-header">
        <div className="item-name">{name}</div>
        <AisIcon onClick={toggleExpanded} icon={isExpanded ? "chevron-up-M" : "chevron-down-M"} />
        <div className="spacer"></div>
        <AisIcon onClick={onTogglePlus} icon={isPlusExpanded ? "close-M" : "plus-M"} />
      </div>
      {isExpanded && (
        <div className="related-list">
          {isPlusExpanded && <AddRelationItem relationUIStore={relationUIStore} targetInfo={targetInfo} kind={kind} />}
          {items.map((item, i) => {
            return (
              <RelationItem
                item={item} 
                key={i}
                kind={kind} 
                onDeleteRelation={onDeleteRelation}
              />
            );
          })}
        </div>
      )}
    </div>
  );
});

export default KindGroup;
