import { observable, computed } from "mobx";
import KindModel from "~/modules/relations/models/Kind";

/**
 * Модель записи связи в данных отчета о трассировке
 *
 * @class  DataRelationItemModel
 */
class DataRelationItemModel {
  /**
   * uid связи
   *
   * @type String
   */
  @observable
  uid = undefined;

  /**
   * class связи
   *
   * @type String
   */
  @observable
  class = undefined;

  /**
   * Тип связи
   *
   * @type Object
   */
  @observable
  type = undefined;

  /**
   * Источник начала связи
   *
   * @type Object
   */
  @observable
  source = undefined;

  /**
   * Цель связи
   *
   * @type Object
   */
  @observable
  dest = undefined;

  /**
   * Объект, который находится в левой колонке от колонки связи
   * По этому объкуту, мы будем потом определять направление связи - in | out
   *
   * @type DataObjectItem
   */
  @observable
  leftObject = undefined;

  
  /**
   * Cоздание модели
   *
   * @param {Object} params параметры модели
   * @param {String} params.uid uid связи
   * @param {String} params.class class связи
   * @param {Object} params.representation представление связи
   * @param {Object} params.representation.linkType тип связи
   * @param {Object} params.representation.source источник связи
   * @param {Object} params.representation.dest цель связи
   *
   * @retrun {DataRelationItemModel}
   */
  static create({
    uid,
    class:klass,
    representation
  }, rootStore, leftObject) {
    return new  DataRelationItemModel({
      uid,
      class: klass,
      representation
    }, rootStore, leftObject);
  }

  constructor(params, rootStore, leftObject) {
    this.uid = params.uid;
    this.class = params.class;
    if (params.representation) {
      this.type = params.representation.linkType && KindModel.create(params.representation.linkType);
      this.source = params.representation.source;
      this.dest = params.representation.dest;
    }
    

    this.leftObject = leftObject; 
    

    this.rootStore = rootStore;
  }

  get dataType() {
    return "link";
  }

  /**
   * Строковое представление связи
   *
   * @return {String}
   */
  @computed
  get title() {
    return this.type.name;
  }

  /**
   * Направление связи - in | out
   *
   * @return {String}
   */
  @computed
  get direction() {
    if (!this.leftObject) {
      return;
    }
    if (this.dest && this.dest.uid === this.leftObject.uid) {
      return "in";
    }

    if (this.source && this.source.uid === this.leftObject.uid) {
      return "out";
    }
    return;
  }
}

export default DataRelationItemModel;
