import React from "react";
import PropTypes from "prop-types";

/**
 * @component
 * 
 * Компонент для заголовка таблицы в табличном представлении результата поиска артефактов
 * 
 * @param {String} columnId id колонки - ключ для отображения значения
 * @param {String} title текст заголовка 
 */
const TableColHeader = ({ columnId, title }) => {
  return (
    <th
      className={`entities-table-column-header ${columnId}-header`}
    >
      <span className="entities-table-column-header-text">
        {title}
      </span>
    </th>
  );
};

TableColHeader.propTypes = {
  columnId: PropTypes.string.isRequired,
  title:    PropTypes.string.isRequired 
};

export default TableColHeader;
