import Server from "~/core/api/server";

class WorkflowApi extends Server {
  methods = {
    getMachines: {
      url:         this.getServiceUrl("workflow", "api/v0/machines"),
      method:      "GET",
      textProcess: "Получение списка Workflow машин...",
      textSuccess: null,
      textError:   this.template`Во время запроса списка Workflow машин произошла ошибка: ${0}`
    },
    getWorkflows: {
      url: (params) => {
        return this.getServiceUrl(
          "workflow",
          this.template`api/v0/objects/${0}/versions/${1}/ways`,
          params
        );
      },
      method:      "GET",
      textProcess: "Получение текущих путей (workflow) для версии объекта...",
      textSuccess: null,
      textError:   this.template`Во время запроса текущих путей (Workflow) для версии объекта произошла ошибка: ${0}`
    },
    createWorkflow: {
      url: (params) => {
        return this.getServiceUrl(
          "workflow",
          this.template`api/v0/objects/${0}/versions/${1}/ways`,
          params
        );
      },
      method:      "POST",
      textProcess: "Создание нового пути workflow...",
      textSuccess: null,
      textError:   this.template`Во время создания нового пути workflow произошла ошибка: ${0}`
    },
    updateWorkflow: {
      url: (params) => {
        return this.getServiceUrl(
          "workflow",
          this.template`api/v0/ways/${0}/signals`,
          params
        );
      },
      method:      "POST",
      textProcess: "Обновление состояния workflow...",
      textSuccess: null,
      textError:   this.template`Во время во время обновления состояния workflow произошла ошибка: ${0}`
    }
  };
  
  async getMachines() {
    const res = await this.request("getMachines");
    return res;
  };
  async getWorkflows({ uid, version }) {
    const reqData = {
      params: [uid, version]
    };
    const res = await this.request("getWorkflows", reqData);
    return res;
  }
  /**
   * Создать новый воркфлоу отселживаемого элемента
   * 
   * @param {String} uid id отселживаемого элемента
   * @param {Number} version версия отселживаемого элемента
   * @param {Number} machineId id workflow машины
   * @returns 
   */
  async createWorkflow({ uid, version }, machineId) {
    const reqData = {
      params: [uid, version],
      data:   {
        machineId
      }
    };
    try {
      const res = await this.request("createWorkflow", reqData);
      return res;
    } catch (error) {
      this.rootStore.uiStore.setErrorText(error.message);
    }
  }
  /**
   * Обновить воркфлоу отселживаемого элемента (добавить переход)
   * 
   * @param {Number} workflowId id жизненного цикла
   * @param {String} slug наименование пути перехода
   * @returns 
   */
  async updateWorkflow(workflowId, slug) {
    const reqData = {
      params: [workflowId],
      data:   {
        name: slug
      }
    };
    try {
      const res = await this.request("updateWorkflow", reqData);
      return res;
    } catch (error) {
      this.rootStore.uiStore.setErrorText(error.message);
    }
  }
}
export default WorkflowApi;
