import React from "react";
import PropTypes from "prop-types";
import { Components } from "@ais3p/ui-framework";
import { observer } from "mobx-react";
import SchemaLinkItemModel from "../../models/schemaLinkItem";

/**
 * Компонент для отображения записи в последовательном списке объектов
 * 
 * @param {Object} props набор параметров
 * @param {SchemaLinkItemModel} props.item  запись связи в схеме отчетов о трассировке
 */
const LinkItem = observer(({ item }) => {
  return (
    <div 
      className="schema-trace-link"
      // {...{
      //   "data-tooltip": item.title
      // }}
    >
      <Components.Icon
        name={item.direction === "in" ? "arrow-up-M" : "arrow-down-M"}
      />
      <div className="schema-trace-link-title">
        {item.title}
      </div>
    </div>
  );
});

LinkItem.propTypes = {
  item: PropTypes.instanceOf(SchemaLinkItemModel).isRequired
};


export default LinkItem;
