import Server from "~/core/api/server";


class SearchApi extends Server {
  methods = {
    searchEntities: {
      // поиск артефактов
      // url:           this.getServiceUrl("kinds", "api/v2/members/found-members"),
      url:           this.getServiceUrl("composer", ""),
      method:        "POST",
      accept:        "application/json",
      returnHeaders: true,
      textProcess:   "Поиск артефактов АИС...",
      textSuccess:   null,
      textError:     this.template`Во время поиска артефактов АИС произошла ошибка: ${0}.`,
      timeout:       600000
    },
    searchEntitiesByPage: {
      // поиск артефактов с пагинацией
      url: (params) => {
        return this.getServiceUrl("kinds", this.template`api/v2/members/found-members?page=${0}&perPage=${1}`, params);
      },
      method:        "POST",
      returnHeaders: true,
      textProcess:   "Поиск артефактов АИС...",
      textSuccess:   null,
      textError:     this.template`Во время поиска артефактов АИС произошла ошибка: ${0}.`,
      timeout:       300000

    },
    foundObjects: {
      url:         this.getServiceUrl("composer", ""),
      method:      "POST",
      accept:      "application/json",
      textProcess: "Загрузка данных объектов ...",
      textSuccess: null,
      textError:   this.template`Во время загрузки данных объектов произошла ошибка: ${0}.`,
      timeout:     600000
    }
  };


  
  /**
   * Поиск артефактов используя набор фильтров
   *
   * @params {Object} filter набор фильтров
   * @param {Number} page номер страницы
   * @param {Number} perPage максимальное кол-во записей на странице
   * 
   * @return {Promise}
   */
  async searchEntities(filters, page, perPage) {
    const reqData = {
      operationName: "Search",
      variables:     {
        searchPayload: filters,
        page,
        perPage
      },
      query: `query Search ($searchPayload: JSON!, $page: Int, $perPage: Int) {
  Search(
    search_payload: $searchPayload,
    page: $page,
    per_page: $perPage
  )
  {
    __typename
    ... on AisObject {
      uid
      class
      version
      representation
      members {
        uid
        kindUid
        objectId
        codeValues {
          value
          code {
            uid
            name
            template
          }
        }
        values {
          attribute {
            uid
            name
          }
          value
        }
      }
    }
    __typename
    ... on Object {
      id
      class
      version
      members {
        uid
        kindUid
        objectId
        codeValues {
          value
          code {
            uid
            name
            template
          }
        }
        values {
          attribute {
            uid
            name
          }
          value
        }
      }
    }
  }
}`
    };
    // if (page && perPage) {
    //   const reqData = {
    //     params: [page, perPage],
    //     data:   filters
    //   };
    //   return await this.request("searchEntitiesByPage", reqData);
    // } 
    // return await this.request("searchEntities", filters);
    const data = await this.request("searchEntities", reqData);
    return data;
  }

  async foundObjects(uids) {
    const reqData = {
      operationName: "Objects",
      query:         `query Objects {
      Objects(ids_and_versions: [${uids.map((item) => {
    return `{id: "${item.uid}", version: ${item.version}}`;
  })}]) {
    __typename
    ... on AisObject {
    	uid
      class
      version
      representation
      members {
        uid
        objectId
        kindUid
        kind {
          uid
          class
          name
        }
        codeValues {
          value
          code {
            uid
            name
            template
          }
        }
        values {
          attribute {
            uid
            name
          }
          value
        }
      }
    }
    __typename
    ... on Object {
      id
      class
      version
      members {
        uid
        kind {
          class
          name
        }
        codeValues {
          value
          code {
            uid
            name
            template
          }
        }
        values {
          attribute {
            uid
            name
          }
          value
        }
      }
    }
  }
}` 
    };
    const data = await this.request("foundObjects", reqData);
    return data.Objects;
  }
}

export default SearchApi;
