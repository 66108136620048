import React, { useMemo, useCallback } from "react";
import { observer } from "mobx-react";
import { Components } from "@ais3p/ui-framework";
import useStores from "~/core/utils/useStores";
import UserAvatar from "~/modules/users/components/avatar";

const User = observer(() => {
  const { accountStore, uiStore } = useStores();
  // const [isPending, setIsPending] = useState(false);
  const { isPendingTasks } = uiStore;
  const attributes = useMemo(() => {
    return {
      "data-tooltip": `Вы вошли как ${accountStore.user.shortName}. ${
        isPendingTasks ? "" : "Выйти?"
      }`,
      "data-tooltip-at": "bottom"
    };
  }, [accountStore.user && accountStore.user.shortName]);

  const onDoLogout = useCallback(async() => {
    uiStore.setConfirmPending(true);
    try {
      await accountStore.performLogout();
      uiStore.hideConfirm();
    } finally {
      uiStore.setConfirmPending(false);
    }
  }, []);

  const onCancelLogout = useCallback(() => {
    uiStore.hideConfirm();
  }, []);

  const onLogout = useCallback(() => {
    if (uiStore.canLogout) {
      uiStore.setConfirm({
        icon:    "logout-M",
        title:   "Выход из системы",
        content: "Вы действительно хотите выйти?",
        buttons: [
          <Components.Button
            key="exit"
            text="Да"
            icon="logout-M"
            onPress={onDoLogout}
            color="positive"
            // isPendingTasks - условие, чтобы у пользователя не было
            // возможности нажать `Ok`, если еще идут асинхронные запросы.
            // Такое например возможно, если пользователь делал редакцию в `InputEditor` и после
            // нажал на выход из системы. В этот момент проиcходит событие `onBlur` и идет запрос
            // на принятие редакции - T3251
            isLoading={uiStore.isPendingTasks || uiStore.confirmIsPending}
            // isLoading={isPending}
          />,
          <Components.Button
            key="cancel"
            text="Отмена"
            icon="cancel-M"
            onPress={onCancelLogout}
            isLoading={uiStore.confirmIsPending}
            color="dark"
          />
        ],
        onKeyPressEsc:   onCancelLogout,
        onKeyPressEnter: onDoLogout
      });
    }
  }, [uiStore.canLogout, uiStore.isPendingTasks, uiStore.confirmIsPending, onCancelLogout, onDoLogout]);

  return (
    <div className={"app-user"} onClick={onLogout} {...attributes}>
      <div className="app-user-avatar">
        <UserAvatar
          className="image"
          fileId={accountStore.user && accountStore.user.image}
        />
      </div>
      {!isPendingTasks && (
        <div className="logout">
          <Components.Icon
            name="logout-M"
            className={`ais-login-icon ${
              accountStore.pending ? "loading" : ""
            }`}
          />
        </div>
      )}
      {isPendingTasks && <Components.Preloader size={1} className="app-user-preloader" />}
    </div>
  );
});

export default User;
