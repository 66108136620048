import { NativeTypes } from "react-dnd-html5-backend";

/**
 * Константы для DnD сущностей в АИС
 */
const DND_NODE_TYPE = "node"; // Нода дерева в Библиотеке
const DND_ISSUE_TYPE = "issue"; // Карточка Задачи
const DND_EDITORITEM_TYPE = "editoritem"; // Сущность в текстовом представлении
const DND_FILE_TYPE =  NativeTypes.FILE; // Файл из ОС
const DND_SOURCECODE_TYPE = "sourcecode"; // Сущность в коде 
const DND_CONNECTION_TYPE = "connections"; // Связь

export {
  DND_NODE_TYPE,
  DND_ISSUE_TYPE,
  DND_EDITORITEM_TYPE,
  DND_FILE_TYPE,
  DND_SOURCECODE_TYPE,
  DND_CONNECTION_TYPE
}; 
