import React, { useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { v4 as uuid } from "uuid";
import AttrsForm from "~/modules/kindsAndAttrs/components/AttrsForm";
import { IssueModel } from "../../models";

/**
 * Панель для отображения атрибутов задачи
 * 
 * @param {Object} props набор параметров
 * @param {String} props.className пользовательский className
 * @param {String} props.title заголвоок панели
 * @param {String} props.kindUid uid Вида задачи
 * @param {IssueModel} props.issue текущая задача
 * @param {Boolean} props.isDisabled статус disabled
 * @param {Boolean} props.isView is view
 * @param {Function} props.onDeleteFile callback функция при изменении атрибутов задачи
 */
const AttributesPanel = ({
  className,
  title = "Атрибуты",
  kindUid,
  isDisabled = false,
  isView = false,
  onChange,
  issue
}) => {
  const objectUid = useMemo(() => {
    // если новая задача, то создаем временную запись задачи
    return (issue && issue.uid) || uuid();
  }, [issue]);

  const onChangeKindMember = useCallback((member) => {
    issue && issue.setKindMember(member);
    if (member && onChange) {
      onChange(member.allValues);
    }
  }, [issue]);

  return (
    <div className={classNames("k-and-a-sidepanel issue-attributes", className)}>
      <div className={"issue-attributes-title"}>
        {`${title}:`}
      </div>
      <div className="k-and-a-content">
        <div className={"related-list"}>
          <AttrsForm
            objectUid={objectUid}
            version={0}
            kindUid={kindUid}
            isView={isView}
            isDisabled={isDisabled}
            onChange={onChangeKindMember}
          />
        </div>
      </div>
    </div>
  );
};

AttributesPanel.propTypes = {
  className:  PropTypes.string,
  title:      PropTypes.string,
  kindUid:    PropTypes.string,
  issue:      PropTypes.instanceOf(IssueModel),
  isDisabled: PropTypes.bool,
  isView:     PropTypes.bool,
  onChange:   PropTypes.func
};

export default AttributesPanel;
