import { action, computed, observable } from "mobx";
import { DOMAIN_KINDS } from "../../constants/Domains";
import { CLS_LIBRARY_TEXT_VERSION } from "../../constants/Classes";

/**
 *
 * @type {AisVersion}
 */
class AisVersion {
  @observable
  uid = null;

  @observable
  domain = null;

  @observable
  version = 0;

  @observable
  pending = null;

  @observable
  pendingValidation = false;

  @observable
  objectStore = null; // ObjectStore

  @observable
  validatorsMap = new Map();
  /**
   * Конструктор
   *
   * @param {Object} params параметры объекта
   * @param {ObjectStore} objectStore хранилище
   */

  constructor(params, objectStore) {
    this.objectStore = objectStore;

    this.uid = `${params.uid}`;
    if (params.domain) {
      this.domain = params.domain;
    }
    if (params.version) {
      this.version = params.version;
    }

    this.setValidatorsMap(params.validations);
  }

  @action
  setPendingValidation(pending = false) {
    this.pendingValidation = pending;
  }

  @action
  async validate(approval, comment) {
    this.setPendingValidation(true);
    const result = await this.objectStore.validate(this.uid, this.version, this.parentUid || this.editableUid, approval, comment);
    const myValidation = this.validatorsMap.get(this.objectStore.rootStore.accountStore.uid);
    if (myValidation) {
      this.validatorsMap.set(myValidation.uid, { ...myValidation, isValidated: approval, approval: result });
    }
    this.setPendingValidation(false);
  }

  @action
  setValidatorsMap(data = {}) {
    this.objectStore.rootStore.userStore.list.forEach((user) => {
      this.validatorsMap.set(user.uid, {
        uid:         user.uid,
        email:       user.email, 
        label:       user.label, 
        image:       user.image, 
        isMe:        user.isMe,
        approval:    data[user.uid],
        isValidated: data[user.uid] && data[user.uid].state
      });
    });
  }
  /**
   * Обновить информацию о блокировке. Если передать undefined, то блокировка снимется
   *
   * @param {Object} data инфорация о блокировке
   * @param {Date} data.lockedAt  дата блокировки
   * @param {String} data.lockedBy uid пользователя, который заблокировал
   */

  @action
  setLockInfo(data) {
    this.representation.setLockInfo(data);
  }

  @action
  setPending(pending = false) {
    this.pending = pending;
  }

  @computed
  get id() {
    return `${this.uid}-${this.domain}-${this.version}`;
  }

  @computed
  get iconString() {
    if (this.hasKinds) {
      return this.kindsIconString;
    } else return null;
  }

  @computed
  get hasKinds() {
    if (
      this.object 
      && this.object.domains 
      && this.object.domains.size > 0 
      && this.kindsRepresentation 
      && this.kindsRepresentation.kindsArray
    ) {
      return !!this.kindsRepresentation.kindsArray.length;
    }
    return false;
  }
      
  @computed
  get kindsRepresentation() {
    if (
      this.object 
    && this.object.domains 
    && this.object.domains.size > 0 // INFO: это условие тут для реакции на добавление вида если еще небыло видов
    ) {
      return this.objectStore.getVersion(this.uid, DOMAIN_KINDS, this.version) || null;
    }
    return null;
  }

  @computed
  get kindsIconString() {
    return (this.hasKinds && this.kindsRepresentation && this.kindsRepresentation.iconString) || null;
  }

  @computed
  get representationId() {
    return `${this.uid}-${this.domain}`;
  }

  @computed
  get objectId() {
    return `${this.uid}`;
  }

  @computed
  get representation() {
    return this.objectStore.getRepresentation(this.representationId);
  }

  @computed
  get object() {
    return this.objectStore.getObject(this.objectId);
  }

  @computed
  get isValidatable() {
    return new Set([CLS_LIBRARY_TEXT_VERSION]).has(this.class) 
    || (this.isVersion && this.hasKinds && this.domain === "text");
  }

  @computed
  get isProcessingValidation() {
    return !this.isValidated;
  }

  @computed
  get isPending() {
    return this.pending;
  }

  @computed
  get validatorsList() {
    return Array.from(this.validatorsMap.values());
  }

  @computed
  get isValidated() {
    let isValid = true;
    let isInvalid = false;
    this.validatorsMap && this.validatorsMap.forEach((user) => {
      isValid = isValid && user.isValidated === "approved";
      if (user.isValidated === "rejected") {
        isInvalid = true;
        isValid = false;
      }
    });
    if (isInvalid) {
      return "rejected";
    }
    if (isValid) {
      return "approved";
    }
    return null;
  }

  /**
   * Класс объекта.
   */
  @computed
  get className() {
    return "AisVersion";
  }
}

export default AisVersion;
