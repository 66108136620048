import React, { useCallback,  useLayoutEffect, useRef } from "react";
import { observer } from "mobx-react";

import useStores from "~/core/utils/useStores";
import AisIcon from "~/core/components/AisIcon";
import { Components, ContextMenu } from "@ais3p/ui-framework";
import { DOMAIN_LIBRARY } from "../../../../../core/constants/Domains";

const TreeNode = observer(
  ({
    item,
    collectMenu,
    selected,
    setSelected,
    onRename,
    onDelete
  }) => {
    const { name, title, permissions, isVersion, isEditing, uid, isDeleting, isPending } = item;
    const inputEl = useRef(null);

    const { uiStore } = useStores();

    useLayoutEffect(() => {
      isEditing && inputEl && inputEl.current && inputEl.current.focus();
    }, [isEditing]);

    // const confirmContent = useMemo(()=>{

    // }, [isPending, item, name, onCancelDelete, onDeleteItem]);

    useLayoutEffect(() => {
      if (!isDeleting) {
        return;
      }
      uiStore.setConfirm({
        icon:    "delete-M",
        title:   "Удаление узла",
        content: (
          <div className="confirm-delete-content">
            Вы уверены, что хотите удалить
            <div
              className="text-content-confirm" 
            >
              <AisIcon
                className={"expander big text-content-confirm-icon"} 
                item={item}
              />{name}
            </div>?
          </div>
        ),
        buttons: [
          <Components.Button
            key="delete"
            text="Удалить"
            icon="delete-M"
            onPress={onDeleteItem}
            color="negative"
            isLoading={isPending}
          />,
          <Components.Button
            key="cancel"
            text="Отмена"
            icon="cancel-M"
            onPress={onCancelDelete}
            color="dark"
            isLoading={isPending}
          />
        ],
        onKeyPressEsc:   onCancelDelete,
        onKeyPressEnter: onDeleteItem
      });
    }, [isDeleting, isPending]);

    const rename = useCallback(() => {
      let text = inputEl && inputEl.current && inputEl.current.textContent;
      text = text.replaceAll(/\s+/gi, " ").trim();
      if (text !== "" && text !== " ") {
        item.rename(text);
        onRename && onRename(item);
      } else {
        item.revert();
      }
    }, [item, onRename]);

    const onBlur = useCallback(() => {
      rename();
    }, [rename]);

    const onNodeClick = useCallback(() => {
      setSelected(uid);
    }, [setSelected, uid]);
    const onKeyDown = useCallback((e) => {
      if (e.code === "Enter") {
        rename();
      } else if (e.code === "Escape") {
        item.revert();
      }
    }, [rename, item]);

    const onDeleteItem = useCallback(() => {
      onDelete && onDelete(item);
      uiStore.hideConfirm();
    }, [item]);

    const onCancelDelete = useCallback(() => {
      item.setDeleting(false);
      uiStore.hideConfirm();
    }, [item]);

    let content = (
      <div className="version-user">
        <div className="ais-wm-version-number">{name}</div>
      </div>
    );

    if (!isVersion) {
      if (isEditing) {
        content = (
          <div
            className="node-name"
            contentEditable={isEditing}
            onBlur={onBlur}
            onKeyDown={onKeyDown}
            ref={inputEl}
            dangerouslySetInnerHTML={{ __html: name }}
          />
        );
      } else {
        content = <div className="node-name">{name}</div>;
      }
    }

    return (
      <ContextMenu.Trigger
        menuId={DOMAIN_LIBRARY}
        collect={collectMenu}
        context={item}
      >
        <div onClick={onNodeClick} className={`node-content ${selected === uid ? "selected" : ""}`}>
          <AisIcon
            className={`expander big ${!(permissions.get("read")) ? "disabled" : "enabled"}`} 
            item={item}
          />
          <div className={`node-inner-content ${!permissions.get("read") ? "disabled" : "enabled"}`}>
            <div className="node-inner ais-wm-node-inner" title={title}>
              {content}
            </div>
          </div>
        </div>
      </ContextMenu.Trigger>
    );
  }
);

export default TreeNode;
