import { observer } from "mobx-react";
import React, { useCallback, useLayoutEffect, useMemo, useRef } from "react";
import AisIcon from "../../../core/components/AisIcon";
import Indicator from "../../../core/components/Indicator";
import PlusMenu from "./PlusMenu";
import TextItemIcon from "./TextItemIcon";

const Table = observer(({ data, renderItem, setContextMenu }) => {
  const { 
    uid, 
    caption, 
    itemsForRender, 
    idsArray, 
    columnsCount, 
    isExpanded, 
    scrollItemId, 
    isFocusUid, 
    diffClass 
  } = data;
  const captionRender = caption && renderItem(caption);

  const itemsRender = useMemo(() => {
    return itemsForRender.map((itemData) => {
      return renderItem(itemData);
    });
  }, [idsArray, itemsForRender]);

  const onClickIcon = useCallback(() => {
    data.setEditing();
  }, [data]);

  const toggleExpanded = useCallback(
    (e) => {
      e.stopPropagation();
      data.setExpanded(!isExpanded);
    },
    [isExpanded]
  );

  const onMouseOut = useCallback(() => {
    // data.setHoverRect();
  }, [data]);

  const element = useRef();

  useLayoutEffect(() => {
    if (
      uid &&
      scrollItemId &&
      uid === scrollItemId &&
      element &&
      element.current
    ) {
      setTimeout(() => {
        element.current && element.current.scrollIntoView(true);
      }, 200); // set timeout= DIRTY HACK 
      // TODO: get rid of setTimeout()
    }
  }, [uid, scrollItemId, element && element.current]);

  return (
    <div
      ref={element} 
      className={`table element ${diffClass}`} 
      onMouseLeave={onMouseOut}
      id={uid}
    >
      <div className={`item-holder  ${isFocusUid ? "focus" : ""}`}>
        <Indicator data={data} />
        <AisIcon
          onClick={toggleExpanded}
          icon={"plus-M"}
          className={`expand-menu ${isExpanded ? "expanded" : ""}`}
        />
        <TextItemIcon setContextMenu={setContextMenu} onClick={onClickIcon} data={data} />
        <div className="table-holder">
          {caption && captionRender}
          <div className="table-body-holder">
            <div
              className="table-body"
              style={{
                gridTemplateColumns: `repeat(${columnsCount}, 1fr)`
              }}
            >
              {itemsRender}
            </div>
          </div>
        </div>
      </div>
      {isExpanded && <PlusMenu data={data} />}
    </div>
  );
});

export default Table;
