import React, { useCallback, useState, useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import FormTitle from "./FormTitle";
import FieldItem from "./FieldItem";

import Kind from "~/modules/kindsAndAttrs/models/Kind";
import SearchStore from "../../stores/searchStore";

/**
 * @component
 * 
 * Форма для задания вида и аттрибутов для поиска сущности АИС по их значениям
 * 
 * @param {Object} params набор передаваемых параметров для формы 
 * @param {Kind} params.kind выбранный вид
 * @param {Object} params.values набор значение поля код
 * @param {SearchStore} params.store хранилище
 * @param {Function} params.onChange callback функция при изменнении значения код
 * 
 * @returns 
 */
const AttrsForm = ({ kind, values = {}, store, onChange }) => {
  const attributes = useMemo(() => {
    if (!kind) {
      return [];
    }
    
    return kind.attributes || [];
  }, [kind]);

  const [isExpanded, setIsExpanded] = useState(attributes.length > 0);
  
  useEffect(() => {
    setIsExpanded(attributes.length > 0);
  }, [attributes.length]);

  const onToggleExpanded = useCallback(() => {
    setIsExpanded((state) => {
      return !state;
    });
  }, []);

  
  return (
    <div className="filter-form">
      <FormTitle 
        title="Атрибуты"
        isExpanded={isExpanded}
        onToggleExpanded={onToggleExpanded}
      />
      {isExpanded && (
        <div className="filter-form-body">
          {
            attributes.map((attr) => {
              return (
                <FieldItem 
                  key={attr.uid}
                  uid={attr.uid}
                  label={attr.name}
                  value={values[attr.uid]}
                  isMultiple={attr.isMultiple} 
                  type={attr.type}
                  options={attr.opts}
                  store={store}
                  onChange={onChange}
                />
              );
            })
          }
        </div>        
      )}
    </div>
  );
};

AttrsForm.propTypes = {
  kind:     PropTypes.instanceOf(Kind),
  values:   PropTypes.object, 
  store:    PropTypes.instanceOf(SearchStore), 
  onChange: PropTypes.func
};

export default AttrsForm;
