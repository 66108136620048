import React, { useMemo, Fragment, useCallback } from "react";
import { observer } from "mobx-react";
import { ContextMenu } from "@ais3p/ui-framework";

import useStores from "~/core/utils/useStores";
import AisIcon from "~/core/components/AisIcon";
import AisObject from "~/core/components/AisObject";

const RelationItem = observer(({ item, kind, onDeleteRelation }) => {
  const { relationStore, objectStore } = useStores();
  const { isStart } = item;
  const { start, end } = kind;

  const object = useMemo(() => {
    const { uid, domain, version } = item;
    return objectStore.getVersion(uid, domain, version);
  }, [item]);

  const onHandleDeleteRelation = useCallback(() => {
    onDeleteRelation && onDeleteRelation(item, kind);
  }, [kind, item, onDeleteRelation]);

  if (!object) {
    <div className="item" data-tooltip={isStart ? start : end}>
      <AisIcon icon={isStart ? "arrow-left-M" : "arrow-right-M"} />
      <AisObject
        object={object} withVersion={true} store={relationStore}
        className={"linked-object"}
      />
      <AisIcon
        icon={"delete-M"} className="delete" data-tooltip="Удалить связь"
        onClick={onHandleDeleteRelation}
      />
    </div>;
  }
  return (
    <Fragment>
      <ContextMenu.Trigger
        menuId={"relations-sidepanel"}
        context={{ item, kind }}
      >
        <div className="item" data-tooltip={isStart ? start : end}>
          <AisIcon icon={isStart ? "arrow-left-M" : "arrow-right-M"} />
          <AisObject
            object={object} 
            withVersion={true} 
            store={relationStore}
            className={"linked-object"}
          />
          <AisIcon
            icon={"delete-M"}
            className="delete"
            data-tooltip="Удалить связь"
            onClick={onHandleDeleteRelation}
          />
        </div>
      </ContextMenu.Trigger>
    </Fragment>
  );
});

export default RelationItem;
