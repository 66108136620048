import { observer } from "mobx-react";
import React, { useCallback, useLayoutEffect, useMemo, useRef } from "react";

import AisIcon from "~/core/components/AisIcon";
import Indicator from "../../../core/components/Indicator";
import PlusMenu from "./PlusMenu";
import TextItemIcon from "./TextItemIcon";

const Rubric = observer(({ data, renderItem, setContextMenu }) => {
  const { uid, caption, number, isExpanded, captionId, scrollItemId, isFocusUid } = data;

  const captionRender = useMemo(() => {
    return caption && renderItem(caption);
  }, [caption]);

  const onClickIcon = useCallback(() => {
    if (data.captionId) {
      data.setEditing();
    } else {
      data.createCaption();
    }
    data.setFocusUid();
  }, [data, captionId]);

  const onCreateCaption = useCallback(() => {
    data.createCaption();
  }, [uid]);

  const toggleExpanded = useCallback(
    (e) => {
      e.stopPropagation();
      data.setExpanded(!isExpanded);
    },
    [isExpanded]
  );

  const element = useRef();

  useLayoutEffect(() => {
    if (
      uid &&
      scrollItemId &&
      uid === scrollItemId &&
      element &&
      element.current
    ) {
      setTimeout(() => {
        element.current && element.current.scrollIntoView(true);
      }, 200); // set timeout= DIRTY HACK 
      // TODO: get rid of setTimeout()
    }
  }, [uid, scrollItemId, element && element.current]);

  return (
    <div ref={element} className={"rubric element"} id={uid}>
      <div className={`item-holder  ${isFocusUid ? "focus" : ""}`}>
        <Indicator data={data} />
        <AisIcon
          onClick={toggleExpanded}
          icon={"plus-M"}
          className={`expand-menu ${isExpanded ? "expanded" : ""}`}
        />
        <TextItemIcon onClick={onClickIcon} setContextMenu={setContextMenu} data={data} />
        <div className="rubric-body">
          <div className="index-number">{number}</div>
          <div className="caption">
            {captionId && caption ? (
              captionRender
            ) : (
              <div onClick={onCreateCaption} className="placeholder">
                Заголовок
              </div>
            )}
          </div>
        </div>
      </div>
      {isExpanded && <PlusMenu data={data} />}
    </div>
  );
});

export default Rubric;
