import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import { observer } from "mobx-react";
import { Components } from "@ais3p/ui-framework";

import UserApi from "../../api/usersApi";
import useStores from "~/core/utils/useStores";

import "./css/avatar.scss";

/**
 * Компонент для отображения аватарки пользователя из файлового хранилища АИС ППП
 */
const UserAvatar = observer(
  ({
    /**
     * id файла в файловом ханилище АИСППП
     *
     * @type {String}
     */
    fileId,

    /**
     * Пользовательский className
     *
     * @type {String}
     */
    className,

    /**
     * alt текст для img объекта
     *
     * @type {String}
     */
    title,

    /**
     * Путь до дефолтного файла, который подставляется, если не задан fileId или не произошла
     * загрузка файла из хранилища
     *
     * @type {String}
     */
    defaultFile = "/images/user.png",

    /**
     * Callback ф-я на загрзку файла из хранилища
     *
     * @type {Function}
     */
    onLoad
  }) => {
    const { rootStore, uiStore } = useStores();
    const api = new UserApi(rootStore);
    const [file, setFile] = useState(defaultFile);
    const [isLoading, setIsLoading] = useState(false);
    const isMounted = useRef(false);

    useEffect(() => {
      isMounted.current = true;
      return () => {
        isMounted.current = false;
      };
    }, []);

    useEffect(() => {
      if (fileId) {
        loadFile(fileId);
      } else {
        setFile(defaultFile);
      }
    }, [fileId]);

    const loadFile = async(fileId) => {
      isMounted.current && setIsLoading(true);
      try {
        let file = uiStore.getUserAvatar(fileId);
        // если file === true, значит уже был вызван метод для загрузки изображения другой копмонентой.
        // Необходимо подожать немного. Для этого повторно через секунду сделаем повторный вызов метод загрузки.
        if (file === true) {
          setTimeout(() => {
            loadFile(fileId);
          }, 1000);
          return;
        }
        // Если file не определен, то значит загрузки файла с фалового хранилища еще не было.
        // Загружаем его и потом локальный путь добавлем в список загруженных в кэш аватаров.
        if (!file) {
          // Перед началой загрузкой аватара, выставляем значение true, чтобы другим комопнентам с автаркой сообщить,
          // что изображение начали скачивать и нужно немного подождать.
          uiStore.addUserAvatar(fileId, true);
          try {
            file = await api.getUserAvatar(fileId);
          } catch (ex) {
            console.error(ex);
          }

          uiStore.addUserAvatar(fileId, file);
        }

        isMounted.current && setFile(file);
        if (onLoad) {
          onLoad(file);
        }
      } catch (e) {
        onError(e.message);
      } finally {
        isMounted.current && setIsLoading(false);
      }
    };

    const onError = (msg) => {
      rootStore.onError(
        `Во время загрузки изображения произошла ошибка: ${msg}`
      );
    };

    return (
      <div className={classNames("user-avatar", className)}>
        {isLoading && (
          <div className="user-avatar-preloader">
            <Components.Preloader size={1} />
          </div>
        )}
        {!isLoading && file && (
          <img
            src={file}
            className={"user-avatar-image"}
            alt={title}
            // onLoad={onLoad}
            // onError={onLoad}
          />
        )}
      </div>
    );
  }
);

export default UserAvatar;
