import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";

import { Components } from "@ais3p/ui-framework";
import Target from "~/core/components/Target";

import { UndefinedUid, NoRepresantation } from "../components/common";
import { IssuesContent } from "../components/issues";
import useStores from "~/core/utils/useStores";

import "./css/issues.scss";
import IssueStore from "../stores/IssueStore";

/**
 * Инструмент для работы с задачами СП и ЗИ
 * 
 * @param {Object} props набор параметров
 * @param {LayoutStore} params.layoutStore хранилище для работы с Layout
 * @param {LayoutItem} params.layoutItem сущность в Layout
 * @param {Boolean} params.isTracking признак отслеживания в Layout
 * @param {String} params.id id сущности, для которой октрылась вкладка в Layout. В нашем случае это uid проекта,
 *  для которого нужно отобразить список задач
 * @param {Boolean} params.hasTarget 
 * @param {String} params.tabId id вкладки в Layout
 * @param {Object} params.trackedItem  данные об отслеживаемом объекте в Layout
 * @param {Object} params.isSubVisible набор отображения боковой доп панели - тип панели:boolean
 * @param {String} params.focusUid если задан focusUid, значит происходит переход к задаче по ссылке
 */
const IssuesTool = observer((props) => {
  const {
    layoutItem,
    isTracking,
    id,
    hasTarget,
    tabId,
    trackedItem,
    layoutStore,
    isSubVisible,
    focusUid
  } = props;

  const { rootStore, kindsStore } = useStores();

  const [isPending, setIsPending] = useState(false);
  const [projectUid, setProjectUid] = useState();
  
  const store = useMemo(() => {
    const store = new IssueStore({ rootStore });
    return store;
  }, []);

  useEffect(() => {
    init();
  }, [id, focusUid]);

  const init = async() => {
    setIsPending(true);
    try {
      let projectUid; 
      let kindMember;
      if (id) {
        if (!focusUid) {
          const res = await store.checkProjectsLinks(id);
          kindMember = kindsStore.getKindMemberSync(id);
          if (res) {
            // значит проект в Redmine  уже заведен
            setProjectUid(id);
            projectUid = id;
          } else if (!!id) {
            // если проект с Redmine еще не заведен, то создаем новый проект
            if (kindMember && kindMember.item) {
              const uid = await store.createRedmineProject(id, kindMember.item.name); 
              if (!!uid) {
                projectUid = uid;
                setProjectUid(uid);
              }
            }
          }
        } else {
          setProjectUid(undefined);
        }
      }
      // загружаем необхоимые словари данных - трекеры, статусы и тп
      await store.init();
      
      // теперь проверяем, что все необходимые трекеры связаны с проектом
      if (projectUid && kindMember) {
        const allowedKindUids = store.trackerList
          .filter((tracker) => {
            const kind = kindsStore.getKind(tracker.uid);
            return kindMember && kindMember.allowedTasks.has(kind.name);
          }).map((tracker) => {
            return tracker.uid;
          });
        await store.checkProjectTrackers(projectUid, allowedKindUids);
      }
    } finally {
      setIsPending(false);
    }
  };

  const targetIsIssue = !!focusUid;
  const noRepresantation = !isPending && !projectUid && !targetIsIssue;
  const showContent = useMemo(() => {
    return !!id;
  }, [projectUid, isTracking, hasTarget]);

  const target = useMemo(() => {
    return trackedItem && <Target trackedItem={trackedItem} />;
  }, [trackedItem, id]);

  const permIssues = useMemo(() => {
    // const { permissions } = accountStore;
    // const allowedObjects = permissions.get("object");
    // const permIssues = allowedObjects.get("tasks.Tracker");

    // TODO: После того как в конфигурации системы данный инструмент будет прописан в permissions
    // для пользователей, то нужно будет проверять права - раскоментировать предыдущие три строчки
    const perms = new Map();
    return perms.set("write", true);
  }, []);

  return (
    <div className="issues-tool">
      {(!showContent || noRepresantation) && !targetIsIssue && (
        <Components.ToolBar />
      )}
      {!(showContent && (!!projectUid || targetIsIssue)) && target}
      <div className="issues-tool-content">
        {noRepresantation && <NoRepresantation />}
        {!showContent && <UndefinedUid />}
        {isPending && <Components.Preloader size={3} className={"issues-preloader"} />}
        {showContent && (!!projectUid || targetIsIssue) && (
          <IssuesContent
            store={store}
            trackedItem={trackedItem}
            projectUid={projectUid}
            issueUid={targetIsIssue ? focusUid : undefined}
            canBeEditable={permIssues && permIssues.has("write")}
            tabId={tabId}
            layoutStore={layoutStore}
            layoutItem={layoutItem}
            isSubVisible={isSubVisible}
          />
        )}
      </div>
    </div>
  );
});

IssuesTool.propTypes = {
  layoutItem:   PropTypes.object,
  isTracking:   PropTypes.bool,
  id:           PropTypes.string,
  hasTarget:    PropTypes.bool,
  tabId:        PropTypes.string,
  trackedItem:  PropTypes.object,
  layoutStore:  PropTypes.object,
  isSubVisible: PropTypes.object,
  focusUid:     PropTypes.string
};


export default IssuesTool;
