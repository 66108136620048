import React, { useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import FormTitle from "./FormTitle";
import FieldItem from "./FieldItem";

import Kind from "~/modules/kindsAndAttrs/models/Kind";
import SearchStore from "../../stores/searchStore";


/**
 * @component
 *
 * Форма для задания кода для поиска сущности АИС по ее коду
 *
 * @param {Object} params набор передаваемых параметров для формы 
 * @param {Kind} params.kind выбранный вид
 * @param {Object} params.values набор значение поля код
 * @param {SearchStore} params.store хранилище
 * @param {Function} params.onChange callback функция при изменнении значения код
 *
 * @returns
 */
const CodeForm = ({ kind, values = {}, store, onChange }) => {
  const codes = useMemo(() => {
    if (!kind) {
      return [];
    }

    return kind.codesArray || [];
  }, [kind]);

  const [isExpanded, setIsExpanded] = useState(codes.length > 0);

  useEffect(() => {
    setIsExpanded(codes.length > 0);
  }, [codes.length]);

  const onToggleExpanded = useCallback(() => {
    setIsExpanded((state) => {
      return !state;
    });
  }, []);

  return (
    <div className="filter-form">
      <FormTitle
        title="Коды"
        isExpanded={isExpanded}
        onToggleExpanded={onToggleExpanded}
      />
      {isExpanded && (
        <div className="filter-form-body">
          {codes.map((code) => {
            return (
              <FieldItem
                key={code.uid}
                uid={code.uid}
                label={code.name}
                value={values[code.uid]}
                placeholder={code.template}
                store={store}
                onChange={onChange}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

CodeForm.propTypes = {
  kind:     PropTypes.instanceOf(Kind),
  values:   PropTypes.object, 
  store:    PropTypes.instanceOf(SearchStore), 
  onChange: PropTypes.func
};


export default CodeForm;
