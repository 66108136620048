import React, { useCallback, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Components } from "@ais3p/ui-framework";
import { observer } from "mobx-react";
import InfoToolWindow from "~/core/components/InfoToolWindow/InfoToolWindow.jsx";
import infoToolContent from "~/core/components/InfoToolWindow/infoToolContent.js";
import SearchStore from "../../stores/searchStore";


/**
 * @component
 * 
 * Toolbar для поиска объектов АИС
 * 
 * @param {Object} params набор параметров
 * @param {SearchStore} params.store хранилизе для поиска
 */
const SearchToolbar = observer(({ store }) => {
  const [infoIsVisible, setInfoIsVisible] = useState(false);

  const toggleShowFilters = useCallback(() => {
    store.toggleShowFilters();
  }, []);

  const onToggleInfoModal = useCallback(() => {
    setInfoIsVisible(!infoIsVisible);
  }, [infoIsVisible]);


  const right = useMemo(() => {
    return [
      (
        <Components.Button
          key="filter"
          icon="filiter-M"
          tooltip="Фильтр"
          isSelected={store.isShownFilters}
          onPress={toggleShowFilters}
        />
      ),
      (<Components.Spacer key="right-spacer-2" />),
      (
        <Components.Button
          key={"info-M"} icon="info-M" tooltip={"Справка"}
          onPress={onToggleInfoModal}
          isSelected={infoIsVisible}
        />
      )
    ];
  }, [store.isShownFilters]);

  return (
    <>
      <Components.ToolBar right={right} />
      {infoIsVisible && (
        <InfoToolWindow 
          content={infoToolContent.searchEntities}
          infoIsVisible={infoIsVisible}
          toggleInfoModal={onToggleInfoModal}
        />
      )}
    </>
  );
});

SearchToolbar.propTypes = {
  store: PropTypes.instanceOf(SearchStore) 
};

export default SearchToolbar;
