import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { Components } from "@ais3p/ui-framework";
import useStores from "~/core/utils/useStores";
import AisIcon from "~/core/components/AisIcon";

import AttrsForm from "./AttrsForm";
import KindMember from "../models/KindMember";
import KindItem from "../models/KindItem";

/**
 * @component
 * 
 * Отображение набора атрибутов участника Вида
 * 
 * @param {KindMember} member участник Вида
 * @param {KindItem} kindItem представитель Вида
 */
const KindGroup = observer(({ member, kindItem }) => {
  const { kind, edited, item } = member;

  const { uiStore, accountStore } = useStores();
  if (!kind || !kindItem) {
    return null;
  }
  const { name, id } = kind;
  const [isExpanded, setIsExpanded] = useState(true);
  const toggleExpanded = useCallback(() => {
    setIsExpanded(!isExpanded);
  }, [isExpanded, setIsExpanded]);

  const onDoDeleteKind = useCallback(async() => {
    uiStore.setConfirmPending(true);
    try {
      await member.delete();
      uiStore.hideConfirm();
    } finally {
      uiStore.setConfirmPending(false);
    }
  }, [member]);

  const onCancelDeleteKind = useCallback(() => {
    uiStore.hideConfirm();
  }, []);

  const onDeleteKind = useCallback(() => {
    uiStore.setConfirm({
      icon:    "delete-M",
      title:   "Удаление вида",
      content: `Вы уверены что хотите удалить вид "${name}"?`, 
      buttons: [
        <Components.Button
          key="delete"
          text="Удалить"
          icon="delete-M"
          onPress={onDoDeleteKind}
          color="negative"
        />,
        <Components.Button
          key="cancel"
          text="Отмена"
          icon="cancel-M"
          onPress={onCancelDeleteKind}
          color="dark"
        />
      ],
      onKeyPressEsc:   onCancelDeleteKind,
      onKeyPressEnter: onDoDeleteKind
    });
  }, [member, name]);

  const onSave = useCallback(async() => {
    await member.save();
  }, [member]);

  const onRevert = useCallback(async() => {
    item && item.revert();
  }, [item]);

  return (
    <div className="kind-group">
      {member.pending && 
        <div className="preload-wrapper"><Components.Preloader size={3} /></div>
      }
      <div className="item-header">
        <div className="item-name">
          <Components.Icon name={accountStore.getIcon(name)} />
          <span>{name}</span>
        </div>
        {isExpanded && (
          <AisIcon onClick={toggleExpanded} icon={"chevron-up-M"} />
        )}
        {!isExpanded && (
          <AisIcon onClick={toggleExpanded} icon={"chevron-down-M"} />
        )}
        <div className="spacer"></div>
        {!!edited && !kindItem.version && (
          <AisIcon
            onClick={onSave}
            data-tooltip="Сохранить"
            className="save"
            icon={"save-M"}
          />
        )}
        {!!edited && !kindItem.version && (
          <AisIcon
            onClick={onRevert}
            data-tooltip="Отменить изменения"
            className="revert"
            icon={"rollback-M"}
          />
        )}
        {!edited && !kindItem.version && (
          <AisIcon
            onClick={onDeleteKind}
            className="delete"
            data-tooltip="Удалить вид"
            icon={"delete-M"}
          />
        )}
      </div>
      {isExpanded && (
        <div className="related-list">
          <AttrsForm
            isView={!!kindItem.version}
            isDisabled={false}
            kindItem={kindItem}
            kindUid={id}
          />
        </div>
      )}
    </div>
  );
});

KindGroup.propTypes = {
  member:   PropTypes.instanceOf(KindMember).isRequired,
  kindItem: PropTypes.instanceOf(KindItem).isRequired
};

export default KindGroup;
